import React, { useState, useEffect, memo, useMemo } from "react"
import { ActionCableConsumer } from "react-actioncable-provider"
import { useMutation } from "react-query"
import { mutationRequest } from "../../util/request"
import { Spinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import UserAvatar from "../shared/Header/UserAvatar"
import { AuthenticationDialog } from "../shared/AuthenticationDialog"
import CountdownBlock from "../shared/CountdownBlock"
import LinkifyText from "../shared/LinkifyText"
import { DateDifferenceInWords } from "../../util/dates"
import { isMobile } from "react-device-detect"
import { ReactComponent as SendArrow } from "../../icons/sendArrow.svg"
import { ReactComponent as ApplauseButton } from "../../icons/applause.svg"
import "./Chat.scss"
import { GetRandomInt } from "../../util/numbers"
import { useChats } from "../../hooks/useChats"
import TaggableInput from "../shared/fields/TaggableInput"

const ChatRoom = ({
  bandId,
  bandName,
  user,
  totalHeight,
  className,
  standAlone = false,
}) => {
  return (
    <div id="chat-room-container" className={className}>
      <div className="bottom-0 w-full pr-1 flex flex-col">
        <ChatContent
          userId={user?.id}
          bandId={bandId}
          bandName={bandName}
          totalHeight={totalHeight}
          standAlone={standAlone}
        ></ChatContent>
      </div>
    </div>
  )
}

const ChatContent = ({ userId, bandId, bandName, totalHeight, standAlone }) => {
  totalHeight = isMobile ? window.innerHeight : totalHeight
  const chatInputHeight = 53
  const chatIconHeight = 24
  const chatContentHeight = isMobile
    ? (totalHeight - chatInputHeight) * (standAlone ? 0.99 : 0.4)
    : totalHeight - chatInputHeight

  const [_chats, setChats] = useState([])
  const addChat = chat => setChats(allChats => [...allChats, chat])

  const chats = useMemo(() => _chats, [_chats])

  const { status, data, error, isError } = useChats(bandId)

  useEffect(() => {
    if (status === "success") {
      setChats(data.chats)
      scrollToBottomDelayed()
    }
  }, [status])

  const handleReceivedMessage = receivedMessage => {
    const { action, resource } = receivedMessage
    if (action === "chat" && !chats.some(el => el.id === resource.id)) {
      addChat(resource)
      scrollToBottomDelayed()
    }
  }

  const chatChannelDisconnected = error => {
    console.error("ChatChannel disconnected: ", error)
  }

  const userChannelDisconnected = error => {
    console.error("UserChannel disconnected: ", error)
  }

  return (
    <>
      {status === "loading" ? (
        <Spinner />
      ) : status === "error" ? (
        <RequestError error={error} />
      ) : data ? (
        <>
          <ActionCableConsumer
            channel={{ channel: "ChatChannel", id: bandId }}
            onReceived={handleReceivedMessage}
            onDisconnected={chatChannelDisconnected}
          />
          {userId ? (
            <ActionCableConsumer
              channel={{ channel: "UserChannel", id: userId }}
              onReceived={handleReceivedMessage}
              onDisconnected={userChannelDisconnected}
            />
          ) : null}

          <div
            id="chat-area"
            className="pb-2 overflow-y-auto text-base"
            style={{ height: chatContentHeight }}
          >
            {chats.length > 0
              ? chats.map(chat => <ChatMessage key={chat.id} chat={chat} />)
              : null}
          </div>

          <div
            id="chat-input-area"
            style={{ height: chatInputHeight, maxHeight: chatInputHeight }}
            className="text-base"
          >
            <SubmitChatComponent
              userId={userId}
              bandId={bandId}
              chatInputHeight={chatInputHeight}
              chatIconHeight={chatIconHeight}
              bandName={bandName}
              addChat={addChat}
            />
          </div>
        </>
      ) : null}
    </>
  )
}

const scrollToBottom = e => {
  var el = document.getElementById("chat-area")
  if (el) el.scrollTop = el.scrollHeight + 100
}

const scrollToBottomDelayed = () => {
  setTimeout(() => scrollToBottom(), 200)
}

const SubmitChatComponent = ({
  userId,
  bandId,
  chatInputHeight,
  chatIconHeight,
  bandName,
  addChat,
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [messageText, setMessageText] = useState("")
  const [userTags, setUserTags] = useState([])
  const mutation = useMutation(mutationRequest)
  const commandRegex = /^\//
  const submitApplause = async e => {
    const applauseOptions = ["👏👏👏", "👏🤘👏🤘👏", "🎉🤘🤘🎉"]
    submitChatMessage(e, applauseOptions[GetRandomInt(0, 2)], true)
  }

  const submitChatMessage = async (
    e = null,
    message = null,
    isApplause = false,
  ) => {
    message = message ? message : messageText
    if (e) e.preventDefault()
    if (commandRegex.test(message)) {
      runChatCommand(e, message)
    } else {
      if ((message && message.trim().length > 0) || isApplause) {
        mutation.mutate({
          options: { method: "POST" },
          path: `/api/v1/bands/${bandId}/chats`,
          data: {
            chat: { message: message, is_applause: isApplause },
          },
        })
      }
    }
    setUserTags([])
    setMessageText("")
  }

  const runChatCommand = (e, message) => {
    const [command, args] = message.split("/")[1].split(" ")
    if (command === "beer") {
      addChat({
        chat_type: "command_chat",
        command: "beer",
        response: "throwing beer for you righteous one...",
      })
      const beerOptions = [
        "https://bandnada.s3.amazonaws.com/static/gifs/workaholics.gif",
        "https://bandnada.s3.amazonaws.com/static/gifs/movie_1.gif",
        "https://bandnada.s3.amazonaws.com/static/gifs/bush_show_throw.gif",
        "https://bandnada.s3.amazonaws.com/static/gifs/bottle_smash.gif",
        "https://bandnada.s3.amazonaws.com/static/gifs/beer_toss.gif",
        "https://bandnada.s3.amazonaws.com/static/gifs/backfire_axe.gif",
      ]
      const choice = GetRandomInt(0, beerOptions.length - 1)
      const text =
        choice === beerOptions.length - 1
          ? "~$$~threw a beer...but it backfired~$$~"
          : "~$$~throws a beer~$$~"
      submitChatMessage(e, `${text} ${beerOptions[choice]}`, false)
      scrollToBottomDelayed()
    } else {
      addChat({
        chat_type: "command_chat",
        command: command,
        response: `Sorry, I don't do ${command}`,
      })
      scrollToBottomDelayed()
    }
  }

  const submitOnEnter = currentMessageText => {
    let lastKey = currentMessageText.slice(-1)
    if (lastKey === "\n") {
      submitChatMessage()
    } else {
      setMessageText(currentMessageText)
    }
  }

  return (
    <>
      {userId ? (
        <div className="w-full">
          <div className="flex w-full">
            <div className="mr-2 relative flex-1">
              <TaggableInput
                value={messageText}
                onChange={submitOnEnter}
                placeholder="Your chat here"
                className="chat"
              />
              <div
                className="absolute right-0 top-0 flex items-center pr-2"
                style={{
                  height: chatInputHeight,
                  maxHeight: chatInputHeight,
                }}
              >
                <button
                  style={{
                    height: chatIconHeight,
                    maxHeight: chatIconHeight,
                  }}
                  className="text-center rounded mr-2 focus:outline-none"
                  onClick={e => submitApplause(e)}
                >
                  <ApplauseButton className="m-auto w-6 h-6 text-gray-400 hover:text-gray-600" />
                </button>
              </div>
            </div>

            <button
              style={{
                height: chatInputHeight,
                maxHeight: chatInputHeight,
                width: chatInputHeight,
              }}
              onClick={e => submitChatMessage(e)}
              className="rounded text-center focus:outline-none bg-green-400 hover:bg-green-500 "
            >
              <SendArrow className="mx-auto text-white h-10 w-10" />
            </button>
          </div>
        </div>
      ) : (
        <>
          <textarea
            style={{
              height: chatInputHeight,
              maxHeight: chatInputHeight,
            }}
            className={`border-2 rounded chat-input py-2 pl-3 pr-24 w-full right-0 focus:outline-none`}
            placeholder="You must be logged in to chat"
            onFocus={e => setShowDialog(true)}
          ></textarea>
          {showDialog ? (
            <AuthenticationDialog
              explanation={`You must first sign up or log in to chat with ${bandName}`}
            />
          ) : null}
        </>
      )}
    </>
  )
}

const ChatMessage = ({ chat }) => {
  const PureChatPublicMessage = memo(ChatPublicMessage)
  const PureChatTipMessage = memo(ChatTipMessage)
  return (
    <>
      {chat.chat_type === "tip_chat" ? (
        <PureChatTipMessage message={chat.message} />
      ) : chat.chat_type === "user_only_chat" ? (
        <ChatUserOnlyMessage chat={chat} />
      ) : chat.chat_type === "command_chat" ? (
        <ChatCommandResponse chat={chat} />
      ) : (
        <PureChatPublicMessage
          userUrl={chat.user_path}
          username={chat.username}
          message={chat.message}
          createdAt={chat.created_at}
          userAvatarURL={chat.user_avatar_url}
          isApplause={chat.is_applause}
          userId={chat.user_id}
        />
      )}
    </>
  )
}

const ChatPublicMessage = ({
  userUrl,
  username,
  userId,
  message,
  createdAt,
  userAvatarURL,
  isApplause,
}) => {
  return (
    <div className="grid grid-cols-12 pb-2 chat-public-message">
      <div className="flex flex-row col-span-12">
        <div className="mr-3" style={{}}>
          <UserAvatar
            handleClick={() => {}}
            user={{ id: userId, avatar_url_small: userAvatarURL }}
            inHeader={false}
            size="small"
          />
        </div>
        <div className="flex flex-col justify-between">
          <span className="flex flex-row">
            {userUrl ? (
              <a
                href={userUrl}
                target="_blank"
                rel="noreferrer"
                title={username}
                className="font-bold truncate username-text"
              >
                {username}
              </a>
            ) : (
              <span
                title={username}
                className="font-bold truncate username-text"
              >
                {username}
              </span>
            )}
            <div className="date-text">
              {createdAt ? (
                <div className="text-xxs ml-2" style={{}}>
                  {CountdownBlock(createdAt, "align-middle")}
                </div>
              ) : null}
            </div>
          </span>
          <div
            className={`chat-text leading-tight ${
              isApplause ? "text-2xl" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: LinkifyText(message) }}
          />
        </div>
      </div>
    </div>
  )
}

const ChatCommandResponse = ({ chat }) => {
  return (
    <div className="grid grid-cols-12 pb-2">
      <div className="col-span-12 bg-blue-200 text-gray-700 text-left italic px-3 py-1">
        {chat.response}
      </div>
    </div>
  )
}

const ChatTipMessage = ({ message }) => {
  return (
    <div className="grid grid-cols-12 pb-2">
      <div className="col-span-12 bg-yellow-500 text-white text-center font-bold py-1">
        {message}
      </div>
    </div>
  )
}

const ChatUserOnlyMessage = ({ chat }) => {
  const [disableButtons, setDisbaleButtons] = useState(false)
  const mutation = useMutation(mutationRequest)
  const submitChatResponse = async (e, responseType) => {
    e.preventDefault()
    setDisbaleButtons(true)
    mutation.mutate({
      options: { method: "PUT" },
      path:
        responseType === "positive"
          ? chat.positive_response_option_url
          : chat.negative_response_option_url,
    })
  }

  return (
    <>
      <div className="grid grid-cols-12 pb-2 bg-blue-200">
        <div className="col-span-4 text-gray-800">
          <UserAvatar
            handleClick={() => {}}
            user={{ id: chat.user_id, avatar_url_small: chat.user_avatar_url }}
            inHeader={false}
            size="small"
          />
          {chat.user_path ? (
            <a
              href={chat.user_path}
              target="_blank"
              rel="nerefferer"
              className="font-bold"
            >
              {chat.username}
            </a>
          ) : (
            chat.username
          )}
        </div>
        <div className="col-span-6 text-gray-900 leading-tight">
          <span className="italic">{chat.message}</span>
          {chat.positive_response_option_url &&
            chat.positive_response_option_text && (
              <button
                disabled={disableButtons}
                onClick={e => submitChatResponse(e, "positive")}
                className="font-bold ml-3"
              >
                {chat.positive_response_option_text}
              </button>
            )}
          {chat.negative_response_option_url &&
            chat.negative_response_option_text && (
              <button
                disabled={disableButtons}
                onClick={e => submitChatResponse(e, "negative")}
                className="font-bold ml-3"
              >
                {chat.negative_response_option_text}
              </button>
            )}
          {chat.action_url && chat.action_text && (
            <a href={chat.action_url} className="font-bold ml-3">
              {chat.action_text}
            </a>
          )}
        </div>
        <div className="col-span-2 text-gray-400">
          {chat.created_at ? (
            <div className="text-right text-xs">
              {
                DateDifferenceInWords(new Date(chat.created_at), new Date())
                  .fullText
              }
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default ChatRoom
