import React, { useContext } from "react"
import Header from "../shared/Header/Header"
import { FeedSpinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { Followers } from "./Followers"
import { UserInteractions } from "./UserInteractions"
import { Payouts } from "./Payouts"
import { Broadcasts } from "./Broadcasts"
import style from "../shared/PageStyle.module.css"
import { useBand } from "../../hooks/useBand"
import { UserContext } from "../HomeApp"
import Footer from "../shared/Footer/Footer"

export const BandDashboardScreen = ({ bandId }) => {
  const { user } = useContext(UserContext)
  const bandData = useBand(bandId, null, "")
  var band = bandData?.data?.band

  return (
    <div className={style.rootElStyle}>
      <Header user={user} />
      <div className="grid grid-cols-12 w-full pb-2 px-4">
        {bandData.status === "loading" ? (
          <FeedSpinner />
        ) : bandData.status === "error" ? (
          <RequestError error={bandData.error} />
        ) : (
          <div className="col-span-12">
            <h1 className="border-b-4 mt-4 border-black font-bold mb-8 text-3xl uppercase">
              Dashboard
            </h1>

            <Followers bandId={band.id} />
            <UserInteractions bandId={band.id} />
            <Payouts
              bandId={band.id}
              payeeId={band?.payee_id}
              className="pr-2 mb-12"
            />
            <Broadcasts bandId={band.id} className="pr-2" />
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default BandDashboardScreen
