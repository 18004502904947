import React, { useState } from "react"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const WebsiteUrl = ({ band }) => {
  const [websiteUrl, setWebsiteUrl] = useState(band.website_url || "")

  const fieldData = {
    field: "website_url",
    name: "websiteUrl",
    label: "Your website page url",
    placeholder: "The url of your website",
    required: false,
  }

  return (
    <EditBandFormTextField
      fieldData={fieldData}
      value={websiteUrl}
      setValue={setWebsiteUrl}
      bandId={band.id}
    />
  )
}
