import React, { useContext, useEffect, useState } from "react"
import { useBnStats } from "../../hooks/useBnStats"
import { UserContext } from "../HomeApp"
import { AuthenticationDialog } from "../shared/AuthenticationDialog"
import Header from "../shared/Header/Header"
import style from "../shared/PageStyle.module.css"
import "./OwnBandNada.scss"

const OwnBandNadaPage = () => {
  const { user } = useContext(UserContext)
  const [showDialog, setShowDialog] = useState(false)
  const [showPriorities, setShowPriorities] = useState(false)
  const [stats, setStats] = useState({
    total_community_investors: 0,
    total_community_shares_owned: 0,
    user_current_share_total: 0,
  })

  const { status, data, error } = useBnStats()

  useEffect(() => {
    if (status === "success") {
      setStats(data["stats"])
    }
  }, [status])

  return (
    <div className={style.rootElStyle}>
      <Header user={user} />
      <div
        id="own-bandnada"
        className="grid grid-cols-12 w-full pb-2 mt-4 px-3 lg:px-4"
      >
        <div className="col-span-12">
          <div id="headline">
            <h1>do you own bandNada?</h1>
            {user && (
              <p className="font-bold text-green-700">
                Yes, you own {stats.user_current_share_total} shares in
                bandNada!
              </p>
            )}
            <p>
              <button
                className="focus:outline-none"
                onClick={() => setShowPriorities(!showPriorities)}
              >
                A Community Company
              </button>
            </p>

            {showPriorities && (
              <div className="priorities">
                <h2>Priorities:</h2>
                <ol>
                  <li>Employees</li>
                  <li>Community</li>
                  <li>Profits</li>
                </ol>
              </div>
            )}
          </div>

          <div id="monetary-investment">
            <h2>
              bandNada does not accept financial investment.
              <br />
              The only investors in bandNada are:
            </h2>
            <div className="flex flex-col lg:flex-row justify-center mt-6 lg:mt-0">
              <div>
                <h3>
                  employees
                  <span className="details">
                    for investing their time and skills
                  </span>
                </h3>
              </div>
              <h4>&amp;</h4>
              <div>
                <h3>
                  community
                  <span className="details">
                    for investing their love and energy
                  </span>
                </h3>
              </div>
            </div>
          </div>

          <h3>INVESTORS == COMMUNITY + EMPLOYEES</h3>

          <div className="content-section" id="earn-shares">
            <h2>Earn Shares in bandNada</h2>
            <p>You earn actual shares in bandNada just by using it.</p>
            <p>
              Any action that you take on bandNada, such as performing a live
              stream, tipping a band, commenting on a post, all contrbute to the
              value of the network, and earn you shares in bandNada.
            </p>
          </div>

          <div className="content-section" id="so-what">
            <h2>Why Would I Want Shares in this Company?</h2>
            <p>
              Since most companies are owned by the ultra-wealthy, their profits
              go back to the ultra-wealthy and further increase the{" "}
              <a
                className="text-blue-600"
                href="https://www.washingtonpost.com/news/wonk/wp/2018/02/06/how-rising-inequality-hurts-everyone-even-the-rich/"
              >
                wealth gap
              </a>
              . Since bandNada is owned by the community that it serves, it's
              profits go back to that community, strengthening that community
              instead of syphoning money from it.
            </p>
          </div>

          <div className="content-section" id="how">
            <h2>How Do I Earn Shares?</h2>
            {user ? (
              <p>
                You are already a community member, so you have already earned
                <span className="font-bold px-1">
                  {stats.user_current_share_total}
                </span>
                shares in bandNada.
              </p>
            ) : (
              <p>
                Just{" "}
                <button
                  className="text-blue-600 focus:outline-none font-bold"
                  onClick={e => setShowDialog(true)}
                >
                  Sign Up
                </button>{" "}
                and you will start earning shares, that simple.
              </p>
            )}
          </div>

          <div className="flex flex-col lg:flex-row justify-center">
            <div className="stat">
              <div className="value">{stats.total_community_investors}</div>
              <div className="description">Total Community Investors</div>
            </div>
            <div className="stat">
              <div className="value">{stats.total_community_shares_owned}</div>
              <div className="description">Total Community Shares Owned</div>
            </div>
            <div className="stat">
              <div className="value">100</div>
              <div className="description">
                % of bandNada Owned by Community
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDialog ? <AuthenticationDialog /> : null}
    </div>
  )
}

export default OwnBandNadaPage
