import React, { useState } from "react"
import { Link } from "react-router-dom"
import { timeOnly } from "../../../util/dates"
import { ConcertListItem } from "../../posts/ConcertListItem"
import { CopyPostUrlLink } from "../../posts/CopyPostUrlLink"
import InterestedBlock from "../../posts/InterestedBlock"

export const ConcertFeedItem = ({ concert }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <li className="text-sm mb-4">
      {isExpanded ? (
        <ConcertListItem
          setIsExpanded={setIsExpanded}
          concert={concert}
          isExpanded={isExpanded}
        />
      ) : (
        <>
          <div className="font-bold flex">
            <Link to={concert.local_path}>{concert.band_name}</Link>
            <InterestedBlock
              postId={concert.id}
              bandId={concert.band_id}
              maxRsvps={concert.max_rsvps}
              renderMode="text"
            />
            <CopyPostUrlLink nadaUrl={concert.nada_url} renderMode="text" />
          </div>
          <div className="leading-tight uppercase text-sm text-gray-800">
            {concert.venue_name}
          </div>
          <div className="leading-tight text-sm text-gray-500">
            {timeOnly({ dateStr: concert.start_dt })}
            {concert.end_dt && concert.end_dt !== concert.start_dt && (
              <span>~{timeOnly({ dateStr: concert.end_dt })}</span>
            )}
          </div>
          <div className="text-sm">{concert.title}</div>
          <button
            className="focus:outline-none text-blue-600 text-xs"
            onClick={e => setIsExpanded(!isExpanded)}
          >
            see details
          </button>
        </>
      )}
    </li>
  )
}
