import React from "react"
import { Link } from "react-router-dom"

const NavLink = ({ path, content, className = "" }) => {
  const isActive = window.location.pathname === path

  return (
    <Link
      className={`px-3 lg:px-4 py-2 hover:text-green-700 ${className} ${isActive &&
        "text-green-700 font-bold"}`}
      to={path}
    >
      {content}
    </Link>
  )
}

export default NavLink
