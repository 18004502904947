import React, { useContext } from "react"
import { UserContext } from "../HomeApp"
import LiveStreamsFeed from "../live_streams/LiveStreamsFeed"
import Footer from "../shared/Footer/Footer"
import Header from "../shared/Header/Header"
import Heading from "../shared/Heading"
import style from "../shared/PageStyle.module.css"

export const LiveNowPage = () => {
  const { user } = useContext(UserContext)

  return (
    <div className={style.rootElStyle}>
      <Header user={user} />
      <div className="grid grid-cols-12 w-full pb-2 mt-4 px-3 lg:px-4">
        <div className="col-span-12">
          <Heading level={2} type="sectionHeader">
            Live Now
          </Heading>
          <LiveStreamsFeed />
        </div>
      </div>
      <Footer />
    </div>
  )
}
