import React from "react"
import styles from "../HomeScreen.module.scss"
import { useQuery } from "react-query"
import { request } from "../../../util/request"
import { RequestError } from "../../shared/RequestError"
import { Spinner } from "../../shared/Spinner"
import { PostListItemPreview } from "../../posts/PostListItemPreview"

export const RecentActivity = () => {
  const postsData = useQuery(
    [
      "posts",
      // @ts-ignore
      {
        path: "/api/v1/posts.json",
        params: {
          per_page: 4,
        },
      },
    ],
    request,
  )
  return (
    <>
      <section className={`${styles.section}`}>
        <div className="flex flex-col items-center mb-6 md:mb-12">
          <h3 className="text-3xl text-center md:text-5xl leading-tight font-black">
            Recently on bandNada
          </h3>
        </div>
        {postsData.status === "loading" ? (
          <Spinner />
        ) : postsData.status === "error" ? (
          <RequestError error={postsData.error} />
        ) : (
          <ul className="grid grild-cols-1 lg:grid-cols-4 gap-6">
            {postsData.data.posts.map(post => (
              <PostListItemPreview post={post} key={post.id} />
            ))}
          </ul>
        )}
      </section>
    </>
  )
}
