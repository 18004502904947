import React, { useEffect, useState } from "react"
import { queryCache, useQuery } from "react-query"
import { Currency } from "../../util/numbers"
import { request } from "../../util/request"
import { ActionCableConsumer } from "react-actioncable-provider"

export const PromotionHomePageStats = ({ promotionId }) => {
  const refetchInterval = 1000 * 60 * 2 // 1 minute
  const MIN_DISPLAY_AMOUNT = 200
  const [promotion, setPromotion] = useState(null)
  const promotionData = useQuery(
    [
      "promotion",
      {
        path: `/api/v1/promotions/${promotionId}.json`,
        params: {},
      },
    ],
    request,
    {
      refetchInterval: refetchInterval === 0 ? false : refetchInterval,
    },
  )

  const handleUpdatePromotion = newPromotion => {
    queryCache.refetchQueries("promotion")
  }

  useEffect(() => {
    if (promotionData.status === "success") {
      setPromotion(promotionData.data.promotion)
    }
  }, [promotionData?.data?.promotion, promotionData.status])

  const promotionFieldHeaderStyle =
    "uppercase leading-none lg:text-2xl text-2xl font-bold text-gray-700"
  const promotionFieldValueStyle =
    "lg:text-4xl text-5xl font-extrabold text-white"

  return (
    <>
      {promotion && promotion?.total_amount_raised > MIN_DISPLAY_AMOUNT ? (
        <>
          <ActionCableConsumer
            channel={{ channel: "PromotionChannel", id: promotion.id }}
            onReceived={handleUpdatePromotion}
          />
          <div className="grid grid-cols-12 bg-black pb-8 px-4">
            <div className="col-span-4 text-center">
              <div className={promotionFieldHeaderStyle}>Shows Played</div>
              <div className={promotionFieldValueStyle}>
                {promotion.band_streams_count}
              </div>
            </div>
            <div className="col-span-4 text-center">
              <div className={promotionFieldHeaderStyle}>Donations Made</div>
              <div className={promotionFieldValueStyle}>
                {promotion.contributions_count}
              </div>
            </div>
            <div className="col-span-4 text-center">
              <div className={promotionFieldHeaderStyle}>Money Raised</div>
              <div className={promotionFieldValueStyle}>
                {Currency(promotion.total_amount_raised, 0)}
              </div>
            </div>

            <div className="col-span-12 mt-4 text-2xl lg:text-sm text-gray-400">
              Learn more about&nbsp;
              <a className="font-bold text-blue-500" href={promotion.cause_url}>
                {promotion.cause_name}
              </a>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default PromotionHomePageStats
