import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Geo, GeoFill } from "react-bootstrap-icons"

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${props => (props.onClick ? "pointer" : "default")};
  &:hover {
    z-index: 1;
  }
`

//const Marker = ({ text, onClick }) => <Wrapper alt={text} onClick={onClick} />
const Marker = ({ text, onClick }) => (
  <GeoFill
    alt={text}
    onClick={onClick}
    size="25px"
    style={{ color: "#57af79" }}
  />
)

Marker.defaultProps = {
  onClick: null,
}

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
}

export default Marker
