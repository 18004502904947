import React from "react"
import { Link } from "react-router-dom"
import { DateDifferenceInWords } from "../../util/dates"
import Viewer from "../viewer/Viewer"

export const LiveStreamListItem = ({ live_stream, isPreview = true }) => {
  return (
    <li className="mb-6 col-span-1">
      <div>
        {live_stream.band && live_stream.band.band_stream ? (
          <>
            <Viewer
              bandId={live_stream.band.id}
              bandStreamId={live_stream.band_stream_id}
              bandName={live_stream.band.name}
              bandPath={live_stream.band.band_path}
              bandCoverPhotoURL={live_stream.image_url_medium}
              playbackUrl={
                live_stream.band.band_stream?.player_hls_playback_url
              }
              broadcastStatus={live_stream.band.band_stream.broadcast_status}
              isPreview={isPreview}
            />
            <div className="grid grid-cols-12 py-1">
              <h2 className="font-bold col-span-8 lg:text-lg text-3xl">
                <Link to={live_stream.web_path}>{live_stream.band.name}</Link>
              </h2>
              {live_stream.live_at ? (
                <div className="lg:text-xs text-lg text-gray-700 col-span-4 text-right lg:pt-1 pt-4">
                  Live since&nbsp;
                  {
                    DateDifferenceInWords(
                      new Date(live_stream.live_at),
                      new Date(),
                    ).fullText
                  }
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </li>
  )
}

export default LiveStreamListItem
