import React, { useRef, useState } from "react"
import { SmallButton } from "./shared/Button"
import { CardImage } from "react-bootstrap-icons"
import { toast } from "react-toastify"

export const MAX_UPLOAD_BYTES = 6194304

const ImageUpload = ({
  setImageFile,
  imagePreview,
  setImagePreview,
  isSubmitting,
}) => {
  const [btnActive, setBtnActive] = useState(true)
  const hiddenFileInput = useRef(null)
  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const onUploadImage = attachment => {
    if (attachment.size > MAX_UPLOAD_BYTES) {
      toast.error("Sorry! The file must be smaller than 4MB")
      return
    }
    setBtnActive(false)
    handleImageUpload(attachment)
    setBtnActive(true)
  }

  const onRemoveImage = e => {
    e.preventDefault()
    setBtnActive(false)
    setImageFile(null)
    setImagePreview(null)
    setBtnActive(true)
  }

  const handleImageUpload = file => {
    setImageFile(file)
    if (file === null) {
      setImagePreview(null)
    } else {
      const reader = new FileReader()
      reader.onload = e => {
        setImagePreview(e.target.result)
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <>
      {imagePreview && !isSubmitting && (
        <>
          <img src={imagePreview} alt="existing preview pic" />
          <SmallButton
            disabled={!btnActive}
            bg="bg-gray-400"
            className="my-2"
            onClick={onRemoveImage}
          >
            Remove
          </SmallButton>
        </>
      )}

      <button
        type="button"
        disabled={!btnActive}
        onClick={handleClick}
        className="bg-green-200 flex justify-center py-1 w-full focus:outline-none"
      >
        <CardImage size="24" className="text-gray-700" />
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        accept="image/*"
        style={{ display: "none" }}
        onChange={e => onUploadImage(e.target.files[0])}
      />
    </>
  )
}

export default ImageUpload
