import React, { useState, useEffect } from "react"
import { FeedSpinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { UserListItem } from "../users/UserListItem"
import { useFollowers } from "../../hooks/useFollowers"
import { BandSearchResult } from "../search/BandSearchResult"
import ErrorBoundary from "react-error-boundary"

export const Followings = ({ bandId }) => {
  const pageSize = 20
  const colClass = "lg:grid-cols-8 grid-cols-2"
  const [FollowingCount, setFollowingCount] = useState(0)
  const {
    status,
    data,
    error,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
  } = useFollowers(bandId, "following", pageSize)
  useEffect(() => {
    if (status === "success") {
      setFollowingCount(data.pages.slice(-1)[0].total_count)
    }
  }, [status])
  const lastPage = status === "success" ? data.pages.slice(-1)[0] : null

  return (
    <ErrorBoundary>
      <div id="following">
        {status === "loading" ? (
          <FeedSpinner />
        ) : status === "error" ? (
          <RequestError error={error} />
        ) : (
          <>
            {data.pages[0].total_count > 0 && (
              <>
                <h2 className="border-b-4 border-black font-bold my-4 text-2xl uppercase">
                  Following
                </h2>
                <div>
                  <ul className={`grid ${colClass} gap-8`}>
                    {data.pages.map((page, i) => (
                      <React.Fragment key={page.page}>
                        {page.bands.map(band => (
                          <BandSearchResult
                            key={band.id}
                            bandId={band.id}
                            bandName={band.name}
                            bandPath={band.band_path}
                            bandCoverImageUrl={
                              band.cover_image_url ||
                              band.cover_photo_url_square
                            }
                            bandLocation={
                              band.location ? band.location.displayName : null
                            }
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </ul>
                  {lastPage.has_next_page && (
                    <button
                      className="text-blue-600 focus:outline-none"
                      onClick={e => fetchNextPage()}
                      disabled={isFetching}
                    >
                      {isFetching ? "loading..." : `See more bands followed`}
                    </button>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </ErrorBoundary>
  )
}
