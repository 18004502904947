import React from "react"
import { useParams } from "react-router-dom"
import Header from "../shared/Header/Header"
import { request } from "../../util/request"
import { useQuery } from "react-query"
import { RequestError } from "../shared/RequestError"
import { FeedSpinner } from "../shared/Spinner"
import BandNadaPlayer from "../shared/BandNadaPlayer"

export const BandTestScreen = ({ user, spk }) => {
  const testToken = window.location.pathname.split("/").pop()
  let { custom_subdomain: bandId } = useParams()

  const bandData = useQuery(
    [
      "band",
      {
        path: `/api/v1/bands/${bandId}.json`,
        params: { fields: "band_stream" },
      },
    ],
    request,
  )
  var band = bandData?.data?.band
  var bandStream = bandData?.data?.band?.band_stream

  return (
    <div className="w-full px-2 pt-4">
      <Header user={user} />
      <div className="grid grid-cols-12 w-full pb-2" id="page-scroll-container">
        {bandData.status === "loading" ? (
          <FeedSpinner />
        ) : bandData.status === "error" ? (
          <RequestError error={bandData.error} />
        ) : bandStream.token !== testToken ? (
          <div className="col-span-12 text-2xl mt-8">Invalid Page Request</div>
        ) : (
          <>
            <div className="col-span-12">
              <div className="grid grid-cols-12 border-b-4 mt-4 mb-8 border-black">
                <h1 className="lg:col-span-8 col-span-12 font-extrabold text-5xl lg:text-3xl uppercase">
                  {band.name}
                </h1>
              </div>
            </div>

            <div className="col-span-8">
              <BandNadaPlayer
                thumbnailUrl={band?.thumbnail_url}
                playbackUrl={band?.live_stream_playback_url}
              />
            </div>
            <div className="col-span-4 pl-2">
              <h4 className="font-bold text-2xl border-b-4 border-black uppercase mb-4">
                For Reference Only
              </h4>
              <p>Try reloading the page if the video is not appearing</p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
