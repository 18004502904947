import { useQuery } from "react-query"
import { request } from "../util/request"

export function useMostRelevantPosts(bandId, page = 1, perPage = 30) {
  return useQuery(
    ["most_relevant_posts", bandId, page, perPage],
    getMostRelevantPosts,
  )
}

const getMostRelevantPosts = async ({ queryKey }) => {
  const [key, bandId, page, perPage] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands/${bandId}/posts/most_relevant`,
        params: { page: page, per_page: perPage },
      },
    ],
  })
}
