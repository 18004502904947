import React, { useContext } from "react"
import { RequestError } from "../shared/RequestError"
import { FeedSpinner } from "../shared/Spinner"
import { BandListItem } from "./BandListItem"
import { UserContext } from "../HomeApp"
import { useInfiniteBands } from "../../hooks/useBands"

export const BandsListing = ({
  user = null,
  showFollow = true,
  filter = "homepage",
  cols = 2,
  limit = 40,
  showMore = true,
  mobileCols = Math.max(cols / 2, 1),
}) => {
  const { scene } = useContext(UserContext)

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteBands({ filter: filter, pageSize: limit, scene: scene })

  return (
    <>
      <ul
        className={`grid lg:grid-cols-${cols} grid-cols-${mobileCols} md:cols-span-6 gap-6`}
      >
        {status === "loading" ? (
          <FeedSpinner />
        ) : status === "error" ? (
          <RequestError error={error} />
        ) : (
          data.pages.map((page, i) => (
            <React.Fragment key={page.page}>
              {page.bands.length > 0
                ? page.bands.map(band => (
                    <BandListItem
                      key={band.id}
                      bandId={band.id}
                      bandName={band.name}
                      bandPath={band.band_path}
                      bandCoverImageUrl={
                        band.cover_image_url || band.cover_photo_url_square
                      }
                      bandLocation={
                        band.location ? band.location.displayName : null
                      }
                      showFollow={true}
                    />
                  ))
                : null}
            </React.Fragment>
          ))
        )}
      </ul>
      {isFetching && !isFetchingNextPage && <div>Loading...</div>}
      {hasNextPage && showMore && (
        <div className="my-4">
          <button
            className="text-blue-600 focus:outline-none"
            onClick={e => fetchNextPage()}
          >
            Show More
          </button>
        </div>
      )}
    </>
  )
}

export default BandsListing
