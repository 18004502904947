import { useQuery } from "react-query"
import { request } from "../util/request"

export function useCandidates(bandId, enabled) {
  return useQuery(["candidates", bandId], getCandidatesForBand, {
    enabled: enabled,
  })
}

const getCandidatesForBand = async ({ queryKey }) => {
  const [key, id] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands/${id}/candidates.json`,
        params: { fields: "guest_band_stream" },
      },
    ],
  })
}
