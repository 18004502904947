import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import FreeTextFormat from "../shared/FreeTextFormat"
import CommentScreen from "../comments/CommentScreen"
import { format } from "date-fns"
import { UserContext } from "../HomeApp"
import { TextAsImage } from "./TextAsImage"
import { PostListItemStyles } from "./constants"
import InterestedBlock from "./InterestedBlock"
import TaggedBandScreen from "../shared/TaggedBandScreen"
import { CopyPostUrlLink } from "./CopyPostUrlLink"
import EventForm from "../EventForm"
import CreatedPostUserResponse from "./CreatedPostUserResponse"
import { ShowHideButton } from "../shared/Button"
import { ReactionBlock } from "./ReactionBlock"
import RelatedPosts from "./RelatedPosts"
import PostEditButton from "../PostEditButton"

export const ConcertListItem = ({
  concert,
  setIsExpanded,
  setPostListItem = a => {},
  updateStatus = () => {},
  isExpanded,
  adminMode = false,
}) => {
  const [postStatus, setPostStatus] = useState(concert.status)
  const setConcert = newConcert => {
    setPostListItem(newConcert)
  }
  const [showEditForm, setShowEditForm] = useState(false)
  const { user } = useContext(UserContext)
  const isOwner = user && user.band && user.band.id === concert.band_id
  const imgStyle = {
    width: "100%",
    aspectRatio: "16 / 9",
  }

  return showEditForm ? (
    <EventForm
      setShowForm={setShowEditForm}
      concert={concert}
      setConcert={setConcert}
    />
  ) : (
    <div className="grid grid-cols-12">
      <CreatedPostUserResponse
        user_response={concert.user_response}
        type="concert"
        setShowEditForm={setShowEditForm}
      />
      <div className={PostListItemStyles.contentDiv}>
        <>
          {concert.cover_image_url_medium ? (
            <img
              src={concert.cover_image_url_medium}
              alt={concert.title}
              onClick={e => setIsExpanded(!isExpanded)}
              className="cursor-pointer w-full"
              style={imgStyle}
            />
          ) : concert.event_type !== "bNliveStream" &&
            concert.band_cover_photo_url_medium ? (
            <img
              src={concert.band_cover_photo_url_medium}
              alt={concert.band_name}
              onClick={e => setIsExpanded(!isExpanded)}
              className="cursor-pointer w-full"
            />
          ) : (
            <button
              className="cursor-pointer w-full"
              onClick={e => setIsExpanded(!isExpanded)}
            >
              <TextAsImage text={concert.title} />
            </button>
          )}
        </>
      </div>
      <div className={PostListItemStyles.metaDiv}>
        <div className="flex items-start">
          <h2
            className={`${PostListItemStyles.header} cursor-pointer inline`}
            dangerouslySetInnerHTML={{ __html: concert.title }}
            onClick={e => setIsExpanded(!isExpanded)}
          ></h2>
          <CopyPostUrlLink nadaUrl={concert.nada_url} renderMode="icon" />
          <PostEditButton post={concert} setShowEditForm={setShowEditForm} />
        </div>
        <h6 className={PostListItemStyles.secondaryHeader}>
          {concert.event_type === "bNliveStream"
            ? "bandNada Live Stream"
            : "Live In-Person Event"}
          {concert.status === "needs_approved" && isOwner ? (
            <span className="text-red-600 ml-4">
              Hidden Event{" "}
              <button
                className="text-blue-700 ml-2 border-b-2 border-blue-300 focus-outline-none"
                onClick={() => setShowEditForm(true)}
              >
                Publish for your fans now
              </button>
            </span>
          ) : null}
        </h6>

        <div className={PostListItemStyles.postDate}>
          {SmartDateFormat({
            dateStr: concert.start_dt,
            includeTime: true,
          })}
          ~{format(new Date(concert.end_dt), "h:mma")}
          &nbsp;
          <a
            href={concert.add_to_calendar_url}
            target="_blank"
            rel="noreferrer"
            className="text-blue-700"
          >
            (+)
          </a>
        </div>

        <Link className={PostListItemStyles.bandName} to={concert.local_path}>
          {concert.band_name}
        </Link>
        {concert.event_type === "bNliveStream" ? (
          <div className="text-sm">
            <a href={concert.nada_url} className="text-gray-700">
              {concert.nada_url}
            </a>
          </div>
        ) : concert.venue_name || concert.venue_city_display_name ? (
          <div className="text-sm">
            <a
              className="text-gray-700"
              href={concert.google_map_url}
              target="_blank"
              rel="noreferrer"
            >
              {concert.venue_name && concert.venue_city_display_name
                ? `${concert.venue_name} in ${concert.venue_city_display_name}`
                : `${concert.venue_name}${concert.venue_city_display_name}`}
            </a>
          </div>
        ) : null}

        {concert.post_type === "concert" && (
          <div className={PostListItemStyles.externalLink}>
            <a href={concert.external_url} target="_blank">
              view on&nbsp;
              {concert.class_name}
            </a>
          </div>
        )}

        {concert.ticket_link_url ? (
          <div className="mt-2">
            <a
              href={concert.ticket_link_url}
              target="_blank"
              className="border-b-2 border-blue-300 pt-1 text-blue-600 text-sm"
            >
              Ticket Link
            </a>
          </div>
        ) : null}

        <div className="mt-2">
          <InterestedBlock
            postId={concert.id}
            bandId={concert.band_id}
            renderMode="minimal"
            maxRsvps={concert.max_rsvps}
          />
        </div>

        {concert.description && (
          <div className={PostListItemStyles.description}>
            <FreeTextFormat
              text={concert.description}
              expandable={false}
              expanded={false}
              linkify={true}
            />
          </div>
        )}

        <button
          className="focus:outline-none mt-2 text-blue-600 text-sm"
          onClick={e => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Hide" : "See all those details..."}
        </button>

        <div className="text-gray-700 mt-2">
          {concert.performers_ref &&
            concert.performers_ref.map((performer_ref, i) => (
              <span key={performer_ref[0]}>
                {/^http/.test(performer_ref[1]) ? (
                  <a
                    className="block"
                    href={performer_ref[1]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {performer_ref[0]}
                  </a>
                ) : (
                  <Link
                    className="block font-bold text-green-700"
                    to={performer_ref[1]}
                  >
                    {performer_ref[0]}
                  </Link>
                )}
              </span>
            ))}
        </div>
        {adminMode && (
          <ShowHideButton
            updateStatus={updateStatus}
            postStatus={postStatus}
            setPostStatus={setPostStatus}
          />
        )}
        <div className="mt-4">
          <RelatedPosts postId={concert.id} />
        </div>
        <TaggedBandScreen postId={concert.id} />
        <ReactionBlock
          contentType="Post"
          contentId={concert.id}
          postId={concert.id}
        />
        <CommentScreen postId={concert.id} />
      </div>
    </div>
  )
}
