import { useQuery } from "react-query"
import { request } from "../util/request"

export function useBandStreamGuests(filter = "invites", fields = "host_band") {
  return useQuery(["bsgs", filter, fields], getBandStreamGuests)
}

const getBandStreamGuests = async ({ queryKey }) => {
  const [key, filter, fields] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/band_stream_guests.json`,
        params: { filter: filter, fields: fields },
      },
    ],
  })
}
