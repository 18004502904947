import React, { useState, useEffect } from "react"
import { FeedSpinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { SmartDateFormat } from "../../util/dates"
import { Minutes } from "../../util/numbers"
import { useBandStreams } from "../../hooks/useBandStreams"

export const Broadcasts = ({ bandId, className = "" }) => {
  const [page, setPage] = useState(1)
  const [broadcastCount, setBroadcastCount] = useState(0)
  const { status, error, data } = useBandStreams(bandId, page)

  useEffect(() => {
    if (status === "success") {
      setBroadcastCount(data.total_count)
    }
  }, [status])

  return (
    <div className={className}>
      <h2 className="border-b-4 mt-4 border-black font-bold mb-1 text-xl uppercase">
        Broadcasts ({broadcastCount})
      </h2>
      <p className="mb-8 text-sm text-gray-700">
        A listing of each of your broadcasts, and it's length.
      </p>
      {status === "loading" ? (
        <FeedSpinner />
      ) : status === "error" ? (
        <RequestError error={error} />
      ) : data ? (
        <>
          {data.band_streams.length === 0 ? (
            <div>No broadcasts yet...</div>
          ) : (
            <div>
              <ul className="lg:text-base text-xs">
                <li className="grid grid-cols-24 font-bold uppercase border-b-2 border-black text-xs leading:none">
                  <div className="col-span-6">Broadcast Ended At</div>
                  <div className="col-span-3">Type</div>
                  <div className="col-span-3">Minutes</div>
                  <div className="col-span-3">Anon Viewers</div>
                  <div className="col-span-3">Logged Viewers</div>
                  <div className="col-span-3">Anon Avg Mins</div>
                  <div className="col-span-3">Logged Avg Mins</div>
                </li>

                {data.band_streams.map(band_stream => (
                  <li
                    key={band_stream.id}
                    className="grid grid-cols-24 py-2 bg-gray-200 my-1"
                  >
                    <div className="col-span-6 font-bold">
                      {SmartDateFormat({ dateStr: band_stream.last_ended_at })}
                    </div>
                    <div className="col-span-3">{band_stream.origin}</div>
                    <div className="col-span-3">
                      {Minutes(band_stream.broadcast_seconds)}
                    </div>
                    <div className="col-span-3">
                      {band_stream.total_logged_out_viewer_count}
                    </div>
                    <div className="col-span-3">
                      {band_stream.total_logged_in_viewer_count}
                    </div>
                    <div className="col-span-3">
                      {Minutes(band_stream.average_logged_out_viewer_seconds)}
                    </div>
                    <div className="col-span-3">
                      {Minutes(band_stream.average_logged_in_viewer_seconds)}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : null}
    </div>
  )
}
