import React, { useState } from "react"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const BandBio = ({ band }) => {
  const [bandBio, setBandBio] = useState(band?.bio || "")

  const fieldData = {
    field: "bio",
    name: "bandBio",
    label: "Bio",
    placeholder: "What are y'all about? (hint: update this regularly)",
    required: false,
  }

  return (
    <EditBandFormTextField
      fieldData={fieldData}
      value={bandBio}
      setValue={setBandBio}
      bandId={band.id}
    />
  )
}
