import React, { useState } from "react"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const Songkick = ({ band }) => {
  const [url, setUrl] = useState(band.songkick_url || "")

  const fieldData = {
    field: "songkick_url",
    serviceClass: "SongkickService",
    name: "songkick",
    label: `Your ${band.band_type_nice}'s Songkick URL`,
    placeholder: "Songkick URL",
    notes:
      "Songkick removed our API access so we can no longer sync their data (feel free to <a href='mailto:support@songkick.com'>complain to them</a>). You can still add your link on your page like: https://www.songkick.com/artists/258210-autolux",
    required: false,
  }

  return (
    <>
      <EditBandFormTextField
        fieldData={fieldData}
        value={url}
        setValue={setUrl}
        bandId={band.id}
      />
    </>
  )
}
