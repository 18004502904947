import React, { useRef, useEffect, useState } from "react"
import { mutationRequest } from "../../util/request"
import { Button } from "../shared/Button"
import { Checkbox } from "../shared/fields/Checkbox"
import Header from "../shared/Header/Header"
import { DirectUpload } from "@rails/activestorage"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import { PersonFill } from "react-bootstrap-icons"
import style from "../shared/PageStyle.module.css"
import Footer from "../shared/Footer/Footer"
import { root_path } from "../../routes"

export const UserEditScreen = ({ user }) => {
  return (
    <div className={style.rootElStyle}>
      <Header user={user} />
      <div className="grid grid-cols-12 w-full pb-2 px-4">
        <div className="col-span-12 border-b-4 mt-4 border-black">
          <h1 className="lg:col-span-8 col-span-12 font-bold text-3xl uppercase">
            User Settings
          </h1>
        </div>
        <div className="col-span-12">
          <UserEditForm user={user} />
          <h2 className="lg:col-span-8 col-span-12 font-bold border-b mb-4 border-black text-2xl leading-none uppercase mt-10">
            Delete Your Account
          </h2>
          <p className="pb-4">
            This will remove all of your data stored with bandNada, including:
            bands you follow, chats, and user data. Tips will continue to be
            stored for accounting purposes but not displayed publicly.
          </p>
          <UserDeleteForm user={user} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const UserEditForm = ({ user }) => {
  return (
    <>
      <form>
        <FirstNameField user={user} />
        <LastNameField user={user} />
        <NicknameField user={user} />
        <UploadAvatarSection user={user} />
        <h2 className="lg:col-span-8 col-span-12 font-bold border-b mb-4 border-black text-2xl uppercase mt-10">
          Email/Text Preferences
        </h2>
        <EmailField user={user} />
        <PhoneNumberField user={user} />
        <NotificationTable user={user} />
      </form>
    </>
  )
}

const FirstNameField = ({ user }) => {
  const [value, setValue] = useState(user.first_name || "")

  const fieldData = {
    field: "first_name",
    name: "first_name",
    label: "First Name",
    placeholder: "Enter your real first name",
    required: true,
  }

  return (
    <>
      <EditUserFormTextField
        fieldData={fieldData}
        value={value}
        setValue={setValue}
        userId={user.id}
      />
    </>
  )
}

const LastNameField = ({ user }) => {
  const [value, setValue] = useState(user.last_name || "")

  const fieldData = {
    field: "last_name",
    name: "last_name",
    label: "Last Name",
    placeholder: "Enter your real last name",
    required: true,
  }

  return (
    <>
      <EditUserFormTextField
        fieldData={fieldData}
        value={value}
        setValue={setValue}
        userId={user.id}
      />
    </>
  )
}

const NicknameField = ({ user }) => {
  const [value, setValue] = useState(user.nickname || "")

  const fieldData = {
    field: "nickname",
    name: "nickname",
    label: "Nickname",
    placeholder: "Nickname (appears in chat)",
    required: true,
  }

  return (
    <div className="mb-6">
      <EditUserFormTextField
        fieldData={fieldData}
        value={value}
        setValue={setValue}
        userId={user.id}
      />
    </div>
  )
}

const UploadAvatarSection = ({ user }) => {
  const [avatarURL, setAvatarURL] = useState(user.avatar_url_medium || "")
  const [btnActive, setBtnActive] = useState(true)
  const [cropper, setCropper] = useState(null)
  const [uploadedFilePath, setUploadedFilePath] = useState("")

  const hiddenFileInput = useRef(null)
  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const reader = new FileReader()
  reader.onload = e => {
    // @ts-ignore
    setUploadedFilePath(e.target.result)
  }

  const onUploadAvatar = the_attachment => {
    setBtnActive(false)
    reader.readAsDataURL(the_attachment)
    setBtnActive(true)
  }

  const onRemovePhoto = e => {
    e.preventDefault()
    setBtnActive(false)
    mutationRequest({
      path: `/api/v1/users/${user.id}/remove_avatar`,
      options: { method: "PUT" },
    }).then(response => {
      setAvatarURL("")
      setBtnActive(true)
    })
  }

  const onCancel = () => {
    setBtnActive(false)
    hiddenFileInput.current.value = null
    setUploadedFilePath("")
    setBtnActive(true)
  }

  const onSave = () => {
    setBtnActive(false)

    cropper.getCroppedCanvas().toBlob(blob => {
      const upload = new DirectUpload(
        new File([blob], "cropped-photo"),
        "/rails/active_storage/direct_uploads",
      )

      upload.create((error, blob) => {
        if (error) {
          console.error("error:", error)
          setBtnActive(true)
        } else {
          const signed_id = blob.signed_id
          const request_body = {
            avatar: signed_id,
          }
          handleUpdateUserData(user.id, request_body).then(response => {
            if (response && response.user && response.user.avatar_url_medium)
              setAvatarURL(response.user.avatar_url_medium)
            hiddenFileInput.current.value = null
            setUploadedFilePath("")
            setBtnActive(true)
          })
        }
      })
    })
  }

  return (
    <div className="grid grid-cols-12 mt-10">
      <label className="block col-span-12 text-gray-900 text-xs uppercase font-bold">
        Profile Image
      </label>
      <div className="col-span-12 lg:col-span-2">
        {avatarURL && avatarURL.length ? (
          <img className="rounded-sm w-full" src={avatarURL} />
        ) : (
          <PersonFill className="w-full col-span-12 text-gray-500 h-64 block bg-gray-200" />
        )}
        <Button
          disabled={!btnActive}
          bg="bg-green-400"
          className="mt-2 w-full"
          onClick={handleClick}
        >
          Upload an Image
        </Button>
        <input
          type="file"
          ref={hiddenFileInput}
          accept="image/*"
          style={{ display: "none" }}
          onChange={e => onUploadAvatar(e.target.files[0])}
        />
        <div className="mt-2 text-center">
          <button
            className="hover:text-blue-700"
            disabled={!btnActive}
            style={{ cursor: "pointer" }}
            onClick={onRemovePhoto}
          >
            Remove Photo
          </button>
        </div>
      </div>
      {uploadedFilePath && uploadedFilePath.length ? (
        <div className="mx-3 col-span-12 lg:col-span-3 w-full bg-gray-200">
          <h6 className="px-3 font-bold">Crop your photo</h6>
          <Cropper
            className="mt-2"
            initialAspectRatio={1}
            aspectRatio={1}
            src={uploadedFilePath}
            viewMode={1}
            guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={true}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={instance => {
              setCropper(instance)
            }}
          />
          <div className="mt-2 mb-2">
            <Button
              disabled={!btnActive}
              bg="bg-gray-400"
              className="mx-3"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button disabled={!btnActive} bg="bg-green-400" onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const NotificationTable = ({ user }) => {
  const trStyles = "grid grid-cols-12 border-b py-2"
  const labelStyles = "lg:col-span-10 col-span-8 text-left"
  const fieldStyles = "lg:col-span-1 col-span-2 text-center"

  return (
    <div className="grid grid-cols-12">
      <div className="lg:col-span-11 col-span-12">
        <table className="mt-8 w-full lg:text-base text-sm">
          <thead>
            <tr className={trStyles}>
              <th className={labelStyles}>Notification Type</th>
              <th className={fieldStyles}>Email</th>
              <th className={fieldStyles}>Text</th>
            </tr>
          </thead>
          <tbody>
            <NotificationRow
              user={user}
              label="Go Live"
              description="these go out only when a band you follow goes live"
              emailsName="send_go_live_emails"
              textsName="send_go_live_textss"
            />
            <NotificationRow
              user={user}
              label="Notifications &amp; Reminders"
              description="notifications about new comments on threads you follow, and reminders about events you responded to"
              emailsName="send_notification_emails"
              textsName="send_notification_texts"
            />
            <NotificationRow
              user={user}
              label="Weekly Digest"
              description="these go out weekly, but only with new stuff (albums/videos/streams) from bands you follow"
              emailsName="send_weekly_digest_emails"
            />
            <NotificationRow
              user={user}
              label="Weekly Discover"
              description="these go out weekly with suggestions for new bands for you to follow"
              emailsName="send_discovery_emails"
            />
            <NotificationRow
              user={user}
              label="Product Updates"
              description="these go out sporadically as we make significant updates to bandNada"
              emailsName="send_product_update_emails"
            />
          </tbody>
        </table>
      </div>
    </div>
  )
}

const NotificationRow = ({
  user,
  label,
  description,
  emailsName = null,
  textsName = null,
}) => {
  const trStyles = "grid grid-cols-12 border-b py-2"
  const labelStyles = "lg:col-span-10 col-span-8 text-left"
  const fieldStyles = "lg:col-span-1 col-span-2 text-center"
  const helperTextStyles = "text-gray-600 text-xs"

  return (
    <tr className={trStyles}>
      <td className={labelStyles}>
        {label}
        <div className={helperTextStyles}>{description}</div>
      </td>
      <td className={fieldStyles}>
        {emailsName && (
          <UserCheckbox
            name={emailsName}
            userId={user.id}
            value={user[emailsName]}
          />
        )}
      </td>
      <td className={fieldStyles}>
        {textsName && (
          <UserCheckbox
            name={textsName}
            userId={user.id}
            value={user[textsName]}
          />
        )}
      </td>
    </tr>
  )
}

const EmailField = ({ user }) => {
  const [value, setValue] = useState(user.email || "")

  const fieldData = {
    field: "email",
    name: "email",
    label: "Email",
    placeholder: "Email",
    required: true,
  }

  return (
    <>
      <EditUserFormTextField
        fieldData={fieldData}
        value={value}
        setValue={setValue}
        userId={user.id}
      />
    </>
  )
}

const PhoneNumberField = ({ user }) => {
  const [value, setValue] = useState(user.phone_number || "")

  const fieldData = {
    field: "phone_number",
    name: "phone_number",
    label: "Phone number",
    placeholder: "Cell #",
    required: true,
    notes: user.phone_number
      ? null
      : "add your phone number to get text alerts when a band you follow goes live (we won't text/call you otherwise)",
  }

  return (
    <>
      <EditUserFormTextField
        fieldData={fieldData}
        value={value}
        setValue={setValue}
        userId={user.id}
      />
    </>
  )
}

export const UserCheckbox = ({ userId, name, value }) => {
  const [checked, setChecked] = useState(!!value)

  const toggleValue = () => {
    var updateData = {}
    updateData[name] = !checked
    handleUpdateUserData(userId, updateData)
    setChecked(!checked)
  }

  return (
    <Checkbox
      name={name}
      value={value}
      checked={checked}
      onChange={toggleValue}
    />
  )
}

export const EditUserFormTextField = ({
  fieldData,
  value,
  setValue,
  userId = null,
  onSubmit = null,
}) => {
  const [initialValue] = useState(value)
  const [localVal, setLocalVal] = useState(value)
  const [validationState, setValidationState] = useState(
    value ? "valid" : "idle",
  )
  const [validationError, setValidationError] = useState(null)

  const setRemoteValue = newVal => {
    if (newVal === value) {
      setValidationState("valid")
    } else {
      setValidationState("waiting")
      setValue(newVal)
    }
  }

  const handleResponse = response => {
    if (response.success) {
      setValidationState("valid")
      setValidationError(null)
    } else {
      setValidationState("invalid")
      setValidationError(response.errors.error)
    }
  }

  useEffect(() => {
    if (value !== initialValue) {
      let updateData = {}
      updateData[fieldData.field] = value
      setValidationState("validating")
      if (onSubmit) {
        onSubmit(updateData).then(handleResponse)
      } else {
        handleUpdateUserData(userId, updateData).then(handleResponse)
      }
    }
  }, [userId, fieldData.field, value])

  return (
    <div className="grid grid-cols-12 mt-10">
      <label
        className="block col-span-12 text-gray-900 text-xs uppercase font-bold"
        htmlFor={fieldData.name}
      >
        {fieldData.label}
      </label>
      <input
        className={`col-span-11 shadow appearance-none border rounded-sm w-full text-base py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
          validationState === "valid"
            ? "border-green-500"
            : validationState === "invalid"
            ? "border-red-500"
            : validationState === "validating"
            ? "border-yellow-500"
            : ""
        }`}
        type="text"
        name={fieldData.name}
        placeholder={fieldData.placeholder}
        required={fieldData.required}
        value={localVal}
        onFocus={e => setValidationState("waiting")}
        onChange={e => setLocalVal(e.target.value)}
        onBlur={e => setRemoteValue(localVal)}
      />
      <div className="col-span-1 pl-2 text-2xl">
        {validationState === "valid"
          ? "🤘"
          : validationState === "invalid"
          ? "👎"
          : validationState === "validating"
          ? "🤞"
          : validationState === "waiting"
          ? "🤔"
          : ""}
      </div>
      <div className="col-span-12 text-red-700 text-sm">{validationError}</div>
      {fieldData.notes && (
        <div className="col-span-12 pt-1 text-gray-600 text-sm">
          {fieldData.notes}
        </div>
      )}
    </div>
  )
}

const redirectHome = () => {
  window.location.href = root_path()
}

const handleUpdateUserData = async (userId, userUpdateData) => {
  try {
    let responseData = await mutationRequest({
      path: `/api/v1/users/${userId}`,
      options: { method: "PUT" },
      data: { user: userUpdateData },
    })
    return responseData
  } catch (error) {
    console.log("Error updating user data: ", error)
  }
}

export const UserDeleteForm = ({ user }) => {
  const [disabled, setDisabled] = useState(false)

  const handleDeleteUser = () => {
    setDisabled(true)
    deleteUser(user.id).then(redirectHome)
  }

  return (
    <>
      <Button
        disabled={disabled}
        bg="bg-red-500"
        onClick={e => {
          if (window.confirm(`Are you sure you want to delete your account?`)) {
            handleDeleteUser()
          }
        }}
      >
        Delete My Account
      </Button>
    </>
  )
}

const deleteUser = async userId => {
  try {
    let responseData = await mutationRequest({
      path: `/api/v1/users/${userId}`,
      options: { method: "DELETE" },
    })
    return responseData
  } catch (error) {
    console.log("Error attempting to delete user: ", error)
  }
}
export default UserEditScreen
