import { useQuery } from "react-query"
import { request } from "../util/request"

export function usePayee(payeeId) {
  return useQuery(["payee", payeeId], getPayeeById)
}

const getPayeeById = async ({ queryKey }) => {
  const [key, id] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/payees/${id}`,
      },
    ],
  })
}
