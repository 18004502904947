import React, { useState } from "react"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const EmailAddress = ({ band }) => {
  const [bandEmail, setBandEmail] = useState(band?.email || "")

  const fieldData = {
    field: "email",
    name: "bandEmail",
    label: "Email Address",
    placeholder:
      "The official public email address that will be viewable to fans",
    required: false,
  }

  return (
    <EditBandFormTextField
      fieldData={fieldData}
      value={bandEmail}
      setValue={setBandEmail}
      bandId={band.id}
    />
  )
}
