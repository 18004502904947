import React, { useContext, useEffect } from "react"
import { FeedSpinner } from "../../shared/Spinner"
import { RequestError } from "../../shared/RequestError"
import { Link } from "react-router-dom"
import { DayFormat, isSameDay } from "../../../util/dates"
import { ConcertFeedItem } from "./ConcertFeedItem"
import { UserContext } from "../../HomeApp"
import { useInfinitePosts } from "../../../hooks/usePosts"
import { discover_path } from "../../../routes"

const ConcertFeed = ({ showDialog, setShowDialog, newData, feedType }) => {
  const pageSize = 12
  const { scene } = useContext(UserContext)
  let currentDate = null

  const setCurrentDate = newDate => {
    currentDate = newDate
    return true
  }

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfinitePosts({
    feedType: feedType,
    scene: scene,
    contentType: "concerts",
    pageSize: pageSize,
  })

  const addScrollEventListener = () => {
    var scrollContainer = document.getElementById("page-scroll-container")
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll)
    }
  }

  const removeScrollEventListener = () => {
    var scrollContainer = document.getElementById("page-scroll-container")
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", handleScroll)
    }
  }

  useEffect(() => {
    if (newData && data?.pages?.length) {
      data.pages[0].posts = [newData, ...data.pages[0].posts]
    }
  }, [newData])

  useEffect(() => {
    if (!isFetching && !isFetchingNextPage && hasNextPage) {
      addScrollEventListener()
    }
    return () => removeScrollEventListener()
  }, [isFetching])

  function handleScroll() {
    if (isFetchingNextPage) return
    var scrollContainer = document.getElementById("page-scroll-container")
    if (
      scrollContainer.scrollTop /
        (scrollContainer.scrollHeight - scrollContainer.offsetHeight) >
      0.8
    ) {
      removeScrollEventListener()
      fetchNextPage()
    }
  }

  return (
    <>
      <ul>
        {status === "loading" ? (
          <FeedSpinner />
        ) : status === "error" ? (
          <RequestError error={error} />
        ) : data.pages && data.pages[0].total_count > 0 ? (
          <>
            {data.pages.map((page, pageIndex) => (
              <React.Fragment key={page.page}>
                {page.posts.map((post, postIndex) => (
                  <React.Fragment key={post.id}>
                    {(!currentDate || !isSameDay(currentDate, post.start_dt)) &&
                      setCurrentDate(post.start_dt) && (
                        <li className="font-bold mb-3 uppercase text-gray-800 border-b">
                          {DayFormat(currentDate)}
                        </li>
                      )}
                    <ConcertFeedItem concert={post} />
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
            <li>{isFetching && !isFetchingNextPage ? "Loading..." : null}</li>
            {hasNextPage && !isFetchingNextPage && (
              <li>
                <button onClick={e => fetchNextPage()}>Show More</button>
              </li>
            )}
          </>
        ) : feedType === "following" ? (
          <li>
            This is your personalized band feed. No complicated algorithms or
            pay to play pricing.{" "}
            <Link to={discover_path()} className="text-blue-600">
              Start following bands
            </Link>{" "}
            on bandNada to see new content from them in chronological order
            here.
          </li>
        ) : (
          <li>
            It doesn't look like we have any shows listed coming up for {scene}{" "}
            :(
          </li>
        )}
      </ul>
    </>
  )
}

export default ConcertFeed
