import { useQuery } from "react-query"
import { request } from "../util/request"

export function useTips(payoutId, page = 1, perPage = 5) {
  return useQuery(["tips", payoutId, page, perPage], getTipsForPayoutId, {
    keepPreviouData: true,
  })
}

const getTipsForPayoutId = async ({ queryKey }) => {
  const [key, id, page, perPage] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/payouts/${id}/tips`,
        params: { per_page: perPage, page: page },
      },
    ],
  })
}
