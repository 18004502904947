import React from "react"
import { Check } from "react-bootstrap-icons"
import styles from "./styles.module.scss"
import { Spinner } from "../shared/Spinner"

const UploadingStatus = ({ imagePreview, index, onRemoveImage }) => {
  return imagePreview.isUploading ? (
    <Spinner className={styles.uploadingSpinner} />
  ) : imagePreview.isUploaded ? (
    <Check className={styles.uploadedCheck} />
  ) : (
    <button
      className={styles.removeBtn}
      type="button"
      onClick={() => onRemoveImage(index)}
    >
      &times;
    </button>
  )
}

export default UploadingStatus
