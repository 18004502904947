import { createSlice } from "@reduxjs/toolkit"

export const slice = createSlice({
  name: "mediaPlayer",
  initialState: {
    value: {
      currentMediaItem:
        JSON.parse(localStorage.getItem("currentMediaItem")) || null,
      playerState: JSON.parse(localStorage.getItem("playerState")) || "idle",
      queue: JSON.parse(localStorage.getItem("mediaPlayerQueue")) || [],
    },
  },
  reducers: {
    playNext: (state, action) => {
      playNextHelper(state)
    },

    playOrPause: (state, action) => {
      if (state.value.playerState === "playing") {
        state.value.playerState = "paused"
        localStorage.setItem(
          "playerState",
          JSON.stringify(state.value.playerState),
        )
      } else {
        if (state.value.currentMediaItem !== null) {
          state.value.playerState = "playing"
          localStorage.setItem(
            "playerState",
            JSON.stringify(state.value.playerState),
          )
        } else {
          playNextHelper(state)
        }
      }
    },

    prependAndPlay: (state, action) => {
      const parsedItems = action.payload.map(item => mediaItemParser(item))
      state.value.queue = [...parsedItems, ...state.value.queue]
      playNextHelper(state)
    },

    pushToQueue: (state, action) => {
      const parsedItems = action.payload.map(item => mediaItemParser(item))
      const newQueue = [...state.value.queue, ...parsedItems]
      localStorage.setItem("mediaPlayerQueue", JSON.stringify(newQueue))
      state.value.queue = newQueue
    },

    clearQueue: (state, action) => {
      localStorage.setItem("mediaPlayerQueue", JSON.stringify([]))
      state.value.queue = []
    },

    removeElement: (state, action) => {
      if (action.payload >= 0) {
        const newQueue = state.value.queue
        newQueue.splice(action.payload, 1)
        localStorage.setItem("mediaPlayerQueue", JSON.stringify(newQueue))
        state.value.queue = newQueue
      }
    },
  },
})

const playNextHelper = state => {
  state.value.currentMediaItem = state.value.queue.shift() || null
  state.value.playerState = state.value.currentMediaItem ? "playing" : "idle"
  localStorage.setItem("mediaPlayerQueue", JSON.stringify(state.value.queue))
  localStorage.setItem(
    "currentMediaItem",
    JSON.stringify(state.value.currentMediaItem),
  )
  localStorage.setItem("playerState", JSON.stringify(state.value.playerState))
}

const mediaItemParser = mediaItem => {
  if (
    mediaItem.post_type &&
    ["song", "stand_alone_song"].includes(mediaItem.post_type)
  ) {
    return {
      albumName: mediaItem.album_name,
      bandId: mediaItem.band_id,
      bandName: mediaItem.band_name,
      coverImageSmall:
        mediaItem.cover_image_small || mediaItem.external_photo_src,
      duration: mediaItem.duration,
      localPath: mediaItem.local_path,
      nadaPath: mediaItem.parent_path || mediaItem.nada_path,
      postId: mediaItem.id,
      albumId: mediaItem.album_id,
      streamingUrl: mediaItem.streaming_url,
      title: mediaItem.title,
      type: "audio",
    }
  } else {
    return {
      albumName: mediaItem.album_name,
      bandId: mediaItem.band_id,
      bandName: mediaItem.band_name,
      coverImageSmall:
        mediaItem.cover_image_small || mediaItem.external_photo_src,
      duration: mediaItem.duration,
      localPath: mediaItem.local_path,
      nadaPath: mediaItem.parent_path || mediaItem.nada_path,
      postId: mediaItem.id,
      albumId: mediaItem.album_id,
      streamingUrl: mediaItem.external_url,
      title: mediaItem.title,
      type: "video",
    }
  }
}

export const {
  playNext,
  playOrPause,
  prependAndPlay,
  pushToQueue,
  removeElement,
  clearQueue,
} = slice.actions

export const selectMediaPlayer = state => state.mediaPlayer.value

export default slice.reducer
