import React, { useEffect } from "react"
import { Spinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { useMutation } from "react-query"
import { mutationRequest } from "../../util/request"
import { usePayee } from "../../hooks/usePayee"

export const PayeeSection = ({ payeeId }) => {
  const { status, data, error } = usePayee(payeeId)
  const payee = status === "success" ? data.payee : null

  const mutation = useMutation(mutationRequest)
  const handlePayeeUpdate = async (e, updateData) => {
    try {
      await mutation.mutate({
        options: { method: "PUT" },
        path: `/api/v1/payees/${payeeId}`,
        data: {
          payee: updateData,
        },
      })
    } catch {
      console.log("Catching the error", error)
    }
  }

  const handleCreateAccountLink = e => {
    handlePayeeUpdate(e, { create_stripe_account_link: true })
  }

  useEffect(() => {
    if (mutation.status === "success") {
      if (mutation.data.success) {
        window.location.href = mutation.data.payee.stripe_onboarding_url
      } else {
        console.error("Failed to create stripe account link: ", mutation.data)
        window.location.reload()
      }
    } else if (mutation.status === "error") {
      console.error(
        "Mutations failed to create stripe account link: ",
        mutation.error,
      )
      window.location.reload()
    }
  }, [mutation.status])

  return (
    <div className="mb-4 text-base">
      {status === "loading" ? (
        <Spinner />
      ) : status === "error" ? (
        <RequestError error={error} />
      ) : payee.status === "initialized" ? (
        payee.stripe_onboarding_url &&
        !payee.is_stripe_onboarding_url_expired ? (
          <a
            className="text-blue-600"
            href={payee.stripe_onboarding_url}
            target="_blank"
            rel="noreferrer"
          >
            Setup Your Stripe Account
          </a>
        ) : (
          <>
            <button
              className="text-blue-600 focus:outline-none lg:text:base text:sm"
              onClick={e => handleCreateAccountLink(e)}
            >
              Setup Your Stripe Account
            </button>
            <span className="text-gray-600 pl-2 text-sm">
              you need to do this (only once) to get paid
            </span>
          </>
        )
      ) : (
        <a
          className="text-blue-600"
          href={payee.stripe_login_url}
          onClick={e => handlePayeeUpdate(e, { stripe_login_url_used: true })}
          target="_blank"
          rel="noreferrer"
        >
          Your Stripe Payout Dashboard
        </a>
      )}
    </div>
  )
}
