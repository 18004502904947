import React from "react"

import { SongListItem } from "./SongListItem"
import { AlbumListItem } from "./AlbumListItem"
import { VideoListItem } from "./VideoListItem"
import { HeadlineListItem } from "./HeadlineListItem"
import { ConcertListItem } from "./ConcertListItem"
import { LinkListItem } from "./LinkListItem"
import ConcertListItemExpanded from "./ConcertListItemExpanded"
import { AlbumListItemExpanded } from "./AlbumListItemExpanded"
import { VideoListItemExpanded } from "./VideoListItemExpanded"
import { HeadlineListItemExpanded } from "./HeadlineListItemExpanded"
import { SongListItemExpanded } from "./SongListItemExpanded"
import { LinkListItemExpanded } from "./LinkListItemExpanded"

import { mutationRequest } from "../../util/request"

export class PostListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpanded: props.expanded,
      post: props.post,
      adminMode: props.adminMode,
    }
  }

  setIsExpanded = isExpanded => {
    this.setState({ isExpanded: isExpanded })
  }

  setPostListItem = newPostListItem => {
    this.setState({ post: newPostListItem })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.isExpanded !== this.state.isExpanded ||
      nextState.post !== this.state.post ||
      nextProps.adminMode !== this.props.adminMode
    ) {
      return true
    } else {
      return false
    }
  }

  async updateStatus() {
    const post = this.state.post
    try {
      if (post.status === "published") {
        const response = await mutationRequest({
          path: `/api/v1/bands/${post.band_id}/posts/${post.id}`,
          options: { method: "DELETE" },
        })
        if (response.success) {
          this.setState({ post: { ...post } })
        }
      } else {
        const response = await mutationRequest({
          path: `/api/v1/bands/${post.band_id}/posts/${post.id}`,
          options: { method: "PUT" },
          data: {
            post: { status: "published" },
          },
        })
        if (response.success) {
          this.setState({ post: { ...post } })
        }
      }
    } catch (error) {
      console.error("ERROR: ", error)
    }
  }

  render() {
    return this.state.post ? (
      <li
        id={this.state.post.id}
        data-post-type={this.state.post.post_type}
        className={`mb-10 ${this.state.post.class_name}`}
      >
        {this.state.post.post_type === "album" ? (
          this.state.isExpanded ? (
            <AlbumListItemExpanded
              album={this.state.post}
              setIsExpanded={this.setIsExpanded}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          ) : (
            <AlbumListItem
              album={this.state.post}
              setIsExpanded={this.setIsExpanded}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          )
        ) : ["stand_alone_song", "link_song"].includes(
            this.state.post.post_type,
          ) ? (
          this.state.isExpanded ? (
            <SongListItemExpanded
              song={this.state.post}
              setIsExpanded={this.setIsExpanded}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          ) : (
            <SongListItem
              song={this.state.post}
              setIsExpanded={this.setIsExpanded}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          )
        ) : ["youtube_video", "link_video"].includes(
            this.state.post.post_type,
          ) ? (
          this.state.isExpanded ? (
            <VideoListItemExpanded
              video={this.state.post}
              setIsExpanded={this.setIsExpanded}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          ) : (
            <VideoListItem
              video={this.state.post}
              setIsExpanded={this.setIsExpanded}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          )
        ) : ["concert", "event"].includes(this.state.post.post_type) ? (
          this.state.isExpanded ? (
            <ConcertListItemExpanded
              concert={this.state.post}
              setIsExpanded={this.setIsExpanded}
              setPostListItem={this.setPostListItem}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          ) : (
            <ConcertListItem
              concert={this.state.post}
              setIsExpanded={this.setIsExpanded}
              isExpanded={this.state.isExpanded}
              setPostListItem={this.setPostListItem}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          )
        ) : ["headline", "image_album"].includes(this.state.post.post_type) ? (
          this.state.isExpanded ? (
            <HeadlineListItemExpanded
              headline={this.state.post}
              setIsExpanded={this.setIsExpanded}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          ) : (
            <HeadlineListItem
              headline={this.state.post}
              setIsExpanded={this.setIsExpanded}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          )
        ) : ["link", "link_image"].includes(this.state.post.post_type) ? (
          this.state.isExpanded ? (
            <LinkListItemExpanded
              post={this.state.post}
              setIsExpanded={this.setIsExpanded}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          ) : (
            <LinkListItem
              defaultPost={this.state.post}
              setIsExpanded={this.setIsExpanded}
              updateStatus={this.updateStatus.bind(this)}
              adminMode={this.state.adminMode}
            />
          )
        ) : (
          <UnsupportedPostType post={this.state.post} />
        )}
      </li>
    ) : null
  }
}

const UnsupportedPostType = ({ post }) => {
  return <div></div>
}
