import React from "react"
import { useParams } from "react-router-dom"
import { StringParam, useQueryParams } from "use-query-params"
import { Spinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import ChatRoom from "./Chat"
import PromotionShowStats from "../promotions/PromotionShowStats"
import { useBand } from "../../hooks/useBand"

const ChatScreen = ({ user = null, spk }) => {
  let { custom_subdomain: bandId } = useParams()
  const [params] = useQueryParams({
    band_id: StringParam,
  })
  if (!bandId) bandId = params[bandId]

  const bandData = useBand(bandId, null, "band_stream")
  var band = bandData?.data?.band
  var bandStream = bandData?.data?.band?.band_stream

  return (
    <div
      className="w-full px-4 pt-1 pb-2 overflow-y-hidden"
      id="chat-page-dark"
    >
      {bandData.status === "loading" ? (
        <Spinner className="text-gray-500" />
      ) : bandData.status === "error" ? (
        <RequestError error={bandData.error} />
      ) : (
        <>
          <div className="grid grid-cols-12 w-full">
            <div className="col-span-12">
              <ChatRoom
                bandId={band.id}
                bandName={band.name}
                user={user}
                totalHeight={window.innerHeight - 20}
                className="dark-theme"
                standAlone={true}
              />
            </div>
            <div className="col-span-12 pt-3">
              {bandStream.promotion_id ? (
                <PromotionShowStats
                  bandStreamId={bandStream.id}
                  promotionId={bandStream.promotion_id}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ChatScreen
