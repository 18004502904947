import React, { useState, useLayoutEffect, useContext } from "react"
import { Spinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import ChatRoom from "../chat/Chat"
import { TipComponent, TipComponentLoggedOut } from "../tips/TipComponent"
import { isMobile } from "react-device-detect"
import PromotionShowStats from "../promotions/PromotionShowStats"
import { useBand } from "../../hooks/useBand"
import { UserContext } from "../HomeApp"
import { useBandStream } from "../../hooks/useBandStream"
import Viewer from "./Viewer"

const ViewerPage = ({
  bandId,
  bandStreamId = null,
  bandStreamGuestId = null,
}) => {
  const { user } = useContext(UserContext)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    setWidth(window.innerWidth)
    setHeight(width / 2.72)
  })

  const { status: bandStatus, data: bandData, error: bandError } = useBand(
    bandId,
  )
  var band = bandData?.band

  const {
    status: bandStreamStatus,
    data: bandStreamData,
    error: bandStreamError,
  } = useBandStream(bandStreamId || -1)
  let bandStream = bandStreamData?.band_stream

  return (
    <div className="w-full" style={{ height: "min-content" }}>
      {bandStreamStatus === "loading" || bandStatus === "loading" ? (
        <Spinner className="text-gray-500" />
      ) : bandStreamStatus === "error" || bandStatus === "error" ? (
        <RequestError error={bandStreamError} />
      ) : bandStream?.broadcast_status !== "live" ? null : (
        <>
          <div className="grid grid-cols-12 w-full bg-white px-3 pt-3">
            <div className="col-span-12 lg:col-span-8 lg:pr-2">
              <Viewer
                bandId={band.id}
                bandStreamId={bandStreamId}
                bandName={band.name}
                bandPath={band.band_path}
                bandCoverPhotoURL={
                  band.cover_photo_url_large ? band.cover_photo_url_large : ""
                }
                playbackUrl={bandStream?.player_hls_playback_url}
                broadcastStatus={
                  bandStream ? bandStream.broadcast_status : "idle"
                }
                isPreview={false}
              />
            </div>
            <div
              className="col-span-12 lg:col-span-4 lg:pt-0 pt-2"
              style={isMobile ? {} : { height: height }}
            >
              <ChatRoom
                bandId={band.id}
                bandName={band.name}
                user={user}
                totalHeight={height * 1}
                className=""
              />
            </div>
          </div>
          {isMobile ? (
            <>
              <div className="grid grid-cols-12 mt-1 lg:mt-0 bg-white pb-3">
                <div className="col-span-12 lg:col-span-2">
                  {user ? (
                    <TipComponent
                      bandId={band.id}
                      bandName={band.name}
                      userName={user.name}
                      userDefaultPaymentMethodLastFour={
                        user.default_payment_method_last_four
                      }
                      bandStreamId={bandStreamId}
                      promotionId={bandStream?.promotion_id}
                    />
                  ) : (
                    <TipComponentLoggedOut
                      bandId={band.id}
                      bandName={band.name}
                    />
                  )}
                </div>
                <div className="lg:col-span-4 lg:col-start-5 col-span-12 pt-3"></div>
                <div className="col-span-12 lg:col-span-4 pt-3">
                  {bandStream?.promotion_id ? (
                    <PromotionShowStats
                      bandStreamId={bandStream.id}
                      promotionId={bandStream.promotion_id}
                    />
                  ) : null}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="grid grid-cols-12 mt-4 lg:mt-0 bg-white pb-3">
                <div className="col-span-12 lg:col-span-2 pt-3"></div>
                <div className="lg:col-span-4 lg:col-start-5 col-span-12">
                  {user ? (
                    <TipComponent
                      bandId={band.id}
                      bandName={band.name}
                      userName={user.name}
                      userDefaultPaymentMethodLastFour={
                        user.default_payment_method_last_four
                      }
                      bandStreamId={bandStream?.id}
                      promotionId={bandStream?.promotion_id}
                    />
                  ) : (
                    <TipComponentLoggedOut
                      bandId={band.id}
                      bandName={band.name}
                    />
                  )}
                </div>
                <div className="col-span-12 lg:col-span-4 pt-3">
                  {bandStream?.promotion_id ? (
                    <PromotionShowStats
                      bandStreamId={bandStream.id}
                      promotionId={bandStream.promotion_id}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ViewerPage
