import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../../util/dates"
import { UserContext } from "../../HomeApp"
import { react_post_path } from "../../../routes"

const CalendarEvent = ({ calendarEvent }) => {
  const { user } = useContext(UserContext)
  const isOwner = calendarEvent.band_id == user.band_id

  return (
    <li className="text-sm mb-4">
      <div className="leading-none">
        <Link to={calendarEvent.nada_path} className="font-bold uppercase">
          {SmartDateFormat({
            dateStr: calendarEvent.start_dt,
            includeTime: true,
          })}
        </Link>
        {isOwner && calendarEvent.status === "needs_approved" ? (
          <>
            {" - "}
            <span className="font-bold text-red-600 uppercase">
              Just Announced!
            </span>
          </>
        ) : null}
        {" - "}
        <a
          className="text-blue-600 text-xs text-right"
          href={calendarEvent.add_to_calendar_url}
          target="_blank"
          rel="noreferrer"
        >
          add to calendar
        </a>
        {isOwner && (
          <>
            {" - "}
            <Link
              to={react_post_path(calendarEvent.id, { edit: true })}
              className="text-blue-600 text-xs text-right"
            >
              edit
            </Link>
          </>
        )}
      </div>
      <Link
        to={calendarEvent.nada_path}
        className="leading-tight font-bold uppercase text-sm text-gray-500"
      >
        {calendarEvent.venue_name}
      </Link>
      <Link className="display-block block" to={calendarEvent.nada_path}>
        {calendarEvent.title}
      </Link>

      {isOwner && calendarEvent.status === "needs_approved" ? (
        <div className="text-red-600 text-xs leading none">
          This event was just announced on bandsintown and is NOT public. Click
          edit to add some details and make it public.
        </div>
      ) : null}
    </li>
  )
}

export default CalendarEvent
