import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import { format } from "date-fns"
import { UserContext } from "../HomeApp"
import { isMobile } from "react-device-detect"

export const ConcertSearchResult = ({ concert }) => {
  const defaultBgImageUrl =
    "https://bandnada.s3.amazonaws.com/static/external_assets/web/bandnada_dysentery.png"
  const imgStyle = {
    width: "100%",
    aspectRatio: "16 / 9",
  }
  const bgImgStyle = {
    width: "100%",
    height: isMobile ? "200px" : "unset",
    aspectRatio: "16 / 9",
    background: `url(${concert.band_cover_photo_url_medium ||
      defaultBgImageUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }

  return (
    <li
      className="col-span-1"
      style={{ height: isMobile ? "100%" : "max-content" }}
    >
      <Link to={concert.nada_path}>
        {concert.cover_image_url ? (
          <img
            src={concert.cover_image_url}
            alt={concert.title}
            className="cursor-pointer"
            style={imgStyle}
          />
        ) : (
          <>
            <div
              className="w-full h-full bg-black text-white flex flex-col justify-end"
              style={bgImgStyle}
            >
              <div className="text-xl leading-none pb-1 pl-1 bg-black opacity-75">
                {concert.title}
              </div>
            </div>
          </>
        )}
        <div className="font-bold leading-tight text-black-800">
          {concert.band_name}
        </div>
        <div className="text-gray-800 text-sm leading-tight">
          <span>
            {SmartDateFormat({
              dateStr: concert.start_dt,
              includeTime: !concert.already_happened,
            })}
          </span>{" "}
          at {concert.venue_name}
        </div>
      </Link>
    </li>
  )
}
