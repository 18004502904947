import React from "react"
import { Link } from "react-router-dom"

export const BandStreamGuestHeader = ({
  revenueShare,
  bandName,
  guestBandName,
  guestBandPath,
}) => {
  return (
    <div className="bg-blue-200 mb-4 px-2 py-1 rounded-sm">
      <>
        <Link to={guestBandPath} className="font-bold">
          {guestBandName}
        </Link>{" "}
        is currently tagged in the <span className="font-bold">{bandName}</span>{" "}
        broadcast. &nbsp;
        {revenueShare === "even_share"
          ? `All tips are split evenly between ${bandName} and ${guestBandName}`
          : revenueShare === "full_share"
          ? `All tips go to ${guestBandName}`
          : ""}
      </>
    </div>
  )
}
