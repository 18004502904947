import { useQuery } from "react-query"
import { request } from "../util/request"

export function useBandStreamGuestsForBand(
  bandId,
  filter = "invites",
  fields = "host_band",
) {
  return useQuery(
    ["band_stream_guests_for_band", bandId, filter, fields],
    getBandStreamGuests,
  )
}

const getBandStreamGuests = async ({ queryKey }) => {
  const [key, id, filter, fields] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands/${id}/band_stream_guests.json`,
        params: { filter: filter, fields: fields },
      },
    ],
  })
}
