import { useInfiniteQuery } from "react-query"
import { request } from "../util/request"

export const FOLLOWERS_QUERY_KEY = "followers"

export function useFollowers(bandId, type = "followers", pageSize = 5) {
  const queryKey = [FOLLOWERS_QUERY_KEY, bandId, type, null, pageSize]
  return useInfiniteQuery(queryKey, getFollowers, {
    getNextPageParam: (lastPage, pages) => lastPage.next_page,
  })
}

const getFollowers = async ({ queryKey, pageParam = null }) => {
  const [key, bandId, type, page, pageSize] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands/${bandId}/user_bands`,
        params: {
          type: type,
          page: page || pageParam,
          per_page: pageSize,
        },
      },
    ],
  })
}
