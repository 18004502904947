import React from "react"
import { isMobile } from "react-device-detect"
import { useQuery } from "react-query"
import { request } from "../../../../../util/request"
import { PostListItem } from "../../../../posts/PostListItem"
import { RequestError } from "../../../../shared/RequestError"
import { Spinner } from "../../../../shared/Spinner"
import ViewerPage from "../../../../viewer/ViewerScreen"
import { BandStreamGuestHeader } from "../../../BandStreamGuestHeader"
import { BandStreamHostHeader } from "../../../BandStreamHostHeader"
import { OtherBandsSection } from "../../../OtherBandsSection"
import BandHeader from "../../BandHeader"

const TimelineTemplate = ({
  band,
  isAdmin,
  adminMode,
  setAdminMode,
  bandStreamHost,
  bandStreamGuest,
  bandStream,
}) => {
  const isLive = bandStream.broadcast_status === "live"
  const { data, status } = useQuery(
    [
      "band_highlights",
      {
        path: `/api/v1/bands/${band.id}/posts/highlights`,
      },
    ],
    request,
  )
  const width = window.innerWidth

  return (
    <div className="col-span-12">
      <BandHeader
        band={band}
        isAdmin={isAdmin}
        adminMode={adminMode}
        setAdminMode={setAdminMode}
        headerMode="coverImage"
        isLive={isLive}
      />

      <div
        className="grid grid-cols-12 pb-24"
        style={{
          marginTop: isMobile
            ? "0px"
            : isLive
            ? `${width * -0.5}px`
            : `${width * -2.93}px`,
          position: "relative",
        }}
      >
        <div className="col-span-12">
          {bandStreamHost && (
            <BandStreamHostHeader
              revenueShare={bandStreamHost.revenue_share}
              simulcast={bandStreamHost.simulcast}
              bandName={band.name}
              hostBandName={bandStreamHost.host_band.name}
              hostBandPath={bandStreamHost.host_band.band_path}
            />
          )}
          {bandStreamGuest && (
            <BandStreamGuestHeader
              revenueShare={bandStreamGuest.revenue_share}
              bandName={band.name}
              guestBandName={bandStreamGuest.guest_band.name}
              guestBandPath={bandStreamGuest.guest_band.band_path}
            />
          )}
          {bandStreamHost && bandStreamHost.simulcast ? (
            <ViewerPage
              bandId={bandStreamHost.host_band_id}
              bandStreamId={bandStreamHost.host_band_stream_id}
              bandStreamGuestId={bandStreamHost.id}
            />
          ) : (
            <ViewerPage bandId={band.id} bandStreamId={bandStream.id} />
          )}
        </div>

        <div
          className="lg:col-span-4 pr-3 hidden lg:block px-3 lg:px-4"
          style={{ marginTop: isLive ? "0px" : `${width * 0.26}px` }}
        >
          <OtherBandsSection
            bandId={band.id}
            bandName={band.name}
            colClass="lg:grid-cols-2 grid-cols-2"
          />
        </div>
        <div className="lg:col-span-8 col-span-12 px-3 lg:px-4">
          {status === "loading" ? (
            <Spinner className="text-gray-500" />
          ) : status === "error" ? (
            <RequestError error={data.error} />
          ) : (
            <ul className="list-unstyled">
              {data.posts.map(post => (
                <PostListItem
                  key={`${post.id}:${adminMode}`}
                  post={post}
                  adminMode={adminMode}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default TimelineTemplate
