import { useQuery } from "react-query"
import { request } from "../util/request"

export function useFaqs() {
  return useQuery("faqs", getFaqs)
}

const getFaqs = async ({ queryKey }) => {
  const [key] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/faqs.json`,
        params: {},
      },
    ],
  })
}
