import { useQuery } from "react-query"
import { request } from "../util/request"

export function useBnStats() {
  return useQuery("bn_stats", getBnStats)
}

const getBnStats = async ({ queryKey }) => {
  const [key] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bn_stats.json`,
        params: {},
      },
    ],
  })
}
