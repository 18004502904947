import React from "react"
import { format } from "date-fns"

export const UserInteractionForm = ({ startDate, endDate, changeHandler }) => {
  return (
    <form className="relative w-48">
      <div className="relative flex justify-between mt-1">
        <label htmlFor="start" className="mb-1 font-bold text-lg">
          From:
        </label>
        <input
          id="start"
          type="date"
          min="2018-01-01"
          max={format(Date.now(), "yyyy-MM-dd")}
          value={startDate}
          onChange={changeHandler}
          className="border-2 p-0.5 rounded-sm px-2"
        />
      </div>
      <div className="relative flex justify-between mt-1">
        <label htmlFor="end" className="mb-1 font-bold text-lg">
          To:
        </label>
        <input
          id="end"
          type="date"
          min="2018-01-01"
          max={format(Date.now(), "yyyy-MM-dd")}
          value={endDate}
          onChange={changeHandler}
          className="border-2 p-0.5 rounded-sm px-2"
        />
      </div>
    </form>
  )
}
