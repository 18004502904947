import React, { useState } from "react"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-autocomplete-places"

const LocationSearchInput = ({
  initLocation = { displayName: "" },
  setLocation,
  handleFocus = () => {},
  className = "",
}) => {
  const [address, setAddress] = useState(initLocation?.displayName || "")
  const handleChange = address => {
    setAddress(address)
    if (!address) {
      setLocation({})
    }
  }

  const handleSelect = address => {
    let locationHash = { displayName: address }
    setAddress(address)
    geocodeByAddress(address)
      .then(results => {
        if (results[0]) {
          locationHash["googlePlaceId"] = results[0].place_id
          locationHash["formattedAddress"] = results[0].formatted_address
          locationHash["types"] = results[0].types.join(",")
          locationHash["country"] = results[0].address_components.find(i =>
            i.types.includes("country"),
          )?.long_name
          locationHash["state"] = results[0].address_components.find(i =>
            i.types.includes("administrative_area_level_1"),
          )?.long_name
          locationHash["city"] = results[0].address_components.find(i =>
            i.types.includes("locality"),
          )?.long_name
          locationHash["neighborhood"] = results[0].address_components.find(i =>
            i.types.includes("neighborhood"),
          )?.long_name
          locationHash["postalCode"] = results[0].address_components.find(i =>
            i.types.includes("postal_code"),
          )?.long_name
          locationHash["sublocality"] = results[0].address_components.find(i =>
            i.types.includes("sublocality"),
          )?.long_name
          locationHash["street"] = results[0].address_components.find(i =>
            i.types.includes("route"),
          )?.long_name
          locationHash["streetNumber"] = results[0].address_components.find(i =>
            i.types.includes("street_number"),
          )?.long_name
        }
        return getLatLng(results[0])
      })
      .then(latLng => {
        locationHash["lat"] = latLng.lat
        locationHash["lng"] = latLng.lng
      })
      .then(result => {
        setLocation(locationHash)
      })
      .catch(error => console.error("Error", error))
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      onFocus={handleFocus}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="relative">
          <input
            {...getInputProps({
              placeholder: "Search Places ...",
              className: className,
            })}
          />
          <div
            className={`autocomplete-dropdown-container absolute bg-white p-2 z-50 ${
              suggestions.length > 0 ? "block" : "hidden"
            }`}
          >
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? "py-2 text-black-800"
                : "py-2 text-black-800"
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" }
              return (
                <React.Fragment key={suggestion.placeId}>
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default LocationSearchInput
