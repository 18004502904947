import React, { useState } from "react"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const MailingList = ({ band }) => {
  const [mailingListUrl, setMailingListUrl] = useState(
    band?.mailing_list_url || "",
  )

  const fieldData = {
    field: "mailing_list_url",
    name: "bandMailingListUrl",
    label: "Mailing List URL",
    placeholder: "Your Mailing List Link",
    required: false,
  }

  return (
    <EditBandFormTextField
      fieldData={fieldData}
      value={mailingListUrl}
      setValue={setMailingListUrl}
      bandId={band.id}
    />
  )
}
