import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { syncBand } from "../util/backend_api/bands"

export default function useBandSync(bandId, serviceClass) {
  const { mutate, mutateAsync, data, isLoading, isError } = useMutation(
    async () => await syncBand(bandId, serviceClass),
    {
      onSuccess: data => {
        toast.success("Syncing service now")
      },
      onError: () => {
        toast.error("Failed to sync service")
      },
    },
  )
  return { mutate, mutateAsync, data, isLoading, isError }
}
