import React, { useEffect, useState } from "react"
import { handleUpdateBandData } from "../BandEditPage"

export const EditBandFormSelectField = ({
  fieldData,
  value,
  setValue,
  options,
  isArray = false,
  bandId = null,
  onSubmit = null,
}) => {
  const [initialValue] = useState(value)
  const [validationState, setValidationState] = useState(
    value ? "valid" : "idle",
  )
  const [validationError, setValidationError] = useState(null)

  const setRemoteValue = newVal => {
    if (newVal === value) {
      setValidationState("valid")
    } else {
      setValidationState("waiting")
      setValue(newVal)
    }
  }

  const handleResponse = response => {
    if (response.success) {
      setValidationState("valid")
      setValidationError(null)
    } else {
      setValidationState("invalid")
      setValidationError(response.errors.error)
    }
  }

  useEffect(() => {
    if (value !== initialValue) {
      let updateData = {}
      updateData[fieldData.field] = isArray ? [value] : value
      setValidationState("validating")
      if (onSubmit) {
        onSubmit(updateData).then(handleResponse)
      } else {
        handleUpdateBandData(bandId, updateData).then(handleResponse)
      }
    }
  }, [bandId, fieldData.field, value])

  return (
    <div className="grid grid-cols-12 mt-10">
      <label
        className="block col-span-12 text-gray-900 text-xs uppercase font-bold"
        htmlFor={fieldData.name}
      >
        {fieldData.label}
      </label>
      <select
        className={`col-span-11 shadow appearance-none border rounded-sm w-full text-base py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
          validationState === "valid"
            ? "border-green-500"
            : validationState === "invalid"
            ? "border-red-500"
            : validationState === "validating"
            ? "border-yellow-500"
            : ""
        }`}
        value={value}
        onChange={e => setValue(e.target.value)}
        required={fieldData.required}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="col-span-1 pl-2 text-2xl">
        {validationState === "valid"
          ? "🤘"
          : validationState === "invalid"
          ? "👎"
          : validationState === "validating"
          ? "🤞"
          : validationState === "waiting"
          ? "🤔"
          : ""}
      </div>
      <div className="col-span-12 text-red-700 text-sm">{validationError}</div>
      {fieldData.notes && (
        <div className="col-span-12 pt-1 text-gray-600 text-sm">
          {fieldData.notes}
        </div>
      )}
    </div>
  )
}
