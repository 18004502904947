import { useInfiniteQuery } from "react-query"
import { request } from "../util/request"

export const SEARCH_RESULTS_QUERY_KEY = "search"

export function useInfiniteSearchResults({ query, pageSize = 12 }) {
  const queryKey = [SEARCH_RESULTS_QUERY_KEY, query, null, pageSize]
  return useInfiniteQuery(queryKey, getSearchResults, {
    getNextPageParam: (lastPage, pages) => lastPage.next_page,
  })
}

const getSearchResults = async ({ queryKey = null, pageParam = null }) => {
  const [key, query, page, pageSize] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/search/all/${query.replace(/[\/\?\&]/g, "")}`,
        params: {
          page: pageParam || page,
          per_page: pageSize,
        },
      },
    ],
  })
}
