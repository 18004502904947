import React, { useState, useEffect, useContext } from "react"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { request, mutationRequest } from "../../util/request"
import { Spinner } from "./Spinner"
import { RequestError } from "./RequestError"
import { FollowButton } from "../shared/Button"
import { Circle, CheckCircle } from "react-bootstrap-icons"
import { AuthenticationDialog } from "./AuthenticationDialog"
import { UserContext } from "../HomeApp"

export const FollowComponent = ({
  bandId,
  bandName,
  className = "",
  renderMode = "block",
}) => {
  const { user } = useContext(UserContext)
  const [userBandId, setUserBandId] = useState(null)
  const queryKey = ["user_band", user.id, bandId]
  const { isLoading, isError, data, error, isFetching } = useQuery(
    [
      queryKey,
      {
        path: "/api/v1/user_bands/anything.json",
        params: { band_id: bandId, user_id: user.id },
      },
    ],
    request,
  )

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setUserBandId(data?.user_band?.id)
    }
  }, [isLoading, isFetching])

  const mutation = useMutation(mutationRequest)

  const handleCreateUserBand = () => {
    mutation.mutate({
      options: { method: "POST" },
      path: `/api/v1/user_bands`,
      data: { band_id: bandId },
    })
  }

  const handleDestroyUserBand = () => {
    mutation.mutate({
      options: { method: "DELETE" },
      path: `/api/v1/user_bands/${userBandId}`,
    })
  }

  useEffect(() => {
    if (mutation.isSuccess) {
      setUserBandId(mutation.data.id)
    } else if (mutation.isError) {
      console.error("Failed to create user_band relationship")
    }
  }, [mutation.isSuccess, mutation.isError])

  if (renderMode === "block") {
    return (
      <div>
        {isLoading ? (
          <Spinner className="text-gray-500" />
        ) : error ? (
          <RequestError error={error} />
        ) : (
          <FollowButton
            onClick={e => {
              if (!!userBandId) {
                handleDestroyUserBand()
              } else {
                handleCreateUserBand()
              }
            }}
            className={className}
            active={!!userBandId}
          >
            {!!userBandId ? (
              <span>
                <CheckCircle
                  className="inline-block mr-1 align-text-bottom"
                  width={20}
                  height={20}
                />
                &nbsp; Following
              </span>
            ) : (
              <span>
                <Circle
                  className="inline-block mr-1 align-text-bottom"
                  width={20}
                  height={20}
                />
                &nbsp; Follow
              </span>
            )}
          </FollowButton>
        )}
      </div>
    )
  } else {
    return (
      <>
        {isLoading ? (
          <></>
        ) : error ? (
          <></>
        ) : (
          <button
            onClick={e => {
              if (!!userBandId) {
                handleDestroyUserBand()
              } else {
                handleCreateUserBand()
              }
            }}
            className={`${className} focus:outline-none`}
          >
            {!!userBandId ? <span>Stop Following</span> : <span>Follow</span>}
          </button>
        )}
      </>
    )
  }
}

export const FollowComponentLoggedOut = ({
  bandId,
  bandName,
  className = "",
  renderMode = "block",
}) => {
  const [showDialog, setShowDialog] = useState(false)

  if (renderMode === "block") {
    return (
      <div>
        <FollowButton
          className={className}
          active={false}
          onClick={e => setShowDialog(true)}
        >
          <span>
            <Circle
              className="inline-block mr-1 align-text-bottom"
              width={20}
              height={20}
            />
            Follow
          </span>
        </FollowButton>
        {showDialog ? (
          <AuthenticationDialog
            explanation={`You must first sign up or log in to follow ${bandName}`}
          />
        ) : null}
      </div>
    )
  } else {
    return (
      <>
        <button
          onClick={e => setShowDialog(true)}
          className={`${className} focus:outline-none`}
        >
          <span>Follow</span>
        </button>
      </>
    )
  }
}
