import React, { useState, useEffect } from "react"
// @ts-ignore
import styles from "../HomeScreen.module.scss"
import Viewer from "../../viewer/Viewer"
import { Link } from "react-router-dom"
import { useLiveStreams } from "../../../hooks/useLiveStreams"

export const LiveStreams = ({ isFullWidth = false }) => {
  const [refetchInterval, setRefetchInterval] = useState(60000)
  const [liveStream, setLiveStream] = useState(null)
  const { data, error, status } = useLiveStreams(null, null, 1)

  useEffect(() => {
    if (status === "success" && data.live_streams.length > 0) {
      setLiveStream(data.live_streams[0])
      setRefetchInterval(0)
    }
  }, [status])

  return (
    liveStream &&
    liveStream.band &&
    liveStream.band.band_stream && (
      <section className={`mb-24 md:mb-56 text-center ${styles.section}`}>
        <div className="mb-6 md:mb-12">
          <h3 className="text-3xl md:text-5xl leading-tight font-black">
            Currently Live on bandNada
          </h3>
        </div>
        <div className="">
          <Viewer
            bandId={liveStream.band.id}
            bandName={liveStream.band.name}
            bandStreamId={liveStream.band.band_stream.id}
            bandPath={liveStream.band.band_path}
            playbackUrl={liveStream?.band?.band_stream?.player_hls_playback_url}
            broadcastStatus={liveStream.band.band_stream.broadcast_status}
            isPreview={true}
            isFullWidth={isFullWidth}
            bandCoverPhotoURL={liveStream.image_url_medium}
          />
        </div>
        <Link
          className="font-black lg:text-4xl text-2xl uppercase"
          to={liveStream.web_path}
        >
          {liveStream.band.name}
        </Link>
        <div className="mt-2">
          Take part in the full experience on{" "}
          <a href={liveStream.web_path} className="text-blue-700 underline">
            {liveStream.band.name}
            's bandNada page
          </a>
        </div>
      </section>
    )
  )
}
