import React from "react"
import { RequestError } from "../shared/RequestError"
import { FeedSpinner } from "../shared/Spinner"
import { PostListItem } from "../posts/PostListItem"
import ErrorBoundary from "react-error-boundary"
import { useInfiniteBandPosts } from "../../hooks/useBandPosts"

export const PostSection = ({
  title,
  bandId,
  bandName,
  contentType,
  colClass,
  adminMode = false,
}) => {
  const pageSize = adminMode ? 12 : 4
  const {
    data,
    error,
    fetchNextPage,
    isFetching,
    status,
  } = useInfiniteBandPosts({
    bandId: bandId,
    contentType: contentType,
    adminMode: adminMode,
    pageSize: pageSize,
  })
  const lastPage = status === "success" ? data.pages.slice(-1)[0] : null

  return (
    <ErrorBoundary>
      <div id={contentType}>
        {status === "loading" ? (
          <FeedSpinner />
        ) : status === "error" ? (
          <RequestError error={error} />
        ) : (
          <>
            {data.pages[0].total_count > 0 && (
              <>
                {title && (
                  <h2 className="border-b-4 border-black font-bold my-4 text-2xl uppercase">
                    {title}
                  </h2>
                )}
                <div>
                  <ul className={`grid ${colClass} gap-8`}>
                    {data.pages.map((page, i) => (
                      <React.Fragment key={page.page}>
                        {page.posts.map(post => (
                          <PostListItem
                            key={`${post.id}:${adminMode}`}
                            post={post}
                            adminMode={adminMode}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </ul>
                  {lastPage.has_next_page && (
                    <button
                      className="text-blue-600 focus:outline-none"
                      onClick={e => fetchNextPage()}
                      disabled={isFetching}
                    >
                      {isFetching
                        ? "loading..."
                        : `See more ${title.toLowerCase()} from ${bandName}`}
                    </button>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </ErrorBoundary>
  )
}
