import React, { useRef, useState, useLayoutEffect } from "react"
import useComponentSize from "@rehooks/component-size"
import { isMobile } from "react-device-detect"
import { PostListItemStyles, PostListItemExpandedStyles } from "./constants"
import FreeTextFormat from "../shared/FreeTextFormat"

export const TextAsImage = ({
  text,
  expanded = false,
  className = "",
  onClick = e => {},
}) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  if (!text) text = ""

  const ref = useRef(null)
  let size = useComponentSize(ref)

  useLayoutEffect(() => {
    setWidth(size.width)
    setHeight(width ? (width * 9) / 16 : 0)
  })
  // max chars: 250
  const headlineText = text && text > 250 ? `${text.substr(0, 250)}..` : text

  // dynamic font size based on headline title length
  const multiplier = isMobile ? 1.3 : 2.5
  const fontSize = `${(250 / headlineText.length) * multiplier + 25}px`

  const headlineStyle = {
    fontSize: fontSize,
    height: height,
  }
  const contentDivStyles = expanded
    ? PostListItemExpandedStyles.contentDiv
    : PostListItemStyles.contentDiv
  return (
    <div
      ref={ref}
      style={headlineStyle}
      onClick={onClick}
      className={
        contentDivStyles +
        " text-as-image flex flex-row items-center px-4 leading-none font-bold bg-black text-white w-full " +
        className
      }
    >
      <FreeTextFormat
        text={headlineText}
        expandable={false}
        expanded={false}
        linkify={true}
      />
    </div>
  )
}
