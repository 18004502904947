import React, { useEffect, useMemo, useState } from "react"
import { usePosts } from "../../../hooks/usePosts"
import Heading from "../../shared/Heading"
import { RequestError } from "../../shared/RequestError"
import { Spinner } from "../../shared/Spinner"
import { Link } from "react-router-dom"

const ShowOfTheWeekSidebar = ({ className = "" }) => {
  const PER_PAGE = 20
  const [page, setPage] = useState(1)

  const [_calendarEvents, setCalendarEvents] = useState([])
  const calendarEvents = useMemo(() => _calendarEvents, [_calendarEvents])

  const { isLoading, isError, isFetching, data, error } = usePosts(
    "show_of_week",
    "everything",
    page,
    PER_PAGE,
  )

  useEffect(() => {
    if (!isLoading && !isError && !isFetching) {
      if (page === 1) {
        setCalendarEvents(data.posts)
      } else {
        setCalendarEvents([...data.posts, ...calendarEvents])
      }
    }
  }, [isFetching])

  if (_calendarEvents.length === 0) {
    return <></>
  }

  return (
    <div className={className}>
      <Heading level={2} type="sectionHeader">
        bandNada Show of the Week
      </Heading>
      <div className="flex flex-col">
        {isFetching ? (
          <Spinner />
        ) : error ? (
          <RequestError error={error} />
        ) : data ? (
          <>
            <ul>
              {calendarEvents.length > 0 ? (
                calendarEvents.map(concert => (
                  <Link to={concert.nada_path} key={concert.id}>
                    <img
                      src={concert.cover_image_url}
                      alt={concert.title}
                      className="cursor-pointer"
                    />
                  </Link>
                ))
              ) : (
                <li className="italic text-gray-600 text-sm">
                  No Shows of the Week currently
                </li>
              )}
            </ul>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default ShowOfTheWeekSidebar
