import React from "react"
import { ErrorBoundary } from "./shared/ErrorBoundary"
import { useQuery } from "react-query"
import { request } from "../util/request"
import { ActionCableProvider } from "react-actioncable-provider"
import Header from "./shared/Header/Header"

export default function HeaderEntry({ env, host }) {
  const userData = useQuery(["user", { path: `/api/v1/users/1.json` }], request)
  var user = userData?.data?.user
  const wsURL =
    env === "development" ? `ws://${host}/cable` : `wss://${host}/cable`

  return (
    <ErrorBoundary>
      <ActionCableProvider url={wsURL}>
        <Header user={user} standAlone={true} />
      </ActionCableProvider>
    </ErrorBoundary>
  )
}
