import React from "react"
import styles from "./Heading.module.scss"

const Heading = ({
  level = 2,
  type = "headline",
  className = "",
  children,
}) => {
  const Tag = level > 6 ? "h6" : `h${level}`

  return <Tag className={`${styles[type]} ${className}`}>{children}</Tag>
}

export default Heading
