import React from "react"
import { PersonFill } from "react-bootstrap-icons"
import { isMobile } from "react-device-detect"
import UserAvatar from "../shared/Header/UserAvatar"

export const UserListItem = ({ user, size = "medium" }) => {
  const sizeChart = {
    small: { text: "lg:text-sm text-xs" },
    medium: { text: "lg:text-lg text-base" },
    large: { text: "lg:text-xl text-lg" },
  }
  let name = user.band
    ? user.band.name
    : user.nickname
    ? user.nickname
    : user.name

  return (
    <li className="col-span-1">
      {true ? (
        <UserAvatar
          handleClick={() => {}}
          size="medium"
          user={user}
          inHeader={false}
        />
      ) : (
        <div
          title={name}
          className="w-full text-center bg-gray-200 text-blue-400 relative"
          style={{ paddingTop: "100%" }}
        >
          <PersonFill
            size={isMobile ? "50" : "100"}
            className="absolute m-auto w-full h-full"
            style={{ top: 0, bottom: 0, left: 0, right: 0 }}
          />
        </div>
      )}
      <h2
        title={name}
        className={`font-bold py-1 text-gray-800 uppercase leading-none truncate ${sizeChart[size]["text"]}`}
      >
        {name}
      </h2>
    </li>
  )
}

export default UserListItem
