import React, { useContext, useEffect } from "react"
import { FeedSpinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { PostListItem } from "../posts/PostListItem"
import { Link } from "react-router-dom"
import SellItItem from "../home/components/SellItItem"
import { UserContext } from "../HomeApp"
import { useInfinitePosts } from "../../hooks/usePosts"
import { discover_path } from "../../routes"

export const BandsFeed = ({
  filter = "",
  showPitchInFeed,
  showDialog,
  setShowDialog,
  feedType,
  newData = null,
}) => {
  const { scene } = useContext(UserContext)
  const pageSize = 12

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfinitePosts({
    feedType: feedType,
    scene: scene,
    contentType: filter,
    pageSize: pageSize,
  })

  const addScrollEventListener = () => {
    var scrollContainer = document.getElementById("home-screen-container")
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll)
    }
  }

  const removeScrollEventListener = () => {
    var scrollContainer = document.getElementById("home-screen-container")
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", handleScroll)
    }
  }

  useEffect(() => {
    if (
      newData &&
      data?.pages?.length &&
      (!filter ||
        filter === newData.post_type ||
        (filter === "headline" && newData.post_type === "link"))
    ) {
      data.pages[0].posts = [newData, ...data.pages[0].posts]
    }
  }, [newData])

  useEffect(() => {
    if (!isFetching && !isFetchingNextPage && hasNextPage) {
      addScrollEventListener()
    }
    return () => removeScrollEventListener()
  }, [isFetching])

  function handleScroll() {
    if (isFetchingNextPage) return
    var scrollContainer = document.getElementById("home-screen-container")
    if (
      scrollContainer.scrollTop /
        (scrollContainer.scrollHeight - scrollContainer.offsetHeight) >
      0.8
    ) {
      removeScrollEventListener()
      fetchNextPage()
    }
  }

  return (
    <>
      <ul>
        {status === "loading" ? (
          <FeedSpinner />
        ) : status === "error" ? (
          <RequestError error={error} />
        ) : data.pages && data.pages[0].total_count > 0 ? (
          <>
            {data.pages.map((page, pageIndex) => (
              <React.Fragment key={page.page}>
                {page.posts.map((post, postIndex) => (
                  <React.Fragment key={post.id}>
                    {showPitchInFeed && postIndex % 3 === 1 && (
                      <>
                        <SellItItem
                          // @ts-ignore
                          index={pageIndex * 4 + Math.round(postIndex / 3)}
                          showDialog={showDialog}
                          setShowDialog={setShowDialog}
                        />
                      </>
                    )}
                    <PostListItem
                      key={`${post.id}-${post.updated_at}`}
                      post={post}
                    />
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
            <li>{isFetching && !isFetchingNextPage ? "Loading..." : null}</li>
            {hasNextPage && !isFetchingNextPage && (
              <li>
                <button onClick={e => fetchNextPage()}>Show More</button>
              </li>
            )}
          </>
        ) : (
          <li>
            This is your personalized band feed. No complicated algorithms or
            pay to play pricing.{" "}
            <Link to={discover_path()} className="text-blue-600">
              Start following bands
            </Link>{" "}
            on bandNada to see new content from them in chronological order
            here.
          </li>
        )}
      </ul>
    </>
  )
}

export default BandsFeed
