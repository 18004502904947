import React from "react"
import { Link } from "react-router-dom"

export const BandStreamHostHeader = ({
  revenueShare,
  simulcast,
  bandName,
  hostBandName,
  hostBandPath,
}) => {
  return (
    <div className="bg-blue-200 mb-4 px-2 py-1 rounded-sm">
      {simulcast ? (
        <>
          You are watching the{" "}
          <Link to={hostBandPath} className="font-bold">
            {hostBandName}
          </Link>{" "}
          stream because <span className="font-bold">{bandName}</span> are
          tagged in their current broadcast. &nbsp;
          {revenueShare === "zero"
            ? `The chat is joined but tips go to ${hostBandName}`
            : revenueShare === "even_share"
            ? `The chat is joined and tips are split evenly between ${bandName} and ${hostBandName}`
            : `The chat is joined and all tips go to ${bandName}`}
          )
        </>
      ) : (
        <div>
          <span className="font-bold">{bandName}</span> is currently tagged in
          the{" "}
          <Link to={hostBandPath} className="font-bold">
            {hostBandName}
          </Link>{" "}
          stream!
        </div>
      )}
    </div>
  )
}
