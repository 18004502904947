import { useQuery } from "react-query"
import { request } from "../util/request"

export function useSyncSummary(bandId, serviceClass) {
  return useQuery(["sync_summary", bandId, serviceClass], getSyncSummary)
}

const getSyncSummary = async ({ queryKey }) => {
  const [key, bandId, serviceClass] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands/${bandId}/sync_summaries/${serviceClass}.json`,
      },
    ],
  })
}
