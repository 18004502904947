import React, { useState } from "react"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const BandName = ({ band }) => {
  const [bandName, setBandName] = useState(band?.name || "")

  const fieldData = {
    field: "name",
    name: "bandName",
    label: `${band.band_type_nice} Name (required)`,
    placeholder: `Your ${band.band_type_nice} name`,
    required: true,
  }

  return (
    <EditBandFormTextField
      fieldData={fieldData}
      value={bandName}
      setValue={setBandName}
      bandId={band.id}
    />
  )
}
