import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import { format } from "date-fns"
import { UserContext } from "../HomeApp"
import { prependAndPlay, playOrPause } from "../../data/mediaPlayerSlice"
import { useBandPosts } from "../../hooks/useBandPosts"
import { compareByKey } from "../../util/utils"
import { useDispatch, useSelector } from "react-redux"
import { usePost } from "../../hooks/usePost"
import { PauseCircle, PlayCircle } from "react-bootstrap-icons"
import { Spinner } from "../shared/Spinner"
import { isMobile } from "react-device-detect"

export const AlbumSearchResult = ({ album }) => {
  const { isLoading, isError, isFetching, data, error } = usePost(
    album.id,
    "songs",
  )
  const [albumName, setAlbumName] = useState("")

  const dispatch = useDispatch()
  const onPlayClick = e => {
    dispatch(prependAndPlay(sortedSongs()))
  }
  const onPlayOrPauseClick = e => {
    dispatch(playOrPause())
  }

  const sortedSongs = () => {
    return [...data.post.songs]
      .sort((a, b) => compareByKey(a, b, "track_number"))
      .map(song => ({
        ...song,
        album_name: song.album_name,
        cover_image_small: song.cover_image_small,
      }))
  }
  const currentMediaItem = useSelector(
    // @ts-ignore
    state => state.mediaPlayer.value.currentMediaItem,
  )
  // @ts-ignore
  const playerState = useSelector(state => state.mediaPlayer.value.playerState)
  const isCurrentlyPlaying =
    playerState === "playing" && currentMediaItem.albumId === album.id

  useEffect(() => {
    if (data && data.posts && data.posts.length > 0) {
      setAlbumName(data.posts[0].album_name)
    }
  }, [isLoading])

  return (
    <li className="col-span-1" style={{ height: "max-content" }}>
      <>
        <div
          style={{
            backgroundImage: `url(${album.cover_image_large})`,
            backgroundSize: "cover",
            aspectRatio: "1/1",
            minHeight: isMobile ? "156px" : "unset",
          }}
          className="w-full"
        >
          <div
            className="w-full h-full justify-center flex items-center"
            style={{
              background: "rgb(0,0,0,0.3)",
              paddingTop: isMobile ? "36px" : "unset",
              paddingBottom: isMobile ? "36px" : "unset",
            }}
          >
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <>{error}</>
            ) : data && data.post && data.post.songs.length > 0 ? (
              isCurrentlyPlaying ? (
                <PauseCircle
                  className="text-white cursor-pointer"
                  width="50%"
                  height="50%"
                  onClick={onPlayOrPauseClick}
                />
              ) : (
                <PlayCircle
                  className="text-white cursor-pointer"
                  width="50%"
                  height="50%"
                  onClick={onPlayClick}
                />
              )
            ) : null}
          </div>
        </div>
      </>
      <Link to={album.nada_path}>
        <div className="font-bold leading-tight">{album.title}</div>

        <div className="leading-tight">
          <span className="text-black-800 text-sm">
            {SmartDateFormat({
              dateStr: album.active_date,
              includeTime: false,
            })}
          </span>{" "}
          <span className="text-gray-600 text-sm">by</span>{" "}
          <span className="text-black-800">{album.band_name}</span>
        </div>
      </Link>
    </li>
  )
}
