import React, { useRef, useState, useLayoutEffect } from "react"
import useComponentSize from "@rehooks/component-size"
import { PostListItemStyles, PostListItemExpandedStyles } from "./constants"

const defaultUnfurledObj = {
  best_image: "",
  title: "title loading",
  best_title: "title loading",
  description: "description loading",
  host: "external site",
}

export const LinkPreview = ({
  defaultImage,
  unfurledObj = defaultUnfurledObj,
  expanded = false,
  onClick = e => {},
}) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const ref = useRef(null)
  let size = useComponentSize(ref)

  useLayoutEffect(() => {
    setWidth(size.width)
    setHeight(width ? (width * 9) / 16 : 0)
  })

  const metaDataStyle = {
    width: "100%",
  }

  const contentDivStyles = expanded
    ? PostListItemExpandedStyles.contentDiv
    : PostListItemStyles.contentDiv

  return (
    <div
      ref={ref}
      className={contentDivStyles + " col-span-12 cursor-pointer"}
      onClick={onClick}
    >
      <img src={defaultImage} alt={unfurledObj.title} style={metaDataStyle} />
      {unfurledObj.host || unfurledObj.best_title || unfurledObj.description ? (
        <div style={metaDataStyle} className="bg-gray-300 px-3 py-2">
          <p className="text-gray-900 text-xs uppercase font-bold">
            {unfurledObj.host}
          </p>
          <p className="text-xs font-bold">{unfurledObj.best_title}</p>
          <p className="text-xxs">{unfurledObj.description}</p>
        </div>
      ) : null}
    </div>
  )
}
