import { isMobile } from "react-device-detect"

function useVideoHeight(isFullWidth = false) {
  // const [width, setWidth] = useState(0)
  // const [height, setHeight] = useState(0)

  // useLayoutEffect(() => {
  //   setWidth(window.innerWidth)
  //   setHeight(width / (isMobile ? 1.899 : isFullWidth ? 1.838 : 2.741))
  // })

  const height =
    window.innerWidth / (isMobile ? 1.899 : isFullWidth ? 1.838 : 2.741)

  return {
    height,
  }
}

export default useVideoHeight
