import React from "react"
import { Link } from "react-router-dom"
import { stripText } from "../../../util/strings"
import CountdownBlock from "../../shared/CountdownBlock"
import UserAvatar from "../../shared/Header/UserAvatar"

export const ActivityEvent = ({ activityEvent }) => {
  const ACTION_TEXT_MAP = {
    signup_band: {
      text: "just created their band page",
      position: "post",
    },
    live_stream: {
      text: "just went live",
      position: "post",
    },
    created_headline: {
      text: "created the headline",
      position: "pre",
    },
    created_event: {
      text: "created the event",
      position: "pre",
    },
    added_comment: {
      text: "commented on",
      position: "pre",
    },
    added_chat: {
      text: "chatted in",
      position: "pre",
    },
    event_response: {
      text: "is interested in the event",
      position: "pre",
    },
    added_tip: {
      text: "tipped in",
      position: "pre",
    },
    reacted: {
      text: "reacted to",
      position: "pre",
    },
  }
  const actionText = ACTION_TEXT_MAP[activityEvent.event_type]

  return (
    <li className="bg-gray-300 px-2 py-1 mb-2 rounded-smn text-sm">
      <span className="flex flex-row">
        <UserAvatar
          handleClick={() => {}}
          user={{
            id: activityEvent.user_id,
            avatar_url_small: activityEvent.user_avatar_url,
          }}
          size="small"
          inHeader={false}
        />
        <div className="px-4 w-full">
          <div>
            <span className="text-gray-900 font-bold mr-3">
              {activityEvent.user_path ? (
                <Link to={activityEvent.user_path}>
                  {activityEvent.username}
                </Link>
              ) : (
                activityEvent.username
              )}
            </span>
            <span className="text-gray-700 text-xs date-text">
              {CountdownBlock(activityEvent.created_at, "align-middle")}
            </span>
          </div>
          <div>
            {actionText.position === "pre" ? `${actionText.text} ` : null}
            <Link to={activityEvent.content_path} className="text-blue-600">
              {stripText(activityEvent.content_title)}
            </Link>
            {actionText.position === "post" ? ` ${actionText.text}` : null}
          </div>
        </div>
      </span>
    </li>
  )
}
