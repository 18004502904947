import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"

export const Button = ({ className = "", ...props }) => (
  <button
    type="button"
    className={cx(
      `${
        props.bg ? props.bg : "bg-green-400"
      } text-white font-bold rounded focus:outline-none text-base py-2 px-4`,
      props.disabled && "opacity-50",
      className,
    )}
    {...props}
  />
)

export const SecondaryButton = ({
  children,
  className = "",
  active = false,
  ...props
}) =>
  props.to ? (
    <Link
      className={cx(
        `flex bg-gray-300 font-bold rounded focus:outline-none text-base text-center py-2 px-4`,
        props.disabled && "opacity-50",
        active
          ? "bg-gray-900 text-white"
          : "hover:bg-gray-400 hover:text-black-800",
        className,
      )}
      to={props.to}
    >
      {children}
    </Link>
  ) : (
    <button
      type="button"
      className={cx(
        `flex bg-gray-300 font-bold rounded focus:outline-none text-base py-2 px-4`,
        props.disabled && "opacity-50",
        active
          ? "bg-gray-900 text-white"
          : "hover:bg-gray-400 hover:text-black-800",
        className,
      )}
      {...props}
    >
      {children}
    </button>
  )

export const InterestedButton = ({
  className = "",
  renderMode = "full",
  ...props
}) =>
  renderMode === "minimal" ? (
    <button
      type="button"
      className={cx(
        `${
          props.bg ? props.bg : "bg-green-400"
        } focus:outline-none font-bold px-2 py-1 rounded text-sm text-white`,
        props.disabled && "opacity-50",
        className,
      )}
      {...props}
    />
  ) : renderMode === "full" ? (
    <button
      type="button"
      className={cx(
        `${
          props.bg ? props.bg : "bg-green-400"
        } text-white font-bold rounded focus:outline-none text-base py-2 px-4`,
        props.disabled && "opacity-50",
        className,
      )}
      {...props}
    />
  ) : renderMode === "text" ? (
    <button className="text-link" />
  ) : null

export const SmallButton = ({ className = "", ...props }) => (
  <button
    type="button"
    className={cx(
      `${
        props.bg ? props.bg : "bg-green-400"
      } text-white font-bold rounded focus:outline-none text-sm py-1 px-2`,
      props.disabled && "opacity-50",
      className,
    )}
    {...props}
  />
)

export const XSmallButton = ({ className = "", ...props }) => (
  <button
    type="button"
    className={cx(
      `${
        props.bg ? props.bg : "bg-white"
      } text-gray-700 border border-gray-700 font-bold rounded focus:outline-none text-xs py-1 px-2`,
      props.disabled && "opacity-50",
      className,
    )}
    {...props}
  />
)

export const ShowHideButton = ({ className = "", ...props }) => (
  <div className="pb-2 pt-1">
    <SmallButton
      onClick={e =>
        props
          .updateStatus()
          .then(() =>
            props.setPostStatus(
              props.postStatus === "published" ? "trashed" : "published",
            ),
          )
      }
      bg={props.postStatus === "published" ? "bg-red-400" : "bg-green-400"}
    >
      {props.postStatus === "published" ? "Hide Post" : "Show Post"}
    </SmallButton>
  </div>
)

export const LinkButton = ({ className = "", to, ...props }) => (
  <Link
    to={to}
    className={cx(
      `inline-block text-center ${
        props.bg ? props.bg : "bg-green-400"
      } text-white font-bold rounded focus:outline-none text-base py-2 px-4`,
      props.disabled && "opacity-50",
      className,
    )}
    {...props}
  />
)

export const AnchorButton = ({ href, children, target }) => (
  <a
    className="text-white font-bold rounded focus:outline-none py-1 px-2 bg-green-400 inline-block text-center"
    href={href}
    target={target}
  >
    {children}
  </a>
)

export const SubmitButton = ({ className, active, ...props }) => (
  <button
    type="submit"
    disabled={!active}
    className={cx(
      "bg-green-400 text-white font-bold rounded focus:outline-none uppercase text-xl py-2 px-2 w-full",
      !active && "opacity-50",
      className,
    )}
    {...props}
  />
)

export const HomePageButton = ({
  className = "",
  bgColor = "bg-black-800",
  textColor = "text-white",
  active = true,
  ...props
}) => (
  <button
    type="submit"
    disabled={!active}
    className={cx(
      "hover:opacity-25 py-3 px-12 rounded font-medium focus:outline-none",
      !active && "opacity-50",
      bgColor,
      textColor,
      className,
    )}
    {...props}
  />
)

export const BroadcastButton = ({ className, active, ...props }) => (
  <button
    type="button"
    className={cx(
      "text-white font-bold rounded focus:outline-none text-base py-2 w-full",
      active ? "bg-red-400" : "bg-green-400",
      className,
    )}
    {...props}
  />
)

export const GuestManagerButton = ({ disabled = false, ...props }) => (
  <button
    disabled={disabled}
    className={cx(
      "focus:outline-none border border-gray-500 text-gray-700 px-2 rounded-sm",
      disabled ? "opacity-50" : "",
    )}
    {...props}
  />
)

export const OutlineButton = ({ disabled = false, ...props }) => (
  <button
    disabled={disabled}
    className={cx(
      "focus:outline-none border text-base border-gray-500 text-white px-2 py-1 rounded-sm align-bottom",
      disabled ? "opacity-50" : "",
    )}
    {...props}
  />
)

export const FollowButton = ({ className, active, ...props }) => (
  <button
    type="button"
    className={cx(
      "text-white font-bold rounded-b focus:outline-none text-base py-2 px-4 w-full",
      active ? "bg-red-400" : "bg-green-400",
      className,
    )}
    {...props}
  />
)
export const SignInButton = ({ className, active, ...props }) => (
  <button
    type="button"
    className={cx(
      "text-white font-bold rounded focus:outline-none hover:bg-blue-700 focus:shadow-outline text-base py-2 px-4",
      active ? "bg-blue-500" : "bg-blue-200",
      className,
    )}
    {...props}
  />
)
