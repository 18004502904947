import React, { useState, useEffect, useRef } from "react"
import useBroadcaster from "./Broadcaster"
import { Spinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { BroadcastButton } from "../shared/Button"
import { LockFill, InfoCircleFill } from "react-bootstrap-icons"
import TakeoverManager from "./TakeoverManager"
import { useBandStream } from "../../hooks/useBandStream"
import {
  handleUpdateBandStreamData,
  BroadcastStatus,
  BroadcastLinkInfo,
  StreamMode,
} from "./BroadcastScreen"

export const WebBroadcaster = ({
  band,
  broadcastType,
  setBroadcastType,
  isDebug,
  bandStream,
  bandStreamGuestRTCSettings,
  broadcastState,
  setBroadcastState,
}) => {
  const [isTestMode, setIsTestMode] = useState(false)
  const [audioSelect, setAudioSelect] = useState([])
  const [videoSelect, setVideoSelect] = useState([])
  var videoRef = useRef()
  var audioAnalyserRef = useRef()
  var audioSelectRef = useRef()
  var videoSelectRef = useRef()
  const [bandStreamId, setBandStreamId] = useState(band.band_stream_id)

  useEffect(() => {
    if (bandStream?.origin !== "web" && bandStream?.live_at) {
      setBroadcastType(bandStream?.origin)
    }
  }, [bandStream?.origin, bandStream?.live_at])

  const updateBroadcastState = ({ isLive, _isTestMode }) => {
    if (!bandStreamId) return null
    if (isLive && broadcastState === "idle") {
      if (!startPublisher()) {
        return
      }
    } else if (!isLive && broadcastState === "broadcasting") {
      stopPublisher()
    }
    setBroadcastState(isLive ? "broadcasting" : "idle")
    setIsTestMode(_isTestMode)
    handleUpdateBandStreamData(bandStreamId, {
      is_live: isLive,
      is_test_mode: _isTestMode,
      origin: "web",
    })
  }

  const onGuestStreamError = error => {
    console.log("GuestStreamError: ", error)
  }

  const {
    startPublisher,
    stopPublisher,
    setAudioDevice,
    setVideoDevice,
    setGuestStreamSettings,
    startGuestStream,
    stopGuestStream,
    guestStreamConnected,
    userErrorMessage,
    sdpOutput,
  } = useBroadcaster({
    applicationName: bandStream.source_application_name,
    streamName: bandStream.source_stream_name,
    wsURL: bandStream.source_sdp_url,
    setAudioSelect: setAudioSelect,
    setVideoSelect: setVideoSelect,
    audioSelectRef: audioSelectRef,
    videoSelectRef: videoSelectRef,
    videoRef: videoRef,
    audioAnalyserRef: audioAnalyserRef,
    isDebug: isDebug,
    onGuestStreamError: onGuestStreamError,
  })

  useEffect(() => {
    setGuestStreamSettings(bandStreamGuestRTCSettings)
    if (broadcastState === "broadcasting") {
      if (guestStreamConnected) {
        stopGuestStream()
      } else if (bandStreamGuestRTCSettings.wsURL) {
        startGuestStream()
      }
    }
  }, [
    broadcastState,
    bandStreamGuestRTCSettings.wsURL,
    bandStreamGuestRTCSettings.applicationName,
    bandStreamGuestRTCSettings.streamName,
  ])

  const handleBroadcastError = errorMessage => {
    if (broadcastState === "broadcasting") {
      setBroadcastState("idle")
      handleUpdateBandStreamData({ is_live: false })
    }
  }

  useEffect(() => {
    handleBroadcastError(userErrorMessage)
  }, [userErrorMessage])

  return (
    <>
      <>
        <BroadcastStatus
          broadcastState={broadcastState}
          isTestMode={isTestMode}
          audioAnalyserRef={audioAnalyserRef}
        />
        <div className="w-full" style={{ paddingBottom: "56.25%" }}>
          <div className="relative">
            <div className="absolute">
              <video
                className="w-full"
                id=""
                autoPlay={true}
                muted={!guestStreamConnected}
                ref={videoRef}
              />
            </div>
          </div>
        </div>

        {userErrorMessage ? (
          <div
            id="user-error-messaging"
            className="bg-red-500 p-2 text-white opacity-75 relative"
            style={{ marginTop: "-41px" }}
          >
            There was a problem! - {userErrorMessage}
          </div>
        ) : null}

        <div className="grid grid-cols-12 pt-2">
          <div className="col-span-6">
            <BroadcastLinkInfo
              bandPath={band.band_path}
              bandStreamToken={bandStream.token}
              isTestMode={isTestMode}
            />
          </div>
          <div className="col-span-6 text-right">
            <div className="grid grid-cols-2">
              <div className="col-span-1">
                <BroadcastButton
                  className="mb-1"
                  active={broadcastState === "broadcasting" && isTestMode}
                  onClick={e => {
                    updateBroadcastState({
                      isLive:
                        broadcastState === "broadcasting"
                          ? isTestMode
                            ? false
                            : true
                          : true,
                      _isTestMode: true,
                      origin: "web",
                    })
                  }}
                >
                  {broadcastState === "broadcasting"
                    ? isTestMode
                      ? "End Test"
                      : "Switch to Test Mode"
                    : "Start Test Stream"}
                </BroadcastButton>
              </div>
              <div className="col-span-1 ml-2">
                <BroadcastButton
                  id="buttonGo"
                  className=""
                  active={broadcastState === "broadcasting" && !isTestMode}
                  onClick={e => {
                    updateBroadcastState({
                      _isTestMode: false,
                      isLive:
                        broadcastState === "broadcasting"
                          ? isTestMode
                            ? true
                            : false
                          : true,
                      origin: "web",
                    })
                  }}
                >
                  {broadcastState === "broadcasting"
                    ? isTestMode
                      ? "Start Show"
                      : "End Show"
                    : "Start Show"}
                </BroadcastButton>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 grid grid-cols-12">
          <div className="col-span-12">
            <h4 className="font-bold">Settings</h4>
            <select
              ref={audioSelectRef}
              id="audioSource"
              onChange={e => setAudioDevice(e)}
              disabled={broadcastState === "broadcasting"}
              className="border-2 px-1 py-1 text-sm rounded-sm mr-2 mb-1"
            >
              {audioSelect.map(option => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
            <select
              ref={videoSelectRef}
              id="videoSource"
              disabled={broadcastState === "broadcasting"}
              onChange={e => setVideoDevice(e)}
              className="border-2 px-1 py-1 text-sm rounded-sm"
            >
              {videoSelect.map(option => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
        </div>
        {band.enable_takeover_manager && (
          <TakeoverManager bandId={band.id} bandStreamId={bandStream.id} />
        )}
        <div className="grid grid-cols-12 pb-4">
          <div className="col-span-12">
            <div className="mt-2">
              <h4 className="font-bold">Having trouble?</h4>
              If you don't see video of yourself above, click the
              <LockFill className="inline mx-1 text-gray-700 align-baseline" />
              or the
              <InfoCircleFill className="inline mx-1 text-gray-700 align-baseline" />
              icon to the left of your browser's url bar and allow camera and
              microphone access
            </div>
            {isDebug ? <div id="sdpDataTag">SDP: {sdpOutput}</div> : null}
          </div>
        </div>
      </>
    </>
  )
}
