import React, { useEffect, useState } from "react"
import { RequestError } from "../../components/shared/RequestError"
import { Spinner, SpinnerSmall } from "../../components/shared/Spinner"
import { CheckCircle, XCircle } from "react-bootstrap-icons"
import { ActionCableConsumer } from "react-actioncable-provider"
import { useSyncSummary } from "../../hooks/useSyncSummary"
import useBandSync from "../../hooks/useBandSync"
import { XSmallButton } from "../../components/shared/Button"

export const SyncSummary = ({ fieldData, bandId, value }) => {
  const [bandName, setBandName] = useState(null)
  const [summary, setSummary] = useState(null)
  const [syncStatus, setSyncStatus] = useState(null)
  const { status, data, error } = useSyncSummary(bandId, fieldData.serviceClass)
  const {
    mutate,
    data: mutateData,
    isError,
  } = useBandSync(bandId, fieldData.serviceClass)
  let syncSummary = status === "success" ? data.sync_summary : null

  const updateSyncSummaryData = (syncSummaryData) => {
    setBandName(syncSummaryData?.sync_details_hash?.synced_band_name)
    setSummary(syncSummaryData?.summary)
    setSyncStatus(syncSummaryData?.status)
  }

  const syncNow = () => {
    setSyncStatus("syncing")
    mutate()
  }

  useEffect(() => {
    if (status === "success") {
      setBandName(syncSummary?.sync_details_hash.synced_band_name)
      setSummary(syncSummary?.summary)
      setSyncStatus(syncSummary?.status)
    }
  }, [
    syncSummary?.status,
    syncSummary?.summary,
    syncSummary?.sync_details_hash?.synced_band_name,
    status,
  ])

  return (
    <div className="grid grid-cols-12 mt-2">
      {status === "loading" || !syncSummary ? (
        <Spinner className="text-gray-600" />
      ) : status === "error" ? (
        <RequestError error={error} />
      ) : (
        <>
          <ActionCableConsumer
            channel={{
              channel: "SyncSummaryChannel",
              id: syncSummary.id,
            }}
            onReceived={updateSyncSummaryData}
          />
          {syncStatus && syncStatus !== "never_synced" && (
            <div className="col-span-12 text-sm">
              <span className="text-xs uppercase text-gray-700 font-bold">
                Syncing {fieldData.name} For:
              </span>
              <span className="font-bold text-black pl-2">{bandName}</span>
              <span className="text-sm text-gray-500 pl-2 pr-2">{summary}</span>

              {syncStatus === "idle" ? (
                <span className="text-green-600">
                  Synced{" "}
                  <CheckCircle className="text-green-600 inline align-baseline" />
                </span>
              ) : syncStatus === "syncing" ? (
                <span className="text-yellow-600">
                  Syncing <SpinnerSmall className="text-yellow-600" />
                </span>
              ) : syncStatus === "failed" ? (
                <span className="text-red-600">
                  Failed{" "}
                  <XCircle className="text-red-600 inline align-baseline" />
                </span>
              ) : syncStatus === "permanent_fail" ? (
                <span className="text-red-600">
                  Permanent Failure - please double check that the url is right
                </span>
              ) : null}
              <XSmallButton
                className="ml-3"
                onClick={syncNow}
                disabled={syncStatus === "syncing"}
              >
                Sync Now
              </XSmallButton>
            </div>
          )}
        </>
      )}
    </div>
  )
}
