import React, { useEffect, useRef, useState } from "react"
import { useMutation } from "react-query"
import { mutationRequest } from "../../util/request"
import { Button, SubmitButton } from "../shared/Button"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"
import { AuthenticationDialog } from "../shared/AuthenticationDialog"
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import CardSection from "./CardSection"
import { BellFill } from "react-bootstrap-icons"
import { useContext } from "react"
import { UserContext } from "../HomeApp"

export const TipComponent = ({
  bandId,
  bandName,
  userName,
  userDefaultPaymentMethodLastFour,
  bandStreamId = null,
  promotionId = null,
}) => {
  const { spk } = useContext(UserContext)
  const stripePromiseRef = useRef()
  const [tipAmount, setTipAmount] = useState("2")
  const [tip, setTip] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [lastFour, setLastFour] = useState(userDefaultPaymentMethodLastFour)
  const openDialog = () => setShowDialog(true)
  const closeDialog = () => setShowDialog(false)

  useEffect(() => {
    // @ts-ignore
    stripePromiseRef.current = loadStripe(spk)
  }, [])

  const mutation = useMutation(mutationRequest)
  let initTipData = null
  const initializeTipPayment = async e => {
    try {
      await mutation.mutate({
        options: { method: "POST" },
        path: `/api/v1/bands/${bandId}/tips`,
        data: {
          tip: {
            amount: tipAmount,
            band_stream_id: bandStreamId,
            promotion_id: promotionId,
          },
        },
      })
    } catch (error) {
      console.error("Error when creating tip:", error)
    }
  }

  useEffect(() => {
    if (mutation.status === "success") {
      if (mutation.data.success) {
        setTip(mutation.data.tip)
        setShowDialog(true)
      } else {
        alert(
          "We're sorry! There was an error with sending a tip. Reload the page and try again or email support@bandnada.com if you've already done that.",
        )
        console.error("Failed to get client secret: ", initTipData)
      }
    } else if (mutation.status === "error") {
      console.error("tip create/update failed")
    }
  }, [mutation.status])

  return (
    <div>
      <div className="grid grid-cols-12 mt-3 relative">
        <div className="lg:col-span-2 lg:col-start-8 col-span-6 text-base">
          <span
            className="absolute font-bold ml-2 lg:mt-2"
            style={{ marginTop: "0.5em" }}
          >
            $
          </span>
          <input
            type="text"
            value={tipAmount}
            onChange={e => setTipAmount(e.target.value)}
            className="lg:border-4 border-2 rounded w-full px-2 pl-5 pt-2 pb-1 lg:py-1 focus:outline-none pr-2"
          />
        </div>
        <div className="lg:col-span-3 col-span-6 text-right ml-2 lg:pr-2 lg:ml-2 lg:ml-0 lg:mt-0">
          <Button className="w-full" onClick={e => initializeTipPayment(e)}>
            Tip
            <BellFill className="inline ml-2" />
          </Button>
        </div>
      </div>
      {showDialog ? (
        <Dialog
          isOpen={showDialog}
          onDismiss={closeDialog}
          aria-label="Collect information to send a tip to the band"
        >
          <button
            className="close-button focus:outline-none"
            onClick={closeDialog}
          >
            <span className="focus:outline-none" aria-hidden>
              ×
            </span>
          </button>
          <div className="grid grid-cols-12 mt-3">
            <div className="col-span-12">
              <h1 className="border-b-4 border-black mb-6 pb-1 text-xl">
                Tip <span className="font-bold">{bandName}</span> ${tipAmount}
              </h1>
              <Elements stripe={stripePromiseRef.current}>
                <CheckoutForm
                  userName={userName}
                  lastFour={lastFour}
                  setLastFour={setLastFour}
                  bandId={bandId}
                  tipId={tip.id}
                  paymentMethod={tip.payment_method}
                  clientSecret={tip.client_secret}
                />
              </Elements>
            </div>
          </div>
        </Dialog>
      ) : null}
    </div>
  )
}

export const CheckoutForm = ({
  userName,
  lastFour,
  setLastFour,
  bandId,
  tipId,
  paymentMethod = null,
  clientSecret,
}) => {
  const [checkoutCompleted, setCheckoutCompleted] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isSubmitActive, setIsSubmitActive] = useState(true)
  const stripe = useStripe()
  const elements = useElements()
  const mutation = useMutation(mutationRequest)

  // update tip status upon succesful payment submission
  const handleUpdateTip = async paymentMethod => {
    try {
      await mutation.mutate({
        options: { method: "PUT" },
        path: `/api/v1/bands/${bandId}/tips/${tipId}`,
        data: {
          tip: {
            status: "confirmed_by_user",
            card_confirmed: true,
            payment_method: paymentMethod,
          },
        },
      })
      setCheckoutCompleted(true)
    } catch (error) {
      console.log("Catching the error", error)
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsSubmitActive(false)

    if (paymentMethod) {
      handleUpdateTip(paymentMethod)
    } else {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded. Disable Form
        return
      }
      setErrorMessage("")
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: userName,
          },
        },
      })

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        setIsSubmitActive(true)
        setErrorMessage(
          "There was an error with your card: " +
            result.error.message +
            ". Please double check it, and resubmit",
        )
      } else {
        handleUpdateTip(result.setupIntent.payment_method)
      }
    }
  }

  useEffect(() => {
    if (mutation.status === "success") {
      setLastFour(mutation.data.tip.last_four)
    }
  }, [mutation.status])

  return (
    <>
      {checkoutCompleted ? (
        <div>Your tip has been submitted. Thank you!</div>
      ) : (
        <form onSubmit={handleSubmit}>
          {paymentMethod ? (
            <>
              <div className="mb-4">
                Your card ending in&nbsp;
                <span className="font-bold text-gray-800">{lastFour}</span>
                &nbsp;will be used for payment
              </div>
              <SubmitButton type="submit" active={isSubmitActive} className="">
                Confirm Tip
              </SubmitButton>
            </>
          ) : clientSecret && stripe && elements ? (
            <>
              {errorMessage ? (
                <div className="text-red-500 text-sm">{errorMessage}</div>
              ) : null}
              <CardSection />
              <SubmitButton type="submit" active={isSubmitActive} className="">
                Confirm Tip
              </SubmitButton>
              <div className="text-sm text-gray-600 mt-4">
                I authorize bandNada to send instructions to the financial
                institution that issued my card to take payments from my card
                that I explicitly confirmed in bandNada
              </div>
            </>
          ) : (
            <div>Loading payment form...</div>
          )}
        </form>
      )}
    </>
  )
}

export const TipComponentLoggedOut = ({ bandId, bandName }) => {
  const [tipAmount, setTipAmount] = useState("2")
  const [showDialog, setShowDialog] = useState(false)

  return (
    <div>
      <div className="grid grid-cols-12 mt-3">
        <div className="lg:col-span-2 lg:col-start-9 relative col-span-12 text-base">
          <span
            className="absolute font-bold ml-2 lg:mt-2"
            style={{ marginTop: "0.35em" }}
          >
            $
          </span>
          <input
            style={{ paddingLeft: "18px" }}
            type="text"
            value={tipAmount}
            onChange={e => setTipAmount(e.target.value)}
            className="lg:border-4 border-2 rounded w-full px-2 pl-5 py-1 focus:outline-none pr-2"
          />
        </div>
        <div className="lg:col-span-2 col-span-12 text-right lg:pr-2 lg:ml-2 lg:ml-0 mt-2 lg:mt-0">
          <Button className="w-full" onClick={e => setShowDialog(!showDialog)}>
            Tip
          </Button>
        </div>
      </div>
      {showDialog ? (
        <AuthenticationDialog
          explanation={`You must first sign up or log in to tip ${bandName}`}
        />
      ) : null}
    </div>
  )
}

export default TipComponent
