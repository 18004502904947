import { useQuery } from "react-query"
import { request } from "../util/request"

export function useUserTags(postId, page = 1, perPage = 5, fields = "") {
  return useQuery(
    ["user_tags", postId, page, perPage, fields],
    getUserTagsForPostId,
    {
      keepPreviousData: true,
      enabled: !!postId,
    },
  )
}

const getUserTagsForPostId = async ({ queryKey }) => {
  const [key, id, page, perPage, fields] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/posts/${id}/user_tags.json`,
        params: { per_page: perPage, page: page, fields: fields },
      },
    ],
  })
}
