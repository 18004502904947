import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faSoundcloud,
  faBandcamp,
  faSpotify,
  faInstagram,
  faYoutube,
  faBlogger,
} from "@fortawesome/free-brands-svg-icons"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { CartFill, Mailbox2, Globe2 } from "react-bootstrap-icons"
import { CopyPostUrlLink } from "../posts/CopyPostUrlLink"

export const BandSocialIcons = ({
  band,
  color = "text-gray-700",
  margins = "mt-1 lg:mt-3",
  includeShareIcon = false,
}) => {
  // eslint-disable-next-line no-restricted-globals
  const bandUrl = `${location.protocol}//${location.host}${band.band_path}`
  const anchorStyles = `inline-block text-xl ${color}`
  const mailingUrlBase = "https://mail.google.com/mail/u/0/?"
  const mailingUrlData = {
    view: "cm",
    fs: "1",
    tf: "1",
    to: band.email,
    su: `Hey ${band.name}! -- (via bandNada)`,
    body: `Hey ${band.name},\n\n\n\n(powered by bandNada)`,
  }
  const mailingUrlParams = new URLSearchParams(mailingUrlData)
  const mailingUrl = mailingUrlBase + mailingUrlParams.toString()
  return (
    <div className={`flex gap-4 ${margins}`}>
      {band.facebook_url ? (
        <a
          href={band.facebook_url}
          target="_blank"
          rel="noReferrer"
          className={anchorStyles}
          title="facebook"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
      ) : null}
      {band.website_url ? (
        <a
          href={band.website_url}
          target="_blank"
          rel="noReferrer"
          className={anchorStyles}
          title="website"
        >
          <Globe2 className="mt-1" />
        </a>
      ) : null}
      {band.instagram_url ? (
        <a
          href={band.instagram_url}
          target="_blank"
          rel="noReferrer"
          className={anchorStyles}
          title="instagram"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      ) : null}
      {band.store_envy_url ? (
        <a
          href={band.store_envy_url}
          target="_blank"
          rel="noReferrer"
          className={anchorStyles}
          title="store envy"
        >
          <CartFill className="" />
        </a>
      ) : null}
      {band.youtube_url ? (
        <a
          href={band.youtube_url}
          target="_blank"
          rel="noReferrer"
          className={anchorStyles}
          title="youtube"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      ) : null}
      {band.spotify_url ? (
        <a
          href={band.spotify_url}
          target="_blank"
          rel="noReferrer"
          className={anchorStyles}
          title="spotify"
        >
          <FontAwesomeIcon icon={faSpotify} />
        </a>
      ) : null}
      {band.bandcamp_url ? (
        <a
          href={band.bandcamp_url}
          target="_blank"
          rel="noReferrer"
          className={anchorStyles}
          title="bandcamp"
        >
          <FontAwesomeIcon icon={faBandcamp} />
        </a>
      ) : null}
      {band.soundcloud_url ? (
        <a
          href={band.soundcloud_url}
          target="_blank"
          rel="noReferrer"
          className={anchorStyles}
          title="soundcloud"
        >
          <FontAwesomeIcon icon={faSoundcloud} />
        </a>
      ) : null}
      {band.songkick_url ? (
        <a
          href={band.songkick_url}
          target="_blank"
          rel="noReferrer"
          className={anchorStyles}
          title="songkick"
        >
          <span className="font-bold">sk</span>
        </a>
      ) : null}
      {band.mailing_list_url ? (
        <a
          href={band.mailing_list_url}
          target="_blank"
          rel="noReferrer"
          className={anchorStyles}
          title="Mailing List"
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </a>
      ) : null}
      {band.email ? (
        <a
          href={mailingUrl}
          target="_blank"
          rel="noReferrer"
          className={anchorStyles}
          title={`Email ${band.name}`}
        >
          <Mailbox2 className="mt-1" />
        </a>
      ) : null}
      {includeShareIcon && (
        <CopyPostUrlLink
          nadaUrl={bandUrl}
          renderMode="icon"
          color="white"
        ></CopyPostUrlLink>
      )}
    </div>
  )
}
