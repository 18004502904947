import React from "react"
import { isMobile } from "react-device-detect"
import ActivityFeed from "../home/components/ActivityFeed"
import ShowOfTheWeekSidebar from "../home/components/ShowOfTheWeekSidebar"
import UserCalendar from "../home/components/UserCalendar"
import Footer from "../shared/Footer/Footer"
import HeadlinePostForm from "../posts/HeadlinePostForm"
import EventForm from "../EventForm"
import Header from "../shared/Header/Header"
import Heading from "../shared/Heading"
import MakeStuff from "../home/components/MakeStuff"

const NewPostScreen = ({ user, isEvent }) => {
  return (
    <div className="">
      <Header user={user} />
      <div className="lg:grid lg:grid-cols-12 w-full pb-2 mt-4 px-3 lg:px-4 flex flex-col-reverse">
        <div className="lg:col-span-4 col-span-12 lg:pr-6">
          <MakeStuff />
          <ShowOfTheWeekSidebar className="mb-6" />
          {user && (
            <div className="mb-6">
              <UserCalendar user={user} />
            </div>
          )}
          {user && user.enable_activity_feed && !isMobile && (
            <div className="">
              <ActivityFeed />
            </div>
          )}
        </div>
        <div className="lg:col-span-8 col-span-12 lg:mt-0">
          <Heading
            level={2}
            type="sectionHeader"
            className="flex justify-between"
          >
            Post Your {isEvent ? "Event" : "Awesome Thing"}
          </Heading>
          {isEvent ? <EventForm /> : <HeadlinePostForm bandId={user.band_id} />}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default NewPostScreen
