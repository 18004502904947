import React, { useState } from "react"
import { useQueryClient } from "react-query"
import { ActionCableConsumer } from "react-actioncable-provider"
import { FeedSpinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import LiveStreamListItem from "./LiveStreamListItem"
import {
  LIVE_STREAMS_QUERY_KEY,
  useInfiniteLiveStreams,
} from "../../hooks/useLiveStreams"

export const LiveStreamsFeed = ({ cols = 2, mobileCols = 1 }) => {
  const queryClient = useQueryClient()
  const { data, error, status } = useInfiniteLiveStreams({ pageSize: 40 })

  const handleReceivedMessage = message => {
    queryClient.invalidateQueries(LIVE_STREAMS_QUERY_KEY)
  }

  return (
    <>
      <ActionCableConsumer
        channel={{ channel: "LiveStreamChannel" }}
        onReceived={handleReceivedMessage}
      />
      <ul className={`grid lg:grid-cols-${cols} grid-cols-${mobileCols} gap-6`}>
        {status === "loading" ? (
          <FeedSpinner />
        ) : status === "error" ? (
          <RequestError error={error} />
        ) : (
          data.pages.map((page, i) => (
            <React.Fragment key={page.page}>
              {page.live_streams.length > 0 ? (
                page.live_streams.map(live_stream => (
                  <LiveStreamListItem
                    key={live_stream.id}
                    live_stream={live_stream}
                  />
                ))
              ) : (
                <li>No live streams at the moment...</li>
              )}
            </React.Fragment>
          ))
        )}
      </ul>
    </>
  )
}

export default LiveStreamsFeed
