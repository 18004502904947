import React, { useEffect, useState, useRef } from "react"
import Dialog from "@reach/dialog"
import { Button, SubmitButton } from "./Button"
import { useMutation } from "react-query"
import { mutationRequest } from "../../util/request"
import "./AuthenticationDialog.css"

const EMAIL_REGEX = /\A[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]\+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\z/

const LegalLanguage = () => {
  return (
    <p className="italic text-gray-700 text-sm">
      By using bandNada you are agreeing to our{" "}
      <a className="text-blue-600" target="_blank" href="/terms_of_service">
        Terms of Service
      </a>
      , and our{" "}
      <a className="text-blue-600" target="_blank" href="/privacy">
        Privacy Policy
      </a>
      .
    </p>
  )
}

export const AuthenticationDialog = ({
  initShowDialog = true,
  explanation = null,
}) => {
  const [showDialog, setShowDialog] = useState(initShowDialog)
  const [modalState, setModalState] = useState("auth")

  const closeDialog = () => setShowDialog(false)

  const validationError = data => {
    if (data.flow === "register" || data.flow === "authenticate") {
      if (data.user.password.length < 6 || data.user.password.length > 24) {
        return "Password must be between 6 and 24 characters long"
      }
    }
    return null
  }

  return (
    <>
      <Dialog
        isOpen={showDialog}
        onDismiss={closeDialog}
        aria-label="login/signup dialog for users"
      >
        <button
          className="close-button focus:outline-none"
          onClick={closeDialog}
        >
          <span className="focus:outline-none text-lg" aria-hidden>
            ×
          </span>
        </button>
        {explanation ? (
          <div className="border-2 border-r font-bold px-2 py-4 rounded-sm text-blue-500 text-base">
            {explanation}
          </div>
        ) : null}
        <div className="grid grid-cols-12 mt-3">
          {modalState === "register" ? (
            <RegistrationForm
              setModalState={setModalState}
              validationError={validationError}
              closeDialog={closeDialog}
            />
          ) : modalState === "auth" ? (
            <AuthenticationForm
              setModalState={setModalState}
              validationError={validationError}
              closeDialog={closeDialog}
            />
          ) : modalState === "reset_password" ? (
            <ResetPasswordForm
              setModalState={setModalState}
              validationError={validationError}
              closeDialog={closeDialog}
            />
          ) : null}
        </div>
      </Dialog>
    </>
  )
}

const bandTypes = [
  { value: "fan", label: "Fan" },
  { value: "band", label: "Band" },
  { value: "individual", label: "Solo Performer" },
  { value: "venue", label: "Venue" },
  { value: "blog", label: "Blog" },
  { value: "promoter", label: "Promoter" },
]

const getBandTypeRef = bandType => {
  return bandTypes.find(bt => bt.value == bandType)
}

export const RegistrationForm = ({
  setModalState,
  validationError,
  closeDialog,
}) => {
  const [registerErrorMessage, setRegisterErrorMessage] = useState("")
  const [registerButtonState, setRegisterButtonState] = useState(true)
  const [bandType, setBandType] = useState("fan")
  const mutation = useMutation(mutationRequest)
  const [userData, setUserData] = useState({
    email: null,
    password: null,
    first_name: null,
    last_name: null,
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    band_name: null,
  })

  const setRegisterField = (field, value) => {
    var newUserData = userData
    newUserData[field] = value
    setUserData(newUserData)
  }

  const handleBandTypeChange = e => {
    setBandType(e.target.value)
  }

  const handleRegistration = async e => {
    e.preventDefault()
    const registerData = {
      flow: "register",
      current_location: window.location.href,
      user: { ...userData, band_type: bandType },
    }
    setRegisterButtonState(false)
    setRegisterErrorMessage("")
    let errorMessage = validationError(registerData)
    if (errorMessage) {
      setRegisterErrorMessage(errorMessage)
      setRegisterButtonState(true)
      return
    }
    try {
      await mutation.mutate({
        options: { method: "POST" },
        path: `/api/v1/users`,
        data: registerData,
      })
    } catch {
      setRegisterButtonState(true)
      setRegisterErrorMessage("Sorry, there was an unknown error")
    }
  }

  useEffect(() => {
    if (mutation.status === "success") {
      if (mutation.data.success) {
        closeDialog()
        window.location.href = mutation.data.redirect_location
      } else {
        setRegisterButtonState(true)
        setRegisterErrorMessage(mutation.data.errors.error)
      }
    } else if (mutation.status === "error") {
      setRegisterButtonState(true)
      setRegisterErrorMessage("Sorry, there was an unknown error")
    }
  }, [mutation.status])

  return (
    <>
      <div className="lg:col-span-7 col-span-12 lg:pr-6">
        <h1 className="border-b-4 border-black font-bold mb-4 text-xl uppercase">
          Sign Up
        </h1>
        {registerErrorMessage ? (
          <div className="text-red-700 text-sm mb-2">
            {registerErrorMessage}
          </div>
        ) : null}
        <div className="mb-2">
          <p className="italic text-sm">
            We recommend setting up a band account with a band email, and
            separate fan accounts for each person in the band
          </p>
        </div>

        <form onSubmit={handleRegistration}>
          <div className="mb-4">
            {bandTypes.map(bandTypeOption => (
              <label className="inline-flex items-center lg:mr-6">
                <input
                  type="radio"
                  className="form-radio"
                  name="band_type"
                  value={bandTypeOption.value}
                  checked={bandType === bandTypeOption.value}
                  onChange={handleBandTypeChange}
                />
                <span className="ml-2 text-sm text-gray-700">
                  {bandTypeOption.label}
                </span>
              </label>
            ))}
            <div className="text-xs text-gray-500">
              don't worry, you can always change your mind about who you are ;)
            </div>

            <label
              className="mt-4 block text-gray-700 text-xs uppercase font-bold"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="shadow appearance-none border rounded-sm w-full text-base py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="registerEmail"
              placeholder="Email"
              required={true}
              onChange={e => setRegisterField("email", e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-xs uppercase font-bold"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border rounded-sm w-full text-base py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              placeholder="******************"
              required={true}
              onChange={e => setRegisterField("password", e.target.value)}
            />
          </div>
          {bandType == "fan" ? (
            <>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-xs uppercase font-bold"
                  htmlFor="first_name"
                >
                  First Name
                </label>
                <input
                  className="shadow appearance-none border rounded-sm w-full text-base py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="First Name"
                  required={true}
                  onChange={e => setRegisterField("first_name", e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-xs uppercase font-bold"
                  htmlFor="last_name"
                >
                  Last Name
                </label>
                <input
                  className="shadow appearance-none border rounded-sm w-full text-base py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Last Name"
                  required={true}
                  onChange={e => setRegisterField("last_name", e.target.value)}
                />
              </div>
            </>
          ) : (
            <>
              <div className="mb-4">
                <label
                  className="mt-2 block text-gray-700 text-xs uppercase font-bold"
                  htmlFor="band_name"
                >
                  {getBandTypeRef(bandType).label} Name
                </label>
                <input
                  className="shadow appearance-none border rounded-sm w-full text-base py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder={`${bandType} Name`}
                  required={true}
                  onChange={e => setRegisterField("band_name", e.target.value)}
                />
              </div>
            </>
          )}

          <div className="flex items-center justify-between lg:mt-4 mt-8">
            <SubmitButton className="" active={registerButtonState}>
              Sign Up
            </SubmitButton>
          </div>
          <div className="mt-6">
            <LegalLanguage />
          </div>
        </form>
      </div>
      <div className="lg:col-span-5 col-span-12 lg:mt-0 mt-16">
        <h1 className="border-b-4 border-black font-bold mb-4 text-xl text-right">
          Hip to bandnada?
        </h1>
        <div className="float-right mt-4">
          <Button onClick={e => setModalState("auth")}>Sign In</Button>
        </div>
      </div>
    </>
  )
}

export const AuthenticationForm = ({
  setModalState,
  validationError,
  closeDialog,
}) => {
  const [authErrorMessage, setAuthErrorMessage] = useState("")
  const [loginLinkValidMessage, setLoginLinkValidMessage] = useState(null)
  const [authButtonState, setAuthButtonState] = useState(true)
  const [loginLinkButtonState, setLoginLinkButtonState] = useState(true)
  const mutation = useMutation(mutationRequest)
  const [flow, setFlow] = useState("authenticate")
  const [authData, setAuthData] = useState({
    flow: "authenticate",
    current_location: window.location.href,
    user: {
      email: null,
      password: null,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      remember_me: true,
    },
  })
  const [loginLinkData, setLoginLinkData] = useState({
    flow: "login_link",
    current_location: window.location.href,
    user: {
      email: null,
    },
  })

  const setAuthField = (field, value) => {
    var newAuthData = authData
    newAuthData.user[field] = value
    setAuthData(newAuthData)
  }

  const setLoginLinkEmail = email => {
    var newLoginLinkData = loginLinkData
    newLoginLinkData.user.email = email
    setLoginLinkData(loginLinkData)
  }

  const emailInputRef = useRef(null)
  const passwordInputRef = useRef(null)

  const handleAuthentication = async e => {
    e.preventDefault()
    setFlow("authenticate")
    setAuthButtonState(false)
    setAuthErrorMessage("")

    if (authData.user.email === null) {
      setAuthField("email", emailInputRef.current.value)
    }

    if (authData.user.password === null) {
      setAuthField("password", passwordInputRef.current.value)
    }

    let errorMessage = validationError(authData)
    if (errorMessage) {
      setAuthErrorMessage(errorMessage)
      setAuthButtonState(true)
      return
    }

    try {
      await mutation.mutate({
        options: { method: "POST" },
        path: `/api/v1/users`,
        data: authData,
      })
    } catch {
      setAuthButtonState(true)
      setAuthErrorMessage("Sorry, there was an unknown error")
    }
  }

  const handleSendLoginLink = async e => {
    e.preventDefault()
    setFlow("login_link")
    setLoginLinkButtonState(false)
    setAuthErrorMessage("")

    setLoginLinkEmail(emailInputRef.current.value)

    let errorMessage = validationError(loginLinkData)
    if (errorMessage) {
      setAuthErrorMessage(errorMessage)
      setLoginLinkButtonState(true)
      return
    }
    try {
      await mutation.mutate({
        options: { method: "POST" },
        path: `/api/v1/users`,
        data: loginLinkData,
      })
    } catch {
      setLoginLinkButtonState(true)
      setAuthErrorMessage("Sorry, there was an unknown error")
    }
  }

  useEffect(() => {
    if (mutation.status === "success") {
      if (mutation.data.success) {
        if (flow === "authenticate") {
          closeDialog()
          window.location.href = mutation.data.redirect_location
        } else {
          setLoginLinkValidMessage(
            "Check your email (spam) for login link instructions from bandnada.com",
          )
          setLoginLinkButtonState(true)
        }
      } else {
        setAuthButtonState(true)
        setAuthErrorMessage(mutation.data.errors.error)
      }
    } else if (mutation.status === "error") {
      setAuthButtonState(true)
      setAuthErrorMessage("Sorry, there was an unknown error")
    }
  }, [mutation.status])

  return (
    <>
      <div className="lg:col-span-7 col-span-12 lg:pr-6">
        <h1 className="border-b-4 border-black font-bold mb-4 text-xl uppercase">
          Sign In
        </h1>
        {authErrorMessage ? (
          <div className="text-red-700 text-sm mb-2">{authErrorMessage}</div>
        ) : null}
        {loginLinkValidMessage ? (
          <div className="text-green-700 text-sm mb-2">
            {loginLinkValidMessage}
          </div>
        ) : null}
        <form onSubmit={handleAuthentication}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-xs uppercase font-bold"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="shadow appearance-none border rounded-sm w-full text-base py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              ref={emailInputRef}
              name="authEmail"
              placeholder="Email"
              required={true}
              onChange={e => setAuthField("email", e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-xs uppercase font-bold"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border rounded-sm w-full text-base py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              ref={passwordInputRef}
              placeholder="******************"
              required={true}
              onChange={e => setAuthField("password", e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between">
            <SubmitButton className="" active={authButtonState}>
              Sign In
            </SubmitButton>
          </div>
          <div className="text-center my-4 font-bold text-gray-700">
            -- Or --
          </div>
          <div className="flex items-center justify-between">
            <Button
              className="block bg-blue-500 w-full uppercase"
              disabled={!loginLinkButtonState}
              onClick={e => handleSendLoginLink(e)}
            >
              Send Login Link
            </Button>
          </div>
          <p className="italic leading-tight pt-2 text-gray-700 text-sm">
            We'll send you an email now with a link that logs you in
            automatically. Easy as 1,2. Or you can{" "}
            <button
              className="focus:outline-none text-blue-600 text-sm inline"
              onClick={e => setModalState("reset_password")}
            >
              Reset Your Password (old-school).
            </button>
          </p>
        </form>
      </div>
      <div className="lg:col-span-5 col-span-12 lg:mt-0 mt-16 flex flex-col justify-between">
        <div>
          <h1 className="border-b-4 border-black font-bold mb-4 text-xl text-right">
            New to bandNada?
          </h1>
          <div className="mt-8 text-right">
            <Button
              className="block ml-auto"
              onClick={e => setModalState("register")}
            >
              Sign Up for Free
            </Button>
          </div>
        </div>

        <div className="mt-4">
          <LegalLanguage />
        </div>
      </div>
    </>
  )
}

export const ResetPasswordForm = ({
  setModalState,
  validationError,
  closeDialog,
}) => {
  const [resetPasswordButtonState, setResetPasswordButtonState] = useState(true)
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] = useState("")
  const [resetPasswordValidMessage, setResetPasswordValidMessage] = useState("")
  const mutation = useMutation(mutationRequest)
  const [resetPasswordData, setResetPasswordData] = useState({
    flow: "reset_password",
    current_location: window.location.href,
    user: {
      email: null,
    },
  })

  const setResetPasswordField = (field, value) => {
    var newResetPasswordData = resetPasswordData
    newResetPasswordData.user[field] = value
    setResetPasswordData(newResetPasswordData)
  }

  const handleResetPassword = async e => {
    e.preventDefault()
    setResetPasswordButtonState(false)
    setResetPasswordErrorMessage("")
    let errorMessage = validationError(resetPasswordData)
    if (errorMessage) {
      setResetPasswordErrorMessage(errorMessage)
      setResetPasswordButtonState(true)
      return
    }
    try {
      await mutation.mutate({
        options: { method: "POST" },
        path: `/api/v1/users`,
        data: resetPasswordData,
      })
    } catch {
      setResetPasswordButtonState(true)
      setResetPasswordErrorMessage("Sorry, there was an unknown error")
    }
  }

  useEffect(() => {
    if (mutation.status === "success") {
      if (mutation.data.success) {
        setResetPasswordValidMessage(
          "Check your email (spam) for reset password instructions from bandnada.com",
        )
      } else {
        setResetPasswordButtonState(true)
        setResetPasswordErrorMessage(mutation.data.errors.error)
      }
    } else if (mutation.status === "error") {
      setResetPasswordButtonState(true)
      setResetPasswordErrorMessage("Sorry, there was an unknown error")
    }
  }, [mutation.status])

  return (
    <>
      <div className="lg:col-span-7 col-span-12 lg:pr-6">
        <h1 className="border-b-4 border-black font-bold mb-4 text-xl uppercase">
          Reset Your Password
        </h1>
        {resetPasswordErrorMessage ? (
          <div className="text-red-700 text-sm mb-2">
            {resetPasswordErrorMessage}
          </div>
        ) : null}
        {resetPasswordValidMessage ? (
          <div className="text-green-700 text-sm mb-2">
            {resetPasswordValidMessage}
          </div>
        ) : null}
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-xs uppercase font-bold"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="shadow appearance-none border rounded-sm w-full text-base px-2 py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Email"
              required={true}
              onChange={e => setResetPasswordField("email", e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between">
            <SubmitButton className="" active={resetPasswordButtonState}>
              Send Reset Password Email
            </SubmitButton>
          </div>
        </form>
      </div>
      <div className="lg:col-span-5 col-span-12 lg:mt-0 mt-16">
        <h1 className="border-b-4 border-black font-bold mb-4 text-xl text-right">
          Got it
        </h1>
        <div className="float-right mt-4">
          <Button onClick={e => setModalState("auth")}>Sign In</Button>
        </div>
      </div>
    </>
  )
}
