import React from "react"

const CreatedPostUserResponse = ({ user_response, type, setShowEditForm }) => {
  const post_name = {
    concert: "event",
  }

  if (user_response?.warnings) {
    return (
      <div className="bg-blue-600 col-span-12 mb-2 py-2 px-3 rounded-sm text-white">
        <h2 className="font-bold">
          Thanks for posting your {post_name[type]}! It's live, but here's a
          tip...
        </h2>
        <ul className="text-gray-300 list-disc list-inside mb-4">
          {user_response.warnings.map((warning, i) => (
            <li key={warning}>{warning}</li>
          ))}
        </ul>
        <h2>
          You can{" "}
          <button
            className="border-b-2 cursor-pointer italic focus:outline-none"
            onClick={e => setShowEditForm(true)}
          >
            edit it now
          </button>{" "}
          if you like
        </h2>
      </div>
    )
  } else if (user_response?.notice) {
    return (
      <div className="bg-green-600 col-span-12 mb-2 py-2 px-3 rounded-sm text-white">
        <h2 className="font-bold">{user_response.notice}</h2>
        <h2>
          You can{" "}
          <button
            className="border-b-2 cursor-pointer italic focus:outline-none"
            onClick={e => setShowEditForm(true)}
          >
            edit it now
          </button>
          , or anytime you like from the edit link on the event.
        </h2>
      </div>
    )
  } else {
    return null
  }
}

export default CreatedPostUserResponse
