import React, { useContext, useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { mutationRequest } from "../../../util/request"
import { UserContext } from "../../HomeApp"
import { InterestedButton } from "../../shared/Button"
import { useReactions } from "../../../hooks/useReactions"
import Pluralize from "react-pluralize"
import { ActionCableConsumer } from "react-actioncable-provider"
import { AuthenticationDialog } from "../../shared/AuthenticationDialog"
import ReactedUsers from "../../ReactedUsers"
import { Link } from "react-router-dom"
import styles from "./styles.module.scss"
import { toast } from "react-toastify"

const LOW_RSVP_THRESHOLD = 5

const InterestedBlock = ({ postId, bandId, maxRsvps, renderMode = "full" }) => {
  const { user } = useContext(UserContext)
  const [interestedPeople, setInterestedPeople] = useState(0)
  const [userInterestedState, setUserInterestedState] = useState("")
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [showReactedUsers, setShowReactedUsers] = useState(false)
  const [rsvpsRemaining, setRsvpsRemaining] = useState(maxRsvps == 0 ? 1000 : 0)

  const { isLoading, isError, data, status } = useReactions(
    "Post",
    postId,
    "rsvps",
    true,
  )

  useEffect(() => {
    switch (true) {
      case maxRsvps === null:
        setRsvpsRemaining(10000)
        break
      case maxRsvps > 0:
        setRsvpsRemaining(maxRsvps - interestedPeople)
        break
      default:
        setRsvpsRemaining(0)
        break
    }
  }, [interestedPeople])

  useEffect(() => {
    if (!isLoading && !isError && status === "success") {
      setInterestedPeople(data.total_count)
      if (user) {
        if (data?.current_user_setting?.reaction_type)
          setUserInterestedState(data.current_user_setting.reaction_type)
        else {
          setUserInterestedState("uninterested")
        }
      } else {
        setUserInterestedState("unauthenticated")
      }
    }
  }, [isError, isLoading, status, data?.total_count])

  const mutation = useMutation(mutationRequest)

  const submitReaction = async (e, reactionState) => {
    setButtonDisabled(true)
    e.preventDefault()
    await mutation.mutate({
      options: { method: "POST" },
      path: `/api/v1/reactions`,
      data: {
        reaction: {
          content_type: "Post",
          content_id: postId,
          post_id: postId,
          reaction_type: reactionState,
        },
        filter: "rsvps",
      },
    })
  }

  const handleReceivedMessage = newReaction => {
    if (newReaction.reaction && newReaction.reaction.interested_count) {
      setInterestedPeople(newReaction.reaction.interested_count)
    }
  }

  const queryClient = useQueryClient()

  useEffect(() => {
    if (mutation.isSuccess) {
      setUserInterestedState(
        mutation.data?.current_user_setting?.reaction_type || "uninterested",
      )
      setButtonDisabled(false)
      setInterestedPeople(mutation.data.total_count)
      queryClient.invalidateQueries("user-posts")
    }
  }, [mutation.isSuccess, mutation.isError])

  const addAvatarPrompt = () => {
    const addAvatarHTML = (
      <div>
        You need to add a profile image in order to see RSVPs.
        <br />
        <Link to="/settings" className="text-blue-700">
          Add Profile Image
        </Link>
      </div>
    )
    toast.error(addAvatarHTML)
  }

  return (
    <>
      <ActionCableConsumer
        channel={{ channel: "PostChannel", id: postId }}
        onReceived={handleReceivedMessage}
      >
        {rsvpsRemaining == 0 && userInterestedState != "interested" ? (
          <>
            <div className="mb-4">
              😞{" "}
              <span className={styles.fullCapacity}>
                Sorry, it's maxed out. Check back in case if spots open up.
              </span>
              <button
                className="focus:outline-none text-left"
                onClick={() =>
                  user
                    ? user.can_see_avatars
                      ? setShowReactedUsers(!showReactedUsers)
                      : addAvatarPrompt()
                    : setShowDialog(true)
                }
              >
                <span className="italic pl-1 text-gray-600 text-xs">
                  ({showReactedUsers ? "hide" : "show the jerks who are going"})
                </span>
              </button>
            </div>
          </>
        ) : (
          <div className={`flex ${renderMode === "text" ? "" : "mb-4"}`}>
            {user ? (
              renderMode === "text" ? (
                <button
                  className="mx-3 focus:outline-none text-blue-600"
                  disabled={buttonDisabled}
                  onClick={e =>
                    submitReaction(
                      e,
                      userInterestedState === "interested"
                        ? "uninterested"
                        : "interested",
                    )
                  }
                >
                  {userInterestedState === "interested"
                    ? "You are Going!"
                    : "RSVP?"}
                </button>
              ) : (
                <InterestedButton
                  disabled={buttonDisabled}
                  renderMode={renderMode}
                  onClick={e =>
                    submitReaction(
                      e,
                      userInterestedState === "interested"
                        ? "remove"
                        : "interested",
                    )
                  }
                  bg={
                    userInterestedState === "interested"
                      ? "bg-black-800"
                      : "bg-green-400"
                  }
                >
                  {userInterestedState === "interested"
                    ? "You are Going!"
                    : "RSVP?"}
                </InterestedButton>
              )
            ) : renderMode === "text" ? (
              <button
                className="mx-3 focus:outline-none text-blue-600"
                onClick={e => setShowDialog(!showDialog)}
              >
                RSVP?
              </button>
            ) : (
              <InterestedButton
                disabled={buttonDisabled}
                renderMode={renderMode}
                onClick={e => setShowDialog(!showDialog)}
              >
                RSVP?
              </InterestedButton>
            )}
            {interestedPeople > 0 && renderMode !== "text" && (
              <div
                className={`flex-grow ${
                  renderMode === "minimal" ? "" : "text-sm"
                } ml-2`}
              >
                <button
                  className="focus:outline-none text-left"
                  onClick={() =>
                    user
                      ? user.can_see_avatars
                        ? setShowReactedUsers(!showReactedUsers)
                        : addAvatarPrompt()
                      : setShowDialog(true)
                  }
                >
                  {rsvpsRemaining < LOW_RSVP_THRESHOLD ? (
                    <span>
                      {rsvpsRemaining == 0 ? "" : "only"}{" "}
                      <span className={styles.lowCapacity}>
                        <Pluralize
                          singular={"spot"}
                          plural={"spots"}
                          count={rsvpsRemaining}
                          className="font-bold"
                        />{" "}
                      </span>
                      left
                      <span className="italic pl-1 text-gray-600 text-xs">
                        ({showReactedUsers ? "hide" : "show"})
                      </span>
                    </span>
                  ) : (
                    <>
                      <Pluralize
                        singular={"person"}
                        plural={"people"}
                        count={interestedPeople}
                        className="font-bold"
                      />{" "}
                      interested
                      <span className="italic pl-1 text-gray-600 text-xs">
                        ({showReactedUsers ? "hide" : "show"})
                      </span>
                    </>
                  )}
                </button>
              </div>
            )}
          </div>
        )}

        {showReactedUsers && (
          <ReactedUsers
            className="pb-4"
            contentId={postId}
            bandId={bandId}
            contentType="Post"
            filter="rsvps"
          />
        )}
        {showDialog && (
          <AuthenticationDialog
            explanation={`You must first sign up or log in to respond to an event`}
          />
        )}
      </ActionCableConsumer>
    </>
  )
}

export default InterestedBlock
