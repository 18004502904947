import React from "react"
import styles from "../HomeScreen.module.scss"
import BandsListing from "../../bands/BandsListing"
import { discover_path } from "../../../routes"

export const HomeScreenBandsListing = () => {
  return (
    <section className={`${styles.section}`}>
      <div className="flex flex-col items-center mb-8 md:mb-12">
        <h3 className="text-3xl text-center md:text-5xl leading-tight font-black">
          Some bands on bandNada
        </h3>
      </div>
      <div className="mb-12">
        <BandsListing cols={6} limit={6} mobileCols={2} showMore={false} />
      </div>
      <div className="flex-1 text-center">
        <p className="text-base">
          <a
            className="border-b-2 border-black hover:opacity-25"
            href={discover_path()}
          >
            View All
          </a>
        </p>
      </div>
    </section>
  )
}
