export const Currency = (number, roundTo = 2) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: roundTo,
    maximumFractionDigits: roundTo,
  })

  return formatter.format(number)
}

export const Minutes = seconds => {
  return Math.round((seconds / 60) * 10) / 10
}

export const GetRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}
