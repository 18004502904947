import { useInfiniteQuery, useQuery } from "react-query"
import { request } from "../util/request"

export function useBands(filter, scene, page = 1, pageSize = 20) {
  const queryKey = ["bands", filter, scene, page, pageSize]
  return useQuery(queryKey, getBands)
}

export function useInfiniteBands({ filter, scene, pageSize }) {
  const queryKey = ["bands", filter, scene, null, pageSize]
  return useInfiniteQuery(queryKey, getBands, {
    getNextPageParam: (lastPage, pages) => lastPage.next_page,
  })
}

const getBands = async ({ queryKey = null, pageParam = null }) => {
  const [key, filter, scene, page, pageSize] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands`,
        params: {
          filter: filter,
          current_scene: scene,
          page: pageParam || page,
          per_page: pageSize,
        },
      },
    ],
  })
}
