import React, { useState } from "react"
import { useComments } from "../../hooks/useComments"
import LinkifyText from "../shared/LinkifyText"
import { RequestError } from "../shared/RequestError"
import { Spinner } from "../shared/Spinner"
import Carousel from "react-gallery-carousel"
import "react-gallery-carousel/dist/index.css"
import UserAvatar from "../shared/Header/UserAvatar"
import { SmartDateFormat } from "../../util/dates"
import { Link } from "react-router-dom"
import FreeTextFormat from "../shared/FreeTextFormat"

const ConcertMediaScreen = ({
  postId,
  headerText = "Pictures and Videos from the show",
  className = "my-4",
}) => {
  const PER_PAGE = 20
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(PER_PAGE)

  const { isLoading, isError, isFetching, data, error } = useComments(
    postId,
    page,
    perPage,
    "has_media",
  )

  return (
    <div className={className}>
      {isFetching ? (
        <Spinner />
      ) : error ? (
        <RequestError error={error} />
      ) : data ? (
        <>
          <ul>
            {data.comments.length > 0 ? (
              <>
                <h4 className="uppercase font-bold text-gray-700 text-xs">
                  {headerText}
                </h4>

                <Carousel
                  canAutoPlay={false}
                  hasIndexBoard={false}
                  hasThumbnails={false}
                >
                  {data.comments.map(comment =>
                    comment.media_urls.map(url => (
                      <div key={comment.id}>
                        {comment.media_type === "direct_upload" ? (
                          <div>
                            <img src={url} alt="direct_upload" />
                          </div>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: LinkifyText(url, false),
                            }}
                          ></div>
                        )}

                        <div className="flex flex-row mt-2">
                          <UserAvatar
                            handleClick={() => {}}
                            user={{
                              id: comment.user_id,
                              avatar_url_small: comment.user_avatar_url,
                            }}
                            size="small"
                            inHeader={false}
                          />
                          <div
                            className="pl-2 w-full"
                            style={{ marginTop: "-2px" }}
                          >
                            <div>
                              <span className="text-gray-900 font-bold mr-3">
                                {comment.user_path ? (
                                  <Link to={comment.user_path}>
                                    {comment.username}
                                  </Link>
                                ) : (
                                  comment.username
                                )}
                              </span>
                              <span
                                className="text-gray-700 text-xs"
                                style={{ verticalAlign: "text-top" }}
                              >
                                {SmartDateFormat({
                                  dateStr: comment.created_at,
                                  includeTime: true,
                                })}
                              </span>
                            </div>
                            <div className="text-sm text-gray-800">
                              <FreeTextFormat
                                text={comment.stripped_comment_text}
                                expandable={false}
                                expanded={true}
                                linkify={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )),
                  )}
                </Carousel>
              </>
            ) : null}
          </ul>
        </>
      ) : null}
    </div>
  )
}

export default ConcertMediaScreen
