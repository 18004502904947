import React, { useState } from "react"
import ReactPlayer from "react-player"

export const BandNadaPlayer = ({
  playbackUrl,
  thumbnailUrl,
  playerId = "bandNadaPlayer",
  mute = false,
  controls = true,
  autoplay = true,
  onError = () => {},
  onPlay = () => {},
  onPause = () => {},
}) => {
  const MAX_RETRY_COUNT = 6
  const [showPlayer, setShowPlayer] = useState(true)
  const [retryCount, setRetryCount] = useState(1)

  const defaultError = e => {
    console.log("ReactJWPlayer error for playbackUrl: ", playbackUrl, e)
    setShowPlayer(false)
    if (retryCount < MAX_RETRY_COUNT) {
      setRetryCount(retryCount + 1)
      setTimeout(() => {
        setShowPlayer(true)
      }, 3000 * retryCount)
    }
  }

  return (
    <>
      {showPlayer ? (
        <ReactPlayer
          url={playbackUrl}
          playing={autoplay}
          muted={mute}
          width="100%"
          height="unset"
          controls={controls}
          onError={defaultError}
          onPlay={onPlay}
          onPause={onPause}
          className="focus:outline-none"
        />
      ) : (
        <div className="text-center bg-black text-white h-full flex justify-center items-center">
          Video not currently available. Will refresh automatically when it is
          available.
        </div>
      )}
    </>
  )
}

export default BandNadaPlayer
