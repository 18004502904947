import React, { useState, useEffect } from "react"
import { FeedSpinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { UserListItem } from "../users/UserListItem"
import { useFollowers } from "../../hooks/useFollowers"

export const Followers = ({ bandId }) => {
  const pageSize = 20
  const [followerCount, setFollowerCount] = useState(0)
  const {
    status,
    data,
    error,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
  } = useFollowers(bandId, "followers", pageSize)
  useEffect(() => {
    if (status === "success") {
      setFollowerCount(data.pages.slice(-1)[0].total_count)
    }
  }, [status])

  return (
    <div>
      <h2 className="border-b-4 mt-4 mb-1 border-black font-bold text-xl uppercase">
        Followers ({followerCount})
      </h2>
      <p className="mb-8 text-sm text-gray-700">
        Your followers see your new music, shows, and videos in their personal
        band feed as it comes in. They are also emailed or texted when you go
        live. Here's a list of {followerCount > pageSize ? "some of" : ""} your
        followers:
      </p>
      {status === "loading" ? (
        <FeedSpinner />
      ) : status === "error" ? (
        <RequestError error={error} />
      ) : status === "success" ? (
        <>
          {data.pages[0].total_count === 0 ? (
            <div>
              No followers yet...
              <a
                href="https://www.youtube.com/watch?v=9gpbjprHivM"
                target="_blank"
                rel="noreferrer"
              >
                Spread Your Love!
              </a>
            </div>
          ) : (
            <div>
              <ul className={`grid lg:grid-cols-12 grid-cols-4 lg:gap-8 gap-4`}>
                {data.pages.map((page, i) => (
                  <React.Fragment key={page.page}>
                    {page.users.map(user => (
                      <UserListItem key={user.id} user={user} size="small" />
                    ))}
                  </React.Fragment>
                ))}

                {isFetching && !isFetchingNextPage ? (
                  <li>"Loading..."</li>
                ) : null}

                {hasNextPage && !isFetchingNextPage && (
                  <li className="flex items-end">
                    <button
                      className="text-blue-700 text-sm"
                      onClick={e => fetchNextPage()}
                    >
                      Show More
                    </button>
                  </li>
                )}
              </ul>
            </div>
          )}
        </>
      ) : null}
    </div>
  )
}
