import React, { useEffect } from "react"
import { LinkButton } from "../shared/Button"
import Header from "../shared/Header/Header"
import { RequestError } from "../shared/RequestError"
import { Spinner } from "../shared/Spinner"
import { BandEditForm } from "../../pages/BandEditPage/BandEditForm"
import style from "../shared/PageStyle.module.css"
import { useBand } from "../../hooks/useBand"
import { useQueryClient } from "react-query"
import Footer from "../shared/Footer/Footer"

export const NewBandScreen = ({ user, band }) => {
  const bandId = user?.band_id
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.invalidateQueries("user")
  }, [bandId])

  return (
    <div className={style.rootElStyle}>
      <Header user={user} />
      <>
        <div className="grid grid-cols-12 w-full pb-2 px-4">
          <div className="col-span-12 border-b-4 mt-4 mb-8 border-black">
            <h1 className="lg:col-span-8 col-span-12 font-extrabold text-5xl lg:text-3xl uppercase">
              Bring Your {band.band_type_nice} To bandNada
            </h1>
          </div>
          <div className="col-span-12">
            <div>
              bandNada can pull in data from other services and then display
              them on your bandNada page. The more info you provide, the better
              your page will look, and the more time someone will spend on your
              page.
            </div>
            <FullBandForm bandId={bandId} />
          </div>
        </div>
      </>
      <Footer />
    </div>
  )
}

export const FullBandForm = ({ bandId }) => {
  const {
    status: bandStatus,
    data: bandData,
    error: bandError,
  } = useBand(bandId, "active")
  var band = bandData?.band

  return (
    <>
      {bandStatus === "error" ? (
        <RequestError error={bandError} />
      ) : bandStatus === "loading" || !band ? (
        <Spinner />
      ) : (
        <>
          <BandEditForm band={band} isOnboarding={true} />
          <div className="mt-4">
            <LinkButton to={band.band_path}>See Your Band Page</LinkButton>
          </div>
        </>
      )}
    </>
  )
}
