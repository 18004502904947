import { useQuery } from "react-query"
import { request } from "../util/request"

export function useRelatedPosts(postId, page = 1, perPage = 5) {
  return useQuery(
    ["related_posts", postId, page, perPage],
    getRelatedPostsForPostId,
    {
      keepPreviousData: true,
    },
  )
}

const getRelatedPostsForPostId = async ({ queryKey }) => {
  const [key, id, page, perPage] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/posts/${id}/related_posts.json`,
        params: { per_page: perPage, page: page },
      },
    ],
  })
}
