import React, { useEffect, useState } from "react"
import { queryCache, useQuery } from "react-query"
import { Currency } from "../../util/numbers"
import { request } from "../../util/request"
import { Spinner } from "../shared/Spinner"
import { ActionCableConsumer } from "react-actioncable-provider"

export const PromotionShowStats = ({ bandStreamId, promotionId }) => {
  const refetchInterval = 0 // 1000 * 60 // 1 minute
  const [promotion, setPromotion] = useState(null)
  const promotionData = useQuery(
    promotionId
      ? [
          "promotion_show_stats",
          {
            path: `/api/v1/band_streams/${bandStreamId}/promotions/${promotionId}.json`,
            params: {},
          },
        ]
      : false,
    request,
  )

  const handleUpdatePromotion = newPromotion => {
    queryCache.refetchQueries("promotion_show_stats")
  }

  useEffect(() => {
    if (promotionData.status === "success") {
      setPromotion(promotionData.data.promotion)
    }
  }, [promotionData?.data?.promotion, promotionData.status])

  const promotionFieldHeaderStyle =
    "uppercase leading-none text-sm font-bold text-gray-700"
  const promotionFieldValueStyle = "text-xl font-extrabold text-black"

  return (
    <>
      {promotion ? (
        <>
          <ActionCableConsumer
            channel={{ channel: "PromotionChannel", id: promotionId }}
            onReceived={handleUpdatePromotion}
          />
          <div className="grid grid-cols-12">
            <div className="col-span-3 bg-black p-4 rounded-sm flex items-center">
              <img
                src={promotion.logo_external_asset.url}
                alt={promotion.name}
              />
            </div>
            <div className="col-span-9">
              <div className="grid grid-cols-3">
                <div className="col-span-1 text-center">
                  <div className={promotionFieldHeaderStyle}>Show Raised</div>
                  <div className={promotionFieldValueStyle}>
                    {Currency(promotion.band_stream_amount, 0)}
                  </div>
                </div>
                <div className="col-span-1 text-center">
                  <div className={promotionFieldHeaderStyle}>Total Raised</div>
                  <div className={promotionFieldValueStyle}>
                    {Currency(promotion.total_amount_raised, 0)}
                  </div>
                </div>
                <div className="col-span-1 text-center">
                  <div className={promotionFieldHeaderStyle}>Donations</div>
                  <div className={promotionFieldValueStyle}>
                    {promotion.contributions_count || 0}
                  </div>
                </div>
                <div className="col-span-3 lg:mt-4 mt-2 pl-2 text-sm text-gray-700">
                  100% of proceeds from this show go to&nbsp;
                  <a
                    className="font-bold text-blue-500"
                    href={promotion.cause_url}
                  >
                    {promotion.cause_name}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default PromotionShowStats
