import React, { useContext, useEffect, useState } from "react"
import { Button } from "../shared/Button"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./EventFormStyles.css"
import "cropperjs/dist/cropper.css"
import LocationSearchInput from "../shared/fields/LocationSearchInput"
import { UserContext } from "../HomeApp"
import TaggableInput from "../shared/fields/TaggableInput"
import { useUserTags } from "../../hooks/useUserTags"
import { Loader } from "@googlemaps/js-api-loader"
import PostDeleteButton from "../PostDeleteButton"
import { useQueryClient } from "react-query"
import { EventFlyerPhotoUploadSection } from "./EventFlyerPhotoUploadSection"
import {
  createEvent,
  updateEvent,
  uploadEventImages,
} from "../../util/backend_api/posts"
import clsx from "clsx"
import { toast } from "react-toastify"
import { root_path } from "../../routes"

const concertProps = {
  title: "",
  start_dt: new Date(),
  duration_minutes: 15,
  event_type: "inPersonEvent",
  location: { displayName: "" },
  description: "",
  cover_image_url: undefined,
}

const eventDurationOptions = [
  [15, "15 minutes"],
  [30, "30 minutes"],
  [60, "1 hour"],
  [120, "2 hours"],
  [240, "4 hours"],
  [480, "8 hours"],
  [1440, "full day"],
  [4320, "all weekend"],
]

const eventTypeOptions = [
  ["bNliveStream", "bandNada LiveStream"],
  ["inPersonEvent", "Live In-Person Event"],
]

const eventStatusOptions = [
  ["published", "Public"],
  ["needs_approved", "Hidden"],
]

export const EventForm = ({
  setShowForm = null,
  concert = concertProps,
  setConcert = null,
}) => {
  const { user, googlek } = useContext(UserContext)

  const [title, setTitle] = useState(concert.title)
  const [startDate, setStartDate] = useState(new Date(concert.start_dt))
  const [durationMinutes, setDurationMinutes] = useState(
    concert.duration_minutes,
  )
  const [eventType, setEventType] = useState(concert.event_type)
  const [eventLocation, setEventLocation] = useState(concert.location)
  const [eventTicketLinkUrl, setEventTicketLinkUrl] = useState(
    concert.ticket_link_url,
  )
  const [eventDescription, setEventDescription] = useState(concert.description)
  const [coverImageUrl, setCoverImageUrl] = useState(concert.cover_image_url)
  const [eventStatus, setEventStatus] = useState("published")
  const [maxRsvps, setMaxRsvps] = useState(concert.max_rsvps)
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [userTags, setUserTags] = useState([])
  const [formError, setFormError] = useState(null)
  const [btnActive, setBtnActive] = useState(true)
  const [cropper, setCropper] = useState(null)
  const [originalFile, setOriginalFile] = useState(null)
  const [fileType, setFileType] = useState(null)
  const [gifFile, setGifFile] = useState(null)
  const [gifPreview, setGifPreview] = useState(null)
  const queryClient = useQueryClient()
  const isEdit = !!concert.id

  const displayNewEventPost = response => {
    response.then(data => {
      if (data.success) {
        isEdit
          ? setConcert(data.post)
          : toast.success(
              "Thanks for posting your event! (sending you there in...)",
              {
                autoClose: 2000,
                onClose: () => {
                  window.location.href = data.post.nada_url
                },
              },
            )
      } else {
        console.log("failed to create new post: ", data)
      }
    })
  }

  const postHash = (
    croppedSignedId = undefined,
    originalSignedId = undefined,
  ) => {
    let hash = {
      post_type: "event",
      title: title,
      future_active_date: startDate,
      status: eventStatus,
      content_hash: {
        duration_minutes: durationMinutes,
        event_type: eventType,
        location: eventLocation,
        ticket_link_url: eventTicketLinkUrl,
        description: eventDescription,
        max_rsvps: maxRsvps,
        start: {
          datetime: startDate,
        },
      },
    }
    if (croppedSignedId) {
      hash["custom_cover_image"] = croppedSignedId
      hash["attached_media"] = [originalSignedId]
    } else if (originalSignedId) {
      hash["custom_cover_image"] = originalSignedId
      hash["attached_media"] = [originalSignedId]
    }
    return hash
  }

  const { isLoading, isError, data, error } = useUserTags(concert.id)

  useEffect(() => {
    if (!isLoading && !isError && data?.user_tags) {
      setUserTags(data.user_tags)
    } else if (isError) {
      console.error("ERROR: ", error)
    }
  }, [isLoading, isError])

  const loader = new Loader({
    apiKey: googlek,
    libraries: ["places"],
  })
  loader.load()

  const createOrUpdateEvent = async (bandId, croppedBlob) => {
    const uploadImageResponse =
      croppedBlob || originalFile
        ? await uploadEventImages(originalFile, croppedBlob)
        : {}

    if (uploadImageResponse.error) {
      setFormError(uploadImageResponse.error)
      setBtnActive(true)
    } else {
      let responseData = isEdit
        ? updateEvent(
            bandId,
            concert.id,
            postHash(
              uploadImageResponse.croppedSignedId,
              uploadImageResponse.originalSignedId,
            ),
            userTags,
          )
        : createEvent(
            bandId,
            postHash(
              uploadImageResponse.croppedSignedId,
              uploadImageResponse.originalSignedId,
            ),
            userTags,
          )
      if (responseData) displayNewEventPost(responseData)
      setTimeout(() => {
        queryClient.invalidateQueries("user-posts")
      }, 500)
    }
  }

  const submitEvent = async bandId => {
    if (cropper !== null) {
      cropper.getCroppedCanvas().toBlob(blob => {
        createOrUpdateEvent(bandId, blob)
      })
    } else {
      createOrUpdateEvent(bandId)
    }
  }

  const formFieldContainerClasses = "mb-2"

  const onCreate = async () => {
    setBtnActive(false)
    submitEvent(user.band.id)
  }

  const onUpdate = async () => {
    setBtnActive(false)
    await submitEvent(concert.band_id)
    setShowForm(null)
    // queryCache.refetchQueries(["posts"])
  }

  const handleGifUpload = gifFile => {
    setGifFile(gifFile)
    if (gifFile === null) {
      setGifPreview(null)
    } else {
      const reader = new FileReader()
      reader.onload = e => {
        setGifPreview(e.target.result)
      }
      reader.readAsDataURL(gifFile)
    }
  }

  return (
    <div className="grid grid-cols-12 mb-10">
      <div className={"lg:col-span-7 col-span-12"}>
        <EventFlyerPhotoUploadSection
          setOriginalFile={setOriginalFile}
          setFileType={setFileType}
          setCropper={setCropper.bind(this)}
          setGifFile={handleGifUpload.bind(this)}
          gifPreview={gifPreview}
          coverImageUrl={coverImageUrl}
        />
      </div>

      <div className="lg:col-span-5 col-span-12 lg:pl-4 pt-2 lg:pt-0">
        <form>
          <div className={formFieldContainerClasses}>
            <label className="block col-span-12 text-gray-900 text-xs uppercase font-bold">
              Event Name
            </label>
            <input
              className="col-span-11 shadow appearance-none border rounded-sm w-full text-base py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="eventName"
              placeholder="Name your event"
              value={title}
              onChange={e => setTitle(e.target.value)}
              required={true}
            />
          </div>
          <div className={formFieldContainerClasses}>
            <label className="block text-gray-900 text-xs uppercase font-bold">
              Starts At:
            </label>
            <DatePicker
              showTimeSelect
              selected={startDate}
              onChange={date => setStartDate(date)}
              popperPlacement="left"
              className="col-span-11 shadow appearance-none block border rounded-sm w-full text-base py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </div>
          <div className={formFieldContainerClasses}>
            <label className="block text-gray-900 text-xs uppercase font-bold">
              Duration:
            </label>
            <select
              className="border-2 px-1 py-1 text-sm rounded-sm mr-2 mb-1"
              value={durationMinutes}
              onChange={e => setDurationMinutes(parseInt(e.target.value))}
            >
              {eventDurationOptions.map(option => (
                <option key={option[0]} value={option[0]}>
                  {option[1]}
                </option>
              ))}
            </select>
          </div>
          <div className={formFieldContainerClasses}>
            <label className="block text-gray-900 text-xs uppercase font-bold">
              Event Type:
            </label>
            <select
              className="border-2 px-1 py-1 text-sm rounded-sm mr-2 mb-1"
              value={eventType}
              onChange={e => setEventType(e.target.value)}
            >
              {eventTypeOptions.map(option => (
                <option key={option[0]} value={option[0]}>
                  {option[1]}
                </option>
              ))}
            </select>
          </div>
          {eventType === "inPersonEvent" && (
            <div className={formFieldContainerClasses}>
              <label className="block col-span-12 text-gray-900 text-xs uppercase font-bold">
                Event Location
              </label>
              <LocationSearchInput
                initLocation={eventLocation}
                setLocation={setEventLocation}
                className="col-span-11 shadow appearance-none border rounded-sm w-full text-base py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          )}
          <div className={formFieldContainerClasses}>
            <label className="block col-span-12 text-gray-900 text-xs uppercase font-bold">
              Ticket Link
            </label>
            <input
              className="col-span-11 shadow appearance-none border rounded-sm w-full text-base py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="eventTicketLinkUrl"
              placeholder="Do you have an external ticket link? (or ignore it)"
              value={eventTicketLinkUrl}
              onChange={e => setEventTicketLinkUrl(e.target.value)}
              required={true}
            />
          </div>
          <div className={formFieldContainerClasses}>
            <label className="block col-span-12 text-gray-900 text-xs uppercase font-bold">
              Description
            </label>
            <TaggableInput
              value={eventDescription}
              onChange={setEventDescription}
              placeholder="What are the details of the event"
              className="event-form"
            />
            <div className="text-xs text-gray-500 leading-tight mt-1">
              <span role="img" aria-label="hot tip">
                🔥
              </span>{" "}
              @mention your band, and other bands on the bill to generate
              preview blocks and for more reach
              <span role="img" aria-label="hot tip">
                🔥
              </span>
            </div>
            {isEdit && (
              <div className={clsx(formFieldContainerClasses, "mt-2")}>
                <label className="block text-gray-900 text-xs uppercase font-bold">
                  Event Status:
                </label>
                <select
                  className="border-2 px-1 py-1 text-sm rounded-sm mr-2 mb-1"
                  value={eventStatus}
                  onChange={e => setEventStatus(e.target.value)}
                >
                  {eventStatusOptions.map(option => (
                    <option key={option[0]} value={option[0]}>
                      {option[1]}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <button
            type="button"
            className="text-blue-600 italic text-sm mb-2 focus:outline-none"
            onClick={e => setShowAdvanced(!showAdvanced)}
          >
            {showAdvanced ? "Hide" : "Show"} Advanced
          </button>
          {showAdvanced && (
            <div className={formFieldContainerClasses}>
              <label className="block text-gray-900 text-xs uppercase font-bold">
                Max RSVPs:
              </label>
              <input
                type="text"
                className="col-span-11 shadow appearance-none border rounded-sm w-full text-base py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="maxRsvps"
                placeholder="Cap the number of people who can RSVP"
                value={maxRsvps}
                onChange={e => setMaxRsvps(e.target.value)}
              ></input>
            </div>
          )}
          <div className="col-span-12 text-red-700 lg:text-sm text-lg">
            {formError}
          </div>
          <div className="mt-3">
            {concert.id ? (
              <>
                <Button
                  disabled={!btnActive}
                  className="mr-2 mb-1"
                  bg="bg-green-400"
                  onClick={onUpdate}
                >
                  Update Event
                </Button>
                <PostDeleteButton
                  postId={concert.id}
                  bandId={concert.band_id}
                  setShowForm={setShowForm}
                  queryKey={["posts"]}
                />
              </>
            ) : (
              <Button
                disabled={!btnActive}
                bg="bg-green-400"
                onClick={onCreate}
                className="mr-2 mb-1"
              >
                Create Event
              </Button>
            )}

            <Button
              disabled={!btnActive}
              bg="bg-gray-400"
              onClick={() => (window.location.href = root_path())}
              style={{ height: "fit-content" }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EventForm
