import React, { useState } from "react"
import { SyncSummary } from "./SyncSummary"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const Bandsintown = ({ band }) => {
  const [bandsintownName, setBandsintownName] = useState(
    band.bandsintown_name || "",
  )

  const fieldData = {
    field: "bandsintown_name",
    serviceClass: "BandsintownService",
    name: "bandsintown",
    label: `Your ${band.band_type_nice}'s Name on Bandsintown`,
    placeholder: "Bandsintown Name",
    notes:
      "we sync your bandsintown shows, but email you before we publish them so you can add a flyer/details. Your  name is usually your band name, but sometimes it's different so you can set that here",
    required: false,
  }

  return (
    <>
      <EditBandFormTextField
        fieldData={fieldData}
        value={bandsintownName}
        setValue={setBandsintownName}
        bandId={band.id}
      />
      <SyncSummary
        fieldData={fieldData}
        bandId={band.id}
        value={bandsintownName}
      />
    </>
  )
}
