import React from 'react'

export const UserInteractionStatCard = ({
    type,
    dataStat
}) => {
    return (
        <li className='flex flex-col justify-center items-center border-4 rounded-sm w-full h-48 mx-auto leading-none'>
            <div className="text-3xl font-semibold uppercase tracking-wide px-1">{type}</div>
            <div className="text-6xl font-bold">{dataStat}</div>
        </li>
    )
}
