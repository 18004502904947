import React from "react"
import { HomeScreenBanner } from "./HomeScreenBanner"
import { PromotionModule } from "./PromotionModule"
import {
  SellItEventsVideo,
  SellItGeneralPitch,
  SellItJoinNow,
  SellItOwnBandNada,
} from "./SellIt"
import ShowOfTheWeekSidebar from "./ShowOfTheWeekSidebar"

const SellItItem = ({ index, showDialog, setShowDialog }) => {
  const genKey = `pitch-${index}`
  const items = [
    <HomeScreenBanner
      key="home-screen-banner"
      showDialog={showDialog}
      setShowDialog={setShowDialog}
    />,
    <SellItGeneralPitch key="sellit-general-pitch" />,
    <ShowOfTheWeekSidebar key="show-of-the-week-sidebar" className="mb-6" />,
    <PromotionModule key="promotion-module" />,
    <SellItJoinNow
      key="sell-it-join-now"
      showDialog={showDialog}
      setShowDialog={setShowDialog}
    />,
    <SellItEventsVideo key="sellit-events-video" />,
    <SellItOwnBandNada
      key="sell-it-own-bandnada"
      showDialog={showDialog}
      setShowDialog={setShowDialog}
    />,
  ]
  return items[index] || null
}

export default SellItItem
