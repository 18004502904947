import React, { useContext } from "react"
import { useParams } from "react-router-dom"
import { usePost } from "../../hooks/usePost"
import { UserContext } from "../HomeApp"
import Header from "../shared/Header/Header"
import Heading from "../shared/Heading"
import style from "../shared/PageStyle.module.css"
import { PostListItem } from "./PostListItem"
import { Spinner } from "../shared/Spinner"
import FreeTextFormat from "../shared/FreeTextFormat"
import { isMobile } from "react-device-detect"
import UserCalendar from "../home/components/UserCalendar"
import ActivityFeed from "../home/components/ActivityFeed"
import ShowOfTheWeekSidebar from "../home/components/ShowOfTheWeekSidebar"
import Footer from "../shared/Footer/Footer"
import MakeStuff from "../home/components/MakeStuff"

const PostScreen = () => {
  let { id: postId } = useParams()
  let { user } = useContext(UserContext)

  const { status: postStatus, data: postData } = usePost(postId)
  var post = postData?.post

  return (
    <div className={style.rootElStyle}>
      <Header user={user} />
      <div className="lg:grid lg:grid-cols-12 w-full pb-2 mt-4 px-3 lg:px-4 flex flex-col-reverse">
        <div className="lg:col-span-4 col-span-12 lg:pr-6">
          <MakeStuff />
          <ShowOfTheWeekSidebar className="mb-6" />
          {user && (
            <div className="mb-6">
              <UserCalendar user={user} />
            </div>
          )}
          {user && user.enable_activity_feed && !isMobile && (
            <div className="">
              <ActivityFeed />
            </div>
          )}
        </div>
        <div className="lg:col-span-8 col-span-12 lg:mt-0">
          <Heading
            level={2}
            type="sectionHeader"
            className="flex justify-between"
          >
            <span>
              <FreeTextFormat
                text={post ? post.title : "Band Post"}
                expandable={false}
                expanded={false}
              />
            </span>
          </Heading>
          {postStatus === "success" ? (
            <ul>
              <PostListItem bandId={post.band_id} post={post} expanded={true} />
            </ul>
          ) : postStatus === "error" ? (
            <div>
              Sorry, we couldn't find that post. Try the search box above, a new
              post may have been created in its place.
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PostScreen
