import DOMPurify from "dompurify"
import { anchormeBandNada } from "../../util/strings"

const LinkifyText = (message, stylize = true) => {
  const result = anchormeBandNada(message, stylize)
  // return DOMPurify.sanitize(result)
  var allowedAttrs = [
    "onerror",
    "class",
    "src",
    "controls",
    "poster",
    "href",
    "target",
  ]
  var disallowedAttrs = []
  if (stylize) {
    allowedAttrs.push("style")
  } else {
    disallowedAttrs.push("style")
  }
  return DOMPurify.sanitize(result, {
    ALLOWED_TAGS: ["span", "a", "iframe", "video", "source", "img"],
    ALLOWED_ATTR: allowedAttrs,
    FORBID_ATTR: disallowedAttrs,
  })
}

export default LinkifyText
