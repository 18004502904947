import React, { useContext } from "react"
import { isMobile } from "react-device-detect"
import { UserContext } from "../../../HomeApp"
import { CopyPostUrlLink } from "../../../posts/CopyPostUrlLink"
import PreviewPlayer from "../../../PreviewPlayer"
import { OutlineButton } from "../../../shared/Button"
import {
  FollowComponent,
  FollowComponentLoggedOut,
} from "../../../shared/FollowComponent"
import { BandSocialIcons } from "../../BandSocialIcons"
import styles from "./styles.module.css"

const defaultBackgroundGif =
  "https://bandnada.s3.amazonaws.com/static/gifs/bandnada_with_slogans_edit_1.gif"
const defaultBackgroundStatic =
  "https://bandnada.s3.amazonaws.com/static/external_assets/web/background_logo_bottom_left_gray.png"

const BandHeader = ({
  band,
  headerMode,
  isAdmin,
  adminMode,
  setAdminMode,
  isLive = false,
}) => {
  const { user } = useContext(UserContext)

  const bandUrl = `${location.protocol}//${location.host}${band.band_path}`
  const isFan = band.band_type === "fan"

  const fadeGradient = "linear-gradient(transparent, 47%, white)"
  const blackGradient = "linear-gradient(black, 30%, transparent)"
  const angleGradient =
    "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))"
  const backgroundImage = band.cover_photo_url_large
    ? `url(${band.cover_photo_url_large})`
    : isMobile
    ? `url(${defaultBackgroundStatic})`
    : `url(${defaultBackgroundGif})`

  const classicBgImage = band.cover_photo_url_large
    ? `${
        isMobile ? "url('')" : fadeGradient
      }, ${blackGradient}, ${backgroundImage}`
    : isMobile
    ? `${backgroundImage}`
    : `linear-gradient(transparent, 79%, white), ${backgroundImage}`
  const bgImage = band.cover_photo_url_large
    ? `${
        isMobile ? "url('')" : fadeGradient
      }, ${angleGradient}, ${backgroundImage}`
    : `${fadeGradient}, ${angleGradient}, ${backgroundImage}`

  const backgroundStyle = {
    aspectRatio: "16 / 9",
    backgroundImage: bgImage,
    backgroundSize: band.cover_photo_url_large ? "cover" : "contain",
    backgroundRepeat: "no-repeat",
  }

  const classicBackgroundStyle = {
    aspectRatio: isFan ? null : "16 / 9",
    backgroundImage: isFan ? null : classicBgImage,
    backgroundColor: isFan ? "black" : null,
    backgroundSize: band.cover_photo_url_large ? "cover" : "contain",
    backgroundRepeat: "no-repeat",
  }

  const width = window.innerWidth
  const imageSliceStyle = {
    width: 0,
    height: 0,
    borderTop: `${width * 1.91}px solid transparent`,
    borderBottom: `${width * 1.09}px solid transparent`,
    borderLeft: `${width * 2.29}px solid white`,
    transform: `rotate(70deg)`,
    marginTop: `${width * -0.33}px`,
    marginLeft: `${-width}px`,
    opacity: 0.8,
  }

  const coverImageHeader = (
    <>
      <div
        style={
          headerMode === "classic" ? classicBackgroundStyle : backgroundStyle
        }
      >
        <div className="flex flex-col lg:flex-row justify-between h-full lg:h-auto lg:border-b mb-4 border-white text-white px-3 lg:px-4">
          <div className="flex">
            <div className="lg:text-right relative my-2">
              <PreviewPlayer
                bandId={band.id}
                width="4rem"
                height="4rem"
                className="text-white mr-2"
              />
            </div>
            <div className="lg:col-span-9 col-span-10 flex">
              <div className="flex-grow py-2">
                <div className="flex flex-row align-items-start">
                  <h1 className="font-extrabold text-3xl uppercase leading-none">
                    {band.name}
                    <span className="block font-normal lg:inline lg:ml-2 text-sm">
                      {band.band_type_nice}{" "}
                      {band.location && band.location.displayName
                        ? `${band.band_type === "venue" ? "in" : "from"} ${
                            band.location.displayName
                          }`
                        : null}
                    </span>
                    {isAdmin && !isMobile && !isFan && (
                      <span className="pl-4">
                        <OutlineButton onClick={e => setAdminMode(!adminMode)}>
                          {adminMode ? "Exit Admin Mode" : "Enter Admin Mode"}
                        </OutlineButton>
                      </span>
                    )}
                  </h1>
                  {!isMobile && (
                    <div className={styles.shareContainer}>
                      <CopyPostUrlLink
                        nadaUrl={bandUrl}
                        renderMode="button"
                      ></CopyPostUrlLink>
                    </div>
                  )}
                </div>
                <BandSocialIcons
                  band={band}
                  color="text-white"
                  margins="mr-3 mt-1"
                  includeShareIcon={true}
                />
              </div>
            </div>
          </div>

          <div className="lg:text-right relative py-2 lg:self-center">
            {isFan ? null : user ? (
              <FollowComponent
                bandId={band.id}
                bandName={band.name}
                className="rounded-t"
              />
            ) : (
              <FollowComponentLoggedOut
                bandId={band.id}
                bandName={band.name}
                className="rounded-t"
              />
            )}
          </div>
        </div>
      </div>
      {headerMode !== "classic" && !isLive && (
        <div className="hidden lg:block" style={imageSliceStyle}></div>
      )}
    </>
  )

  return coverImageHeader
}

export default BandHeader
