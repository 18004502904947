import { format } from "date-fns"

export const DateDifferenceInWords = (a, b) => {
  const _MS_PER_SEC = 1000
  const _MS_PER_MIN = _MS_PER_SEC * 60
  const _MS_PER_HOUR = _MS_PER_MIN * 60
  const _MS_PER_DAY = _MS_PER_HOUR * 24

  const utc1 = Date.UTC(
    a.getFullYear(),
    a.getMonth(),
    a.getDate(),
    a.getHours(),
    a.getMinutes(),
    a.getSeconds(),
  )
  const utc2 = Date.UTC(
    b.getFullYear(),
    b.getMonth(),
    b.getDate(),
    b.getHours(),
    b.getMinutes(),
    b.getSeconds(),
  )
  const diffMS = utc2 - utc1
  const absDiffMS = Math.abs(diffMS)
  const inFuture = utc1 > utc2
  const preText = inFuture ? "In " : ""
  const postText = inFuture ? "" : " ago"

  if (absDiffMS < _MS_PER_MIN) {
    // display seconds
    return {
      fullText: `<1 min${postText}`,
      inFuture: inFuture,
      units: "seconds",
      diffInSeconds: absDiffMS * _MS_PER_SEC,
      updateInMS: 20 * _MS_PER_SEC,
    }
  } else if (absDiffMS < _MS_PER_HOUR) {
    // display minutes
    return {
      fullText: `${preText}${Math.abs(
        Math.floor(diffMS / _MS_PER_MIN),
      )} min${postText}`,
      inFuture: inFuture,
      units: "minutes",
      diffInSeconds: absDiffMS * _MS_PER_SEC,
      updateInMS: 60 * _MS_PER_SEC,
    }
  } else if (absDiffMS < _MS_PER_DAY) {
    // display hours
    return {
      fullText: `${preText}${Math.abs(
        Math.floor(diffMS / _MS_PER_HOUR),
      )} hrs${postText}`,
      inFuture: inFuture,
      units: "hours",
      diffInSeconds: absDiffMS * _MS_PER_SEC,
      updateInMS: 20 * 60 * _MS_PER_SEC,
    }
  } else {
    // return date
    return {
      fullText: SmartDateFormat({ date: a, includeTime: true }),
      inFuture: inFuture,
      units: "days",
      diffInSeconds: absDiffMS * _MS_PER_SEC,
      updateInMS: 100 * 60 * _MS_PER_SEC,
    }
  }
}

export const isSameDay = (a, b) => {
  if (!a || !b) return false
  a = new Date(a)
  b = new Date(b)
  return (
    !!a &&
    !!b &&
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  )
}

export const SmartDateFormat = ({
  date = null,
  dateStr = null,
  includeTime = true,
  includeOrdinal = true,
}) => {
  if (!date && !dateStr) return ""
  date = date ? date : new Date(dateStr)
  const today = new Date()

  const isThisYear = today.getFullYear() === date.getFullYear()
  const isThisWeek = format(today, "w") === format(date, "w")
  const isToday =
    isThisYear &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  const timeFormat = includeTime ? "h:mma" : ""

  if (date) {
    const ordinalStr = includeOrdinal ? nth(format(date, "d")) : ""

    return isToday
      ? includeTime
        ? "Today " + format(date, timeFormat)
        : "Today"
      : isThisWeek
      ? format(date, `EEEE LLLL d`) +
        ordinalStr +
        (includeTime ? format(date, ` ${timeFormat}`) : "")
      : isThisYear
      ? format(date, `EEEE LLLL d`) +
        ordinalStr +
        (includeTime ? format(date, ` ${timeFormat}`) : "")
      : format(date, `yyyy LLLL d`) +
        ordinalStr +
        (includeTime ? format(date, ` ${timeFormat}`) : "")
  } else {
    console.log("Failed to parse date, and format:", date)
    return ""
  }
}

export const timeOnly = ({ date = null, dateStr = null }) => {
  if (!date && !dateStr) return ""
  date = date ? date : new Date(dateStr)
  return format(date, "h:mma")
}

export const timeToMinutesSeconds = (time = 0) => {
  // @ts-ignore
  const totalTimeInSeconds = parseInt(time, 10)
  const minutes = Math.floor(totalTimeInSeconds / 60)
  const seconds = totalTimeInSeconds - minutes * 60

  if (seconds < 10) return `${minutes}:0${seconds}`
  return `${minutes}:${seconds}`
}

export const DayFormat = dateStr => {
  if (!dateStr) return ""
  const date = new Date(dateStr)
  const ordinalStr = nth(format(date, "d"))
  return format(date, `EEEE LLLL d`) + ordinalStr
}

export const DateFormat = dateStr => {
  if (!dateStr) return ""
  const date = new Date(dateStr)
  return format(date, `MM/dd/yyyy`)
}

const nth = function(d) {
  if (d > 3 && d < 21) return "th"
  switch (d % 10) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}
