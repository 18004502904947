import React, { useState } from "react"
import { SyncSummary } from "./SyncSummary"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const Bandcamp = ({ band }) => {
  const [bandcampUrl, setBandcampUrl] = useState(band.bandcamp_url || "")

  const fieldData = {
    field: "bandcamp_url",
    serviceClass: "BandcampService",
    name: "bandcamp",
    label: "Your Bandcamp URL",
    placeholder: "Bandcamp URL",
    notes:
      "we sync all of your bandcamp releases and display them on your page so your fans can see and listen to your recorded music on bandNada. Your url should look like: https://blackmothsuperrainbow.bandcamp.com/",
    required: false,
  }

  return (
    <>
      <EditBandFormTextField
        fieldData={fieldData}
        value={bandcampUrl}
        setValue={setBandcampUrl}
        bandId={band.id}
      />
      <SyncSummary fieldData={fieldData} bandId={band.id} value={bandcampUrl} />
    </>
  )
}
