import React, { useState, useContext } from "react"
import Header from "../shared/Header/Header"
import BandsFeed from "../bands/BandsFeed"
import Heading from "../shared/Heading"
import style from "../shared/PageStyle.module.css"
import { isMobile } from "react-device-detect"
import { UserContext } from "../HomeApp"
import MakeStuff from "./components/MakeStuff"
import ActivityFeed from "./components/ActivityFeed"
import UserCalendar from "./components/UserCalendar"
import ShowOfTheWeekSidebar from "./components/ShowOfTheWeekSidebar"
import SellIt from "./components/SellIt"
import { AuthenticationDialog } from "../shared/AuthenticationDialog"
import ConcertFeed from "./components/ConcertFeed"
import SuggestiveAction from "../SuggestiveAction"

const MIN_FOLLOWS_FOR_FOLLOWING = 3

export const HomeScreen = () => {
  const initFormState = new URLSearchParams(window.location.search).get(
    "make-stuff",
  )
  const [showForm, setShowForm] = useState(initFormState)
  const [newData, setNewData] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [postFilter, setPostFilter] = useState("") // albums, concerts, videos, headlines, or nada
  const { user, scene } = useContext(UserContext)
  const [feedType, setFeedType] = useState(
    user && user.user_bands_following > MIN_FOLLOWS_FOR_FOLLOWING
      ? "following"
      : "scene",
  ) // following, everything, scene
  const showPitchInFeed = !user && isMobile

  const onFilterClick = filter => {
    if (postFilter === filter) {
      setPostFilter("")
    } else {
      setPostFilter(filter)
    }
  }

  return (
    <div className={style.rootElStyle} id="home-screen-container">
      <Header user={user} />
      <div className="grid grid-cols-12 w-full pb-2 mt-4 px-3 lg:px-4">
        <SuggestiveAction />
        {!showPitchInFeed && (
          <div className="lg:col-span-4 col-span-12 lg:pr-6">
            {showDialog ? <AuthenticationDialog /> : null}
            {!user && !showPitchInFeed && (
              <SellIt showDialog={showDialog} setShowDialog={setShowDialog} />
            )}
            {user?.band ? (
              <>
                <div className="text-xs text-white uppercase leading-none">
                  Create Your Event or Post in bandNada
                </div>
                <MakeStuff />
              </>
            ) : (
              <div className="mt-3"></div>
            )}
            {user && <ShowOfTheWeekSidebar className="mb-6" />}

            {!(isMobile && showForm) && (
              <div className="mb-6">
                <UserCalendar user={user} />
              </div>
            )}
            {user?.enable_activity_feed && !isMobile && (
              <div className="mb-6">
                <ActivityFeed />
              </div>
            )}
          </div>
        )}

        <div className="lg:col-span-8 col-span-12">
          {!(isMobile && showForm) && (
            <Heading
              level={2}
              type="sectionHeader"
              className="lg:flex justify-between"
            >
              <div className="">
                <div className="text-xs text-black-800 uppercase leading-none">
                  Feed
                </div>
                {user && (
                  <>
                    <button
                      onClick={e => setFeedType("following")}
                      title="only content from the bands you follow"
                      className={`focus:outline-none ${
                        feedType === "following"
                          ? "text-black-800 font-bold uppercase"
                          : "text-gray-500 lowercase"
                      }`}
                    >
                      Following
                    </button>
                    <span className="px-2 text-gray-300">|</span>
                  </>
                )}
                <button
                  onClick={e => setFeedType("scene")}
                  title="only content from your current scene"
                  className={`focus:outline-none ${
                    feedType === "scene"
                      ? "text-black-800 font-bold uppercase"
                      : "text-gray-500 lowercase"
                  }`}
                >
                  {scene}
                </button>
                <span className="px-2 text-gray-300">|</span>
                <button
                  onClick={e => setFeedType("everything")}
                  title="everything (not recommended)"
                  className={`focus:outline-none ${
                    feedType === "everything"
                      ? "text-black-800 font-bold uppercase"
                      : "text-gray-500 lowercase"
                  }`}
                >
                  <span role="img" aria-label="everything (not recommended)">
                    ☠️
                  </span>
                </button>
              </div>

              <div className="flex items-end pb-1">
                <button
                  onClick={e => onFilterClick("")}
                  className="text-sm focus:outline-none mr-3"
                  style={{ color: postFilter === "" ? "black" : "gray" }}
                >
                  Everything
                </button>
                <button
                  onClick={e => onFilterClick("albums")}
                  className="text-sm focus:outline-none mr-3"
                  style={{ color: postFilter === "albums" ? "black" : "gray" }}
                >
                  Music
                </button>
                <button
                  onClick={e => onFilterClick("concerts")}
                  className="text-sm focus:outline-none mr-3"
                  style={{
                    color: postFilter === "concerts" ? "black" : "gray",
                  }}
                >
                  Shows
                </button>
                <button
                  onClick={e => onFilterClick("videos")}
                  className="text-sm focus:outline-none mr-3"
                  style={{ color: postFilter === "videos" ? "black" : "gray" }}
                >
                  Video
                </button>
                <button
                  onClick={e => onFilterClick("headlines")}
                  className="text-sm focus:outline-none"
                  style={{
                    color: postFilter === "headlines" ? "black" : "gray",
                  }}
                >
                  Posts
                </button>
              </div>
            </Heading>
          )}

          {!(isMobile && showForm) ? (
            postFilter === "concerts" ? (
              <ConcertFeed
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                newData={newData}
                feedType={feedType}
              />
            ) : (
              <BandsFeed
                showPitchInFeed={showPitchInFeed}
                filter={postFilter}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                feedType={feedType}
                newData={newData}
              />
            )
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default HomeScreen
