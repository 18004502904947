import React, { useEffect, useMemo, useState } from "react"
import { useUserPosts } from "../../../hooks/useUserPosts"
import Heading from "../../shared/Heading"
import { RequestError } from "../../shared/RequestError"
import { Spinner } from "../../shared/Spinner"
import CalendarEvent from "./CalendarEvent"

const UserCalendar = ({ user }) => {
  if (!user) return null
  const PER_PAGE = 20
  const [page, setPage] = useState(1)

  const [_calendarEvents, setCalendarEvents] = useState([])
  const calendarEvents = useMemo(() => _calendarEvents, [_calendarEvents])

  // Change this to useUserPosts and make a new endpoint like /user/{id}/posts
  //  cause this filter is too different to go through normal posts controller
  const { isLoading, isError, isFetching, data, error } = useUserPosts(
    user.id,
    "future_concerts",
    page,
    PER_PAGE,
  )

  useEffect(() => {
    if (!isLoading && !isError && !isFetching) {
      if (page === 1) {
        setCalendarEvents(data.posts)
      } else {
        setCalendarEvents([...data.posts, ...calendarEvents])
      }
    }
  }, [isFetching])

  return (
    <>
      <Heading level={2} type="sectionHeader">
        Your Calendar Events
      </Heading>
      <div className="flex flex-col">
        {isFetching ? (
          <Spinner />
        ) : error ? (
          <RequestError error={error} />
        ) : data ? (
          <>
            <ul>
              {calendarEvents.length > 0 ? (
                calendarEvents.map(calendarEvent => (
                  <CalendarEvent
                    key={`activity-feed-item-${calendarEvent.id}`}
                    calendarEvent={calendarEvent}
                  />
                ))
              ) : (
                <li className="italic text-gray-600 text-sm">
                  There aren't any upcoming shows that you have RSVP'd for. Try
                  clicking the "Shows" filter on your Band Feed and look for
                  some.
                </li>
              )}
            </ul>
          </>
        ) : null}
      </div>
    </>
  )
}

export default UserCalendar
