import React, { useState, useRef } from "react"
import { Button } from "../shared/Button"
import Cropper from "react-cropper"
import { CardImage } from "react-bootstrap-icons"

export const EventFlyerPhotoUploadSection = ({
  setOriginalFile,
  setFileType,
  setCropper,
  setGifFile,
  gifPreview,
  coverImageUrl,
}) => {
  const [btnActive, setBtnActive] = useState(true)
  const [uploadedFilePath, setUploadedFilePath] = useState("")
  const [removeImage, setRemoveImage] = useState(false)

  const hiddenFileInput = useRef(null)
  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const reader = new FileReader()
  reader.onload = e => {
    // @ts-ignore
    setUploadedFilePath(e.target.result)
  }

  const onUploadFlyerPhoto = the_attachment => {
    setBtnActive(false)
    setOriginalFile(the_attachment)
    setFileType(the_attachment.type)
    if (the_attachment.type !== "image/gif") {
      reader.readAsDataURL(the_attachment)
    }
    setBtnActive(true)
  }

  const onRemoveGif = e => {
    e.preventDefault()
    setGifFile(null)
    setBtnActive(true)
  }

  const onRemovePhoto = e => {
    e.preventDefault()
    hiddenFileInput.current = null
    setRemoveImage(true)
    setBtnActive(true)
  }

  const onCancel = () => {
    setBtnActive(false)
    hiddenFileInput.current = null
    setUploadedFilePath(null)
    setBtnActive(true)
  }

  const gifPreviewStyle = {
    width: "100%",
    aspectRatio: "16 / 9",
  }

  return (
    <div className="grid grid-cols-12">
      <label className="block col-span-12 text-gray-900 text-xs uppercase font-bold">
        Show Flyer Image
      </label>
      <div className="col-span-12 ">
        {coverImageUrl && !removeImage ? (
          <div>
            <img src={coverImageUrl} alt="existing flyer" />
            <Button
              disabled={!btnActive}
              bg="bg-gray-400"
              className="mt-2"
              onClick={onRemovePhoto}
            >
              Replace Image
            </Button>
          </div>
        ) : gifPreview !== null ? (
          <div>
            <img
              src={gifPreview}
              alt="existing flyer"
              style={gifPreviewStyle}
            />
            <Button
              disabled={!btnActive}
              bg="bg-gray-400"
              className="mt-2"
              onClick={onRemoveGif}
            >
              Replace Image
            </Button>
          </div>
        ) : uploadedFilePath && uploadedFilePath.length ? (
          <>
            <h6 className="px-3 font-bold">Crop your photo</h6>
            <Cropper
              className="mt-2"
              initialAspectRatio={16 / 9}
              aspectRatio={16 / 9}
              src={uploadedFilePath}
              viewMode={1}
              guides={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={true}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={instance => {
                setCropper(instance)
              }}
            />
            <div className="mt-2 mb-2">
              <Button
                disabled={!btnActive}
                bg="bg-gray-400"
                className="mx-3"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <>
            <CardImage className="w-full col-span-12 text-gray-500 h-64 block bg-gray-200" />
            <Button
              disabled={!btnActive}
              bg="bg-green-400"
              className="mt-2 w-full"
              onClick={handleClick}
            >
              Upload an Image
            </Button>
            <input
              type="file"
              ref={hiddenFileInput}
              accept="image/*"
              style={{ display: "none" }}
              onChange={e => onUploadFlyerPhoto(e.target.files[0])}
            />
          </>
        )}
      </div>
    </div>
  )
}
