import React from "react"
import { ReactComponent as LogoIcon } from "../../../icons/bandnada-logo-white.svg"
import { youTubePath, instagramPath } from "../../../util/paths"
import { faq_path, own_bandnada_path, root_path } from "../../../routes"

const Footer = () => {
  return (
    <footer className="w-full bg-black-800 mt-32 pb-16">
      <div className="py-16 px-5 flex flex-col items-center">
        <div className="mb-2">
          <a href={root_path()}>
            <LogoIcon className="h-16" />
          </a>
        </div>
        <div className="flex flex-wrap mb-16 justify-center">
          <a
            className="text-white text-base border-transparent border-b-2 hover:border-white mx-4 py-1"
            href={youTubePath()}
          >
            Youtube
          </a>
          <a
            className="text-white text-base border-transparent border-b-2 hover:border-white mx-4 py-1"
            href={instagramPath()}
          >
            Instagram
          </a>
          <a
            className="text-white text-base border-transparent border-b-2 hover:border-white mx-4 py-1"
            href={faq_path()}
          >
            FAQ
          </a>
          <a
            className="text-white text-base border-transparent border-b-2 hover:border-white mx-4 py-1"
            href={own_bandnada_path()}
          >
            Own bandNada
          </a>
          <a
            className="text-white text-base border-transparent border-b-2 hover:border-white mx-4 py-1"
            href="/how_to_broadcast"
          >
            Broadcast Help
          </a>
        </div>
        <div className="flex flex-col items-center opacity-75 text-center">
          <div className="text-xs text-white">
            &copy; bandNada {new Date().getFullYear()}
          </div>
          <div className="text-xs text-white">
            bandNada is owned and operated by Cole Hill Prime LLC
          </div>
          <div className="flex">
            <a
              className="text-white text-sm border-transparent border-b-1 hover:border-white mx-4 pt-1"
              href="/legal"
            >
              Legal
            </a>
            <a
              className="text-white text-sm border-transparent border-b-1 hover:border-white mx-4 pt-1"
              href="/privacy"
            >
              Privacy
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
