import { useQuery } from "react-query"
import { request } from "../util/request"

export function useUserPosts(userId, contentType, page = 1, perPage = 30) {
  return useQuery(
    ["user-posts", userId, contentType, page, perPage],
    getUserPosts,
  )
}

const getUserPosts = async ({ queryKey }) => {
  const [key, userId, contentType, page, perPage] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/users/${userId}/posts`,
        params: { content_type: contentType, page: page, per_page: perPage },
      },
    ],
  })
}
