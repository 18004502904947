import React, { useLayoutEffect, useRef, useState } from "react"
import { CardImage } from "react-bootstrap-icons"
import { toast } from "react-toastify"
import { CameraVideo } from "react-bootstrap-icons"

import styles from "./styles.module.scss"
import clsx from "clsx"
import UploadingStatus from "./UploadingStatus"
import { isMobile } from "react-device-detect"

export const MAX_UPLOAD_BYTES = 103238400

const ImagesUpload = ({
  setImageFiles,
  imageFiles,
  imagePreviews,
  setImagePreviews,
  isSubmitting,
}) => {
  const [btnActive, setBtnActive] = useState(true)
  const hiddenFileInput = useRef(null)
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useLayoutEffect(() => {
    if (isMobile) {
      setHeight(window.innerWidth / 3)
    } else {
      let width = (((window.innerWidth * 5) / 12) * 2) / 3
      setHeight((width / 3) * 0.725)
    }
  })

  const handleFileClick = () => {
    hiddenFileInput.current.click()
  }

  const onUploadImages = fileList => {
    const fileListArray = Array.from(fileList)
    fileListArray.forEach(attachment => {
      if (attachment.size > MAX_UPLOAD_BYTES) {
        toast.error("Sorry! The file must be smaller than 100MB")
      } else {
        setBtnActive(false)
        handleImageUpload(attachment)
        setBtnActive(true)
      }
    })
    setImageFiles(fileListArray)
  }

  const onRemoveImage = index => {
    setImagePreviews(imagePreviews.filter((_item, i) => i !== index))
    setImageFiles(imageFiles.filter((_item, i) => i !== index))
    setBtnActive(true)
  }

  const handleImageUpload = file => {
    if (file !== null) {
      const reader = new FileReader()
      reader.onload = e => {
        setImagePreviews(currentImagePreviews => [
          ...currentImagePreviews,
          {
            data: e.target.result,
            filename: file.name,
            file: file,
            isUploading: false,
            isUploaded: false,
            contentTypeGeneric: file.type.split("/")[0],
            contentTypeSpecific: file.type.split("/")[1],
          },
        ])
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <>
      {imagePreviews?.length > 0 && !isSubmitting && (
        <>
          <label className="block col-span-12 text-gray-900 text-xs uppercase font-bold mt-3">
            Image Previews
          </label>
          <div className="grid grid-cols-3 gap-3 mb-2" ref={ref}>
            {imagePreviews.map((imagePreview, index) => (
              <React.Fragment
                key={imagePreview.filename || imagePreview.signedId}
              >
                {imagePreview.contentTypeGeneric === "video" ? (
                  <div
                    className={clsx(
                      styles.imageContainer,
                      imagePreview.isUploading || imagePreview.isUploaded
                        ? styles.imageContainerCentered
                        : "",
                      "col-span-1",
                    )}
                    style={{ height: height }}
                  >
                    <div className={styles.videoThumbReplacementContainer}>
                      <div>
                        <CameraVideo className={styles.videoThumbReplacement} />
                        <div className={styles.videoThumbReplacementText}>
                          {imagePreview.filename}
                        </div>
                      </div>
                    </div>
                    <UploadingStatus
                      imagePreview={imagePreview}
                      index={index}
                      onRemoveImage={onRemoveImage}
                    />
                  </div>
                ) : (
                  <div
                    className={clsx(
                      styles.imageContainer,
                      imagePreview.isUploading || imagePreview.isUploaded
                        ? styles.imageContainerCentered
                        : "",
                      "col-span-1",
                    )}
                    style={{
                      height: height,
                    }}
                  >
                    <img
                      src={imagePreview.url || imagePreview.data}
                      alt={imagePreview.filename || imagePreview.filename}
                      title={imagePreview.filename || imagePreview.filename}
                      className={clsx(
                        styles.image,
                        imagePreview.isUploading ? styles.uploadingImage : "",
                      )}
                    />
                    <UploadingStatus
                      imagePreview={imagePreview}
                      index={index}
                      onRemoveImage={onRemoveImage}
                    />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}

      <button
        type="button"
        disabled={!btnActive}
        onClick={handleFileClick}
        className="bg-green-200 flex justify-center py-1 w-full focus:outline-none"
      >
        <CardImage size="24" className="text-gray-700 mr-1" /> Add{" "}
        {imagePreviews?.length > 0 && "More"} Images/Video
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        accept="image/*,video/*"
        style={{ display: "none" }}
        onChange={e => onUploadImages(e.target.files)}
        multiple={true}
      />
    </>
  )
}

export default ImagesUpload
