import React, { useState } from "react"
import {
  Link,
} from "react-router-dom"
import { useBandStreamGuests } from "../../../hooks/useBandStreamGuests"
import { mutationRequest } from "../../../util/request"
import { ActionCableConsumer } from "react-actioncable-provider"
import { Button } from "../Button"
import { queryCache } from "react-query"

export const UserNotification = ({ userId }) => {
  const [disableButton, setDisableButton] = useState(false)
  const [response, setResponse] = useState(null)
  const {
    status: bandStreamGuestsStatus,
    data: bandStreamGuestsData,
    error: bandStreamGuestsError,
  } = useBandStreamGuests()
  let bandStreamGuest = bandStreamGuestsData?.band_stream_guests[0]

  const updateBandStreamGuest = (field, value) => {
    setResponse(value)
    setDisableButton(true)
    handleUpdateBandStreamGuest(
      bandStreamGuest?.host_band_stream_id,
      bandStreamGuest.id,
      field,
      value,
    )
  }

  const notificationReceived = receivedMessage => {
    const { action, resource } = receivedMessage
    if (action === "new_user_notification") {
      queryCache.refetchQueries("bsgs")
    }
  }

  return (
    <>
      <ActionCableConsumer
        channel={{
          channel: "UserChannel",
          id: userId,
        }}
        onReceived={notificationReceived}
      />
      {bandStreamGuestsStatus === "success" && bandStreamGuest ? (
        <div className="bg-gray-200 mt-3 mx-3 px-2 py-2 rounded-sm">
          <h2 className="font-bold uppercase">Takeover Alert!</h2>
          <p className="mt-1">
            <Link to={bandStreamGuest.host_band.band_path} className="font-bold">
              {bandStreamGuest.host_band.name}
            </Link>{" "}
            has tagged you as a performer in their stream. Would you like to
            join their stream?
          </p>
          {response ? (
            response === "accepted" ? (
              <div className="mt-2 font-bold text-lg text-green-700">
                Great, you've joined the stream!
              </div>
            ) : (
              <div className="mt-2 font-bold text-lg text-green-700">
                Ignored ;)
              </div>
            )
          ) : (
            <>
              <div className="mt-2">
                <Button
                  disabled={disableButton}
                  onClick={e => updateBandStreamGuest("status", "accepted")}
                >
                  Join Stream
                </Button>
                <Button
                  disabled={disableButton}
                  bg="bg-gray-500"
                  className="ml-3"
                  onClick={e => updateBandStreamGuest("status", "rejected")}
                >
                  Ignore
                </Button>
              </div>
              <p className="mt-2">
                {bandStreamGuest.revenue_share === "zero" ? (
                  <span>
                    If you accept, we will temporarily add a link to your page,
                    telling your fans you are performing on{" "}
                    {bandStreamGuest.host_band.name}'s page
                  </span>
                ) : bandStreamGuest.revenue_share === "even_share" ? (
                  <span>
                    If you accept, their stream will temporarily show up on your
                    page along with their chat, and you will split any tips that
                    are given
                  </span>
                ) : (
                  <span>
                    If you accept, their stream will temporarily show up on your
                    page along with their chat, and you will receive all tips
                    from the stream until they remove the tag or end the stream
                  </span>
                )}
              </p>
            </>
          )}
        </div>
      ) : null}
    </>
  )
}

const handleUpdateBandStreamGuest = async (
  bandStreamId,
  bandStreamGuestId,
  field,
  value,
) => {
  let params = {}
  params[field] = value
  mutationRequest({
    options: { method: "PUT" },
    path: `/api/v1/band_streams/${bandStreamId}/band_stream_guests/${bandStreamGuestId}`,
    data: {
      band_stream_guest: params,
    },
  })
}
