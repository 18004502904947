import React, { useState } from "react"
import { destroyPost } from "../../util/backend_api/posts"
import { useQueryClient } from "react-query"
import { Button } from "../shared/Button"
import styles from "./styles.module.scss"
import { CheckCircle, XCircle } from "react-bootstrap-icons"

const PostDeleteButton = ({
  postId,
  bandId,
  setShowForm,
  queryKey = ["posts"],
}) => {
  const [btnState, setBtnState] = useState("active")
  const queryClient = useQueryClient()

  const onDestroyClick = async () => {
    if (btnState === "active") {
      setBtnState("confirm")
    } else if (btnState === "confirm") {
      setBtnState("disabled")
      await destroyPost(postId, bandId)
      setShowForm(null)
      queryClient.invalidateQueries(queryKey)
    }
  }

  return btnState === "confirm" ? (
    <>
      <div className={styles.confirmBtn}>
        Are you sure?
        <button onClick={onDestroyClick} className={styles.affirmBtn}>
          <CheckCircle size={20} />
        </button>
        <button
          onClick={() => setBtnState("active")}
          className={styles.cancelBtn}
        >
          <XCircle size={20} />
        </button>
      </div>
    </>
  ) : (
    <Button
      bg="bg-red-500 mr-2 mb-1"
      onClick={onDestroyClick}
      disabled={btnState === "disabled"}
    >
      {btnState === "active" ? "Delete Post" : "deleting..."}
    </Button>
  )
}

export default PostDeleteButton
