import React, { useState } from "react"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const Facebook = ({ band }) => {
  const [facebookUrl, setFacebookUrl] = useState(band.facebook_url || "")

  const fieldData = {
    field: "facebook_url",
    name: "facebookUrl",
    label: "Your facebook page url",
    placeholder: "The url of your facebook page",
    required: false,
  }

  return (
    <EditBandFormTextField
      fieldData={fieldData}
      value={facebookUrl}
      setValue={setFacebookUrl}
      bandId={band.id}
    />
  )
}
