import React, { useState } from "react"
import { Link } from "react-router-dom"
import { DateDifferenceInWords, SmartDateFormat } from "../../util/dates"
import { PlayCircle, PlusCircle } from "react-bootstrap-icons"
import { isMobile } from "react-device-detect"
import CommentScreen from "../comments/CommentScreen"
import { useDispatch } from "react-redux"
import { prependAndPlay, pushToQueue } from "../../data/mediaPlayerSlice"
import { PostListItemExpandedStyles } from "./constants"
import { CopyPostUrlLink } from "./CopyPostUrlLink"
import { AnchorButton, ShowHideButton } from "../shared/Button"
import { ReactionBlock } from "./ReactionBlock"
import RelatedPosts from "./RelatedPosts"

export const SongListItemExpanded = ({
  song,
  setIsExpanded,
  updateStatus,
  adminMode = false,
}) => {
  const [postStatus, setPostStatus] = useState(song.status)

  const dispatch = useDispatch()

  const onPlayClick = e => {
    dispatch(prependAndPlay(mappedSong()))
  }

  const onAddClick = e => {
    dispatch(pushToQueue(mappedSong()))
  }

  const mappedSong = () => {
    return [song].map(song => ({
      ...song,
      album_name: song.title,
      cover_image_small: song.external_photo_src,
    }))
  }

  return (
    <div className="grid grid-cols-12">
      <div className={PostListItemExpandedStyles.contentDiv}>
        <img
          src={song.external_photo_src}
          alt={song.title}
          className="w-full"
          onClick={e => setIsExpanded(false)}
        />
      </div>
      <div className={PostListItemExpandedStyles.metaDiv}>
        <div className="flex">
          <div className="flex flex-none w-12">
            <PlayCircle
              className="mt-1 mr-1 inline cursor-pointer"
              size="16"
              onClick={onPlayClick}
            />
            <PlusCircle
              className="mt-1 mr-1 inline cursor-pointer"
              size="16"
              onClick={onAddClick}
            />
          </div>

          <div className="flex-grow">
            <h2
              className={`${PostListItemExpandedStyles.header} cursor-pointer inline mr-2`}
              dangerouslySetInnerHTML={{ __html: song.title }}
              onClick={e => setIsExpanded(false)}
            ></h2>
            <CopyPostUrlLink nadaUrl={song.nada_url} renderMode="button" />
          </div>
        </div>
        <h3>
          <Link
            className={PostListItemExpandedStyles.bandName}
            to={song.local_path}
          >
            {song.band_name}
          </Link>
        </h3>
        <div className="text-xs">
          {song.future_active_date &&
            DateDifferenceInWords(
              new Date(song.future_active_date),
              new Date(song.active_date),
            ).diffInSeconds > 86400 ? (
            <div>
              Announced&nbsp;
              {SmartDateFormat({
                dateStr: song.active_date,
                includeTime: false,
              })}
              &nbsp;for Release on&nbsp;
              {SmartDateFormat({
                dateStr: song.future_active_date,
                includeTime: false,
              })}
            </div>
          ) : (
            <div>
              Released&nbsp;
              {SmartDateFormat({
                dateStr: song.active_date,
                includeTime: false,
              })}
            </div>
          )}
        </div>
        <div className={PostListItemExpandedStyles.externalLink}>
          {song.class_name === "Bandcamp" ? (
            <div className="mt-1">
              <AnchorButton href={song.external_url} target="_blank">
                Buy on&nbsp;
                {song.class_name}
              </AnchorButton>
            </div>
          ) : (
            <a href={song.external_url} target="_blank" rel="noreferrer">
              view on&nbsp;
              {song.class_name}
            </a>
          )}
        </div>
        <div className="mt-4">
          <RelatedPosts postId={song.id} />
        </div>
        <div className="mt-4">
          <ReactionBlock
            contentType="Post"
            contentId={song.id}
            postId={song.id}
          />
        </div>
        <div className="mt-2 text-sm text-black" style={{ display: "flex" }}>
          <PlayCircle
            className={`mt-1 mr-1 cursor-pointer`}
            onClick={onPlayClick}
          />
          <PlusCircle
            className={`mt-1 mr-1 cursor-pointer`}
            onClick={onAddClick}
          />
          <span className="font-bold">1.</span>
          &nbsp;
          <span className="text-gray-700">{song.title}</span>
        </div>
      </div>
      <div className={PostListItemExpandedStyles.commentsDiv}>
        {adminMode && (
          <ShowHideButton
            updateStatus={updateStatus}
            postStatus={postStatus}
            setPostStatus={setPostStatus}
          />
        )}

        <CommentScreen
          postId={song.id}
          headerText="The Peanut Gallery Says:"
          className="mb-4"
        />
      </div>
    </div>
  )
}
