import { useQuery } from "react-query"
import { request } from "../util/request"

export function useBandStreams(bandId, page = 1, perPage = 5) {
  return useQuery(
    ["band_streams", bandId, page, perPage],
    getBandStreamsForBandId,
    {
      keepPreviouData: true,
    },
  )
}

const getBandStreamsForBandId = async ({ queryKey }) => {
  const [key, id, page, perPage] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands/${id}/band_streams`,
        params: { per_page: perPage, page: page },
      },
    ],
  })
}
