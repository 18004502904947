import { useQuery } from "react-query"
import { request } from "../util/request"

export function useReactions(contentType, contentId, filter, summary = false) {
  return useQuery(
    ["reactions", contentType, contentId, filter, summary],
    getReactionsForContent,
  )
}

export const getReactionsForContent = async ({ queryKey }) => {
  const [key, contentType, contentId, filter, summary] = [...queryKey]
  const params = {
    summary: summary,
    filter: filter,
    content_type: contentType,
    content_id: contentId,
  }

  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/reactions.json`,
        params,
      },
    ],
  })
}
