import React, { useState } from "react"
import { anchormeBandNada } from "../../util/strings"
import "./FreeTextFormat.scss"

export const FreeTextFormat = ({
  text,
  expandable = true,
  expanded = false,
  linkify = false,
}) => {
  const [expandText, setExpandText] = useState(expanded)

  return (
    <>
      {expandText ? (
        <>
          <div
            className="free-text-format"
            dangerouslySetInnerHTML={{
              __html: linkify
                ? anchormeBandNada(text.replace(/\n/g, "<br/>"))
                : text.replace(/\n/g, "<br/>"),
            }}
          ></div>
          {expandable && (
            <span
              className="cursor-pointer italic text-blue-600"
              onClick={e => setExpandText(false)}
            >
              less
            </span>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              maxHeight: "9em",
              overflow: "hidden",
              textOverflow: "elipsis",
            }}
            dangerouslySetInnerHTML={{
              __html: linkify ? anchormeBandNada(text, false) : text,
            }}
          ></div>
          {expandable && (
            <span
              className="cursor-pointer italic text-blue-600"
              onClick={e => setExpandText(true)}
            >
              more
            </span>
          )}
        </>
      )}
    </>
  )
}

export default FreeTextFormat
