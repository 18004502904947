import React, { createContext } from "react"
import { Provider } from "react-redux"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import "./HomeEntry.css"
import { ErrorBoundary } from "./shared/ErrorBoundary"
import { QueryParamProvider } from "use-query-params"
import { request } from "../util/request"
import { Spinner } from "./shared/Spinner"
import { ActionCableProvider } from "react-actioncable-provider"
import { RequestError } from "./shared/RequestError"
import { BandTestScreen } from "./bands/BandTestScreen"
import { BandHighlightsScreen } from "./bands/BandHighlightsScreen"
import HomeScreen from "./home/HomeScreen"
import BandDashboardScreen from "./band_dashboard/BandDashboardScreen"
import ChatScreen from "./chat/ChatScreen"
import BroadcastScreen, {
  GuestBroadcastScreen,
} from "./broadcast/BroadcastScreen"
import BandEditPage from "../pages/BandEditPage"
import { NewBandScreen } from "./bands/NewBandScreen"
import UserEditScreen from "./users/UserEditScreen"
import SearchResultsPage from "./search/SearchResultsPage"
import UniversalPlayer from "./UniversalPlayer"
import store from "../data/store"
import PostScreen from "./posts/PostScreen"
import { useQuery } from "react-query"
import ReactGA from "react-ga4"
import { createBrowserHistory } from "history"
import { LiveNowPage } from "./live_now/LiveNowPage"
import DiscoverPage from "../pages/DiscoverPage"
import FaqPage from "./faq/FaqPage"
import OwnBandNadaPage from "./own_bandnada/OwnBandNadaPage"
import { Loader } from "@googlemaps/js-api-loader"
import { BandScreen } from "./bands/BandScreen"
import ScrollToTop from "./ScrollToTop"
import {
  api_v1_user_path,
  band_dashboard_path,
  band_path,
  band_stream_guest_broadcast_path,
  chat_band_path,
  discover_path,
  edit_band_path,
  faq_path,
  go_live_path,
  highlights_band_path,
  live_now_path,
  new_band_path,
  new_event_path,
  new_post_path,
  own_bandnada_path,
  react_post_path,
  root_path,
  search_path,
  test_band_path,
  user_settings_path,
} from "../routes"
import NewPostScreen from "./NewPostScreen"
import { ToastContainer } from "react-toastify"

export const UserContext = createContext({
  user: null,
  spk: undefined,
  player_toggle_delay_secs: null,
  googlek: null,
  scene: null,
})

export default function HomeApp({
  env,
  host,
  spk,
  player_toggle_delay_secs,
  googlek,
  gaid,
  is_summary_screen,
}) {
  const userData = useQuery(["user", { path: api_v1_user_path }], request)
  const user = userData?.data?.user
  const band = user?.band
  const wsURL =
    env === "development" ? `ws://${host}/cable` : `wss://${host}/cable`
  const loader = new Loader({
    apiKey: googlek,
    libraries: ["places"],
  })
  loader.load()
  const scene = user?.scene_list || "new york city"

  if (env === "production" && gaid) {
    ReactGA.initialize(gaid)
    ReactGA.set({
      userId: user?.id,
      // add other data here
    })
    const history = createBrowserHistory()
    history.listen((location) => {
      ReactGA.set({ page: location.pathname }) // Update the user's current page
      ReactGA.send("pageview") // Record a pageview for the given page
    })
  }

  if (userData.status === "loading") {
    return <Spinner className="text-gray-500" />
  }
  if (userData.status === "error") {
    return <RequestError error={userData.error} />
  }

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Router>
          <ScrollToTop />
          <QueryParamProvider ReactRouterRoute={Route}>
            <ActionCableProvider url={wsURL}>
              <UserContext.Provider
                value={{
                  user,
                  spk,
                  player_toggle_delay_secs,
                  googlek,
                  scene,
                }}
              >
                <Switch>
                  <Route path={root_path()} exact={true}>
                    <HomeScreen />
                  </Route>

                  <Route path={react_post_path(":id")} exact={false}>
                    <PostScreen />
                  </Route>

                  <Route path={new_event_path()} exact={false}>
                    {user ? (
                      <NewPostScreen user={user} isEvent={true} />
                    ) : (
                      <Redirect from={new_event_path()} to={root_path()} />
                    )}
                  </Route>

                  <Route path={new_post_path()} exact={true}>
                    {user ? (
                      <NewPostScreen user={user} isEvent={false} />
                    ) : (
                      <Redirect from={new_post_path()} to={root_path()} />
                    )}
                  </Route>

                  <Route
                    path={chat_band_path(":custom_subdomain")}
                    exact={true}
                  >
                    <ChatScreen user={user} spk={spk} />
                  </Route>

                  <Route
                    path={test_band_path(":custom_subdomain")}
                    exact={false}
                  >
                    <BandTestScreen user={user} spk={spk} />
                  </Route>

                  <Route
                    path={edit_band_path(":custom_subdomain")}
                    exact={false}
                  >
                    {user ? (
                      <BandEditPage />
                    ) : (
                      <Redirect
                        from={edit_band_path(":custom_subdomain")}
                        to={root_path()}
                      />
                    )}
                  </Route>

                  <Route
                    path={highlights_band_path(":custom_subdomain")}
                    exact={false}
                  >
                    <BandHighlightsScreen user={user} />
                  </Route>

                  <Route path={new_band_path()} exact={false}>
                    <NewBandScreen user={user} band={band} />
                  </Route>

                  <Route path={band_path(":custom_subdomain")} exact={false}>
                    {is_summary_screen ? (
                      <BandHighlightsScreen user={user} />
                    ) : (
                      <BandScreen />
                    )}
                  </Route>

                  <Route path={band_dashboard_path()}>
                    {user && user.band ? (
                      <BandDashboardScreen bandId={user.band_id} />
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>

                  <Route path={user_settings_path()}>
                    {user ? (
                      <UserEditScreen user={user} />
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>

                  <Route path={go_live_path()} exact={true}>
                    {user ? <BroadcastScreen /> : <Redirect to="/" />}
                  </Route>

                  <Route
                    path={band_stream_guest_broadcast_path(":id")}
                    exact={false}
                  >
                    <GuestBroadcastScreen user={user} />
                  </Route>

                  <Route path={search_path()}>
                    <SearchResultsPage user={user} />
                  </Route>

                  <Route path={discover_path()}>
                    <DiscoverPage />
                  </Route>

                  <Route path={live_now_path()}>
                    <LiveNowPage />
                  </Route>

                  <Route path={faq_path()}>
                    <FaqPage />
                  </Route>

                  <Route path={own_bandnada_path()}>
                    <OwnBandNadaPage />
                  </Route>

                  <Route>
                    <div>Not found</div>
                  </Route>
                </Switch>
              </UserContext.Provider>
            </ActionCableProvider>
          </QueryParamProvider>
          <UniversalPlayer />
          <ToastContainer />
        </Router>
      </ErrorBoundary>
    </Provider>
  )
}
