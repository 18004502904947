import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from "react"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useAppTitle } from "../../hooks/useDocumentTitle"
import useBroadcaster from "./Broadcaster"
import { request, mutationRequest } from "../../util/request"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { Spinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import ChatRoom from "../chat/Chat"
import Header from "../shared/Header/Header"
import {
  BroadcastButton,
  Button,
  GuestManagerButton,
  LinkButton,
} from "../shared/Button"
import { ActionCableConsumer } from "react-actioncable-provider"
import "@reach/dialog/styles.css"
import styles from "./BroadcastScreen.module.css"
import Dialog from "@reach/dialog"
import { LockFill, InfoCircleFill, CircleFill } from "react-bootstrap-icons"
import { UserContext } from "../HomeApp"
import { useBand } from "../../hooks/useBand"
import { useBandStream } from "../../hooks/useBandStream"
import { WebBroadcaster } from "./WebBroadcaster"
import { RTMPBroadcaster } from "./RTMPBroadcaster"
import { MobileBroadcaster } from "./MobileBroadcaster"
import { usePromotion } from "../../hooks/usePromotion"
import { useCandidates } from "../../hooks/useCandidates"
import { ReactComponent as DesktopIcon } from "../../icons/desktop_broadcast_icon.svg"
import { ReactComponent as MobileIcon } from "../../icons/mobile_broadcast_icon.svg"
import { ReactComponent as RTMPIcon } from "../../icons/obs_broadcast_icon.svg"
import "./BroadcastScreen.scss"

const BroadcastScreen = () => {
  useAppTitle(`Broadcaster`)
  const { user } = useContext(UserContext)
  let ref = useRef(null)
  const [bandStreamGuestRTCSettings, setBandStreamGuestRTCSettings] = useState(
    {},
  )
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [isDebug] = useState(false)
  const [broadcastState, setBroadcastState] = useState("idle")
  const [bandStream, setBandStream] = useState(null)

  const { status: bandStatus, data: bandData, error: bandError } = useBand(
    user.band_id,
    "active",
    "band_stream",
  )
  let band = bandData?.band
  const {
    status: bandStreamStatus,
    data: bandStreamData,
    error: bandStreamError,
  } = useBandStream(band?.band_stream_id)

  const [broadcastType, setBroadcastType] = useState(null)
  const [wowzaState, setWowzaState] = useState(null)
  useEffect(() => {
    if (bandStreamStatus === "success") {
      setBandStream(bandStreamData.band_stream)
      setBroadcastType(bandStreamData.band_stream.origin)
      setWowzaState(bandStreamData.band_stream.wowza_state)
    }
  }, [bandStreamStatus, bandStreamData?.band_stream?.origin])

  const refreshBandStreamData = newBandStreamData => {
    setBandStream(newBandStreamData)
    setBroadcastState(newBandStreamData.live_at ? "broadcasting" : "idle")
    setWowzaState(newBandStreamData.wowza_state)
  }

  const updateBroadcastType = async newBroadcastType => {
    try {
      await handleUpdateBandStreamData(bandStream.id, {
        origin: newBroadcastType,
      })
      setBroadcastType(newBroadcastType)
    } catch (error) {
      console.error("Failed to update band stream", error)
    }
  }

  useLayoutEffect(() => {
    setWidth(window.innerWidth)
    setHeight(width / 2.72)
  })

  return (
    <div className="w-full" style={{ height: "min-content" }}>
      <Header user={user} />
      <ActionCableConsumer
        channel={{ channel: "BandStreamChannel", id: user.band_id }}
        onReceived={refreshBandStreamData}
      />
      {bandStatus === "loading" ||
      bandStreamStatus === "loading" ||
      !bandData?.band ? (
        <Spinner />
      ) : (
        <>
          <div className="grid grid-cols-12 w-full mt-4 px-4 pb-2">
            <div
              ref={ref}
              className="lg:col-span-8 col-span-12 pr-2"
              id="broadcast-container"
            >
              <>
                {wowzaState === "starting" ||
                (wowzaState === "does_not_exist" && !!broadcastType) ? (
                  <div className="flex h-full items-center justify-center text-center">
                    <div>
                      <Spinner className="text-gray-600 mb-2" />
                      <h3>Creating your live stream now...</h3>
                      <p className="text-sm text-gray-700">
                        (refresh the page if this takes more than 30 seconds)
                      </p>
                    </div>
                  </div>
                ) : wowzaState === "started" && broadcastType === "web" ? (
                  <WebBroadcaster
                    band={bandData.band}
                    broadcastType={broadcastType}
                    bandStream={bandStream}
                    setBroadcastType={setBroadcastType}
                    isDebug={isDebug}
                    bandStreamGuestRTCSettings={bandStreamGuestRTCSettings}
                    broadcastState={broadcastState}
                    setBroadcastState={setBroadcastState}
                  />
                ) : wowzaState === "started" && broadcastType === "rtmp" ? (
                  <RTMPBroadcaster
                    band={bandData.band}
                    broadcastType={broadcastType}
                    bandStream={bandStream}
                    setBroadcastType={setBroadcastType}
                    broadcastState={broadcastState}
                    setBroadcastState={setBroadcastState}
                  />
                ) : wowzaState === "started" && broadcastType === "mobile" ? (
                  <MobileBroadcaster
                    band={bandData.band}
                    broadcastType={broadcastType}
                    bandStream={bandStream}
                    setBroadcastType={setBroadcastType}
                    broadcastState={broadcastState}
                  />
                ) : bandStream ? (
                  <div className="grid grid-cols-12">
                    <div className="col-span-12 chooser-section">
                      <button onClick={e => updateBroadcastType("web")}>
                        <h2>Web Broadcaster</h2>
                        <DesktopIcon className="chooser-icon" />
                        <p>Probably this!</p>
                      </button>
                    </div>
                    <div className="col-span-6 chooser-section">
                      <button onClick={e => updateBroadcastType("mobile")}>
                        <h2>Smartphone</h2>
                        <MobileIcon className="chooser-icon" />
                        <p>
                          You can also broadcast with your iOS or Android phone.
                        </p>
                        <p>
                          A bit more complicated to setup than our Web
                          Broadcaster, but works great if you're remote.
                        </p>
                      </button>
                    </div>
                    <div className="col-span-6 chooser-section">
                      <button onClick={e => updateBroadcastType("rtmp")}>
                        <h2>OBS</h2>
                        <RTMPIcon className="chooser-icon" />
                        <p>
                          You can also broadcast on bandNada using OBS or other
                          RTMP streaming software.
                        </p>
                        <p>
                          If this doesn't make sense to you, stick with the Web
                          Broadcaster.
                        </p>
                      </button>
                    </div>
                  </div>
                ) : (
                  <Spinner className="text-gray-500" />
                )}
              </>
            </div>
            <div className="lg:col-span-4 col-span-12">
              <ChatRoom
                bandId={bandData.band.id}
                bandName={bandData.band.name}
                user={user}
                totalHeight={height * 1}
                className=""
              />
              {bandData.band.enable_guest_manager && (
                <GuestManager
                  bandId={bandData.band.id}
                  bandStreamId={bandData.band.band_stream_id}
                  bandStreamGuestRTCSettings={bandStreamGuestRTCSettings}
                  setBandStreamGuestRTCSettings={setBandStreamGuestRTCSettings}
                  broadcastState={broadcastState}
                />
              )}
            </div>
          </div>
          <ShowPromotionRequest band={bandData.band} />
        </>
      )}
    </div>
  )
}

export const handleUpdateBandStreamData = async (
  bandStreamId,
  bandStreamUpdateData,
) => {
  mutationRequest({
    path: `/api/v1/band_streams/${bandStreamId}`,
    options: { method: "PUT" },
    data: { band_stream: bandStreamUpdateData },
  })
}

export const StreamMode = ({
  bandStreamId,
  broadcastType,
  setBroadcastType,
}) => {
  const broadcastTypes = [
    ["web", "bandNada Web (basic)"],
    ["rtmp", "External RTMP (OBS, etc.)"],
    ["mobile", "Mobile"],
  ]

  useEffect(() => {
    handleUpdateBandStreamData(bandStreamId, { origin: broadcastType })
  }, [broadcastType])

  return (
    <>
      <h4 className="font-bold">Streaming Mode</h4>
      <select
        className="border-2 px-1 py-1 text-sm rounded-sm mr-2 mb-1"
        value={broadcastType}
        onChange={e => setBroadcastType(e.target.value)}
      >
        {broadcastTypes.map(option => (
          <option key={option[0]} value={option[0]}>
            {option[1]}
          </option>
        ))}
      </select>
    </>
  )
}

export const BroadcastLinkInfo = ({
  bandPath,
  bandStreamToken,
  isTestMode,
}) => {
  return (
    <div className="text-base">
      <div>
        <span className="font-bold">Viewer Link: </span>
        <Link to={bandPath} target="_blank" className="text-blue-500">
          {bandPath}
        </Link>
      </div>
      {isTestMode && (
        <div>
          <span className="font-bold">Test Link: </span>
          <Link
            to={`${bandPath}/test/${bandStreamToken}`}
            target="_blank"
            className="text-blue-500"
          >
            Private Test Link
          </Link>
        </div>
      )}
    </div>
  )
}

export const BroadcastStatus = ({
  broadcastState,
  isTestMode,
  bandStreamGuestStatus = null,
  audioAnalyserRef = null,
}) => {
  return (
    <div className="absolute bg-white ml-2 mt-2 px-4 py-1 z-10 rounded text-base">
      <>
        {broadcastState === "broadcasting" ? (
          isTestMode ? (
            <span className="text-gray-800">
              <CircleFill
                className={`align-text-top inline mr-2 ${
                  bandStreamGuestStatus && bandStreamGuestStatus === "active"
                    ? "text-red-600"
                    : "text-yellow-400"
                }`}
              />
              {bandStreamGuestStatus
                ? bandStreamGuestStatus === "active"
                  ? "You Are Live!"
                  : "Waiting for host to let you in"
                : "You Are Live in Test Mode"}
            </span>
          ) : (
            <span className="font-bold">
              <CircleFill className="align-text-top inline mr-2 text-red-600" />
              {bandStreamGuestStatus
                ? bandStreamGuestStatus === "active"
                  ? "You Are Live!"
                  : "Waiting for host to let you in"
                : "You Are Live!"}
            </span>
          )
        ) : (
          <span className="text-gray-800">
            <CircleFill className="align-text-top inline mr-2 text-gray-400" />
            Not Broadcasting
          </span>
        )}
        {audioAnalyserRef && (
          <AudioAnalyserCanvas audioAnalyserRef={audioAnalyserRef} />
        )}
      </>
    </div>
  )
}

const AudioAnalyserCanvas = ({ audioAnalyserRef }) => {
  return (
    <canvas
      style={{ width: "100px", height: "50px" }}
      className="inline ml-2"
      ref={audioAnalyserRef}
    ></canvas>
  )
}

export const ShowPromotionRequest = ({ band }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [completionMessage, setCompletionMessage] = useState(null)
  const openDialog = () => setShowDialog(true)
  const closeDialog = () => setShowDialog(false)

  const {
    status: bandStreamStatus,
    data: bandStreamData,
    error: bandStreamError,
  } = useBandStream(band.band_stream_id)
  let bandStream = bandStreamData?.band_stream

  const {
    status: promotionStatus,
    data: promotionData,
    error: promotionError,
  } = usePromotion()
  let promotion = promotionData?.promotions[0]

  const setShowPromotion = () => {
    setShowDialog(
      !!bandStream &&
        !(bandStream.promotion_id || bandStream.promotion_passed_at) &&
        !!promotionData?.promotions,
    )
  }

  const handlePromotionResponse = response => {
    setCompletionMessage(response)
    if (response === "accepted") {
      handleUpdateBandStreamData(bandStream.id, {
        promotion_id: promotion.id,
      })
    } else {
      handleUpdateBandStreamData(bandStream.id, {
        promotion_opt_out: true,
      })
    }
  }

  useEffect(() => {
    setShowPromotion()
  }, [bandStreamStatus, promotionStatus])

  return (
    <>
      {bandStreamStatus === "loading" ? null : promotionStatus ===
        "loading" ? null : promotionData?.promotions.length === 0 ? null : (
        <Dialog
          isOpen={showDialog}
          onDismiss={closeDialog}
          aria-label="Request to join a bandNada fundraising campaign"
        >
          {completionMessage === "accepted" ? (
            <>
              <h1 className="font-bold text-4xl lg:text-xl border-b-4 border-black">
                Thank you for joining us in {promotion.name}!
              </h1>
              <p className="text-gray-800 text-3xl lg:text-base mt-4">
                We'll be publishing how much our donation is to{" "}
                <span className="font-bold">{promotion.cause_name}</span> and
                thanking all the bands that participated.
              </p>
              <p className="text-gray-800 text-3xl lg:text-base mt-4">
                Have a great show!
              </p>
              <Button className="mt-8" onClick={closeDialog}>
                Get Ready to Go Live
              </Button>
            </>
          ) : completionMessage === "declined" ? (
            <>
              <h1 className="font-bold text-4xl lg:text-xl border-b-4 border-black">
                All good, have a great show!
              </h1>
              <Button className="mt-8" onClick={closeDialog}>
                Get Ready to Go Live
              </Button>
            </>
          ) : (
            <>
              <h1 className="font-bold text-4xl lg:text-xl border-b-4 border-black">
                It is {promotion.name} month at bandNada!
              </h1>
              <p className="text-gray-800 text-3xl lg:text-base mt-4">
                {promotion.description}
              </p>
              <p className="text-gray-800 text-3xl lg:text-base mt-2">
                You can find more info on&nbsp;
                <a
                  className="text-blue-500 focus:outline-none"
                  href={promotion.cause_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {promotion.cause_name} here.
                </a>
              </p>
              <p className="text-gray-800 text-3xl lg:text-base mt-2">
                Would you like to participate in&nbsp;
                <span className="font-bold">{promotion.name}</span>?
              </p>
              <div className="grid grid-cols-2 mt-6">
                <div className="col-span-1">
                  <Button onClick={e => handlePromotionResponse("accepted")}>
                    Count me In!
                  </Button>
                </div>
                <div className="col-span-1">
                  <Button
                    bg="bg-gray-500"
                    onClick={e => handlePromotionResponse("declined")}
                  >
                    No thanks, I'll Pass
                  </Button>
                </div>
              </div>
            </>
          )}
        </Dialog>
      )}
    </>
  )
}

const createBandStreamGuest = async (bandStreamId, guestBandId) => {
  mutationRequest({
    options: { method: "POST" },
    path: `/api/v1/band_streams/${bandStreamId}/band_stream_guests`,
    data: {
      band_stream_guest: {
        guest_band_id: guestBandId,
        host_band_stream_id: bandStreamId,
        guest_type: "guest",
      },
    },
  })
}

const handleUpdateBandStreamGuest = async (
  bandStreamId,
  bandStreamGuestId,
  status,
) => {
  mutationRequest({
    options: { method: "PUT" },
    path: `/api/v1/band_streams/${bandStreamId}/band_stream_guests/${bandStreamGuestId}`,
    data: {
      band_stream_guest: {
        status: status,
      },
    },
  })
}

export const GuestManager = ({
  bandId,
  bandStreamId,
  broadcastState,
  bandStreamGuestRTCSettings,
  setBandStreamGuestRTCSettings,
}) => {
  const [hasActiveGuestStream, setHasActiveGuestStream] = useState(false)
  const [refetchInterval, setRefetchInterval] = useState(
    broadcastState === "broadcasting" ? 10000 : 0,
  )

  useEffect(() => {
    return () => {
      setBandStreamGuestRTCSettings({
        wsURL: null,
        applicationName: null,
        streamName: null,
      })
      setHasActiveGuestStream(false)
    }
  }, [])

  const {
    status: bandStreamStatus,
    data: bandStreamData,
    error: bandStreamError,
  } = useBandStream(bandStreamId)
  let bandStream = bandStreamData?.band_stream

  const {
    status: candidatesStatus,
    data: candidatesData,
    error: candidatesError,
  } = useCandidates(bandId, broadcastState === "broadcasting")
  const queryClient = useQueryClient()

  const refetchGuestListData = () => {
    queryClient.invalidateQueries(["candidates", bandId])
  }

  return (
    <div className="mt-4">
      <ActionCableConsumer
        channel={{
          channel: "BandStreamChannel",
          id: bandId,
        }}
        onReceived={refetchGuestListData}
      />
      <h2 className="font-bold border-b border-black mb-1">
        Guest Stream Manager
      </h2>
      {broadcastState === "idle" ? (
        <div className="italic text-gray-700 text-sm">
          Guest Stream Manager will be available after you go live
        </div>
      ) : (
        <>
          {candidatesStatus === "loading" ? (
            <Spinner className="text-gray-700" />
          ) : candidatesStatus === "success" ? (
            <ul className="text-sm">
              {candidatesData.candidates.length === 0 ? (
                <li className="mb-1 italic text-gray-700 text-sm">
                  No eligible guest streamers at the moment
                </li>
              ) : (
                candidatesData.candidates.map(candidate => (
                  <GuestStreamCandidate
                    key={candidate.band.id}
                    bandStreamId={bandStreamId}
                    bandId={bandId}
                    candidate={candidate}
                    hasActiveGuestStream={hasActiveGuestStream}
                    setBandStreamGuestRTCSettings={
                      setBandStreamGuestRTCSettings
                    }
                    setHasActiveGuestStream={setHasActiveGuestStream}
                  />
                ))
              )}
            </ul>
          ) : null}{" "}
        </>
      )}
    </div>
  )
}

export const GuestStreamCandidate = ({
  bandId,
  bandStreamId,
  candidate,
  setHasActiveGuestStream,
  hasActiveGuestStream,
  setBandStreamGuestRTCSettings,
}) => {
  const [inviteDisabled, setInviteDisabled] = useState(false)
  const queryClient = useQueryClient()

  const submitBandStreamGuest = async guestBandId => {
    setInviteDisabled(true)
    try {
      await createBandStreamGuest(bandStreamId, guestBandId)
      queryClient.invalidateQueries(["candidates", bandId])
    } catch {
      setInviteDisabled(false)
    }
  }

  const updateBandStreamGuest = async (bandStreamGuestId, status) => {
    await handleUpdateBandStreamGuest(bandStreamId, bandStreamGuestId, status)
    queryClient.invalidateQueries(["candidates", bandId])
  }

  const switchOnGuestStream = candidate => {
    setBandStreamGuestRTCSettings({
      wsURL: candidate?.band?.streaming_server?.ws_url,
      applicationName: candidate?.band?.streaming_server?.application_name,
      streamName: candidate?.band?.guest_stream_name,
    })
    updateBandStreamGuest(candidate.band_stream_guest.id, "active")
    setHasActiveGuestStream(true)
  }

  const switchOffGuestStream = candidate => {
    setBandStreamGuestRTCSettings({
      wsURL: null,
      applicationName: null,
      streamName: null,
    })
    updateBandStreamGuest(candidate.band_stream_guest.id, "accepted")
    setHasActiveGuestStream(false)
  }

  return (
    <li className="grid grid-cols-12 mb-1">
      <div className="col-span-6 font-bold">{candidate.band.name}</div>
      <div className="col-span-6 text-right mr-2 text-gray-700">
        {candidate.band_stream_guest ? (
          candidate.band_stream_guest.status === "initiated" ? (
            "waiting for approval"
          ) : candidate.band_stream_guest.status === "accepted" ? (
            candidate.band_stream_guest?.guest_band_stream?.live_at ? (
              <GuestManagerButton
                disabled={hasActiveGuestStream}
                onClick={e => switchOnGuestStream(candidate)}
              >
                Switch to Guest Stream
              </GuestManagerButton>
            ) : (
              "waiting for broadcast"
            )
          ) : candidate.band_stream_guest.status === "active" ? (
            <GuestManagerButton onClick={e => switchOffGuestStream(candidate)}>
              End Guest Stream
            </GuestManagerButton>
          ) : candidate.band_stream_guest.status === "rejected" ? (
            <div>
              denied &nbsp;
              <GuestManagerButton
                onClick={e => submitBandStreamGuest(candidate.band.id)}
              >
                Invite Again
              </GuestManagerButton>
            </div>
          ) : null
        ) : (
          <GuestManagerButton
            onClick={e => submitBandStreamGuest(candidate.band.id)}
            disabled={inviteDisabled}
          >
            {inviteDisabled ? "Invite Sent" : "Invite"}
          </GuestManagerButton>
        )}
      </div>
    </li>
  )
}

export const GuestBroadcastScreen = ({ user }) => {
  useAppTitle(`Guest Broadcaster`)
  let { id } = useParams()
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    setWidth(window.innerWidth)
    setHeight(width / 2.72)
  })

  const [bandStreamGuest, setBandStreamGuest] = useState(null)
  const [band, setBand] = useState(null)
  const [bandStream, setBandStream] = useState(null)
  const bandStreamGuestData = useQuery(
    [
      "band_stream_guest",
      {
        path: `/api/v1/band_stream_guests/${id}.json`,
        params: {
          fields: "host_band_stream,guest_band_stream,host_band,guest_band",
        },
      },
    ],
    request,
  )

  useEffect(() => {
    if (bandStreamGuestData.status === "success") {
      setBandStreamGuest(bandStreamGuestData.data.band_stream_guest)
      setBand(bandStreamGuestData.data.band_stream_guest.guest_band)
      if (bandStreamGuestData.data.band_stream_guest.guest_band_stream) {
        setBandStream(
          bandStreamGuestData.data.band_stream_guest.guest_band_stream,
        )
      }
    }
  }, [
    bandStreamGuestData?.data?.band_stream_guest?.status,
    bandStreamGuestData.status,
  ])

  const [broadcastState, setBroadcastState] = useState(
    bandStreamGuest?.guest_band_stream?.live_at ? "broadcasting" : "idle",
  )
  const [isTestMode] = useState(true)
  const queryClient = useQueryClient()
  const refreshBandStreamGuestData = bandStreamObject => {
    queryClient.invalidateQueries("band_stream_guest")
  }

  return (
    <div className="w-full px-2 pt-4" style={{ height: "min-content" }}>
      {bandStreamGuestData.status === "loading" || !bandStreamGuest ? (
        <Spinner className="text-gray-500" />
      ) : bandStreamGuestData.status === "error" ? (
        <RequestError error={bandStreamGuestData.error} />
      ) : (
        <>
          <Header user={user} />
          <ActionCableConsumer
            channel={{
              channel: "BandStreamChannel",
              id: bandStreamGuest.host_band_id,
            }}
            onReceived={refreshBandStreamGuestData}
          />
          <ActionCableConsumer
            channel={{
              channel: "BandStreamChannel",
              id: bandStreamGuest.guest_band_id,
            }}
            onReceived={refreshBandStreamGuestData}
          />
          <div className="grid grid-cols-12 w-full mt-4 pb-2">
            <div
              className="lg:col-span-8 col-span-12 pr-2"
              id="broadcast-container"
            >
              <>
                {band && bandStream && bandStreamGuest ? (
                  <WebGuestBroadcaster
                    band={band}
                    bandStream={bandStream}
                    bandStreamGuest={bandStreamGuest}
                    broadcastState={broadcastState}
                    setBroadcastState={setBroadcastState}
                    isTestMode={isTestMode}
                  />
                ) : (
                  <Spinner className="text-gray-500" />
                )}
              </>
            </div>
            <div className="lg:col-span-4 col-span-12">
              <ChatRoom
                bandId={bandStreamGuest.host_band_id}
                bandName={bandStreamGuest.host_band.name}
                user={user}
                totalHeight={height * 1}
                className=""
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const WebGuestBroadcaster = ({
  band,
  bandStream,
  bandStreamGuest,
  broadcastState,
  setBroadcastState,
  isTestMode,
}) => {
  const [audioSelect, setAudioSelect] = useState([])
  const [videoSelect, setVideoSelect] = useState([])
  const [showRedirectDialog, setShowRedirectDialog] = useState(false)
  var videoRef = useRef()
  var audioSelectRef = useRef()
  var videoSelectRef = useRef()
  // @ts-ignore
  let videoWidth = videoRef?.current?.clientWidth
  var videoHeight = videoWidth ? videoWidth / 1.78 : 200

  const [, forceBroadcastStatusRefresh] = useState()
  const bandStreamMutation = useMutation(mutationRequest)
  const handleUpdateBandStreamData = useCallback(
    async updateData => {
      bandStreamMutation.mutate({
        options: { method: "PUT" },
        path: `/api/v1/band_streams/${bandStream.id}`,
        data: { band_stream: updateData },
      })
    },
    [bandStream.id],
  )

  const bsgMutation = useMutation(mutationRequest)
  const handleUpdateBandStreamGuestData = useCallback(
    async updateData => {
      bsgMutation.mutate({
        options: { method: "PUT" },
        path: `/api/v1/band_stream_guests/${bandStreamGuest.id}`,
        data: {
          band_stream_guest: {
            ...updateData,
            guest_band_stream_id: bandStream.id,
          },
        },
      })
    },
    [bandStream.id],
  )

  const updateBroadcastState = ({ isLive, _isTestMode, origin = "web" }) => {
    if (isLive && broadcastState === "idle") {
      if (!startPublisher()) {
        // this is caught with userErrorMessage useEffect
        return
      }
    } else if (!isLive && broadcastState === "broadcasting") {
      stopPublisher()
      setShowRedirectDialog(true)
    }
    setBroadcastState(isLive ? "broadcasting" : "idle")
    handleUpdateBandStreamData({
      is_live: isLive,
      is_test_mode: isTestMode,
      origin: origin,
    })
  }

  const {
    startPublisher,
    stopPublisher,
    setAudioDevice,
    setVideoDevice,
    userErrorMessage,
  } = useBroadcaster({
    applicationName:
      bandStreamGuest.guest_band.streaming_server.application_name,
    streamName: bandStreamGuest.guest_band.guest_stream_name,
    wsURL: bandStreamGuest.guest_band.streaming_server.ws_url,
    setAudioSelect: setAudioSelect,
    setVideoSelect: setVideoSelect,
    audioSelectRef: audioSelectRef,
    videoSelectRef: videoSelectRef,
    videoRef: videoRef,
    isDebug: false,
  })

  const handleBroadcastError = errorMessage => {
    setBroadcastState("idle")
    handleUpdateBandStreamData({ is_live: false })
  }

  useEffect(() => {
    handleBroadcastError(userErrorMessage)
  }, [userErrorMessage])

  return (
    <>
      <div className={styles.broadcastContainer}>
        <BroadcastStatus
          broadcastState={broadcastState}
          isTestMode={isTestMode}
          bandStreamGuestStatus={bandStreamGuest.status}
        />
        <video
          className="w-full border"
          id="localVideo"
          autoPlay={true}
          muted={true}
          ref={videoRef}
        />
      </div>
      {userErrorMessage ? (
        <div
          id="user-error-messaging"
          className="bg-red-500 p-2 text-white opacity-75 relative"
          style={{ marginTop: "-41px" }}
        >
          There was a problem! - {userErrorMessage}
        </div>
      ) : null}

      <div className="grid grid-cols-12 pt-2">
        <div className="col-span-6">
          <h4 className="font-bold">Instructions</h4>
          <ol className="list-decimal list-inside text-sm text-gray-800">
            <li>
              Set your microphone and camera in{" "}
              <span className="font-bold">Settings</span> below - see{" "}
              <span className="font-bold">Having Trouble</span> if they don't
              show up
            </li>
            <li>
              Click the <span className="italic">Start Stream</span> button
            </li>
            <li>
              Wait for the host to let you in. Watch the status in the top left
              to know when you are live!
            </li>
          </ol>
        </div>
        <div className="col-span-6 text-right">
          <div className="grid grid-cols-2">
            <div className="col-span-1"></div>
            <div className="col-span-1 ml-2">
              <BroadcastButton
                className=""
                active={broadcastState === "broadcasting"}
                onClick={e => {
                  updateBroadcastState({
                    _isTestMode: false,
                    isLive: broadcastState !== "broadcasting",
                    origin: "web",
                  })
                }}
              >
                {broadcastState === "broadcasting"
                  ? "End Stream"
                  : "Start Stream"}
              </BroadcastButton>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 grid grid-cols-12">
        <div className="col-span-8">
          <h4 className="font-bold">Settings</h4>
          <select
            ref={audioSelectRef}
            id="audioSource"
            onChange={e => setAudioDevice(e)}
            disabled={broadcastState === "broadcasting"}
            className="border-2 px-1 py-1 text-sm rounded-sm mr-2 mb-1"
          >
            {audioSelect.map(option => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
          <select
            ref={videoSelectRef}
            id="videoSource"
            disabled={broadcastState === "broadcasting"}
            onChange={e => setVideoDevice(e)}
            className="border-2 px-1 py-1 text-sm rounded-sm"
          >
            {videoSelect.map(option => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-12 pb-4">
        <div className="col-span-12">
          <div className="mt-2">
            <h4 className="font-bold">Having trouble?</h4>
            If you don't see video of yourself above, click the
            <LockFill className="inline mx-1 text-gray-700 align-baseline" />
            or the
            <InfoCircleFill className="inline mx-1 text-gray-700 align-baseline" />
            icon to the left of your browser's url bar and allow camera and
            microphone access
          </div>
        </div>
      </div>
      <ShowRedirectDialog
        bandStreamGuest={bandStreamGuest}
        setShowDialog={setShowRedirectDialog}
        showDialog={showRedirectDialog}
      />
    </>
  )
}

export const ShowRedirectDialog = ({
  bandStreamGuest,
  setShowDialog,
  showDialog,
}) => {
  const openDialog = () => setShowDialog(true)
  const closeDialog = () => setShowDialog(false)

  return (
    <>
      {showDialog && (
        <Dialog
          isOpen={showDialog}
          onDismiss={closeDialog}
          aria-label="Redirect guest broadcaster back to primary show"
        >
          <>
            <h1 className="font-bold text-4xl lg:text-xl">
              Hope your Guest Spot went well!
            </h1>
            <h2 className="font-bold text-3xl lg:text-lg">
              Would you like to return to {bandStreamGuest.host_band.name}'s
              stream?
            </h2>
            <div className="grid grid-cols-12 mt-6">
              <div className="col-span-4 col-start-3">
                <LinkButton to={bandStreamGuest.host_band.band_path}>
                  Take Me Back!
                </LinkButton>
              </div>
              <div className="col-span-4">
                <Button onClick={closeDialog} className="bg-gray-400">
                  No, I'm not done yet
                </Button>
              </div>
            </div>
          </>
        </Dialog>
      )}
    </>
  )
}

export default BroadcastScreen
