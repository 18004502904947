import { useQuery } from "react-query"
import { request } from "../util/request"

export function useBandPlayStats(bandId, startDate, endDate) {
    return useQuery(["user_interactions", bandId, startDate, endDate], getPlayStatsForBanId)
}

const getPlayStatsForBanId = async ({ queryKey }) => {
    const [ key, id, startDate, endDate ] = [...queryKey]
    return await request({
        queryKey: [
            key,
            {
                path: `/api/v1/bands/${id}/user_interactions`,
                params: {
                    start_date: startDate,
                    end_date: endDate
                }
            }
        ]
    })
}
