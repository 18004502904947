import React, { useState } from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import CommentScreen from "../comments/CommentScreen"
import { TextAsImage } from "./TextAsImage"
import { PostListItemExpandedStyles } from "./constants"
import { CopyPostUrlLink } from "./CopyPostUrlLink"
import { ShowHideButton } from "../shared/Button"
import { ReactionBlock } from "./ReactionBlock"
import RelatedPosts from "./RelatedPosts"
import PreviewImages from "./PreviewImages"
import clsx from "clsx"
import FreeTextFormat from "../shared/FreeTextFormat"

export const HeadlineListItemExpanded = ({
  headline,
  setIsExpanded,
  updateStatus,
  adminMode = false,
}) => {
  const [postStatus, setPostStatus] = useState(headline.status)

  return (
    <div className="grid grid-cols-12 py-1">
      {headline.attached_media ? (
        <PreviewImages
          attachedMedia={headline.attached_media}
          expanded={true}
        />
      ) : (
        <TextAsImage
          text={headline.title}
          onClick={e => setIsExpanded(true)}
          className="cursor-pointer"
        />
      )}
      <div className={PostListItemExpandedStyles.metaDiv}>
        <div className="flex-grow">
          <h2
            className={clsx(
              PostListItemExpandedStyles.header,
              headline.title.length > 100
                ? PostListItemExpandedStyles.headerLong
                : PostListItemExpandedStyles.headerShort,
              "cursor-pointer",
            )}
            onClick={e => setIsExpanded(false)}
          >
            <FreeTextFormat
              text={
                headline.title
                  ? headline.title
                  : `New Headline from ${headline.band_name}`
              }
              expandable={false}
              expanded={false}
              linkify={true}
            />
          </h2>
          <CopyPostUrlLink nadaUrl={headline.nada_url} renderMode="button" />
        </div>
        <Link
          className={PostListItemExpandedStyles.bandName}
          to={headline.local_path}
        >
          {headline.band_name}
        </Link>
        <h3 className={PostListItemExpandedStyles.postDate}>
          Posted:&nbsp;
          {SmartDateFormat({
            dateStr: headline.active_date,
            includeTime: false,
          })}
        </h3>
      </div>
      <div className={PostListItemExpandedStyles.commentsDiv}>
        {adminMode && (
          <ShowHideButton
            updateStatus={updateStatus}
            postStatus={postStatus}
            setPostStatus={setPostStatus}
          />
        )}
        <RelatedPosts postId={headline.id} />
        <ReactionBlock
          contentType="Post"
          contentId={headline.id}
          postId={headline.id}
        />
        <CommentScreen
          postId={headline.id}
          headerText="The Peanut Gallery Says:"
          className="mb-4"
        />
      </div>
    </div>
  )
}
