import React, { useState, useRef } from "react"
import { mutationRequest } from "../../util/request"
import Header from "../../components/shared/Header/Header"
import { RequestError } from "../../components/shared/RequestError"
import { Spinner } from "../../components/shared/Spinner"
import { Button } from "../../components/shared/Button"
import { DirectUpload } from "@rails/activestorage"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import { CardImage } from "react-bootstrap-icons"
import style from "../../components/shared/PageStyle.module.css"
import { BandEditForm } from "./BandEditForm"
import { BandDeleteForm } from "./BandDeleteForm"
import { useBand } from "../../hooks/useBand"
import { UserContext } from "../../components/HomeApp"
import { useContext } from "react"
import Footer from "../../components/shared/Footer/Footer"

export const BandEditPage = () => {
  const { user } = useContext(UserContext)
  const {
    status: bandStatus,
    data: bandData,
    error: bandError,
  } = useBand(user.band_id, "active", null)
  var band = bandData?.band

  return (
    <div className={style.rootElStyle}>
      <Header user={user} />
      {bandStatus === "error" ? (
        <RequestError error={bandError} />
      ) : bandStatus === "loading" || !band ? (
        <Spinner />
      ) : (
        <>
          <div className="grid grid-cols-12 pb-2 px-4">
            <div className="col-span-12 border-b-4 mt-4 lg:mb-8 mb-4 border-black">
              <h1 className="lg:col-span-8 col-span-12 font-extrabold lg:text-3xl text-xl uppercase">
                Manage Page for {band.name}
              </h1>
            </div>
            <div className="col-span-12">
              <div className="text-base">
                bandNada can pull in data from other band related services and
                then display them on your bandNada page. The more info you
                provide, the better your page will look, and the more likely
                someone new will pay attention to your page
              </div>
              <BandEditForm band={band} />
              <h2 className="lg:col-span-8 col-span-12 font-bold border-b mb-4 border-black lg:text-3xl text-xl uppercase lg:mt-10 mt-5">
                Delete Band Completely
              </h2>
              <p className="pb-4 text-base">
                This will remove all of your band related data stored with
                bandNada, including: followers, stream data, and post data.
                Payments and tips will continue to be stored for accounting
                purposes but not displayed publicly.
              </p>
              <BandDeleteForm band={band} />
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  )
}

export const CoverPhotoUploadSection = ({ band }) => {
  const [coverPhotoURL, setCoverPhotoURL] = useState(
    band.cover_photo_url_large || "",
  )
  const [btnActive, setBtnActive] = useState(true)
  const [cropper, setCropper] = useState(null)
  const [uploadedFilePath, setUploadedFilePath] = useState("")

  const hiddenFileInput = useRef(null)
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const reader = new FileReader()
  reader.onload = (e) => {
    // @ts-ignore
    setUploadedFilePath(e.target.result)
  }

  const onUploadCoverPhoto = (the_attachment) => {
    setBtnActive(false)
    reader.readAsDataURL(the_attachment)
    setBtnActive(true)
  }

  const onRemovePhoto = (e) => {
    e.preventDefault()
    setBtnActive(false)
    mutationRequest({
      path: `/api/v1/bands/${band.id}/remove_cover_photo`,
      options: { method: "PUT" },
    }).then((response) => {
      setCoverPhotoURL("")
      setBtnActive(true)
    })
  }

  const onCancel = () => {
    setBtnActive(false)
    hiddenFileInput.current.value = null
    setUploadedFilePath("")
    setBtnActive(true)
  }

  const onSave = () => {
    setBtnActive(false)

    cropper.getCroppedCanvas().toBlob((blob) => {
      const upload = new DirectUpload(
        new File([blob], "cropped-photo"),
        "/rails/active_storage/direct_uploads",
      )

      upload.create((error, blob) => {
        if (error) {
          console.error("error:", error)
          setBtnActive(true)
        } else {
          const signed_id = blob.signed_id
          const request_body = {
            cover_photo: signed_id,
          }
          handleUpdateBandData(band.id, request_body).then((response) => {
            if (
              response &&
              response.band &&
              response.band.cover_photo_url_large
            )
              setCoverPhotoURL(response.band.cover_photo_url_large)
            hiddenFileInput.current.value = null
            setUploadedFilePath("")
            setBtnActive(true)
          })
        }
      })
    })
  }

  return (
    <div className="grid grid-cols-12 mt-10">
      <label className="block col-span-12 text-gray-900 text-xs uppercase font-bold">
        Cover Photo Image (Highly Recommended)
      </label>
      <div className="col-span-12 lg:col-span-2">
        {coverPhotoURL && coverPhotoURL.length ? (
          <img
            className="rounded-sm w-full"
            src={coverPhotoURL}
            alt="band cover"
          />
        ) : (
          <CardImage className="w-full col-span-12 text-gray-500 h-64 block bg-gray-200" />
        )}
        <Button
          disabled={!btnActive}
          bg="bg-green-400"
          className="mt-2 w-full"
          onClick={handleClick}
        >
          Upload an Image
        </Button>
        <input
          type="file"
          ref={hiddenFileInput}
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => onUploadCoverPhoto(e.target.files[0])}
        />
        <div className="mt-2 text-center">
          <button
            className="hover:text-blue-700"
            disabled={!btnActive}
            style={{ cursor: "pointer" }}
            onClick={onRemovePhoto}
          >
            Remove Photo
          </button>
        </div>
      </div>
      {uploadedFilePath && uploadedFilePath.length ? (
        <div className="mx-3 col-span-12 lg:col-span-3 w-full bg-gray-200">
          <h6 className="px-3 font-bold">Crop your photo</h6>
          <Cropper
            className="mt-2"
            initialAspectRatio={16 / 9}
            aspectRatio={16 / 9}
            src={uploadedFilePath}
            viewMode={1}
            guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={true}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance)
            }}
          />
          <div className="mt-2 mb-2">
            <Button
              disabled={!btnActive}
              bg="bg-gray-400"
              className="mx-3"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button disabled={!btnActive} bg="bg-green-400" onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export const handleUpdateBandData = async (bandId, bandUpdateData) => {
  try {
    let responseData = await mutationRequest({
      path: `/api/v1/bands/${bandId}`,
      options: { method: "PUT" },
      data: { band: bandUpdateData },
    })
    return responseData
  } catch (error) {
    console.log("Error updating band data: ", error)
  }
}

export default BandEditPage
