import anchorme from "anchorme"

export const unicodeToChar = text => {
  return text.replace(/\\u[\dA-F]{4}/gi, function(match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16))
  })
}

export const transformCloudUrl = url => {
  if (url.includes("www.dropbox.com")) {
    return url.replace("www.dropbox.com", "dl.dropbox.com")
  } else if (url.includes("drive.google.com")) {
    let matches = url.match(/\/file\/d\/([^\/]+)\//) || []
    if (matches.length > 0) {
      return `https://drive.google.com/uc?export=view&id=${matches[1]}`
    }
  } else {
    return url
  }
}

export const stripText = str => {
  // @[@Jason Lee](439) and The Deli posted a rad writeup of our split 7" with @[@Kissed by an Animal](/b/kissedbyananimal) - Songs About New York. Check it out!
  return str.replace(/\([^\)]+\)/gi, "").replace(/@\[@([^\]]+)\]/gi, "$1")
}

export const anchormeBandNada = (message, stylize = true) => {
  const bgImage =
    "https://bandnada.s3.amazonaws.com/static/external_assets/web/bandnada_dysentery.png"
  return anchorme({
    input: message,
    options: {
      attributes: {
        target: "_blank",
        class: stylize
          ? "text-blue-600 hover:text-gray-600 text-sm cursor-pointer"
          : "",
      },
      truncate: 40,
      middleTruncation: true,
      specialTransform: [
        {
          test: /(drive\.google\.com)/i,
          transform: s => {
            const url = transformCloudUrl(s)
            return `<img style="max-height:416px;" class="my-2" src="${url}" onerror="vidEl = document.createElement('video'); vidEl.style='${
              stylize ? "max-height:416px;width:100%" : ""
            }'; vidEl.controls='true'; vidEl.class='my-2'; srcEl=document.createElement('source'); srcEl.src='${url}'; srcEl.type='video/mp4'; vidEl.appendChild(srcEl);this.after(vidEl); this.remove();"/>`
          },
        },
        {
          test: /(.*\.(png|jpg|gif|jpeg)(\?|$))/i,
          transform: s => {
            const url = transformCloudUrl(s)
            return `<img style="max-height:416px;" class="my-2" src="${url}" onerror="newEl=createElement('span');newEl.innerText='(image missing)';newEl.style='font-style:italic;color:#777;';this.after(newEl);this.remove();"/>`
          },
        },
        {
          test: /.*\.(mp4)(\?|$)/,
          transform: s => {
            const url = transformCloudUrl(s)
            return `<video style="max-height:416px;" class="my-2" width="100%" controls="true" poster="${bgImage}"><source src="${url}" type="video/mp4"></video>`
          },
        },
        {
          test: /youtube\.com\/watch\?v\=/,
          transform: str => {
            return `<iframe style="width:100%;aspect-ratio:16/9;" src="https://www.youtube.com/embed/${str.replace(
              /.*watch\?v=(.*)$/,
              "$1",
            )}"/>`
          },
        },
      ],
    },
    extensions: [
      // chat commands meta
      {
        test: /(\~\$\$\~[^\~]+\~\$\$\~)/gi,
        transform: s => `<span class="text-xs text-gray-500">${s}</span>`,
      },
      // Hashtags
      {
        test: /(^href)#(\w|_)+/gi,
        transform: string =>
          `<span class="font-bold text-green-600">${string}</span>`,
      },
      {
        test: /@\[@?([^\]]+)\]\(([^)]+)\)/g,
        transform: str => {
          const [, username, path] = /@\[@?([^\]]+)\]\(([^)]+)\)/.exec(str)
          const functionalPath = /^\//.test(path) ? path : "#"
          return `<a class="font-bold text-green-700" href="${functionalPath}">${username}</a>`
        },
      },
    ],
  })
}
