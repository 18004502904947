import React from "react"
import { useQuery } from "react-query"
import { request } from "../../../util/request"
import PromotionHomePageStats from "../../promotions/PromotionHomePageStats"

export const PromotionModule = () => {
  const promoImagesData = useQuery(
    [
      "promo_images",
      // @ts-ignore
      {
        path: "/api/v1/external_assets.json",
        params: {
          component_filter: "promotion",
          fields: "promotion",
          per_page: 1,
        },
      },
    ],
    request,
  )
  const promoImage = promoImagesData?.data?.external_assets[0]

  return (
    <>
      {promoImage && (
        <div className="grid grid-cols-1">
          <div className="col-span-1">
            {promoImage.asset_url ? (
              <a href={promoImage.asset_url}>
                <PromotionContent promoImage={promoImage} />
              </a>
            ) : (
              <PromotionContent promoImage={promoImage} />
            )}
          </div>
        </div>
      )}
      {promoImage?.promotion_id && (
        <PromotionHomePageStats promotionId={promoImage.promotion_id} />
      )}
    </>
  )
}
const PromotionContent = ({ promoImage }) => {
  return (
    <>
      <img
        src={promoImage.url}
        className="w-full"
        alt={`promotion from ${promoImage.credit_name}`}
      />
      <div
        className="absolute pr-2 right-0 text-white text-xs lg:text-base text-right"
        style={{ marginTop: "-3em" }}
      >
        {promoImage.band_name ? (
          promoImage.band_url ? (
            <>
              <a
                className="font-bold"
                target="_blank"
                rel="noreferrer"
                href={promoImage.band_url}
              >
                {promoImage.band_name}
              </a>{" "}
              {"-"} Design by
            </>
          ) : (
            `${promoImage.band_name} by`
          )
        ) : (
          "Art by"
        )}
        &nbsp;
        <a
          className="font-bold"
          href={promoImage.credit_url}
          target="_blank"
          rel="noreferrer"
        >
          {promoImage.credit_name}
        </a>
        {promoImage.notes && (
          <div dangerouslySetInnerHTML={{ __html: promoImage.notes }}></div>
        )}
      </div>
    </>
  )
}
