import React, { useState } from "react"
import { SyncSummary } from "./SyncSummary"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const Youtube = ({ band }) => {
  const [url, setUrl] = useState(band.youtube_url || "")

  const fieldData = {
    field: "youtube_url",
    serviceClass: "YoutubeService",
    name: "youtube",
    label: "Your Youtube URL",
    placeholder: "Youtube URL",
    notes:
      "we sync all of your youtube videos and display them on your page so your fans can watch them on bandNada. You can sync a channel (i.e. https://www.youtube.com/channel/UCHZpR2qr9NNmcw8LGIRYb7A), a playlist (i.e. https://www.youtube.com/watch?v=hTWKbfoikeg&list=PLF1D793B61571DD4A) or a user (i.e. https://www.youtube.com/user/blastingcaps). To get your channel url, login to youtube, click the menu on the top right, and then select Your Channel. That url is your Channel URL.",
    required: false,
  }

  return (
    <>
      <EditBandFormTextField
        fieldData={fieldData}
        value={url}
        setValue={setUrl}
        bandId={band.id}
      />
      <SyncSummary fieldData={fieldData} bandId={band.id} value={url} />
    </>
  )
}
