import { useQuery } from "react-query"
import { request } from "../util/request"

// TODO: I don't think we actually want to default to adding "is_live" fields
export function useBand(bandId, filter = null, fields = "is_live") {
  return useQuery(["band", bandId, filter, fields], getBandById, {
    retry: false,
  })
}

const getBandById = async ({ queryKey }) => {
  const [key, id, filter, fields] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands/${id}.json`,
        params: { filter: filter, fields: fields },
      },
    ],
  })
}
