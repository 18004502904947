import React from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import { ReactionBlock } from "../posts/ReactionBlock"
import UserAvatar from "../shared/Header/UserAvatar"
import LinkifyText from "../shared/LinkifyText"

export const Comment = ({ comment }) => {
  return (
    <>
      <li className="bg-gray-100 px-2 py-1 mb-2 rounded-smn text-sm">
        <span className="flex flex-row">
          <UserAvatar
            handleClick={() => {}}
            user={{
              id: comment.user_id,
              avatar_url_small: comment.user_avatar_url,
            }}
            size="small"
            inHeader={false}
          />
          <div className="px-4 w-full">
            <div>
              <span className="text-gray-900 font-bold mr-3">
                {comment.user_path ? (
                  <Link to={comment.user_path}>{comment.username}</Link>
                ) : (
                  comment.username
                )}
              </span>
              <span className="text-gray-700 text-xs">
                {SmartDateFormat({
                  dateStr: comment.created_at,
                  includeTime: true,
                })}
              </span>
            </div>
            <div
              className="text-black"
              dangerouslySetInnerHTML={{
                __html: LinkifyText(comment.comment_text),
              }}
            ></div>
            {comment.image_url && (
              <a
                href={comment.image_url_medium || comment.image_url}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ maxHeight: "250px" }}
                  src={comment.image_url_medium || comment.image_url}
                  alt={comment.comment_text}
                />
              </a>
            )}
          </div>
        </span>
        <div className="mt-2">
          <ReactionBlock
            contentType="Comment"
            contentId={comment.id}
            postId={comment.post_id}
          />
        </div>
      </li>
    </>
  )
}
