import React, { useState } from "react"
import { BroadcastButton } from "../shared/Button"
import styles from "./BroadcastScreen.module.css"
import { Check } from "react-bootstrap-icons"
import BandNadaPlayer from "../shared/BandNadaPlayer"
import TakeoverManager from "./TakeoverManager"
import {
  handleUpdateBandStreamData,
  BroadcastStatus,
  BroadcastLinkInfo,
} from "./BroadcastScreen"
import useVideoHeight from "../../hooks/useVideoHeight"

export const RTMPBroadcaster = ({ band, broadcastState, bandStream }) => {
  const [isTestMode, setIsTestMode] = useState(false)
  const [userErrorMessage, setUserErrorMessage] = useState(null)
  const [bandStreamId, setBandStreamId] = useState(bandStream.id)
  const { height: videoHeight } = useVideoHeight()

  const onError = e => {
    console.log("onError: ", e)
  }

  const updateBroadcastState = ({ _isTestMode }) => {
    if (!bandStreamId) return null
    setIsTestMode(_isTestMode)
    handleUpdateBandStreamData(bandStreamId, {
      is_test_mode: _isTestMode,
      origin: "rtmp",
    })
  }

  return (
    <>
      <>
        <div
          className={styles.broadcastContainer}
          style={{ height: videoHeight }}
        >
          <BroadcastStatus
            broadcastState={broadcastState}
            isTestMode={isTestMode}
          />
          {broadcastState === "broadcasting" ? (
            <BandNadaPlayer
              thumbnailUrl={band?.thumbnail_url}
              playbackUrl={bandStream.player_hls_playback_url}
              controls={false}
              mute={true}
              onError={onError}
            />
          ) : (
            <div className="bg-black text-white h-full flex items-center justify-center">
              We will show you a preview of your stream here once we are
              receiving it from your external broadcaster
            </div>
          )}
        </div>

        {userErrorMessage ? (
          <div
            id="user-error-messaging"
            className="bg-red-500 p-2 text-white opacity-75 relative"
            style={{ marginTop: "-41px" }}
          >
            There was a problem! - {userErrorMessage}
          </div>
        ) : null}

        <div className="grid grid-cols-12 pt-2">
          <div className="col-span-6">
            <BroadcastLinkInfo
              bandPath={band.band_path}
              bandStreamToken={bandStream.token}
              isTestMode={isTestMode}
            />
          </div>
          <div className="col-span-6 text-right">
            <div className="grid grid-cols-2">
              <div className="col-span-1">
                <BroadcastButton
                  className="mb-1"
                  active={isTestMode}
                  onClick={e => {
                    updateBroadcastState({
                      _isTestMode: !isTestMode,
                    })
                  }}
                >
                  {isTestMode ? "Switch to LIVE mode" : "Switch to Test Mode"}
                </BroadcastButton>
              </div>
              <div className="col-span-1 ml-2">
                {broadcastState === "broadcasting" ? (
                  <div className="bg-green-400 text-white text-center font-bold rounded focus:outline-none text-2xl lg:text-base lg:py-2 py-4 w-full">
                    RTMP Stream Found &nbsp;
                    <Check size={20} className="inline align-middle" />
                  </div>
                ) : (
                  <div className="bg-yellow-500 text-white text-center font-bold rounded focus:outline-none text-2xl lg:text-base lg:py-2 py-4 w-full">
                    listening for stream
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 grid grid-cols-12">
          <div className="col-span-12">
            <h2 className="font-semibold text-base">RTMP Settings for OBS:</h2>
            <div>
              <span className="font-semibold pr-2">RTMP URL:</span>
              {bandStream.source_primary_server}
            </div>
            <div>
              <span className="font-semibold pr-2">Stream Key:</span>
              {bandStream.source_stream_name}
            </div>
            <div>
              <span className="font-semibold pr-2">Stream Username:</span>
              {bandStream.source_username}
            </div>
            <div>
              <span className="font-semibold pr-2">Stream Password:</span>
              {bandStream.source_password}
            </div>
            <div className="text-sm mt-2 text-gray-800">
              <div>
                <span className="font-semibold pr-2">
                  Output - Video Bitrate:
                </span>
                1000 Kbps
              </div>
              <div>
                <span className="font-semibold pr-2">
                  Output - Audio Bitrate:
                </span>
                128
              </div>
              <div>
                <span className="font-semibold pr-2">
                  Video - Output Resolution:
                </span>
                960x540
              </div>
              <div>
                <span className="font-semibold pr-2">
                  Video - Common FPS Values:
                </span>
                20
              </div>
            </div>
          </div>
        </div>
        {band.enable_takeover_manager && (
          <TakeoverManager bandId={band.id} bandStreamId={bandStream.id} />
        )}
        <div className="grid grid-cols-12 pb-4">
          <div className="col-span-12">
            <div className="mt-2">
              <h4 className="font-bold">Having trouble?</h4>
              If you are streaming via an external RTMP broadcaster and the
              player is not available to test the stream above, you can reload
              the page and it should appear. If not, double check your settings.
              And if you are still having trouble, email support@bandnada.com
            </div>
          </div>
        </div>
      </>
    </>
  )
}
