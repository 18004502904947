import React from "react"
import "./HomeEntry.css"
import { QueryClient, QueryClientProvider } from "react-query"
import HomeApp from "./HomeApp"

export default function HomeEntry(props) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <HomeApp {...props} />
    </QueryClientProvider>
  )
}
