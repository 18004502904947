import React, { useState, useEffect, useMemo } from "react"
import { ActionCableConsumer } from "react-actioncable-provider"
import Heading from "../../shared/Heading"
import { Spinner } from "../../shared/Spinner"
import { useActivityEvents } from "../../../hooks/useActivityEvents"
import { ActivityEvent } from "./ActivityEvent"
import { RequestError } from "../../shared/RequestError"

const ActivityFeed = () => {
  const PER_PAGE = 5
  const [page, setPage] = useState(1)

  const [_activityEvents, setActivityEvents] = useState([])
  const addActivityEvent = newItem => {
    setActivityEvents(allItems => [newItem, ...allItems].slice(0, PER_PAGE))
  }

  const activityEvents = useMemo(() => _activityEvents, [_activityEvents])

  const { isLoading, isError, isFetching, data, error } = useActivityEvents(
    page,
    PER_PAGE,
  )

  useEffect(() => {
    if (!isLoading && !isError && !isFetching) {
      if (page === 1) {
        setActivityEvents(data.activity_events)
      } else {
        setActivityEvents([...data.activity_events, ...activityEvents])
      }
    }
  }, [isFetching])

  const handleReceivedMessage = newActivityEvent => {
    if (newActivityEvent.activity_event) {
      if (
        !activityEvents.some(el => el.id === newActivityEvent.activity_event.id)
      ) {
        addActivityEvent(newActivityEvent.activity_event)
      }
    }
  }

  const activityFeedChannelDisconnected = error => {
    console.error("ActivityEventChannel disconnected: ", error)
  }

  return (
    <>
      <Heading level={2} type="sectionHeader">
        Action
      </Heading>
      <div className="flex flex-col">
        {isFetching ? (
          <Spinner />
        ) : error ? (
          <RequestError error={error} />
        ) : data ? (
          <>
            <ActionCableConsumer
              channel={{ channel: "ActivityEventChannel" }}
              onReceived={handleReceivedMessage}
              onDisconnected={activityFeedChannelDisconnected}
            />
            <ul>
              {activityEvents.length > 0
                ? activityEvents.map(activityEvent => (
                    <ActivityEvent
                      key={`activity-feed-item-${activityEvent.id}`}
                      activityEvent={activityEvent}
                    />
                  ))
                : null}
            </ul>
          </>
        ) : null}
      </div>
    </>
  )
}

export default ActivityFeed
