import { useQuery } from "react-query"
import { request } from "../util/request"

export function useActivityEvents(page = 1, perPage = 20) {
  return useQuery(["activity_events", page, perPage], getActivityEvents, {
    keepPreviousData: true,
  })
}

const getActivityEvents = async ({ queryKey }) => {
  const [key, page, perPage] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/activity_events.json`,
        params: { per_page: perPage, page: page },
      },
    ],
  })
}
