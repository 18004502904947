import { useInfiniteQuery, useQuery } from "react-query"
import { request } from "../util/request"

export const LIVE_STREAMS_QUERY_KEY = "live_streams"

export function useLiveStreams(filter, fields, page, perPage) {
  return useQuery(
    [LIVE_STREAMS_QUERY_KEY, filter, fields, page, perPage],
    getLiveStreams,
  )
}

export function useInfiniteLiveStreams({
  filter = null,
  fields = null,
  pageSize = 10,
}) {
  const queryKey = [LIVE_STREAMS_QUERY_KEY, filter, fields, null, pageSize]
  return useInfiniteQuery(queryKey, getLiveStreams, {
    getNextPageParam: (lastPage, pages) => lastPage.next_page,
  })
}

const getLiveStreams = async ({ queryKey, pageParam = null }) => {
  const [key, filter, fields, page, pageSize] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: "/api/v1/live_streams.json",
        params: {
          filter: filter,
          fields: fields,
          page: page,
          per_page: pageSize,
        },
      },
    ],
  })
}
