import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Lightning, PauseCircle, PlayCircle } from "react-bootstrap-icons"
import { useDispatch, useSelector } from "react-redux"
import { playOrPause, prependAndPlay } from "../../data/mediaPlayerSlice"
import { useBandPosts } from "../../hooks/useBandPosts"
import { compareByKey } from "../../util/utils"
import { Spinner } from "../shared/Spinner"
import { isMobile } from "react-device-detect"

export const BandSearchResult = ({
  bandName,
  bandCoverImageUrl,
  bandId,
  bandPath,
  bandLocation,
}) => {
  const { isLoading, isError, isFetching, data, error } = useBandPosts(
    bandId,
    "songs",
  )
  const [albumName, setAlbumName] = useState("")

  const dispatch = useDispatch()
  const onPlayClick = e => {
    dispatch(prependAndPlay(sortedSongs()))
  }

  const onPlayOrPauseClick = e => {
    dispatch(playOrPause())
  }
  const currentMediaItem = useSelector(
    // @ts-ignore
    state => state.mediaPlayer.value.currentMediaItem,
  )
  // @ts-ignore
  const playerState = useSelector(state => state.mediaPlayer.value.playerState)
  const isCurrentlyPlaying =
    playerState === "playing" && currentMediaItem.bandId === bandId

  const sortedSongs = () => {
    return [...data.posts]
      .sort((a, b) => compareByKey(a, b, "track_number"))
      .map(song => ({
        ...song,
        album_name: song.album_name,
        cover_image_small: song.cover_image_small,
      }))
  }

  useEffect(() => {
    if (data && data.posts && data.posts.length > 0) {
      setAlbumName(data.posts[0].album_name)
    }
  }, [isLoading])

  return (
    <li className="col-span-1">
      <div>
        {bandCoverImageUrl ? (
          <div
            style={{
              backgroundImage: `url(${bandCoverImageUrl})`,
              backgroundSize: "cover",
              aspectRatio: "1/1",
              minHeight: isMobile ? "156px" : "unset",
            }}
            className="w-full"
          >
            <div
              className="w-full h-full justify-center flex items-center"
              style={{
                background: "rgb(0,0,0,0.3)",
                paddingTop: isMobile ? "36px" : "unset",
                paddingBottom: isMobile ? "36px" : "unset",
              }}
            >
              {isLoading ? (
                <Spinner />
              ) : isError ? (
                <></>
              ) : data && data.posts && data.posts.length > 0 ? (
                isCurrentlyPlaying ? (
                  <PauseCircle
                    className="text-white cursor-pointer"
                    width="50%"
                    height="50%"
                    onClick={onPlayOrPauseClick}
                  />
                ) : (
                  <PlayCircle
                    className="text-white cursor-pointer"
                    width="50%"
                    height="50%"
                    onClick={onPlayClick}
                  />
                )
              ) : null}
            </div>
          </div>
        ) : (
          <div
            className="w-full bg-gray-700 flex justify-center items-center"
            style={{ aspectRatio: "1/1" }}
          >
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <>ERROR</>
            ) : data && data.posts && data.posts.length > 0 ? (
              isCurrentlyPlaying ? (
                <PauseCircle
                  className="text-white cursor-pointer"
                  width="50%"
                  height="50%"
                  onClick={onPlayOrPauseClick}
                />
              ) : (
                <PlayCircle
                  className="text-white cursor-pointer"
                  width="50%"
                  height="50%"
                  onClick={onPlayClick}
                />
              )
            ) : (
              <Lightning
                className="text-white"
                width="50%"
                height="50%"
                style={{
                  paddingTop: isMobile ? "36px" : "unset",
                  paddingBottom: isMobile ? "36px" : "unset",
                }}
              />
            )}
          </div>
        )}
      </div>
      <>
        <Link to={bandPath} title={bandName}>
          <div className="font-bold leading-tight text-black-800">
            {bandName}
          </div>
        </Link>
        <Link to={bandPath} title={bandName}>
          <div className="text-gray-800 text-sm leading-tight">
            {bandLocation}
          </div>
        </Link>
      </>
    </li>
  )
}

export default BandSearchResult
