import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { mutationRequest, request } from "../../util/request"
import { RequestError } from "../shared/RequestError"
import { Spinner } from "../shared/Spinner"
import Autosuggest from "react-autosuggest"
import theme from "../shared/AutoSuggestTheme.module.css"
import { Button, GuestManagerButton } from "../shared/Button"
import { Checkbox } from "../shared/fields/Checkbox"
import { useQueryClient } from "react-query"
import { useBandStreamGuestsForBandStream } from "../../hooks/useBandStreamGuestsForBandStream"
import { ActionCableConsumer } from "react-actioncable-provider"

const TakeoverManager = ({ bandId, bandStreamId }) => {
  const {
    status: bandStreamGuestsStatus,
    data: bandStreamGuestsData,
    error: bandStreamGuestsError,
  } = useBandStreamGuestsForBandStream(bandStreamId)
  const queryClient = useQueryClient()
  const refreshBandStreamGuestsData = bsg => {
    queryClient.invalidateQueries("band_stream_guests")
  }

  return (
    <div className="grid grid-cols-12 my-3">
      <ActionCableConsumer
        channel={{ channel: "BandStreamGuestsChannel", id: bandStreamId }}
        onReceived={refreshBandStreamGuestsData}
      />
      <div className="col-span-12">
        <h4 className="font-bold">Tagged Perfomers</h4>
        <p className="mb-2">
          You can tag other performers that are performing the stream. The
          stream will also be shown on tagged performer pages, and tips will be
          split with them.
        </p>
        <FindBand bandStreamId={bandStreamId} />
        {bandStreamGuestsStatus === "loading" ? (
          <Spinner />
        ) : bandStreamGuestsStatus === "error" ? (
          <RequestError error={bandStreamGuestsError} />
        ) : (
          <>
            {bandStreamGuestsData.band_stream_guests.length > 0 && (
              <>
                <h3 className="uppercase text-sm text-gray-600 mt-2">
                  Performing Now:
                </h3>
                <table className="w-full">
                  <tbody>
                    {bandStreamGuestsData.band_stream_guests.map(
                      bandStreamGuest => (
                        <TakeoverGuest
                          key={bandStreamGuest.id}
                          bandStreamGuest={bandStreamGuest}
                        />
                      ),
                    )}
                  </tbody>
                </table>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const TakeoverGuest = ({ bandStreamGuest }) => {
  const [revenueShareChoice, setRevenueShareChoice] = useState(
    bandStreamGuest.revenue_share,
  )
  const revenueShareOptions = [
    { value: "zero", text: "Not Shared" },
    { value: "even_share", text: "Even Split" },
    { value: "full_share", text: "Full Share" },
  ]

  useEffect(() => {
    handleUpdateBandStreamGuest(
      bandStreamGuest.host_band_stream_id,
      bandStreamGuest.id,
      "revenue_share",
      revenueShareChoice,
    )
  }, [revenueShareChoice])

  const queryClient = useQueryClient()

  const submitDeleteBandStreamGuest = () => {
    handleDeleteBandStreamGuest(
      bandStreamGuest.host_band_stream_id,
      bandStreamGuest.id,
    ).then(response => {
      queryClient.invalidateQueries("band_stream_guests")
    })
  }

  return (
    <tr>
      <td className="align-bottom">
        <button
          className="bg-gray-200 focus:outline-none font-bold inline-block mr-1 px-2 rounded-full text-red-600"
          onClick={submitDeleteBandStreamGuest}
        >
          &times;
        </button>
      </td>
      <td className="align-bottom">
        <span className="font-bold">{bandStreamGuest.guest_band?.name}</span>
      </td>
      <td className="">
        <div className="uppercase text-xs text-gray-500">
          Revenue Share{" "}
          <span className="text-xxs lowercase">
            (cannot be changed once accepted)
          </span>
        </div>
        <select
          value={revenueShareChoice}
          disabled={bandStreamGuest.status === "accepted"}
          onChange={e => setRevenueShareChoice(e.currentTarget.value)}
          className="border-2 px-1 py-1 text-sm rounded-sm w-full"
        >
          {revenueShareOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </td>
      <td className="align-bottom text-right">
        <StatusTag status={bandStreamGuest.status} />
      </td>
    </tr>
  )
}

const StatusTag = ({ status }) => {
  const bgColorStatusMap = {
    initiated: "bg-gray-500",
    accepted: "bg-green-300",
    completed: "bg-black",
    rejected: "bg-red-400",
    expired: "bg-black",
  }
  const textStatusMap = {
    initiated: "requested",
    accepted: "accepted",
    completed: "completed",
    rejected: "rejected",
    expired: "expired",
  }

  return (
    <div
      className={`inline-block ${bgColorStatusMap[status]} text-white px-2 py-1 text-center rounded-sm`}
    >
      {textStatusMap[status]}
    </div>
  )
}

const FindBand = ({ bandStreamId }) => {
  const [suggestions, setSuggestions] = useState([])
  const [value, setValue] = useState("")

  const onChange = (event, { newValue }) => {
    setValue(newValue)
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: "What band is performing with you?",
    value: value,
    onChange: onChange,
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = async value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    if (inputLength === 0) return []
    return fetchBandsFor(inputValue)
  }

  const fetchBandsFor = async inputValue => {
    return await fetch(
      `/api/v1/search/bands/${inputValue.replace(/[\/\?\&]/g, "")}`,
    )
      .then(response => response.json())
      .then(data => data.bands)
      .catch(console.error)
  }

  const queryClient = useQueryClient()

  const addNewPerformer = () => {
    createBandStreamGuest(bandStreamId, value).then(json => {
      setValue("")
      if (json.success) queryClient.invalidateQueries("band_stream_guests")
      else {
        // TODO: error response to user
        console.error("addNewPerformer failed: ", json)
      }
    })
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    getSuggestions(value).then(data => {
      if (data.error) {
        setSuggestions([])
      } else {
        setSuggestions(data)
      }
    })
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion.custom_subdomain

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => <div>{suggestion.name}</div>

  // Finally, render it!
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-5">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          theme={theme}
        />
      </div>
      <div className="col-span-3 ml-2">
        <button
          onClick={addNewPerformer}
          className="bg-gray-200 focus:outline-none pb-2 pt-1 px-3 rounded-sm text-gray-700 text-sm"
        >
          Add Performer
        </button>
      </div>
    </div>
  )
}

const createBandStreamGuest = async (
  bandStreamId,
  guestBandCustomSubdomain,
) => {
  return mutationRequest({
    options: { method: "POST" },
    path: `/api/v1/band_streams/${bandStreamId}/band_stream_guests`,
    data: {
      band_stream_guest: {
        guest_band_custom_subdomain: guestBandCustomSubdomain,
        host_band_stream_id: bandStreamId,
        guest_type: "takeover",
      },
    },
  })
}

const handleUpdateBandStreamGuest = async (
  bandStreamId,
  bandStreamGuestId,
  field,
  value,
) => {
  let params = {}
  params[field] = value
  return mutationRequest({
    options: { method: "PUT" },
    path: `/api/v1/band_streams/${bandStreamId}/band_stream_guests/${bandStreamGuestId}`,
    data: {
      band_stream_guest: params,
    },
  })
}

const handleDeleteBandStreamGuest = async (bandStreamId, bandStreamGuestId) => {
  return mutationRequest({
    options: { method: "DELETE" },
    path: `/api/v1/band_streams/${bandStreamId}/band_stream_guests/${bandStreamGuestId}`,
  })
}

export const BandStreamGuestCheckbox = ({
  bandStreamId,
  bandStreamGuestId,
  name,
  value,
}) => {
  const [checked, setChecked] = useState(!!value)

  const toggleValue = () => {
    handleUpdateBandStreamGuest(
      bandStreamId,
      bandStreamGuestId,
      "simulcast",
      !checked,
    )
    setChecked(!checked)
  }

  return (
    <Checkbox
      name={name}
      value={value}
      checked={checked}
      onChange={toggleValue}
    />
  )
}

export default TakeoverManager
