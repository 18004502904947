// adapted from: https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Visualizations_with_Web_Audio_API

function AudioAnalyser(context, stream, canvasEl) {
  this.width = canvasEl.width
  this.height = canvasEl.height
  this.analyser = context.createAnalyser()
  this.source = context.createMediaStreamSource(stream)
  this.source.connect(this.analyser)
  this.analyser.fftSize = 2048
  this.bufferLength = this.analyser.frequencyBinCount
  this.dataArray = new Uint8Array(this.bufferLength)
  this.drawVisual = null
  this.canvas = canvasEl
  this.canvasCtx = canvasEl.getContext("2d")
  this.canvasCtx.clearRect(0, 0, this.width, this.height)
  var that = this
  this.draw = function() {
    // loop the drawing function
    that.drawVisual = requestAnimationFrame(that.draw)
    // get the time domain data
    that.analyser.getByteTimeDomainData(that.dataArray)
    // fill the canvas with a solid color to start
    that.canvasCtx.fillStyle = "rgb(255, 255, 255)"
    that.canvasCtx.fillRect(0, 0, that.width, that.height)
    // set line width and stroke color for wave
    that.canvasCtx.lineWidth = 2
    that.canvasCtx.strokeStyle = "rgb(104, 211, 145)"
    that.canvasCtx.beginPath()
    // Determine the width of each segment of the line
    var sliceWidth = (that.width * 1.0) / that.bufferLength
    var x = 0
    // Now we run through a loop, defining the position of a small segment of the wave
    // for each point in the buffer at a certain height based on the data point value
    // form the array, then moving the line across to the place where the next wave
    // segment should be drawn:
    for (var i = 0; i < that.bufferLength; i++) {
      var v = that.dataArray[i] / 128.0
      var y = (v * that.height) / 2
      if (i === 0) {
        that.canvasCtx.moveTo(x, y)
      } else {
        that.canvasCtx.lineTo(x, y)
      }
      x += sliceWidth
    }
    // Finally, we finish the line in the middle of the right hand side of the canvas, then draw the stroke we've defined
    that.canvasCtx.lineTo(that.width, that.height / 2)
    that.canvasCtx.stroke()
  }

  this.draw()
}

export default AudioAnalyser
