import React from "react"

export const Checkbox = ({ name, value, checked, onChange }) => {
  return (
    <input
      type="checkbox"
      value={value}
      name={name}
      className="cursor-pointer"
      checked={checked}
      onChange={onChange}
    />
  )
}
