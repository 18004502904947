import React from "react"
import { RequestError } from "../shared/RequestError"
import { FeedSpinner } from "../shared/Spinner"
import BandListItem from "./BandListItem"
import { ErrorBoundary } from "../shared/ErrorBoundary"
import { useInfiniteOtherBands } from "../../hooks/useOtherBands"

export const OtherBandsSection = ({ bandId, bandName, colClass }) => {
  const pageSize = 8
  const { data, error, fetchNextPage, status } = useInfiniteOtherBands({
    bandId: bandId,
    pageSize: pageSize,
  })
  const lastPage = status === "success" ? data.pages.slice(-1)[0] : null

  return (
    <ErrorBoundary>
      <>
        {status === "loading" ? (
          <FeedSpinner />
        ) : status === "error" ? (
          <RequestError error={error} />
        ) : (
          data.pages[0].total_count > 0 && (
            <>
              <h2 className="border-b-4 border-black font-bold my-4 text-2xl uppercase">
                Related Bands
              </h2>

              <ul className={`grid ${colClass} md:cols-span-6 gap-6`}>
                {data.pages.map((page, i) => (
                  <React.Fragment key={page.page}>
                    {page.bands.length > 0
                      ? page.bands.map(band => (
                          <BandListItem
                            key={band.id}
                            bandId={band.id}
                            bandName={band.name}
                            bandPath={band.band_path}
                            bandCoverImageUrl={
                              band.cover_image_url ||
                              band.cover_photo_url_square
                            }
                            bandLocation={
                              band.location ? band.location.displayName : null
                            }
                            showFollow={true}
                          />
                        ))
                      : null}
                  </React.Fragment>
                ))}
              </ul>
              {lastPage.has_next_page && (
                <button
                  className="text-blue-600 focus:outline-none mt-4"
                  onClick={e => fetchNextPage()}
                >
                  See more bands related to {bandName}
                </button>
              )}
            </>
          )
        )}
      </>
    </ErrorBoundary>
  )
}
