import React, { useState } from "react"
import { handleUpdateBandData } from "../BandEditPage"

export const BandType = ({ band }) => {
  const [bandType, setBandType] = useState(band?.band_type)

  const handleBandTypeChange = event => {
    setBandType(event.target.value)
    handleUpdateBandData(band.id, { band_type: event.target.value })
  }

  return (
    <>
      <div className="mt-10">
        <label
          className="block col-span-12 text-gray-900 text-xs uppercase font-bold"
          htmlFor="bandType"
        >
          What Best Describes You?
        </label>
        <div className="mt-2">
          <label className="lg:inline-flex block items-center lg:mr-6">
            <input
              type="radio"
              className="form-radio"
              name="band_type"
              value="band"
              checked={bandType === "band"}
              onChange={handleBandTypeChange}
            />
            <span className="ml-2 text-gray-700">Band</span>
          </label>
          <label className="lg:inline-flex block items-center lg:mr-6">
            <input
              type="radio"
              className="form-radio"
              name="band_type"
              value="individual"
              checked={bandType === "individual"}
              onChange={handleBandTypeChange}
            />
            <span className="ml-2 text-gray-700">Musician</span>
          </label>
          <label className="lg:inline-flex block items-center lg:mr-6">
            <input
              type="radio"
              className="form-radio"
              name="band_type"
              value="venue"
              checked={bandType === "venue"}
              onChange={handleBandTypeChange}
            />
            <span className="ml-2 text-gray-700">Venue</span>
          </label>
          <label className="lg:inline-flex block items-center lg:mr-6">
            <input
              type="radio"
              className="form-radio"
              name="band_type"
              value="blog"
              checked={bandType === "blog"}
              onChange={handleBandTypeChange}
            />
            <span className="ml-2 text-gray-700">Blog</span>
          </label>
          <label className="lg:inline-flex block items-center">
            <input
              type="radio"
              className="form-radio"
              name="band_type"
              value="promoter"
              checked={bandType === "promoter"}
              onChange={handleBandTypeChange}
            />
            <span className="ml-2 text-gray-700">Promoter</span>
          </label>
        </div>
        <div className="text-xs text-gray-500">
          don't worry, you can change this anytime
        </div>
      </div>
    </>
  )
}
