import React from "react"
import { SecondaryButton } from "../../shared/Button"
import Heading from "../../shared/Heading"
import { ReactComponent as CalendarIcon } from "../../../icons/calendar.svg"
import { ReactComponent as PostIcon } from "../../../icons/post.svg"
import { ReactComponent as VideoIcon } from "../../../icons/video.svg"
import { go_live_path, new_event_path, new_post_path } from "../../../routes"

const MakeStuff = ({}) => {
  return (
    <>
      <Heading level={2} type="sectionHeader">
        Make Stuff
      </Heading>
      <div className="flex flex-col mb-6">
        <SecondaryButton
          to={go_live_path()}
          className="flex-1 mb-2"
          active={window.location.pathname === go_live_path()}
        >
          <VideoIcon className="mr-2" />
          Go Live
        </SecondaryButton>
        <SecondaryButton
          to={new_event_path()}
          className="flex-1 mb-2"
          active={window.location.pathname === new_event_path()}
        >
          <CalendarIcon className="mr-2" />
          Create an Event
        </SecondaryButton>
        <SecondaryButton
          to={new_post_path()}
          className="flex-1"
          active={window.location.pathname === new_post_path()}
        >
          <PostIcon className="mr-2" />
          Post Something
        </SecondaryButton>
      </div>
    </>
  )
}

export default MakeStuff
