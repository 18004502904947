import React, { useState, useEffect } from "react"
import { Spinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { useRelatedPosts } from "../../hooks/useRelatedPosts"
import { MiniLinkPreview } from "./MiniLinkPreview"

const RelatedPosts = ({
  postId,
  headerText = "Related Posts",
  className = "my-4",
}) => {
  const PER_PAGE = 3
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(PER_PAGE)
  const [currentCount, setCurrentCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [relatedPosts, setRelatedPosts] = useState([])

  const { isLoading, isError, isFetching, data, error } = useRelatedPosts(
    postId,
    page,
    perPage,
  )

  useEffect(() => {
    if (!isLoading && !isError && !isFetching) {
      if (page === 1) {
        setRelatedPosts(data.posts)
        setCurrentCount(data.posts.length)
      } else {
        setRelatedPosts([...data.posts, ...relatedPosts])
        setCurrentCount(data.posts.length + currentCount)
      }
      setTotalCount(data.total_count)
    }
  }, [isFetching])

  const showMore = e => {
    if (perPage !== PER_PAGE) {
      setPerPage(PER_PAGE)
    } else {
      setPage(curPage => curPage + 1)
    }
  }

  return isFetching ? (
    <Spinner />
  ) : error ? (
    <RequestError error={error} />
  ) : data ? (
    currentCount > 0 ? (
      <div className={className}>
        <h4 className="uppercase font-bold text-gray-700 text-xs">
          {headerText}&nbsp;
          <>
            <span className="font-normal">
              ({currentCount}/{totalCount})
            </span>
            {currentCount < totalCount ? (
              <span>
                <button
                  className="pl-2 text-blue-500 focus:outline-none"
                  onClick={showMore}
                >
                  show more
                </button>
              </span>
            ) : null}
          </>
        </h4>
        <ul>
          {relatedPosts.length > 0
            ? relatedPosts.map(relatedPost => (
                <div key={`related-post-${relatedPost.id}`} className="mb-3">
                  <MiniLinkPreview post={relatedPost} />
                </div>
              ))
            : null}
        </ul>
      </div>
    ) : null
  ) : null
}

export default RelatedPosts
