import React, { useState, useRef, useContext } from "react"
import { ActionCableConsumer } from "react-actioncable-provider"
import { UserContext } from "../HomeApp"
import { postUserInteraction } from "../../mutations/postUserInteraction"
import BandNadaPlayer from "../shared/BandNadaPlayer"
import styles from "./ViewerScreen.module.css"
import useVideoHeight from "../../hooks/useVideoHeight"
import { SuggestRedirect } from "./SuggestRedirect"
import PreviewPlayer from "../PreviewPlayer"

const Viewer = ({
  bandId,
  bandStreamId,
  bandName,
  bandPath,
  bandCoverPhotoURL,
  playbackUrl,
  broadcastStatus,
  isPreview,
  isFullWidth = false,
}) => {
  const PREVIEW_LENGTH_MS = 1000 * 120 // 2 minutes
  const { player_toggle_delay_secs } = useContext(UserContext)
  const PLAYER_TOGGLE_DELAY_SECS = player_toggle_delay_secs || 20 // set default val in case env is missing
  const intervalRef = useRef()
  let timeout = null
  let ref = useRef(null)
  const [showPlayer, setShowPlayer] = useState(broadcastStatus === "live")
  const [showDialog, setShowDialog] = useState(false)
  const { height: videoHeight } = useVideoHeight(isFullWidth)

  const handleReceivedMessage = newBandStream => {
    broadcastStatus = newBandStream?.broadcast_status
    setTimeout(() => {
      setShowPlayer(broadcastStatus === "live")
    }, PLAYER_TOGGLE_DELAY_SECS * 1000)
  }

  const onPlay = () => {
    if (intervalRef.current) clearInterval(intervalRef.current)
    const intervalId = setInterval(() => {
      postUserInteraction(bandId, bandStreamId, "broadcast_view")
    }, 30000)
    if (isPreview) {
      timeout = setTimeout(() => {
        setShowDialog(true)
      }, PREVIEW_LENGTH_MS)
    }
  }

  const onPause = () => {
    clearTimeout(timeout)
    clearInterval(intervalRef.current)
  }

  const standByStyle = {
    height: `${videoHeight}px`,
  }
  if (bandCoverPhotoURL && bandCoverPhotoURL.length) {
    standByStyle.background = `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bandCoverPhotoURL})`
    standByStyle.backgroundSize = "cover"
  }

  return (
    <div className={styles.viewerContainer} style={{}}>
      <ActionCableConsumer
        channel={{ channel: "BandStreamChannel", id: bandId }}
        onReceived={handleReceivedMessage}
      />
      {showPlayer ? (
        <BandNadaPlayer
          thumbnailUrl={bandCoverPhotoURL}
          playbackUrl={playbackUrl}
          controls={true}
          mute={true}
          autoplay={false}
          onPlay={onPlay}
          onPause={onPause}
        />
      ) : (
        <div
          ref={ref}
          className="text-center bg-black text-white text-lg flex justify-center items-center"
          style={standByStyle}
        >
          <PreviewPlayer bandId={bandId} />
        </div>
      )}
      <SuggestRedirect
        bandName={bandName}
        bandPath={bandPath}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </div>
  )
}

export default Viewer
