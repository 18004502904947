import React, { useState } from "react"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const Instagram = ({ band }) => {
  const [instagramUrl, setInstagramUrl] = useState(band.instagram_url || "")

  const fieldData = {
    field: "instagram_url",
    name: "instagramUrl",
    label: "Your instagram url",
    placeholder: "https://instagram.com/yourIGname",
    required: false,
  }

  return (
    <EditBandFormTextField
      fieldData={fieldData}
      value={instagramUrl}
      setValue={setInstagramUrl}
      bandId={band.id}
    />
  )
}
