import React, { useState } from "react"
import { mutationRequest } from "../../util/request"
import { Button } from "../../components/shared/Button"

export const BandDeleteForm = ({ band }) => {
  const [disabled, setDisabled] = useState(false)

  const handleDeleteBand = () => {
    setDisabled(true)
    deleteBand(band.id).then(redirectHome)
  }

  return (
    <>
      <Button
        disabled={disabled}
        bg="bg-red-500"
        onClick={(e) => {
          if (
            window.confirm(
              `Are you sure to delete all band data for ${band.name}?`,
            )
          ) {
            handleDeleteBand()
          }
        }}
      >
        Delete {band.name}
      </Button>
    </>
  )
}

export const deleteBand = async (bandId) => {
  try {
    let responseData = await mutationRequest({
      path: `/api/v1/bands/${bandId}`,
      options: { method: "DELETE" },
    })
    return responseData
  } catch (error) {
    console.log("Error attempting to delete band: ", error)
  }
}

export const redirectHome = () => {
  window.location.href = "/"
}
