import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import { DateDifferenceInWords, SmartDateFormat } from "../../util/dates"
import GoogleMapReact from "google-map-react"
import { PlayFill } from "react-bootstrap-icons"
import { request } from "../../util/request"
import { useQuery } from "react-query"
import { compareByKey } from "../../util/utils"
import useComponentSize from "@rehooks/component-size"
import { isMobile } from "react-device-detect"

import { useDispatch } from "react-redux"
import { prependAndPlay, pushToQueue } from "../../data/mediaPlayerSlice"
import { TextAsImage } from "./TextAsImage"

export const PostListItemPreview = ({ post, className = "" }) => {
  return (
    <li
      id={post.id}
      data-post-type={post.post_type}
      className={`mb-6 cols-span-1 ${className}`}
    >
      {post.post_type === "album" ? (
        <AlbumListItemPreview album={post} />
      ) : post.post_type === "stand_alone_song" ? (
        <SongListItemPreview song={post} />
      ) : post.post_type === "youtube_video" ? (
        <VideoListItemPreview video={post} />
      ) : post.post_type === "photo" ? (
        <PhotoListItemPreview photo={post} />
      ) : ["concert", "event"].includes(post.post_type) ? (
        <ConcertListItemPreview concert={post} />
      ) : post.post_type === "headline" ? (
        <HeadlineListItemPreview headline={post} />
      ) : (
        <UnsupportedPostTypePreview post={post} />
      )}
    </li>
  )
}

const PostListItemStyles = {
  title: "text-gray-800 text-sm font-bold leading-tight",
  bandName: "text-black-800 text-base font-bold leading-none py-2 block",
  contentDiv: "w-full text-center bg-gray-700 relative",
  date: "text-xxs text-gray-700",
}

const AlbumListItemPreview = ({ album }) => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false)
  const albumDetailData = useQuery(
    [
      "album",
      { path: `/api/v1/posts/${album.id}.json`, params: { fields: "songs" } },
    ],
    request,
  )
  var albumDetails = albumDetailData?.data?.post

  const dispatch = useDispatch()

  const onPlayClick = e => {
    dispatch(prependAndPlay(sortedSongs()))
  }

  const onAddClick = e => {
    dispatch(pushToQueue(sortedSongs()))
  }

  const sortedSongs = () => {
    return [...albumDetails.songs]
      .sort((a, b) => compareByKey(a, b, "track_number"))
      .map(song => ({
        ...song,
        album_name: albumDetails.title,
        cover_image_small: albumDetails.cover_image_small,
      }))
  }

  return (
    <div className="">
      <div
        className="relative text-center"
        style={{
          backgroundImage: `url(${album.cover_image_large})`,
          backgroundSize: "contain",
          backgroundRepeat: "none",
          paddingTop: "100%",
          width: "100%",
        }}
      >
        <button
          onClick={onPlayClick}
          className="absolute m-auto text-white text-center focus:outline-none"
          style={{ top: 0, bottom: 0, left: 0, right: 0 }}
        >
          <PlayFill size={75} className="m-auto" />
        </button>
      </div>
      <Link className={PostListItemStyles.bandName} to={album.local_path}>
        {album.band_name}
      </Link>
      <h2
        className={PostListItemStyles.title}
        dangerouslySetInnerHTML={{ __html: album.title }}
      ></h2>
      <div className={PostListItemStyles.date}>
        {album.future_active_date &&
        DateDifferenceInWords(
          new Date(album.future_active_date),
          new Date(album.active_date),
        ).diffInSeconds > 86400 ? (
          <div>
            Announced&nbsp;
            {SmartDateFormat({
              dateStr: album.active_date,
              includeTime: false,
            })}
            &nbsp;for Release on&nbsp;
            {SmartDateFormat({
              dateStr: album.future_active_date,
              includeTime: false,
            })}
          </div>
        ) : (
          <div>
            Released&nbsp;
            {SmartDateFormat({
              dateStr: album.active_date,
              includeTime: false,
            })}
          </div>
        )}
      </div>
    </div>
  )
}

const SongListItemPreview = ({ song }) => {
  const [style, setStyle] = useState({
    visibility: isMobile ? "visible" : "hidden",
  })

  const dispatch = useDispatch()

  const onPlayClick = e => {
    dispatch(prependAndPlay([song]))
  }

  const onAddClick = e => {
    dispatch(pushToQueue([song]))
  }

  return (
    <div className="">
      <div
        className={PostListItemStyles.contentDiv}
        style={{
          backgroundImage: `url(${song.external_photo_src})`,
          backgroundRepeat: "none",
          backgroundSize: "contain",
          paddingTop: "100%",
        }}
      >
        <button
          onClick={onPlayClick}
          className="absolute m-auto text-white focus:outline-none"
          style={{ top: 0, bottom: 0, left: 0, right: 0 }}
        >
          <PlayFill size={75} />
        </button>
      </div>

      <Link className={PostListItemStyles.bandName} to={song.local_path}>
        {song.band_name}
      </Link>
      <h2
        className={PostListItemStyles.title}
        dangerouslySetInnerHTML={{ __html: song.title }}
      ></h2>
      <div className={PostListItemStyles.date}>
        {song.future_active_date &&
        DateDifferenceInWords(
          new Date(song.future_active_date),
          new Date(song.active_date),
        ).diffInSeconds > 86400 ? (
          <div>
            Announced&nbsp;
            {SmartDateFormat({
              dateStr: song.active_date,
              includeTime: false,
            })}
            &nbsp;for Release on&nbsp;
            {SmartDateFormat({
              dateStr: song.future_active_date,
              includeTime: false,
            })}
          </div>
        ) : (
          <div>
            Released&nbsp;
            {SmartDateFormat({
              dateStr: song.active_date,
              includeTime: false,
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export const VideoListItemPreview = ({ video }) => {
  let ref = useRef(null)
  let size = useComponentSize(ref)
  let { width, height } = size
  const [expandText, setExpandText] = useState(false)

  return (
    <div>
      <iframe
        src={video.embed_url}
        width="100%"
        height={width}
        title={video.title}
        allow="fullscreen"
        ref={ref}
      />
      <Link className={PostListItemStyles.bandName} to={video.local_path}>
        {video.band_name}
      </Link>
      <h2
        className={PostListItemStyles.title}
        dangerouslySetInnerHTML={{ __html: video.title }}
      ></h2>
      <div className={PostListItemStyles.date}>
        Released&nbsp;
        {SmartDateFormat({
          dateStr: video.active_date,
          includeTime: false,
        })}
      </div>
    </div>
  )
}

export const HeadlineListItemPreview = ({ headline }) => {
  const ref = useRef(null)
  let size = useComponentSize(ref)

  // max chars: 250
  const headlineText =
    headline.title && headline.title.length > 250
      ? `${headline.title.substr(0, 250)}..`
      : headline.title

  // dynamic font size based on headline title length
  const multiplier = isMobile ? 1 : 2
  const fontSize = `${Math.floor(
    (250 / headlineText.length) * multiplier + 20,
  )}px`

  const headlineStyle = {
    fontSize: isMobile ? 16 : fontSize, // setting static mobile size to fix weird zoom-in issue
    height: size.width,
    overflowWrap: "anywhere",
  }

  return (
    <div className="">
      <div
        ref={ref}
        style={headlineStyle}
        className={
          " flex flex-row items-center px-4 leading-none font-bold bg-black text-white"
        }
      >
        {headlineText}
      </div>
      <div className={PostListItemStyles.metaDiv}>
        <Link className={PostListItemStyles.bandName} to={headline.local_path}>
          {headline.band_name}
        </Link>
        <div className={PostListItemStyles.date}>
          Posted:&nbsp;
          {SmartDateFormat({
            dateStr: headline.active_date,
            includeTime: false,
          })}
        </div>
      </div>
    </div>
  )
}

const PhotoListItemPreview = ({ photo }) => {
  return <div></div>
}

const ConcertListItemPreview = ({ concert }) => {
  let ref = useRef(null)
  let size = useComponentSize(ref)

  return (
    <Link to={concert.nada_path}>
      <div
        ref={ref}
        style={{ height: size.width }}
        className="flex flex-col justify-end"
      >
        <>
          {concert.cover_image_url ? (
            <img
              src={concert.cover_image_url}
              alt={concert.title}
              className="cursor-pointer"
            />
          ) : concert.event_type !== "bNliveStream" &&
            concert.band_cover_photo_url_medium ? (
            <img src={concert.band_cover_photo_url_medium} alt="venue map" />
          ) : (
            <button className="cursor-pointer w-full">
              <TextAsImage text={concert.title} />
            </button>
          )}
        </>
      </div>
      <div className={PostListItemStyles.metaDiv}>
        {concert.band_name}

        <h2
          className={PostListItemStyles.title}
          dangerouslySetInnerHTML={{ __html: concert.title }}
        ></h2>
        <h3 className="text-sm">
          <a
            className="text-gray-700"
            href={concert.google_map_url}
            target="_blank"
            rel="noreferrer"
          >
            {concert.venue_name} in {concert.venue_city_display_name}
          </a>
          <div className={PostListItemStyles.date}>
            <a href={concert.add_to_calendar_url} target="_blank">
              {SmartDateFormat({
                dateStr: concert.start_dt,
                includeTime: true,
              })}
              &nbsp;(+)
            </a>
          </div>
        </h3>
      </div>
    </Link>
  )
}

const UnsupportedPostTypePreview = ({ post }) => {
  return <div></div>
}
