import { useInfiniteQuery, useQuery } from "react-query"
import { request } from "../util/request"

export const OTHER_BANDS_QUERY_KEY = "other_bands"

export function useOtherBands(bandId, page = 1, pageSize = 20) {
  const queryKey = [OTHER_BANDS_QUERY_KEY, bandId, page, pageSize]
  return useQuery(queryKey, getOtherBands)
}

export function useInfiniteOtherBands({ bandId, pageSize }) {
  const queryKey = [OTHER_BANDS_QUERY_KEY, bandId, null, pageSize]
  return useInfiniteQuery(queryKey, getOtherBands, {
    getNextPageParam: (lastPage, pages) => lastPage.next_page,
  })
}

const getOtherBands = async ({ queryKey = null, pageParam = null }) => {
  const [key, bandId, page, pageSize] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands/${bandId}/other_bands`,
        params: {
          page: pageParam || page,
          per_page: pageSize,
        },
      },
    ],
  })
}
