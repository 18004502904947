import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Lightning, PauseCircle, PlayCircle } from "react-bootstrap-icons"
import {
  FollowComponent,
  FollowComponentLoggedOut,
} from "../shared/FollowComponent"
import { UserContext } from "../HomeApp"
import { useDispatch, useSelector } from "react-redux"
import { playOrPause, prependAndPlay } from "../../data/mediaPlayerSlice"
import { useBandPosts } from "../../hooks/useBandPosts"
import { compareByKey } from "../../util/utils"
import { Spinner } from "../shared/Spinner"
import { isMobile } from "react-device-detect"

export const BandListItem = ({
  bandName,
  bandCoverImageUrl,
  bandId,
  bandPath,
  bandLocation,
  showFollow = true,
  size = "medium",
}) => {
  const { user } = useContext(UserContext)
  const sizeChart = {
    small: { text: "text-sm", icon: "50" },
    medium: { text: "text-lg", icon: "100" },
    large: { text: "text-xl", icon: "200" },
  }

  const { isLoading, isError, isFetching, data, error } = useBandPosts(
    bandId,
    "songs",
  )
  const [albumName, setAlbumName] = useState("")

  const dispatch = useDispatch()
  const onPlayClick = e => {
    dispatch(prependAndPlay(sortedSongs()))
  }
  const onPlayOrPauseClick = e => {
    dispatch(playOrPause())
  }

  const sortedSongs = () => {
    return [...data.posts]
      .sort((a, b) => compareByKey(a, b, "track_number"))
      .map(song => ({
        ...song,
        album_name: song.album_name,
        cover_image_small: song.cover_image_small,
      }))
  }
  const currentMediaItem = useSelector(
    // @ts-ignore
    state => state.mediaPlayer.value.currentMediaItem,
  )
  // @ts-ignore
  const playerState = useSelector(state => state.mediaPlayer.value.playerState)
  const isCurrentlyPlaying =
    playerState === "playing" && currentMediaItem.bandId === bandId

  useEffect(() => {
    if (data && data.posts && data.posts.length > 0) {
      setAlbumName(data.posts[0].album_name)
    }
  }, [isLoading])

  return (
    <li className="col-span-1">
      {bandCoverImageUrl ? (
        <div
          style={{
            backgroundImage: `url(${bandCoverImageUrl})`,
            backgroundSize: "cover",
            aspectRatio: "1/1",
            minHeight: isMobile ? "156px" : "unset",
          }}
          className="w-full"
        >
          <div
            className="w-full h-full justify-center flex items-center"
            style={{
              background: "rgb(0,0,0,0.3)",
              paddingTop: isMobile ? "36px" : "unset",
              paddingBottom: isMobile ? "36px" : "unset",
            }}
          >
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <></>
            ) : data && data.posts && data.posts.length > 0 ? (
              isCurrentlyPlaying ? (
                <PauseCircle
                  className="text-white cursor-pointer"
                  width="50%"
                  height="50%"
                  onClick={onPlayOrPauseClick}
                />
              ) : (
                <PlayCircle
                  className="text-white cursor-pointer"
                  width="50%"
                  height="50%"
                  onClick={onPlayClick}
                />
              )
            ) : null}
          </div>
        </div>
      ) : (
        <div
          className="w-full bg-gray-700 flex justify-center items-center"
          style={{ aspectRatio: "1/1" }}
        >
          {isLoading ? (
            <Spinner />
          ) : isError ? (
            <>ERROR</>
          ) : data && data.posts && data.posts.length > 0 ? (
            isCurrentlyPlaying ? (
              <PauseCircle
                className="text-white cursor-pointer"
                width="50%"
                height="50%"
                onClick={onPlayOrPauseClick}
              />
            ) : (
              <PlayCircle
                className="text-white cursor-pointer"
                width="50%"
                height="50%"
                onClick={onPlayClick}
              />
            )
          ) : (
            <Lightning
              className="text-white"
              width="50%"
              height="50%"
              style={{
                paddingTop: isMobile ? "36px" : "unset",
                paddingBottom: isMobile ? "36px" : "unset",
              }}
            />
          )}
        </div>
      )}
      <>
        <div
          className="bg-black font-semiBold px-2 text-white opacity-75 hover:opacity-100 truncate text-base"
          style={{ marginTop: "-1.5em" }}
        >
          <Link to={bandPath} title={bandName} className="flex justify-between">
            <span> {bandName}</span>
            {["small", "medium"].includes(size) ? null : (
              <span className="text-xs pt-1">{bandLocation}</span>
            )}
          </Link>
        </div>
        {showFollow ? (
          user ? (
            <FollowComponent bandId={bandId} bandName={bandName} />
          ) : (
            <FollowComponentLoggedOut bandId={bandId} bandName={bandName} />
          )
        ) : null}
      </>
    </li>
  )
}

export default BandListItem
