import React from "react"
import Header from "../shared/Header/Header"
import { StringParam, useQueryParams } from "use-query-params"
import { isMobile } from "react-device-detect"
import style from "../shared/PageStyle.module.css"
import SearchResults from "./SearchResults"

export const SearchResultsPage = ({ user, query = null }) => {
  const [params, setParams] = useQueryParams({
    q: StringParam,
  })
  if (!query) query = params["q"]
  return (
    <div className={style.rootElStyle}>
      <Header user={user} query={query} />
      <div className="grid grid-cols-12 w-full pb-2 mt-4 px-3 lg:px-4">
        <div className="col-span-12">
          <div className="mt-4" />

          <SearchResults query={query} cols={isMobile ? 1 : 4} />
        </div>
      </div>
    </div>
  )
}

export default SearchResultsPage
