import useComponentSize from "@rehooks/component-size"
import React, { useLayoutEffect, useRef, useState } from "react"
import Carousel from "react-gallery-carousel"
import { PostListItemExpandedStyles, PostListItemStyles } from "../constants"
import styles from "./styles.module.scss"
import { isMobile } from "react-device-detect"

const PreviewImages = ({ attachedMedia, expanded = false }) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const ref = useRef(null)
  const videoRef = useRef(null)
  let size = useComponentSize(ref)

  useLayoutEffect(() => {
    setWidth(size.width)
    if (isMobile) {
      setHeight((width * 9) / 16)
    } else {
      expanded
        ? setHeight(width ? (width * 2) / 3 : 0)
        : setHeight(width ? (width * 9) / 16 : 0)
    }
  })

  const headlineStyle = {
    height: height,
  }
  const contentDivStyles = expanded
    ? PostListItemExpandedStyles.contentDiv
    : PostListItemStyles.contentDiv

  const playableMedia = attachedMedia.filter(
    media =>
      media.content_type_generic === "image" ||
      media.content_type_specific === "mp4",
  )
  const processingMedia = attachedMedia
    .filter(
      media =>
        media.content_type_generic === "video" &&
        media.content_type_specific !== "mp4" &&
        !playableMedia.find(
          playableMedia =>
            playableMedia.filename.replace(/\.\w{3}/, "") ===
            media.filename.replace(/\.\w{3}/, ""),
        ),
    )
    .map(media => ({ ...media, isProcessing: true }))
  const allMedia = [...playableMedia, ...processingMedia]

  const handleCarouselSwipe = () => {
    videoRef.current?.pause()
  }

  return (
    <div
      ref={ref}
      style={headlineStyle}
      className={contentDivStyles + " w-full "}
    >
      <Carousel
        canAutoPlay={false}
        hasThumbnails={false}
        className={styles.carousel}
        style={{
          backgroundColor: "black",
          alignItems: "center",
          color: "white",
        }}
        hasIndexBoard="bottomRight"
        onIndexChange={handleCarouselSwipe}
      >
        {allMedia.map(media =>
          media.isProcessing ? (
            <div key={media.filename} className={styles.processingContainer}>
              <div>
                <img
                  className={styles.processingImage}
                  src="https://bandnada.s3.amazonaws.com/static/external_assets/web/bandnada_under_construction.jpg"
                />
                <div className={styles.processingText}>
                  Stil processing{" "}
                  <span className={styles.filename}>{media.filename}</span>
                  <div className={styles.smallProcessingText}>
                    Check back soon
                  </div>
                </div>
              </div>
            </div>
          ) : media.content_type_generic === "video" ? (
            media.content_type_specific === "mp4" ? (
              <div className={styles.videoContainer} key={media.filename}>
                <video ref={videoRef} className={styles.video} controls>
                  <source src={media.url} type="video/mp4" />
                </video>
              </div>
            ) : null
          ) : (
            <div className={styles.imgContainer} key={media.filename}>
              <img
                src={media.url}
                alt={media.filename}
                title={media.filename}
              />
            </div>
          ),
        )}
      </Carousel>
    </div>
  )
}

export default PreviewImages
