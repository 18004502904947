import React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Link45deg, Share } from "react-bootstrap-icons"
import { toast } from "react-toastify"
import styles from "./styles.module.scss"
import "react-toastify/dist/ReactToastify.css"
import clsx from "clsx"

export const CopyPostUrlLink = ({
  nadaUrl,
  renderMode = "icon",
  color = "blue",
}) => {
  const bgColor = color === "blue" ? "bg-blue-600" : "bg-white"
  const textColor = color === "blue" ? "text-blue-500" : "text-white"
  const Msg = () => (
    <div className="flex">
      <img
        src="https://bandnada.s3.amazonaws.com/static/external_assets/web/bandnada_cher_pic.png"
        alt="share-icon"
        style={{ width: "50px" }}
        className="mr-2"
      />
      <div>
        <span className="font-bold">Cher Link</span>
        <br />
        copied to your clipboard!
      </div>
    </div>
  )
  return (
    <>
      <CopyToClipboard
        text={nadaUrl}
        onCopy={() =>
          toast.success(<Msg />, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            icon: false,
          })
        }
      >
        {renderMode === "icon" ? (
          <button className="align-top focus:outline-none">
            <Link45deg className={`${textColor} ml-1`} size={22} />
          </button>
        ) : renderMode === "text" ? (
          <button className={`focus:outline-none ${textColor}`}>Cher</button>
        ) : (
          <button
            className={clsx(
              bgColor,
              styles.cherBtn,
              "focus:outline-none px-1 rounded text-sm text-white mb-2",
            )}
          >
            <Share
              size={11}
              className="text-white inline align-baseline mr-1"
            />
            Cher
          </button>
        )}
      </CopyToClipboard>
    </>
  )
}
