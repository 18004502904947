export const PostListItemStyles = {
  contentDiv: "lg:col-span-7 col-span-12",
  metaDiv: "lg:col-span-5 col-span-12 lg:pl-4 pt-2 lg:pt-0",
  header: "lg:text-xl text-base font-bold text-black-800 pb-2 leading-none",
  headerShort: "leading-none",
  headerLong: "leading-tight",
  bandName: "text-black-800 font-bold leading-none",
  postDate: "text-sm",
  externalLink: "text-xs text-gray-500 italic",
  description: "text-xs text-gray-500 mt-2",
  secondaryHeader: "text-sm text-gray-700 mt-neg-2 mb-2",
}

export const PostListItemExpandedStyles = {
  contentDiv: "col-span-12",
  metaDiv: "lg:col-span-7 col-span-12 pt-2",
  commentsDiv: "lg:col-span-5 col-span-12 lg:pl-4 pt-2",
  header: "text-xl font-black uppercase text-black-800 pb-2 leading-none",
  headerShort: "leading-none",
  headerLong: "leading-tight",
  bandName: "text-black-800 font-bold leading-none",
  postDate: "text-sm mb-1",
  externalLink: "text-xs text-gray-500 italic",
  description: "text-sm text-black-800 mt-2",
  secondaryHeader: "text-sm text-gray-700 mt-neg-2 mb-2",
  secondaryDescriptor:
    "text-xxs font-bold leading-none uppercase text-gray-800",
}
