import { sync_api_v1_band_path } from "../../routes"
import { mutationRequest } from "../request"

export const syncBand = async (bandId, serviceClass) => {
  const resp = await mutationRequest({
    path: sync_api_v1_band_path(bandId),
    options: { method: "put" },
    data: {
      service_class: serviceClass,
    },
  })
  return resp
}
