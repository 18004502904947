import React, { useState } from "react"
import "./TaggableInput.scss"
import { PersonFill } from "react-bootstrap-icons"
import { MentionsInput, Mention } from "react-mentions"

const TaggableInput = ({
  value,
  onChange,
  onFocus = null,
  placeholder = "enter text",
  className = "",
  autoFocus = false,
}) => {
  const [placeholderText, setPlaceholderText] = useState(placeholder)
  // const onEmojiClick = (event, emojiObject) => {
  //   event.preventDefault()
  //   insertContent(`${emojiObject.emoji} `)
  //   setShowEmojiPicker(false)
  // }

  const highlightSearchTerm = (text, search) => {
    if (!search || !text || text.length === 0 || search.length === 0)
      return <span>{text}</span>
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text.split(new RegExp(`(${search})`, "gi"))
    return (
      <span>
        {parts.map(part =>
          part.toLowerCase() === search.toLowerCase() ? (
            <b key={part}>{part}</b>
          ) : (
            part
          ),
        )}
      </span>
    )
  }

  const renderSuggestion = (entry, search) => {
    return (
      <>
        <div className="flex">
          <div className="mr-2 rounded-sm">
            {entry.avatarUrlSmall ? (
              <img
                className="h-10 w-10"
                src={entry.avatarUrlSmall}
                alt={entry.nickname}
              />
            ) : (
              <div className="h-10 w-10 bg-gray-800">
                <PersonFill className="text-gray-500 h-full w-full" />
              </div>
            )}
          </div>
          <div>
            <div className="primary-name h-5">
              {highlightSearchTerm(entry.nickname, search)}
            </div>
            <div className="secondary-name text-gray-700">
              {highlightSearchTerm(entry.name, search)} -{" "}
              {highlightSearchTerm(entry.bandName || "", search)}
            </div>
          </div>
        </div>
      </>
    )
  }

  const resetPlaceholderText = () => {
    if (value.length === 0) {
      setPlaceholderText(placeholder)
    }
  }

  const onBlur = e => {
    resetPlaceholderText()
  }

  // useEffect(() => {
  //   resetPlaceholderText()
  // }, [value])

  const onMentionsInputFocus = e => {
    if (placeholderText?.length > 0) {
      setPlaceholderText("")
    }
    if (onFocus) {
      onFocus(e)
    }
  }

  const fetchUsers = (query, callback) => {
    if (!query) return
    fetch(`/api/v1/search/users/${query}?per_page=5`)
      .then(res => res.json())
      .then(res =>
        res.users.map(user => ({
          display: user.band ? `@${user.band.name}` : `@${user.name}`,
          id: user.band ? user.band.band_path : user.id,
          name: user.name,
          bandName: user.band ? user.band.name : null,
          nickname: user.nickname,
          avatarUrlSmall: user.avatar_url_small,
          path: user.band ? user.band.band_path : null,
        })),
      )
      .then(callback)
      .catch(console.error)
  }

  return (
    <MentionsInput
      value={value || placeholderText}
      onFocus={onMentionsInputFocus}
      onBlur={onBlur}
      autoFocus={autoFocus}
      allowSpaceInQuery={true}
      onChange={(_, v) => onChange(v)}
      className={`mentions ${className} ${
        placeholderText?.length > 0 ? "deactivated" : "activated"
      }`}
    >
      <Mention
        trigger="@"
        data={fetchUsers}
        markup="@[__display__](__id__)"
        renderSuggestion={renderSuggestion}
      />
    </MentionsInput>
  )
}

export default TaggableInput
