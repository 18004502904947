import React from "react"
import styles from "../HomeScreen.module.scss"
import { Link } from "react-router-dom"
import { HomePageButton } from "../../shared/Button"
import { useQuery } from "react-query"
import { request } from "../../../util/request"

export const HomeScreenBanner = ({ showDialog, setShowDialog }) => {
  const backgroundImagesData = useQuery(
    [
      "background_images",
      // @ts-ignore
      {
        path: "/api/v1/external_assets.json",
        params: {
          component_filter: "home_page_banner",
          randomize: true,
          per_page: 1,
        },
      },
    ],
    request,
  )
  const backgroundImage = backgroundImagesData?.data?.external_assets[0]

  return backgroundImagesData.status === "success" ? (
    <div>
      <div
        style={{
          backgroundImage: `url(${backgroundImage.url})`,
          backgroundSize: "cover",
          backgroundPosition: backgroundImage.position,
        }}
      >
        <div className={styles.bannerOverlay}>
          <div className="lg:px-8 w-full">
            <div className={`${styles.section} ${styles.topSection}`}>
              <div className="py-16">
                <h1
                  className={`text-white text-3xl leading-tight sm:text-5xl leading-none font-black mb-12`}
                >
                  The Underground Music Network.
                  <br />
                  Post Shows &amp; Find Shows.
                  <br />
                  Listen, Watch, and Support Music.
                </h1>
                <h5
                  className="text-white text-xl leading-tight sm:leading-normal font-normal mb-8"
                  style={{ maxWidth: "480px" }}
                >
                  bandNada lets you interact with your music scene without
                  dealing with corporate overlords.
                </h5>
                <HomePageButton
                  className="bg-green-400 text-black"
                  bgColor="bg-green-400"
                  textColor="text-white"
                  onClick={e => setShowDialog(!showDialog)}
                  active={true}
                >
                  Sign Up
                </HomePageButton>
              </div>
            </div>
          </div>
          <div className="text-white pt-1 pr-2 absolute right-0 bottom-0 mb-2 text-sm">
            {backgroundImage.band_path ? (
              <Link className="italic" to={backgroundImage.band_path}>
                {backgroundImage.band_name}
              </Link>
            ) : (
              <span> {backgroundImage.band_name}</span>
            )}
            &nbsp;by&nbsp;
            <a
              className="font-bold"
              href={backgroundImage.credit_url}
              target="_blank"
              rel="noreferrer"
            >
              {backgroundImage.credit_name}
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : null
}
