import { useQuery } from "react-query"
import { request } from "../util/request"

export function useChats(bandId, page = 1, perPage = 30) {
  return useQuery(["chats", bandId, page], getChatsForBandId, {
    keepPreviousData: true,
  })
}

const getChatsForBandId = async ({ queryKey }) => {
  const [key, id, page, perPage] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands/${id}/chats.json`,
        params: { per_page: perPage, page: page },
      },
    ],
  })
}
