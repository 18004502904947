import React, { useState, useEffect, useContext } from "react"
import { Lightning, PauseCircle, PlayCircle } from "react-bootstrap-icons"
import { useDispatch, useSelector } from "react-redux"
import {
  prependAndPlay,
  pushToQueue,
  playOrPause,
} from "../../data/mediaPlayerSlice"
import { useBandPosts } from "../../hooks/useBandPosts"
import { compareByKey } from "../../util/utils"
import { FollowComponent, FollowComponentLoggedOut } from "./FollowComponent"
import { Spinner } from "./Spinner"
import { Link } from "react-router-dom"
import { UserContext } from "../HomeApp"

const BandPreviewBlock = ({ band }) => {
  if (band.band_type != "band") {
    return null
  }

  const { user } = useContext(UserContext)
  const { isLoading, isError, data } = useBandPosts(band.id, "songs")
  const [albumName, setAlbumName] = useState("")

  const dispatch = useDispatch()
  const currentMediaItem = useSelector(
    // @ts-ignore
    state => state.mediaPlayer.value.currentMediaItem,
  )
  // @ts-ignore
  const playerState = useSelector(state => state.mediaPlayer.value.playerState)
  const isCurrentlyPlaying =
    playerState === "playing" && currentMediaItem.bandId === band.id
  const isCurrentlyQueued =
    playerState === "paused" && currentMediaItem.bandId === band.id

  const onPlayClick = e => {
    dispatch(prependAndPlay(sortedSongs()))
  }
  const onPlayOrPauseClick = e => {
    dispatch(playOrPause())
  }

  const onAddClick = e => {
    dispatch(pushToQueue(sortedSongs()))
  }

  const sortedSongs = () => {
    return [...data.posts]
      .sort((a, b) => compareByKey(a, b, "track_number"))
      .map(song => ({
        ...song,
        album_name: song.album_name,
        cover_image_small: song.cover_image_small,
      }))
  }

  useEffect(() => {
    if (data && data.posts && data.posts.length > 0) {
      setAlbumName(data.posts[0].album_name)
    }
  }, [isLoading])

  const bgImage = band.cover_image_url || band.cover_photo_url_square

  return (
    <>
      <div className="grid grid-cols-12 mb-2">
        <div className="col-span-2">
          {bgImage ? (
            <div
              style={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                aspectRatio: "1/1",
              }}
              className="w-full h-full"
            >
              <div
                className="w-full h-full justify-center flex items-center"
                style={{ background: "rgb(0,0,0,0.3)" }}
              >
                {isLoading ? (
                  <Spinner />
                ) : isError ? (
                  <></>
                ) : data && data.posts && data.posts.length > 0 ? (
                  isCurrentlyPlaying ? (
                    <PauseCircle
                      className="text-white cursor-pointer"
                      width="50%"
                      height="50%"
                      onClick={onPlayOrPauseClick}
                    />
                  ) : (
                    <PlayCircle
                      className="text-white cursor-pointer"
                      width="50%"
                      height="50%"
                      onClick={
                        isCurrentlyQueued ? onPlayOrPauseClick : onPlayClick
                      }
                    />
                  )
                ) : null}
              </div>
            </div>
          ) : (
            <div
              className="w-full bg-gray-700 h-full flex justify-center items-center"
              style={{ aspectRatio: "1/1" }}
            >
              {isLoading ? (
                <Spinner />
              ) : isError ? (
                <></>
              ) : data && data.posts && data.posts.length > 0 ? (
                <PlayCircle
                  className="text-white cursor-pointer"
                  width="50%"
                  height="50%"
                  onClick={onPlayClick}
                />
              ) : (
                <Lightning className="text-white" width="50%" height="50%" />
              )}
            </div>
          )}
        </div>
        <div className="col-span-10 bg-gray-200 pl-3 flex flex-col justify-between">
          <div>
            <div className="font-bold text-black-800">
              <Link to={band.band_path}>{band.name}</Link>
            </div>
            <div
              className="text-sm text-gray-800 overflow-hidden"
              style={{ maxHeight: "1.4rem" }}
            >
              {albumName}
            </div>
          </div>
          <div>
            {user ? (
              <FollowComponent
                bandId={band.id}
                bandName={band.name}
                renderMode="plain"
                className="text-blue-700 text-xs lowercase"
              />
            ) : (
              <FollowComponentLoggedOut
                bandId={band.id}
                bandName={band.name}
                renderMode="plain"
                className="text-blue-700 text-xs lowercase"
              />
            )}

            {data && data.posts && data.posts.length > 0 && (
              <>
                {" - "}
                <button
                  className="focus:outline-none text-blue-700 text-xs"
                  onClick={onAddClick}
                >
                  add to queue
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default BandPreviewBlock
