import React, { useContext, useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useReactions } from "../../../hooks/useReactions"
import { mutationRequest } from "../../../util/request"
import { UserContext } from "../../HomeApp"
import styles from "./style.module.css"
import clsx from "clsx"
import { AuthenticationDialog } from "../../shared/AuthenticationDialog"

export const ReactionBlock = ({ contentType, contentId, postId }) => {
  const [userReaction, setUserReaction] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const { user } = useContext(UserContext)
  const queryClient = useQueryClient()

  const { data, isLoading, isError, status } = useReactions(
    contentType,
    contentId,
    "emotions",
    true,
  )

  useEffect(() => {
    if (!isLoading && !isError && status === "success" && user) {
      setUserReaction(data.current_user_setting)
    }
  }, [isLoading, isError, status])

  const mutation = useMutation(mutationRequest)

  const submitReaction = async reactionType => {
    await mutation.mutate({
      options: { method: "POST" },
      path: `/api/v1/reactions`,
      data: {
        reaction: {
          id: userReaction?.id,
          content_type: contentType,
          content_id: contentId,
          post_id: postId,
          reaction_type:
            userReaction?.reaction_type !== reactionType
              ? reactionType
              : "remove",
        },
        filter: "emotions",
      },
    })
  }

  useEffect(() => {
    if (mutation.isSuccess) {
      setUserReaction(mutation.data.current_user_setting)
      queryClient.invalidateQueries([
        "reactions",
        contentType,
        contentId,
        "emotions",
        true,
      ])
    }
  }, [mutation.isSuccess])

  if (isLoading) {
    return null
  }

  return (
    <>
      <div
        className={clsx(
          styles.reactionContainer,
          contentType === "Comment" ? styles.reactionContainerAlt : null,
        )}
      >
        <span>
          <span
            className={
              userReaction?.reaction_type === "like"
                ? styles.reactionIconActive
                : styles.reactionIcon
            }
            onClick={() =>
              user ? submitReaction("like") : setShowDialog(true)
            }
            role="img"
            aria-label="thumbs up"
            title="Like"
          >
            👍
          </span>
          <span className={styles.likeCount}>{data.counts.like}</span>
        </span>

        <span>
          <span
            className={
              userReaction?.reaction_type === "right_on"
                ? styles.reactionIconActive
                : styles.reactionIcon
            }
            onClick={() =>
              user ? submitReaction("right_on") : setShowDialog(true)
            }
            role="img"
            aria-label="right on"
            title="right on!"
          >
            ✊
          </span>
          <span className={styles.likeCount}>{data.counts.right_on}</span>
        </span>

        <span>
          <span
            className={
              userReaction?.reaction_type === "horns"
                ? styles.reactionIconActive
                : styles.reactionIcon
            }
            onClick={() =>
              user ? submitReaction("horns") : setShowDialog(true)
            }
            role="img"
            aria-label="rock on"
            title="rock on!"
          >
            🤘
          </span>
          <span className={styles.likeCount}>{data.counts.horns}</span>
        </span>

        <span>
          <span
            className={
              userReaction?.reaction_type === "fingers_crossed"
                ? styles.reactionIconActive
                : styles.reactionIcon
            }
            onClick={() =>
              user ? submitReaction("fingers_crossed") : setShowDialog(true)
            }
            role="img"
            aria-label="fingers crossed"
            title="fingers crossed..."
          >
            🤞
          </span>
          <span className={styles.likeCount}>
            {data.counts.fingers_crossed}
          </span>
        </span>

        <span>
          <span
            className={
              userReaction?.reaction_type === "ded"
                ? styles.reactionIconActive
                : styles.reactionIcon
            }
            onClick={() => (user ? submitReaction("ded") : setShowDialog(true))}
            role="img"
            aria-label="woh"
            title="Hell No!"
          >
            ☠️
          </span>
          <span className={styles.likeCount}>{data.counts.ded}</span>
        </span>
      </div>
      {showDialog ? (
        <AuthenticationDialog
          explanation={`You must first sign up or log in to like stuff`}
        />
      ) : null}
    </>
  )
}
