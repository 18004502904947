import React, { useState } from "react"
import { BroadcastButton } from "../shared/Button"
import { isMobile } from "react-device-detect"
import styles from "./BroadcastScreen.module.css"
import { Check } from "react-bootstrap-icons"
import BandNadaPlayer from "../shared/BandNadaPlayer"
import TakeoverManager from "./TakeoverManager"
import { useBandStream } from "../../hooks/useBandStream"
import {
  handleUpdateBandStreamData,
  BroadcastStatus,
  BroadcastLinkInfo,
  StreamMode,
} from "./BroadcastScreen"
import useVideoHeight from "../../hooks/useVideoHeight"

export const MobileBroadcaster = ({
  band,
  broadcastType,
  setBroadcastType,
  bandStream,
  broadcastState,
}) => {
  const [isTestMode, setIsTestMode] = useState(false)
  const [userErrorMessage, setUserErrorMessage] = useState(null)
  const [bandStreamId, setBandStreamId] = useState(bandStream.id)
  const { height: videoHeight } = useVideoHeight()

  const onError = e => {
    console.log("onError: ", e)
  }

  const updateBroadcastState = ({ _isTestMode }) => {
    if (!bandStreamId) return null
    setIsTestMode(_isTestMode)
    handleUpdateBandStreamData(bandStreamId, {
      is_test_mode: _isTestMode,
      origin: "mobile",
    })
  }

  return (
    <>
      <div
        className={styles.broadcastContainer}
        style={{ height: videoHeight }}
      >
        <BroadcastStatus
          broadcastState={broadcastState}
          isTestMode={isTestMode}
        />
        {broadcastState === "broadcasting" ? (
          <BandNadaPlayer
            playerId="broadcast-monitor"
            thumbnailUrl={band?.thumbnail_url}
            playbackUrl={band?.live_stream_playback_url}
            controls={false}
            mute={false}
            onError={onError}
          />
        ) : (
          <div className="bg-black text-white text-lg h-full flex items-center justify-center">
            We will show you a preview of your stream here once we are receiving
            it from your mobile broadcaster
          </div>
        )}
      </div>

      {userErrorMessage ? (
        <div
          id="user-error-messaging"
          className="bg-red-500 p-2 text-white opacity-75 relative"
          style={{ marginTop: "-41px" }}
        >
          There was a problem! - {userErrorMessage}
        </div>
      ) : null}

      <div className="grid grid-cols-12 pt-2">
        {!isMobile && (
          <div className="lg:col-span-6 col-span-12">
            <BroadcastLinkInfo
              bandPath={band.band_path}
              bandStreamToken={bandStream?.token}
              isTestMode={isTestMode}
            />
          </div>
        )}

        <div className="lg:col-span-6 col-span-12 text-right">
          <div className="grid grid-cols-2">
            <div className="lg:col-span-1 col-span-2">
              <BroadcastButton
                className="mb-1"
                active={isTestMode}
                onClick={e => {
                  updateBroadcastState({
                    _isTestMode: !isTestMode,
                    origin: "mobile",
                  })
                }}
              >
                {isTestMode ? "Switch to LIVE mode" : "Switch to Test Mode"}
              </BroadcastButton>
            </div>
            <div className="lg:col-span-1 col-span-2 lg:ml-2">
              {broadcastState === "broadcasting" ? (
                <div className="bg-green-400 text-white text-center font-bold rounded focus:outline-none text-base py-2 w-full">
                  Mobile Stream Found &nbsp;
                  <Check size={20} className="inline align-middle" />
                </div>
              ) : (
                <div className="bg-yellow-500 text-white text-center font-bold rounded focus:outline-none text-base py-2 w-full">
                  listening for stream
                </div>
              )}
            </div>
          </div>
        </div>

        {isMobile && (
          <div className="lg:col-span-6 col-span-12 lg:mt-0 mt-4">
            <BroadcastLinkInfo
              bandPath={band.band_path}
              bandStreamToken={bandStream?.token}
              isTestMode={isTestMode}
            />
          </div>
        )}
      </div>
      <div className="mt-2 grid grid-cols-12">
        <div className="col-span-12 text-base mt-4 lg:mt-0">
          {broadcastState === "idle" && (
            <>
              <h2 className="font-semibold">
                Mobile Broadcasting Instructions:
              </h2>
              <ol className={styles.instructions}>
                <li>
                  Download <span className="italic">Larix Broadcaster</span>{" "}
                  from the iOS or Android app store
                  <div className="text-gray-600 text-sm">
                    you can use other mobile broadcasters, but we recommend
                    Larix
                  </div>
                </li>
                <li>
                  Open the <span className="italic">Larix Broadcaster</span> app
                </li>
                <li>
                  Add bandNada as a new "Connection"
                  <div className="text-gray-600 text-sm">
                    (you only need to do this the first time you setup the app)
                  </div>
                  <ul className="list-disc list-inside pl-6 break-words">
                    <li>Click "Connections"</li>
                    <li>Click the "+" in the top right corner</li>
                    <li>
                      Enter the following:
                      <br />
                      <span className="font-bold">Name:</span>
                      &nbsp;
                      <span className="text-gray-700 text-sm">bandNada</span>
                      <br />
                      <span className="font-bold">URL:</span>
                      &nbsp;
                      <span className="text-gray-700 text-sm">
                        {`${bandStream.source_primary_server}/${bandStream.source_stream_name}`}
                      </span>
                      <br />
                      <span className="font-bold">Target type:</span>
                      &nbsp;
                      <span className="text-gray-700 text-sm">
                        RTMP authorization
                      </span>
                      <br />
                      <span className="font-bold">Login:</span>
                      &nbsp;
                      <span className="text-gray-700 text-sm">
                        {bandStream.source_username}
                      </span>
                      <br />
                      <span className="font-bold">Password:</span>
                      &nbsp;
                      <span className="text-gray-700 text-sm">
                        {bandStream.source_password}
                      </span>
                    </li>
                    <li>Click "Save"</li>
                    <li>Click the "bandNada" row so that it is checked</li>
                    <li>Click the "Settings" link in the top left</li>
                    <li>
                      Click the "Capture and encoding" link below Connections
                    </li>
                    <li>
                      Under Broadcasting, click Video and set the following:
                      <br />
                      <span className="font-bold">Resolution:</span>
                      &nbsp;
                      <span className="text-gray-700 text-sm">960x540</span>
                      <br />
                      <span className="font-bold">Bitrate:</span>
                      &nbsp;
                      <span className="text-gray-700 text-sm">500Kbps</span>
                      <br />
                      <span className="font-bold">Frame rate:</span>
                      &nbsp;
                      <span className="text-gray-700 text-sm">25 fps</span>
                      <br />
                      <span className="font-bold">Keyframe frequency:</span>
                      &nbsp;
                      <span className="text-gray-700 text-sm">2 sec</span>
                    </li>
                    <li>And then click "Back" in the top left.</li>
                  </ul>
                </li>
                <li>
                  Click the red record button when you are ready to go live.
                  Have a great show!
                </li>
              </ol>
            </>
          )}
        </div>
      </div>
      {band.enable_takeover_manager && (
        <TakeoverManager bandId={band.id} bandStreamId={bandStream.id} />
      )}
      <div className="grid grid-cols-12 pb-4 text-base">
        <div className="col-span-12">
          <div className="mt-2">
            <h4 className="font-bold">Having trouble?</h4>
            If you are streaming via a mobile broadcaster and the player is not
            available to test the stream above, you can reload the page and it
            should appear. If not, double check your settings. And if you are
            still having trouble, email support@bandnada.com
          </div>
        </div>
      </div>
    </>
  )
}
