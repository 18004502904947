import { useEffect } from "react"

/** @param {string} title */
export function useDocumentTitle(title) {
  useEffect(() => {
    document.title = title
  }, [title])
}

/** @param {string} title */
export function useAppTitle(title) {
  return useDocumentTitle(`${title} | bandNada`)
}
