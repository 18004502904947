import React from "react"
import ErrorBoundary from "react-error-boundary"
import { useMostRelevantPosts } from "../../hooks/useMostRelevantPosts"
import { SmartDateFormat } from "../../util/dates"
import { RequestError } from "../shared/RequestError"
import { FeedSpinner } from "../shared/Spinner"
import { Link } from "react-router-dom"
import { AlbumSearchResult } from "../search/AlbumSearchResult"
import { HeadlineListItemPreview } from "../posts/PostListItemPreview"
import { SongSearchResult } from "../search/SongSearchResult"

const MostRelevantBandContent = ({ bandId }) => {
  const { data, error, status } = useMostRelevantPosts(bandId)

  return (
    <ErrorBoundary>
      <div>
        {status === "loading" ? (
          <FeedSpinner />
        ) : status === "error" ? (
          <RequestError error={error} />
        ) : (
          <ul>
            {data.concerts?.length > 0 &&
              data.concerts.map(concert => (
                <MostRelevantConcert key={concert.id} concert={concert} />
              ))}
            {data.latest_content.map(post => (
              <MostRelevantContent key={post.id} post={post} />
            ))}
          </ul>
        )}
      </div>
    </ErrorBoundary>
  )
}

const MostRelevantContent = ({ post }) => {
  return (
    <li>
      {post.post_type === "youtube_video" ? (
        <iframe
          src={post.embed_url}
          width="100%"
          height="auto"
          title={post.title}
          allow="fullscreen"
          style={{ aspectRatio: "16/9" }}
        />
      ) : post.post_type === "album" ? (
        <AlbumSearchResult album={post} />
      ) : post.post_type === "stand_alone_song" ? (
        <SongSearchResult song={post} />
      ) : post.post_type === "headline" ? (
        <HeadlineListItemPreview headline={post} />
      ) : null}
    </li>
  )
}

const MostRelevantConcert = ({ concert }) => {
  return (
    <li className="text-sm mb-4">
      <div className="leading-none">
        <span className="font-bold uppercase text-xs text-gray-500">
          {SmartDateFormat({
            dateStr: concert.start_dt,
            includeTime: true,
          })}
        </span>
      </div>

      <div className="leading-tight font-bold uppercase">
        {concert.venue_name && concert.venue_city_display_name
          ? `${concert.venue_name} in ${concert.venue_city_display_name}`
          : `${concert.venue_name}${concert.venue_city_display_name}`}
      </div>
      <Link className="display-block block" to={concert.nada_path}>
        {concert.title}
      </Link>
    </li>
  )
}

export default MostRelevantBandContent
