import { useInfiniteQuery, useQuery } from "react-query"
import { request } from "../util/request"

export const POSTS_QUERY_KEY = "posts"

export function usePosts(contentType, feedType, page = 1, perPage = 30) {
  return useQuery(
    [POSTS_QUERY_KEY, feedType, contentType, null, page, perPage],
    getPosts,
  )
}

export function useInfinitePosts({ feedType, scene, contentType, pageSize }) {
  const queryKey = [
    POSTS_QUERY_KEY,
    feedType,
    contentType,
    scene,
    null,
    pageSize,
  ]
  return useInfiniteQuery(queryKey, getPosts, {
    getNextPageParam: (lastPage, pages) => lastPage.next_page,
  })
}

const getPosts = async ({ queryKey = null, pageParam = null }) => {
  const [key, feedType, contentType, scene, page, perPage] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/posts`,
        params: {
          feed_type: feedType,
          content_type: contentType || null,
          current_scene: scene,
          page: pageParam || page,
          per_page: perPage,
        },
      },
    ],
  })
}
