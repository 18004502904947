import React, { useState, useEffect } from "react"
import { FeedSpinner, Spinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { SmartDateFormat } from "../../util/dates"
import { Currency } from "../../util/numbers"
import { PayeeSection } from "./PayeeSection"
import { PlusSquare, PlusSquareFill } from "react-bootstrap-icons"
import { usePayouts } from "../../hooks/usePayouts"
import { useTips } from "../../hooks/useTips"

export const Payouts = ({ bandId, payeeId, className = "" }) => {
  const [page, setPage] = useState(1)
  const [payoutsCount, setPayoutsCount] = useState(0)
  const { error, data, status } = usePayouts(bandId, page)
  useEffect(() => {
    if (status === "success") {
      setPayoutsCount(data.total_count)
    }
  }, [status])

  return (
    <div className={className}>
      <h2 className="border-b-4 mt-4 border-black font-bold mb-1 text-xl uppercase">
        Payouts ({payoutsCount})
      </h2>
      <p className="mb-4 text-sm text-gray-700">
        Payouts are summed up once a day, and we pay you out if you've received
        any tips in that period. Your band share is 70% of all tips and bandNada
        takes 30% which includes credit card fees. Your payout goes directly
        into your Stripe Account.
      </p>
      <PayeeSection payeeId={payeeId} />
      {status === "loading" ? (
        <FeedSpinner />
      ) : status === "error" ? (
        <RequestError error={error} />
      ) : data ? (
        <>
          {data.payouts.length === 0 ? (
            <div>No payouts yet...</div>
          ) : (
            <div>
              <ul className="lg:text-base text-xs">
                <li className="grid grid-cols-24 font-bold uppercase border-b-2 border-black text-xs leading:none">
                  <div className="col-span-6">Payout Date</div>
                  <div className="col-span-3">Your Share</div>
                  <div className="col-span-3">Total Payment</div>
                  <div className="col-span-3">BandNada Share</div>
                  <div className="col-span-3">Donation Share</div>
                  <div className="col-span-2"># of Tips</div>
                  <div className="col-span-2"># of Users</div>
                  <div className="col-span-2 text-right">Status</div>
                </li>
                {data.payouts.map(payout => (
                  <PayoutListItem payout={payout} key={payout.id} />
                ))}
              </ul>
            </div>
          )}
        </>
      ) : null}
    </div>
  )
}

const PayoutListItem = ({ payout }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      <li className="grid grid-cols-24 py-2 bg-gray-200 my-1">
        <div className="col-span-6 font-bold">
          <button
            onClick={e => setIsExpanded(!isExpanded)}
            className="focus:outline-none"
          >
            {isExpanded ? (
              <PlusSquareFill className="inline-block mx-2 align-text-top" />
            ) : (
              <PlusSquare className="inline-block mx-2 align-text-top" />
            )}
          </button>

          {SmartDateFormat({ dateStr: payout.created_at })}
        </div>
        <div className="col-span-3 font-bold">
          {Currency(payout.band_share)}
        </div>
        <div className="col-span-3">{Currency(payout.total_amount)}</div>
        <div className="col-span-3 font-bold">{Currency(payout.bn_share)}</div>
        <div className="col-span-3 font-bold">
          {Currency(payout.promotion_share)}
        </div>
        <div className="col-span-2">{payout.tip_ids.length}</div>
        <div className="col-span-2">{payout.user_ids.length}</div>
        <div className="col-span-2 text-right">
          {payout.status === "completed" ? "PAID" : "pending"}
        </div>
      </li>
      {isExpanded && <PayoutTipList payoutId={payout.id} />}
    </>
  )
}

const PayoutTipList = ({ payoutId }) => {
  const { error, data, status } = useTips(payoutId, 1, 100)

  return (
    <li>
      <ul>
        <li className="font-bold">Tip Breakdown</li>
        {status === "loading" ? (
          <Spinner />
        ) : status === "error" ? (
          <RequestError error={error} />
        ) : (
          data.tips.map(tip => (
            <li
              key={tip.id}
              className="grid grid-cols-24 py-2 bg-gray-100 text-sm my-1"
            >
              <div className="col-span-1"></div>
              <div className="col-span-6">
                {SmartDateFormat({ dateStr: tip.tip_date })}
              </div>
              <div className="col-span-6">
                {tip.tipper_name} {tip.tipper_email}
              </div>
              <div className="col-span-3">{Currency(tip.amount)}</div>
            </li>
          ))
        )}
      </ul>
    </li>
  )
}
