import React, { useContext, useState } from "react"
import BandsListing from "../../components/bands/BandsListing"
import { UserContext } from "../../components/HomeApp"
import Footer from "../../components/shared/Footer/Footer"
import Header from "../../components/shared/Header/Header"
import Heading from "../../components/shared/Heading"
import style from "../../components/shared/PageStyle.module.css"

const DiscoverPage = () => {
  const { user, scene } = useContext(UserContext)
  // eslint-disable-next-line no-restricted-globals
  const queryParams = new URLSearchParams(location.search)
  const filterInit = queryParams.get("filter")
  const [filter, setFilter] = useState(filterInit || "scene")

  return (
    <div className={style.rootElStyle}>
      <Header user={user} />
      <div className="grid grid-cols-12 w-full pb-2 mt-4 px-3 lg:px-4">
        <div className="col-span-12">
          <Heading level={2} type="sectionHeader">
            <div className="flex justify-between">
              <div>
                <>
                  <button
                    onClick={(e) => setFilter("scene")}
                    title="only content from the bands you follow"
                    className={`focus:outline-none ${
                      filter === "scene"
                        ? "text-black-800 font-bold uppercase"
                        : "text-gray-500 lowercase"
                    }`}
                  >
                    {scene}
                  </button>
                  <span className="px-2 text-gray-300">|</span>

                  <button
                    onClick={(e) => setFilter("everything")}
                    title="only content from your current scene"
                    className={`focus:outline-none ${
                      filter === "everything"
                        ? "text-black-800 font-bold uppercase"
                        : "text-gray-500 lowercase"
                    }`}
                  >
                    everything
                  </button>

                  <span className="px-2 text-gray-300">|</span>
                  <button
                    onClick={(e) => setFilter("following")}
                    title="only content from your current scene"
                    className={`focus:outline-none ${
                      filter === "following"
                        ? "text-black-800 font-bold uppercase"
                        : "text-gray-500 lowercase"
                    }`}
                  >
                    following
                  </button>
                </>
              </div>
            </div>
          </Heading>
          <BandsListing cols={6} limit={36} mobileCols={2} filter={filter} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default DiscoverPage
