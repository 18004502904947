import React from "react"
import { ReactComponent as DownArrow } from "../../../icons/chevronDown.svg"
import { PersonFill } from "react-bootstrap-icons"

const AVATAR_COLORS = [
  "text-blue-400",
  "text-red-400",
  "text-green-400",
  "text-yellow-400",
  "text-purple-400",
  "text-teal-400",
  "text-indigo-400",
  "text-orange-400",
  "text-gray-400",
  "text-black",
]

const UserAvatar = ({
  handleClick,
  user = null,
  inHeader = false,
  size = "small",
}) => {
  const avatarUrl =
    size === "small" && user.avatar_url_small
      ? user.avatar_url_small
      : size === "medium" && user.avatar_url_medium
      ? user.avatar_url_medium
      : user.avatar_url_large
      ? user.avatar_url_large
      : null
  const sizeChart = {
    small: { width: "10" },
    medium: { width: "32" },
    large: { width: "64" },
  }
  const name = user.band ? user.band.name : user.nickname || user.first_name
  const avatarColor = AVATAR_COLORS[user.id % AVATAR_COLORS.length]
  return (
    <div
      onClick={handleClick}
      className={`flex items-baseline ${
        inHeader ? "ml-1 lg:ml-2 cursor-pointer" : ""
      }`}
    >
      <div title={name} className={`userAvatar w-${sizeChart[size].width}`}>
        {avatarUrl ? (
          <img
            className={`h-full w-full ${
              inHeader ? "rounded-full" : "rounded-md"
            }`}
            src={avatarUrl}
            alt={name}
          />
        ) : (
          <PersonFill
            className={`userAvatar_placeholder h-full w-full ${
              inHeader ? "rounded-full" : "rounded-md"
            } ${avatarColor}`}
          />
        )}
      </div>
      {inHeader ? <DownArrow className="hover:opacity-50" /> : null}
    </div>
  )
}

export default UserAvatar
