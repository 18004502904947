import React from "react"
import { isMobile } from "react-device-detect"
import ViewerPage from "../../../../viewer/ViewerScreen"
import { BandStreamGuestHeader } from "../../../BandStreamGuestHeader"
import { BandStreamHostHeader } from "../../../BandStreamHostHeader"
import { OtherBandsSection } from "../../../OtherBandsSection"
import { PostSection } from "../../../PostSection"
import BandHeader from "../../BandHeader"
import { Followings } from "../../../../Following"

const BasicTemplate = ({
  band,
  isAdmin,
  adminMode,
  setAdminMode,
  bandStreamHost,
  bandStreamGuest,
  bandStream,
}) => {
  const isFan = band.band_type === "fan"
  return (
    <div className="col-span-12">
      <BandHeader
        band={band}
        isAdmin={isAdmin}
        adminMode={adminMode}
        setAdminMode={setAdminMode}
        headerMode="classic"
      />

      <div
        className="px-3 lg:px-4"
        style={{
          marginTop: isMobile
            ? ""
            : bandStream?.broadcast_status === "live"
            ? "-49vw"
            : "-13vw",
        }}
      >
        {bandStreamHost && (
          <BandStreamHostHeader
            revenueShare={bandStreamHost.revenue_share}
            simulcast={bandStreamHost.simulcast}
            bandName={band.name}
            hostBandName={bandStreamHost.host_band.name}
            hostBandPath={bandStreamHost.host_band.band_path}
          />
        )}
        {bandStreamGuest && (
          <BandStreamGuestHeader
            revenueShare={bandStreamGuest.revenue_share}
            bandName={band.name}
            guestBandName={bandStreamGuest.guest_band.name}
            guestBandPath={bandStreamGuest.guest_band.band_path}
          />
        )}

        {bandStreamHost && bandStreamHost.simulcast ? (
          <ViewerPage
            bandId={bandStreamHost.host_band_id}
            bandStreamId={bandStreamHost.host_band_stream_id}
            bandStreamGuestId={bandStreamHost.id}
          />
        ) : (
          <ViewerPage bandId={band.id} bandStreamId={bandStream?.id} />
        )}

        {isFan ? (
          <div className="lg:mt-56">
            <Followings bandId={band.id} />
          </div>
        ) : (
          <>
            <PostSection
              title="Upcoming Shows"
              bandId={band.id}
              bandName={band.name}
              adminMode={adminMode}
              contentType="future_concerts"
              colClass="lg:grid-cols-2 grid-cols-1"
            />
            <PostSection
              title="Posts"
              bandId={band.id}
              bandName={band.name}
              adminMode={adminMode}
              contentType="headlines"
              colClass="lg:grid-cols-2 grid-cols-1"
            />
            <PostSection
              title="Music"
              bandId={band.id}
              bandName={band.name}
              adminMode={adminMode}
              contentType="albums"
              colClass="lg:grid-cols-2 grid-cols-1"
            />
            <PostSection
              title="Video"
              bandId={band.id}
              bandName={band.name}
              adminMode={adminMode}
              contentType="videos"
              colClass="lg:grid-cols-2 grid-cols-1"
            />
            {!adminMode && (
              <OtherBandsSection
                bandId={band.id}
                bandName={band.name}
                colClass="lg:grid-cols-8 grid-cols-2"
              />
            )}

            <PostSection
              title="Past Shows"
              bandId={band.id}
              bandName={band.name}
              adminMode={adminMode}
              contentType="past_concerts"
              colClass="lg:grid-cols-2 grid-cols-1"
            />
          </>
        )}
      </div>
    </div>
  )
}

export default BasicTemplate
