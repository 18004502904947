import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { user_settings_path } from "../../routes"
import { UserContext } from "../HomeApp"

const SuggestiveAction = () => {
  const { user } = useContext(UserContext)
  const containerClasses =
    "bg-blue-600 col-span-12 mb-2 py-2 px-3 rounded-sm text-white"

  if (!user) return <></>

  return (
    <>
      {!user.avatar_url_small ? (
        <div className={containerClasses}>
          bandNada is way better when you have a profile picture. 💀{" "}
          <Link to={user_settings_path} className="underline">
            Upload a pic now
          </Link>{" "}
          💀
        </div>
      ) : null}
    </>
  )
}

export default SuggestiveAction
