import React, { useState, useEffect, useRef, useContext } from "react"
import { useParams } from "react-router-dom"
import Header from "../../shared/Header/Header"
import { FeedSpinner } from "../../shared/Spinner"
import { useBand } from "../../../hooks/useBand"
import { UserContext } from "../../HomeApp"
import { useBandStreamGuestsForBand } from "../../../hooks/useBandStreamGuestsForBand"
import { postUserInteraction } from "../../../mutations/postUserInteraction"
import style from "../../shared/PageStyle.module.css"
import UnknownBand from "../UnknownBand"
import BasicTemplate from "./templates/BasicTemplate"
import TimelineTemplate from "./templates/TimelineTemplate"
import BandFooter from "../BandFooter"

export const BandScreen = () => {
  const intervalRef = useRef()
  let { custom_subdomain: bandId } = useParams()
  const [bandStreamId, setBandStreamId] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [adminMode, setAdminMode] = useState(false)
  const { user } = useContext(UserContext)
  // eslint-disable-next-line no-restricted-globals
  const queryParams = new URLSearchParams(location.search)
  const referrer = queryParams.get("referrer") || document.referrer

  const {
    status: bandStatus,
    data: bandData,
    error: bandError,
  } = useBand(bandId, null, "band_stream")
  var band = bandData?.band

  const {
    status: bandStreamGuestsStatus,
    data: bandStreamGuestsData,
    error: bandStreamGuestsError,
  } = useBandStreamGuestsForBand(bandId, "acceptances", "host_band,guest_band")
  let bandStreamHost = bandStreamGuestsData?.band_stream_guests[0]
  let bandStreamGuest = bandStreamGuestsData?.guests
    ? bandStreamGuestsData?.guests[0]
    : null

  useEffect(() => {
    if (bandStatus === "success") {
      setIsAdmin(
        bandData?.band?.id && user?.id && bandData.band.user_id === user.id,
      )
    }
  }, [bandStatus])

  useEffect(() => {
    if (bandStatus === "success") {
      setBandStreamId(band.band_stream_id)
    }
  }, [band?.band_stream_id, bandStatus])

  useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current)
    const intervalId = setInterval(() => {
      postUserInteraction(bandId, bandStreamId, referrer)
    }, 60000)
    // @ts-ignore
    intervalRef.current = intervalId
    postUserInteraction(bandId, bandStreamId, referrer)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [bandStreamId])

  return (
    <div className={style.rootElStyle}>
      <Header user={user} />
      <div className="grid grid-cols-12 w-full pb-2">
        {bandStatus === "loading" ? (
          <FeedSpinner />
        ) : bandStatus === "error" ? (
          <UnknownBand error={bandError} bandId={bandId} />
        ) : (
          <>
            {band.template_name === "basic" ? (
              <BasicTemplate
                band={band}
                isAdmin={isAdmin}
                adminMode={adminMode}
                setAdminMode={setAdminMode}
                bandStreamHost={bandStreamHost}
                bandStreamGuest={bandStreamGuest}
                bandStream={band.band_stream}
              />
            ) : (
              <TimelineTemplate
                band={band}
                isAdmin={isAdmin}
                adminMode={adminMode}
                setAdminMode={setAdminMode}
                bandStreamHost={bandStreamHost}
                bandStreamGuest={bandStreamGuest}
                bandStream={band.band_stream}
              />
            )}
          </>
        )}
      </div>
      <BandFooter band={band} />
    </div>
  )
}
