import React, { useContext } from "react"
import { UserContext } from "../HomeApp"
import { Pencil } from "react-bootstrap-icons"

const PostEditButton = ({ post, setShowEditForm }) => {
  const { user } = useContext(UserContext)
  const isOwner = user && user.band && user.band.id === post.band_id

  return (
    <div>
      {isOwner && (
        <>
          &nbsp;
          <button
            className="text-blue-600 focus:outline-none ml-2"
            onClick={e => setShowEditForm(true)}
          >
            <Pencil className="text-blue-500" />
          </button>
        </>
      )}
    </div>
  )
}

export default PostEditButton
