import React from "react"
import { Link } from "react-router-dom"
import { discover_path } from "../../routes"

const UnknownBand = ({ error, bandId }) => {
  return (
    <div className="col-span-12 lg:col-start-3 lg:col-span-8 mt-6">
      <h2 className="text-xl font-bold">
        Sorry, we couldn't find a venue or an artist with the handle "{bandId}"{" "}
        ¯\_(ツ)_/¯
      </h2>
      <p className="pt-1">
        Try searching for them in the search box above or use the{" "}
        <Link to={discover_path()} className="text-blue-600">
          Discover page
        </Link>
        .
      </p>
      <img
        className="mt-4"
        src="https://bandnada.s3.amazonaws.com/static/external_assets/web/bandnada_band_not_found.jpg"
        alt="band not found"
      />
    </div>
  )
}

export default UnknownBand
