import React, { useRef, useState, useEffect } from "react"
import {
  PauseFill,
  PlayFill,
  SkipForwardFill,
  SkipBackwardFill,
  XCircle,
  ArrowsFullscreen,
} from "react-bootstrap-icons"
import ReactPlayer from "react-player"
import screenfull from "screenfull"
import { findDOMNode } from "react-dom"
import { Link } from "react-router-dom"
import styles from "./styles.module.scss"

import { timeToMinutesSeconds } from "../../util/dates"

import { postUserMediaInteraction } from "../../mutations/postUserInteraction"

import { useSelector, useDispatch } from "react-redux"
import isEqual from "is-equal"
import watch from "redux-watch"
import store from "../../data/store"
import {
  selectMediaPlayer,
  playNext,
  playOrPause,
  removeElement,
  clearQueue,
} from "../../data/mediaPlayerSlice"
import { isMobile } from "react-device-detect"
import clsx from "clsx"

let currentTimeTimer = null
const active = "#a9a9a9"
const inactive = "#dbdbdb"

const Scrubber = ({ current, max, playerCallback }) => {
  const inputRef = useRef(null)

  const handleChange = (min, max) => event => {
    playerCallback(event.target.value)
  }

  const minValue = 0
  const maxValue = max
  const progress = (current / maxValue) * 100 + "%"

  const styleInput = {
    background: `linear-gradient(90deg, ${active} 0% ${progress},   ${inactive} ${progress} 100%)`,
  }

  return (
    <input
      ref={inputRef}
      id="sliderId"
      className="scrubber"
      name="sliderName"
      type="range"
      min={minValue}
      max={maxValue}
      value={current}
      onChange={handleChange(minValue, maxValue)}
      style={styleInput}
    />
  )
}

export const UniversalPlayer = () => {
  const dispatch = useDispatch()
  const mediaPlayerState = useSelector(selectMediaPlayer)
  const audioPlayerRef = useRef(null)
  const videoPlayerRef = useRef(null)
  const [currentTime, setCurrentTime] = useState(0)
  const [mobileView, setMobileView] = useState("controls")
  const size = 30

  let videoAutoplay = false
  let initialLoad = true

  const clearMediaRefs = () => {
    clearInterval(currentTimeTimer)
    scrubberUpdate(0)
  }

  const playCurrent = mediaItem => {
    if (mediaItem && mediaItem.type === "audio") {
      audioPlayerRef.current.play()
    } else if (mediaItem && mediaItem.type === "video") {
      const internalPlayer =
        videoPlayerRef && videoPlayerRef.current
          ? videoPlayerRef.current.getInternalPlayer()
          : null
      if (internalPlayer) internalPlayer.playVideo()
    }
  }

  const pauseCurrent = mediaItem => {
    if (mediaItem && mediaItem.type === "audio") {
      audioPlayerRef.current.pause()
    } else if (mediaItem && mediaItem.type === "video") {
      const internalPlayer =
        videoPlayerRef && videoPlayerRef.current
          ? videoPlayerRef.current.getInternalPlayer()
          : null
      if (internalPlayer) internalPlayer.pauseVideo()
    }
  }

  const onPlayOrPauseClick = () => {
    dispatch(playOrPause())
  }

  const onSkipClick = () => {
    if (audioPlayerRef && audioPlayerRef.current) {
      localStorage.setItem(
        "endTime",
        JSON.stringify(audioPlayerRef.current.currentTime),
      )
    } else if (videoPlayerRef && videoPlayerRef.current) {
      localStorage.setItem(
        "endTime",
        JSON.stringify(videoPlayerRef.current.getCurrentTime()),
      )
    }

    clearMediaRefs()
    dispatch(playNext())
  }

  const onPreviousClick = () => {
    scrubberUpdate(0)
  }

  const onRemoveFromQueue = index => {
    dispatch(removeElement(index))
  }

  const onPlaying = mediaItem => {
    if (currentTimeTimer) clearInterval(currentTimeTimer)
    currentTimeTimer = setInterval(() => {
      if (
        audioPlayerRef &&
        audioPlayerRef.current &&
        mediaItem &&
        mediaItem.type === "audio"
      ) {
        setCurrentTimeWrapper(audioPlayerRef.current.currentTime)
      } else if (
        videoPlayerRef &&
        videoPlayerRef.current &&
        mediaItem &&
        mediaItem.type === "video"
      ) {
        setCurrentTimeWrapper(videoPlayerRef.current.getCurrentTime())
      } else {
        setCurrentTimeWrapper(0)
      }
    }, 1000)
    playCurrent(mediaItem)
  }

  const scrubberUpdate = newTime => {
    if (audioPlayerRef && audioPlayerRef.current) {
      audioPlayerRef.current.currentTime = newTime
      setCurrentTimeWrapper(audioPlayerRef.current.currentTime)
    }
    if (
      videoPlayerRef &&
      videoPlayerRef.current &&
      videoPlayerRef.current.getDuration()
    ) {
      videoPlayerRef.current.seekTo(newTime, "seconds")
      setCurrentTimeWrapper(newTime)
      playCurrent(mediaPlayerState.currentMediaItem)
    }
  }

  const onClearQueueClick = () => {
    dispatch(clearQueue())
  }

  const onClearAndCloseClick = () => {
    dispatch(clearQueue())
    onSkipClick()
  }

  const setCurrentTimeWrapper = currentTime => {
    localStorage.setItem("currentTime", JSON.stringify(currentTime))
    setCurrentTime(currentTime)
  }

  const onVideoError = e => {
    console.error("Video error!", e)
  }

  const onVideoPlaying = () => {
    if (initialLoad) {
      const savedTime = JSON.parse(localStorage.getItem("currentTime"))
      if (videoPlayerRef && videoPlayerRef.current) {
        videoPlayerRef.current.seekTo(savedTime, "seconds")
      }
      initialLoad = false
    }

    if (!currentTimeTimer) {
      currentTimeTimer = setInterval(() => {
        if (
          videoPlayerRef &&
          videoPlayerRef.current &&
          mediaPlayerState.currentMediaItem &&
          mediaPlayerState.currentMediaItem.type === "video"
        ) {
          setCurrentTimeWrapper(videoPlayerRef.current.getCurrentTime())
        } else {
          setCurrentTimeWrapper(0)
        }
      }, 1000)
    }
  }

  const onVideoPause = () => {}

  const handleClickFullscreen = () => {
    // @ts-ignore
    screenfull.request(findDOMNode(videoPlayerRef.current))
  }

  const playerStyle = {
    position: "fixed",
    left: 0,
    bottom: 0,
    height: "100px",
    width: "100%",
    textAlign: "center",
    color: "white",
    backgroundColor: "#2d2d2d",
  }

  const metaDataStyle = {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "space-between",
  }

  if (!mediaPlayerState.queue.length && !mediaPlayerState.currentMediaItem) {
    playerStyle.visibility = "hidden"
  }

  useEffect(() => {
    const savedTime = JSON.parse(localStorage.getItem("currentTime"))
    if (mediaPlayerState.currentMediaItem) {
      if (mediaPlayerState.currentMediaItem.type === "audio") {
        audioPlayerRef.current.src =
          mediaPlayerState.currentMediaItem?.streamingUrl
        audioPlayerRef.current.onended = () => {
          onSkipClick()
        }
        audioPlayerRef.current.currentTime = savedTime
        setCurrentTime(savedTime)
        if (mediaPlayerState.playerState === "playing") {
          onPlaying(mediaPlayerState.currentMediaItem)
        }
      } else if (mediaPlayerState.currentMediaItem.type === "video") {
        setCurrentTime(savedTime)
      }
    }

    let w = watch(store.getState, "mediaPlayer", isEqual)
    store.subscribe(
      w((newVal, oldVal, objectPath) => {
        if (
          oldVal.value.currentMediaItem?.streamingUrl !==
          newVal.value.currentMediaItem?.streamingUrl
        ) {
          clearMediaRefs()

          if (oldVal.value.currentMediaItem) {
            let interactionResult = "play"
            let partialFraction = oldVal.value.currentMediaItem.duration / 10
            let endTime = JSON.parse(localStorage.getItem("endTime"))

            if (endTime < partialFraction) {
              interactionResult = "skip"
            } else if (
              endTime <
              oldVal.value.currentMediaItem.duration - partialFraction
            ) {
              interactionResult = "partial"
            }

            postUserMediaInteraction(
              oldVal.value.currentMediaItem.bandId,
              "media_play",
              oldVal.value.currentMediaItem.postId,
              oldVal.value.currentMediaItem.type,
              interactionResult,
            )
          }

          if (newVal.value.currentMediaItem) {
            postUserMediaInteraction(
              newVal.value.currentMediaItem.bandId,
              "media_play",
              newVal.value.currentMediaItem.postId,
              newVal.value.currentMediaItem.type,
            )

            if (newVal.value.currentMediaItem.type === "audio") {
              audioPlayerRef.current.src =
                newVal.value.currentMediaItem?.streamingUrl
              audioPlayerRef.current.onended = () => {
                onSkipClick()
              }
              if (newVal.value.playerState === "playing") {
                onPlaying(newVal.value.currentMediaItem)
                return
              }
            } else {
              audioPlayerRef.current.src = ""
            }
          }
        }

        if (
          newVal.value.playerState &&
          newVal.value.playerState !== oldVal.value.playerState
        ) {
          if (newVal.value.playerState === "playing") {
            onPlaying(newVal.value.currentMediaItem)
          }

          if (
            newVal.value.playerState === "paused" ||
            newVal.value.playerState === "idle"
          ) {
            clearInterval(currentTimeTimer)
            pauseCurrent(newVal.value.currentMediaItem)
          }

          if (newVal.value.playerState === "idle") {
            audioPlayerRef.current.src = ""
          }
        }
      }),
    )
  }, [audioPlayerRef, videoPlayerRef])

  videoAutoplay = mediaPlayerState.playerState === "playing"

  return (
    <div
      style={playerStyle}
      className="bg-gray-300 text-sm lg:px-4 py-2 rounded-sm text-gray-700 grid lg:grid-cols-3 grid-cols-1 gap-12"
    >
      <div
        className={`overflow-hidden flex lg:block ${
          mobileView === "details" ? "" : "hidden"
        }`}
      >
        <div className={`${isMobile ? "grid grid-cols-12" : ""}`}>
          {isMobile && <div className="col-span-1"></div>}

          <div className={`${isMobile ? "col-span-10 flex" : "flex"}`}>
            {mediaPlayerState.currentMediaItem &&
            mediaPlayerState.currentMediaItem.type === "audio" ? (
              <>
                <img
                  src={mediaPlayerState.currentMediaItem.coverImageSmall}
                  style={{ maxHeight: "85px" }}
                />
                <div className="ml-2" style={metaDataStyle}>
                  <div className="font-bold">
                    {mediaPlayerState.currentMediaItem.title}
                  </div>
                  <div>
                    by{" "}
                    <Link
                      to={mediaPlayerState.currentMediaItem.localPath}
                      className="font-bold"
                    >
                      {mediaPlayerState.currentMediaItem.bandName}
                    </Link>
                  </div>
                  <div>
                    from{" "}
                    <Link
                      className="font-bold"
                      to={mediaPlayerState.currentMediaItem.nadaPath}
                    >
                      {mediaPlayerState.currentMediaItem.albumName}
                    </Link>
                  </div>
                </div>
              </>
            ) : mediaPlayerState.currentMediaItem &&
              mediaPlayerState.currentMediaItem.type === "video" ? (
              <>
                <ReactPlayer
                  url={mediaPlayerState.currentMediaItem.streamingUrl}
                  playing={videoAutoplay}
                  muted={false}
                  width="50%"
                  height="75px"
                  controls={false}
                  onError={onVideoError}
                  onEnded={onSkipClick}
                  onPlay={onVideoPlaying}
                  onPause={onVideoPause}
                  ref={videoPlayerRef}
                />
                {screenfull.isEnabled && (
                  <button
                    onClick={handleClickFullscreen}
                    className="ml-1 self-start"
                  >
                    <ArrowsFullscreen />
                  </button>
                )}
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          {isMobile && (
            <div className="col-span-1">
              <button
                className="text-gray-500"
                style={{ transform: "translate(-21px, 30px) rotate(-90deg)" }}
                onClick={e => setMobileView("controls")}
              >
                CONTROLS
              </button>{" "}
            </div>
          )}
        </div>
      </div>
      <div className={`${mobileView === "controls" ? "" : "hidden"}`}>
        <div className={`${isMobile ? "grid grid-cols-12" : ""}`}>
          {isMobile && (
            <div className="col-span-1">
              <button
                className="text-gray-500"
                style={{ transform: "translate(-17px, 30px) rotate(90deg)" }}
                onClick={e => setMobileView("details")}
              >
                DETAILS
              </button>
            </div>
          )}

          <div className={`${isMobile ? "col-span-10" : ""}`}>
            <audio ref={audioPlayerRef} />{" "}
            <div style={{ display: "flex" }}>
              {audioPlayerRef && audioPlayerRef.current
                ? timeToMinutesSeconds(currentTime)
                : "-:--"}
              {audioPlayerRef &&
              audioPlayerRef.current &&
              mediaPlayerState &&
              mediaPlayerState.currentMediaItem ? (
                <Scrubber
                  current={currentTime}
                  max={mediaPlayerState.currentMediaItem.duration}
                  playerCallback={scrubberUpdate}
                />
              ) : (
                <div
                  className="scrubber"
                  style={{
                    background: `linear-gradient(90deg, #dbdbdb 0% 0, #dbdbdb 0 100%)`,
                  }}
                ></div>
              )}
              {mediaPlayerState.currentMediaItem
                ? timeToMinutesSeconds(
                    mediaPlayerState.currentMediaItem.duration,
                  )
                : "-:-"}
            </div>
            <div>
              <button
                onClick={e => onPreviousClick()}
                className="mr-2 focus:outline-none"
              >
                <SkipBackwardFill size={size} />
              </button>
              <button
                onClick={e => onPlayOrPauseClick()}
                className="focus:outline-none"
              >
                {mediaPlayerState.playerState === "playing" ? (
                  <PauseFill size={size} />
                ) : (
                  <PlayFill size={size} />
                )}
              </button>
              <button
                onClick={e => onSkipClick()}
                className="ml-2 focus:outline-none"
              >
                <SkipForwardFill size={size} />
              </button>
              <div
                className="font-bold leading-none"
                dangerouslySetInnerHTML={{
                  __html: mediaPlayerState?.currentMediaItem?.title,
                }}
              ></div>
            </div>
          </div>

          {isMobile && (
            <div className="col-span-1">
              <button
                className="text-gray-500"
                style={{ transform: "translate(-2px, 30px) rotate(-90deg)" }}
                onClick={e => setMobileView("queue")}
              >
                QUEUE
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className={`h-full text-left lg:block ${
          mobileView === "queue" ? "" : "hidden"
        }`}
      >
        <div className={`${isMobile ? "grid grid-cols-12" : ""}`}>
          {isMobile && (
            <div className="col-span-1">
              <button
                className="text-gray-500"
                style={{ transform: "translate(-28px, 33px) rotate(90deg)" }}
                onClick={e => setMobileView("controls")}
              >
                CONTROLS
              </button>
            </div>
          )}

          <div
            className={`${isMobile ? "col-span-10" : ""}`}
            style={{ marginTop: "-5px" }}
          >
            <div className="flex justify-between">
              <div>
                {mediaPlayerState.queue.length > 0 ? (
                  <div className="underline">
                    Queue
                    <button
                      className="pl-2 text-xs"
                      onClick={onClearQueueClick}
                    >
                      clear &times;
                    </button>
                  </div>
                ) : (
                  <span>Queue is empty</span>
                )}
              </div>
              <div>
                <button
                  className={clsx(styles.closeBtn, "focus:outline-none")}
                  onClick={onClearAndCloseClick}
                >
                  &times;
                </button>
              </div>
            </div>

            <ul
              className="text-sm overflow-y-scroll"
              style={{ height: "70px" }}
            >
              {mediaPlayerState.queue.map((content, index) => (
                <li key={`queue-${content.title}`} style={{ display: "flex" }}>
                  <span
                    className="text-xs"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <XCircle
                      className="mr-1 cursor-pointer"
                      style={{ display: "inline-block" }}
                      onClick={() => onRemoveFromQueue(index)}
                    />
                    {timeToMinutesSeconds(content.duration)}{" "}
                    {content.type === "audio" ? (
                      <>
                        <span
                          className="font-bold"
                          dangerouslySetInnerHTML={{ __html: content.title }}
                        />{" "}
                        by {content.bandName}
                      </>
                    ) : (
                      <span
                        className="font-bold"
                        dangerouslySetInnerHTML={{ __html: content.title }}
                      />
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          {isMobile && <div className="col-span-1"></div>}
        </div>
      </div>
    </div>
  )
}

export default UniversalPlayer
