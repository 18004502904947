import React, { useContext, useState } from "react"
import styles from "./ScenePicker.module.scss"
import { UserContext } from "../../../HomeApp"
import { mutationRequest } from "../../../../util/request"
import { useQueryClient } from "react-query"

const ScenePicker = ({ user = null }) => {
  const [showScenePicker, setShowScenePicker] = useState(false)
  const { scene } = useContext(UserContext)
  const queryClient = useQueryClient()

  const SCENE_OPTIONS = [
    {
      value: "new york city",
      label: "New York City",
    },
  ]

  const handleUpdateUserScene = async newScene => {
    try {
      await mutationRequest({
        path: `/api/v1/users/${user.id}`,
        options: { method: "PUT" },
        data: { user: { scene_list: newScene } },
      })
      queryClient.invalidateQueries("user")
      setShowScenePicker(false)
    } catch (error) {
      console.log("Error updating user data: ", error)
    }
  }

  return (
    <>
      <button
        className="capitalize focus:outline-none font-bold ml-2 mt-1 text-green-700"
        onClick={e => setShowScenePicker(!showScenePicker)}
      >
        {scene} ^
      </button>
      {showScenePicker && (
        <>
          <div className={`sm:hidden ${styles.overlay}`}></div>

          <ul
            onMouseLeave={() => setShowScenePicker(false)}
            className={styles.menu}
          >
            {SCENE_OPTIONS.map(scene => (
              <li
                onClick={e => handleUpdateUserScene(scene.value)}
                key={scene.value}
                className={styles.item}
              >
                {scene.label}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  )
}

export default ScenePicker
