import React, { useEffect, useState } from "react"
import { PauseCircle, PlayCircle } from "react-bootstrap-icons"
import { useDispatch, useSelector } from "react-redux"
import { prependAndPlay, playOrPause } from "../../data/mediaPlayerSlice"
import { useBandPosts } from "../../hooks/useBandPosts"
import { compareByKey } from "../../util/utils"

const PreviewPlayer = ({
  bandId,
  width = "30%",
  height = "30%",
  className = "text-white",
}) => {
  const dispatch = useDispatch()
  const currentMediaItem = useSelector(
    // @ts-ignore
    state => state.mediaPlayer.value.currentMediaItem,
  )
  // @ts-ignore
  const playerState = useSelector(state => state.mediaPlayer.value.playerState)
  const isCurrentlyPlaying =
    playerState === "playing" && currentMediaItem.bandId === bandId
  const isCurrentlyQueued =
    playerState === "paused" && currentMediaItem.bandId === bandId
  const [, setAlbumName] = useState("")

  const onPlayClick = e => {
    dispatch(prependAndPlay(sortedSongs()))
  }
  const onPlayOrPauseClick = e => {
    dispatch(playOrPause())
  }
  const { isLoading, data } = useBandPosts(bandId, "songs")
  const sortedSongs = () => {
    return [...data.posts]
      .sort((a, b) => compareByKey(a, b, "track_number"))
      .map(song => ({
        ...song,
        album_name: song.album_name,
        cover_image_small: song.cover_image_small,
      }))
  }

  useEffect(() => {
    if (data && data.posts && data.posts.length > 0) {
      setAlbumName(data.posts[0].album_name)
    }
  }, [isLoading])

  if (isLoading || data.posts.length === 0) return null

  return isCurrentlyPlaying ? (
    <PauseCircle
      className={`cursor-pointer ${className}`}
      width={width}
      height={height}
      onClick={onPlayOrPauseClick}
    />
  ) : (
    <PlayCircle
      className={`cursor-pointer ${className}`}
      width={width}
      height={height}
      onClick={isCurrentlyQueued ? onPlayOrPauseClick : onPlayClick}
    />
  )
}

export default PreviewPlayer
