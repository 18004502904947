import { useQuery } from "react-query"
import { request } from "../util/request"

export function usePost(postId, fields) {
  return useQuery(["post", postId, fields], getPostById, { retry: false })
}

const getPostById = async ({ queryKey }) => {
  const [key, id, fields] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/posts/${id}.json`,
        params: { fields: fields },
      },
    ],
  })
}
