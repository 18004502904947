import React, { useEffect, useState } from "react"

import startPlay from "../../util/webrtc/startPlay"
import stopPlay from "../../util/webrtc/stopPlay"

function useGuestBroadcast({
  signalingURL = null,
  applicationName = null,
  streamName = null,
  onError = (error) => {}
}) {
  const [playSettings, setPlaySettings] = useState({
    signalingURL: signalingURL,
    applicationName: applicationName,
    streamName: streamName,
  })
  const [playStart, setPlayStart] = useState(false)
  const [playStarting, setPlayStarting] = useState(false)
  const [playStop, setPlayStop] = useState(false)
  const [playStopping, setPlayStopping] = useState(false)
  const [websocket, setWebsocket] = useState(null)
  const [peerConnection, setPeerConnection] = useState(null)
  const [audioTrack, setAudioTrack] = useState(null)
  const [videoTrack, setVideoTrack] = useState(null)
  const [connected, setConnected] = useState(false)
  const [guestStream, setGuestStream] = useState(null)
  const [ready, setReady] = useState(false)

  // Listen for changes in the play* flags in the playSettings store
  // and stop or stop playback accordingly
  useEffect(() => {
    if (playStart && !playStarting && !connected) {
      setPlayStart(false)
      setPlayStarting(true)
      startPlay(playSettings, websocket, {
        onError: error => {
          onError(error)
        },
        onConnectionStateChange: result => {
          setConnected(result.connected)
        },
        onSetPeerConnection: result => {
          setPeerConnection(result.peerConnection)
        },
        onSetWebsocket: result => {
          setWebsocket(result.websocket)
        },
        onPeerConnectionOnTrack: event => {
          if (event.track != null && event.track.kind != null) {
            if (event.track.kind === "audio") {
              setAudioTrack(event.track)
            } else if (event.track.kind === "video") {
              setVideoTrack(event.track)
            }
          }
        },
      })
    }
    if (playStarting && connected) {
      setPlayStarting(false)
    }

    if (playStop && !playStopping && connected) {
      setPlayStop(false)
      setPlayStopping(true)
      stopPlay(peerConnection, websocket, {
        onSetPeerConnection: result => {
          setPeerConnection(result.peerConnection)
        },
        onSetWebsocket: result => {
          setWebsocket(result.websocket)
        },
        onPlayStopped: () => {
          setConnected(false)
        },
      })
    }
    if (playStopping && !connected) {
      setPlayStopping(false)
    }
  }, [
    playSettings,
    peerConnection,
    websocket,
    connected,
    playStart,
    playStop,
    playStarting,
    playStopping,
  ])

  useEffect(() => {
    if (connected) {
      let newStream = new MediaStream()
      if (audioTrack != null) newStream.addTrack(audioTrack)

      if (videoTrack != null) newStream.addTrack(videoTrack)

      setGuestStream(newStream)
      setReady(!!audioTrack && !!videoTrack)
    } else {
      setReady(false)
      setGuestStream(null)
    }
  }, [audioTrack, videoTrack, connected])

  const startGuestBroadcast = () => {
    setPlayStart(true)
  }

  const stopGuestBroadcast = () => {
    setPlayStop(true)
  }

  return {
    // proper getters
    // actions
    startGuestBroadcast,
    stopGuestBroadcast,
    setPlaySettings,
    // state
    ready,
    guestStream,
    connected,
  }
}

export default useGuestBroadcast
