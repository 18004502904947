import React, { useEffect, useState } from "react"
import { handleUpdateBandData } from "../BandEditPage"

export const EditBandFormTextField = ({
  fieldData,
  value,
  setValue,
  bandId = null,
  onSubmit = null,
}) => {
  const [initialValue] = useState(value)
  const [localVal, setLocalVal] = useState(value)
  const [validationState, setValidationState] = useState(
    value ? "valid" : "idle",
  )
  const [validationError, setValidationError] = useState(null)

  const setRemoteValue = newVal => {
    if (newVal === value) {
      setValidationState("valid")
    } else {
      setValidationState("waiting")
      setValue(newVal)
    }
  }

  const handleResponse = response => {
    if (response.success) {
      setValidationState("valid")
      setValidationError(null)
    } else {
      setValidationState("invalid")
      setValidationError(response.errors.error)
    }
  }

  useEffect(() => {
    if (value !== initialValue) {
      let updateData = {}
      updateData[fieldData.field] = value
      setValidationState("validating")
      if (onSubmit) {
        onSubmit(updateData).then(handleResponse)
      } else {
        handleUpdateBandData(bandId, updateData).then(handleResponse)
      }
    }
  }, [bandId, fieldData.field, value])

  return (
    <div className="grid grid-cols-12 mt-10">
      <label
        className="block col-span-12 text-gray-900 text-xs uppercase font-bold"
        htmlFor={fieldData.name}
      >
        {fieldData.label}
      </label>
      <input
        className={`col-span-11 shadow appearance-none border rounded-sm w-full text-base py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
          validationState === "valid"
            ? "border-green-500"
            : validationState === "invalid"
            ? "border-red-500"
            : validationState === "validating"
            ? "border-yellow-500"
            : ""
        }`}
        type="text"
        name={fieldData.name}
        placeholder={fieldData.placeholder}
        required={fieldData.required}
        value={localVal}
        onFocus={e => setValidationState("waiting")}
        onChange={e => setLocalVal(e.target.value)}
        onBlur={e => setRemoteValue(localVal)}
      />
      <div className="col-span-1 pl-2 text-2xl">
        {validationState === "valid"
          ? "🤘"
          : validationState === "invalid"
          ? "👎"
          : validationState === "validating"
          ? "🤞"
          : validationState === "waiting"
          ? "🤔"
          : ""}
      </div>
      <div className="col-span-12 text-red-700 text-sm">{validationError}</div>
      {fieldData.notes && (
        <div
          className="col-span-12 pt-1 text-gray-600 text-sm"
          dangerouslySetInnerHTML={{ __html: fieldData.notes }}
        ></div>
      )}
    </div>
  )
}
