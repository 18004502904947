import React, { useState } from "react"
import { EditBandFormSelectField } from "./EditBandFormSelectField"

export const BandScene = ({ band }) => {
  const [bandScene, setBandScene] = useState(band?.scene_list)

  const SCENE_OPTIONS = [
    {
      value: null,
      label: "Choose a scene",
    },
    {
      value: "new york city",
      label: "New York City",
    },
  ]

  const notes = (
    <span>
      Being part of a scene helps your music and shows reach local people who
      actually show up. New York City only for now. Tell us what scene you want
      us to add{" "}
      <a
        className="text-blue-500"
        target="_blank"
        href="http://docs.google.com/forms/d/e/1FAIpQLSfUFEegmnAJKjhsnN8KH274nz1SrWpCc6aaD-44mWXvMm24jg/viewform"
        rel="noreferrer"
      >
        here
      </a>
      .
    </span>
  )

  const fieldData = {
    field: "scene_list",
    name: "scene_list",
    label: "Scene",
    placeholder: "The scene/musical region that you are part of",
    required: false,
    notes: notes,
  }

  return (
    <EditBandFormSelectField
      value={bandScene}
      setValue={setBandScene}
      options={SCENE_OPTIONS}
      fieldData={fieldData}
      bandId={band.id}
      isArray={false}
    />
  )
}
