import { useQuery } from "react-query"
import { request } from "../util/request"

export function useComments(postId, page = 1, perPage = 5, filter = null) {
  return useQuery(
    ["comments", postId, page, perPage, filter],
    getCommentsForPostId,
    {
      keepPreviousData: true,
    },
  )
}

const getCommentsForPostId = async ({ queryKey }) => {
  const [key, id, page, perPage, filter] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/posts/${id}/comments.json`,
        params: { per_page: perPage, page: page, filter: filter },
      },
    ],
  })
}
