import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import { useDispatch, useSelector } from "react-redux"
import { prependAndPlay, playOrPause } from "../../data/mediaPlayerSlice"
import { PauseCircle, PlayCircle } from "react-bootstrap-icons"
import { isMobile } from "react-device-detect"

export const SongSearchResult = ({ song }) => {
  const dispatch = useDispatch()
  const onPlayClick = e => {
    dispatch(prependAndPlay([song]))
  }
  const onPlayOrPauseClick = e => {
    dispatch(playOrPause())
  }

  const currentMediaItem = useSelector(
    // @ts-ignore
    state => state.mediaPlayer.value.currentMediaItem,
  )
  // @ts-ignore
  const playerState = useSelector(state => state.mediaPlayer.value.playerState)
  const isCurrentlyPlaying =
    playerState === "playing" && currentMediaItem.postId === song.id

  return (
    <li className="col-span-1" style={{ height: "max-content" }}>
      <div
        style={{
          backgroundImage: `url(${song.external_photo_src})`,
          backgroundSize: "cover",
          aspectRatio: "1/1",
          minHeight: isMobile ? "156px" : "unset",
        }}
        className="w-full"
      >
        <div
          className="w-full h-full justify-center flex items-center"
          style={{
            background: "rgb(0,0,0,0.3)",
            paddingTop: isMobile ? "36px" : "unset",
            paddingBottom: isMobile ? "36px" : "unset",
          }}
        >
          {isCurrentlyPlaying ? (
            <PauseCircle
              className="text-white cursor-pointer"
              width="50%"
              height="50%"
              onClick={onPlayOrPauseClick}
            />
          ) : (
            <PlayCircle
              className="text-white cursor-pointer"
              width="50%"
              height="50%"
              onClick={onPlayClick}
            />
          )}
        </div>
      </div>

      <Link to={song.parent_path || song.nada_path}>
        <div className="font-bold leading-tight">{song.title}</div>

        <div className="leading-tight">
          <span className="text-black-800 text-sm">
            {SmartDateFormat({
              dateStr: song.active_date,
              includeTime: false,
            })}
          </span>{" "}
          <span className="text-gray-600 text-sm">by</span>{" "}
          <span className="text-black-800">{song.band_name}</span>
        </div>
      </Link>
    </li>
  )
}
