import React, { useState, useEffect } from "react"
import { DateDifferenceInWords } from "../../util/dates"

const CountdownBlock = (from, className = "") => {
  const [dateDiff, setDateDiff] = useState(
    DateDifferenceInWords(new Date(from), new Date()),
  )

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const newDateDiff = DateDifferenceInWords(new Date(from), new Date())
      setDateDiff(newDateDiff)
    }, dateDiff.updateInMS || 60000)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [from, dateDiff])

  return <span className={className}>{dateDiff.fullText}</span>
}

export default CountdownBlock
