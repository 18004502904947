import React, { useContext, useState } from "react"
import { useReactions } from "../../hooks/useReactions"
import UserAvatar from "../shared/Header/UserAvatar"
import { Spinner } from "../shared/Spinner"
import { UserContext } from "../HomeApp"

const ReactedUsers = ({
  contentId,
  contentType,
  bandId,
  filter,
  className,
}) => {
  const [viewState, setviewState] = useState("avatar")
  const { user } = useContext(UserContext)
  const isOwner = user && user.band && user.band.id === bandId
  const { isLoading, data } = useReactions(
    contentType,
    contentId,
    filter,
    false,
  )

  return (
    <div className={className}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <button
            className="focus:outline-none italic text-blue-600 text-sm mb-2"
            onClick={() => {
              setviewState(viewState === "avatar" ? "text" : "avatar")
            }}
          >
            show{" "}
            {viewState === "avatar"
              ? isOwner
                ? "names and emails"
                : "names"
              : "avatars"}
          </button>
          <ul
            className={`flex flex-wrap ${
              viewState === "avatar" ? "gap-2" : "flex-col"
            }`}
          >
            {data.users.map(user => (
              <li key={user.id}>
                {viewState === "avatar" ? (
                  <UserAvatar
                    handleClick={() => {}}
                    size="small"
                    user={user}
                    inHeader={false}
                  />
                ) : (
                  <span className="text-sm text-gray-700">
                    {isOwner ? `${user.name} <${user.email}>` : user.name}
                  </span>
                )}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

export default ReactedUsers
