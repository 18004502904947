import React, { useState } from "react"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const Spotify = ({ band }) => {
  const [spotifyUrl, setSpotifyUrl] = useState(band.spotify_url || "")

  const fieldData = {
    field: "spotify_url",
    name: "spotifyUrl",
    label: "Your Spotify Url",
    placeholder: "Spotify URL",
    required: false,
    notes:
      "we don't sync data from Spotify, but we do provide a link to your spotify page if you add your URL here. It should look like: https://open.spotify.com/artist/5MyxZULyqTUV0119j6ocEE",
  }

  return (
    <EditBandFormTextField
      fieldData={fieldData}
      value={spotifyUrl}
      setValue={setSpotifyUrl}
      bandId={band.id}
    />
  )
}
