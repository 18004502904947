import React from "react"
import { HomeScreenBanner } from "./HomeScreenBanner"
import styles from "../HomeScreen.module.scss"
import { HomePageButton } from "../../shared/Button"
import { PromotionModule } from "./../components/PromotionModule"
import { Link } from "react-router-dom"
import ShowOfTheWeekSidebar from "./ShowOfTheWeekSidebar"
import { own_bandnada_path } from "../../../routes"

const SellIt = ({ showDialog, setShowDialog }) => {
  return (
    <div>
      <HomeScreenBanner showDialog={showDialog} setShowDialog={setShowDialog} />

      <SellItGeneralPitch />

      <ShowOfTheWeekSidebar className="mb-6" />

      <SellItGeneralVideo />

      <PromotionModule />

      <SellItJoinNow showDialog={showDialog} setShowDialog={setShowDialog} />

      <SellItEventsVideo />

      <SellItOwnBandNada
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />

      <Boring />
    </div>
  )
}

export const SellItGeneralPitch = () => {
  return (
    <div className={styles.contentContainer}>
      <section className={`${styles.section} ${styles.condensed}`}>
        <div className="flex flex-col items-center ">
          <div className="mb-6 text-center">
            <h2 className="text-3xl leading-tight font-black">
              Built for musicians and fans.
            </h2>
            <h2 className="text-3xl leading-tight font-black">
              Free for all to use.
            </h2>
          </div>
          <p className="text-xl mb-4 text-center">
            Post your in-person events and invite your fans.
            <br />
            Find out when and where your favorite local bands are playing.
            <br />
            No ads. No influencers. No venture capitalists.
          </p>
        </div>
      </section>
    </div>
  )
}

export const SellItGeneralVideo = () => {
  return (
    <section className={`text-center`}>
      <div className="mb-6">
        <h3 className="text-3xl leading-tight font-black">
          <Link className="underline" to="/b/avse">
            A Very Special Episode
          </Link>{" "}
          tells us about bandNada
        </h3>
      </div>
      <div>
        <div className={`${styles.youtube_container}`}>
          <iframe
            className={styles.youtube_iframe}
            src="https://www.youtube.com/embed/VArBN5Nu-u0"
            title="A Very Special Episode Tells Us About bandNada"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </div>
    </section>
  )
}

export const SellItEventsVideo = () => {
  return (
    <section className={`text-center`}>
      <div className="mb-6">
        <h3 className="text-3xl leading-tight font-black">
          <Link className="underline" to="/b/vamanos">
            Vamanos
          </Link>{" "}
          talks about bandNada events
        </h3>
      </div>
      <div>
        <div className={`${styles.youtube_container}`}>
          <iframe
            className={styles.youtube_iframe}
            src="https://www.youtube.com/embed/K2z22wQNtG4"
            title="Vamanos Tells You About bandNada"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </div>
    </section>
  )
}

export const SellItJoinNow = ({ showDialog, setShowDialog }) => {
  return (
    <div className={styles.contentContainer}>
      <section className={`${styles.section}`}>
        <div className="flex flex-col items-center">
          <h3 className="text-3xl md:text-5xl md:leading-tight font-black mb-4">
            Join now, it's free
          </h3>
          <h5
            className="mb-8 text-center text-xl"
            style={{ maxWidth: "610px" }}
          >
            Actually free: no advertising, no investors to pay back, supported
            by your tips only.
            <br />
            In fact, bandNada members earn shares in bandNada just from using
            the platform. bandNada is 100% owned by the community that uses it.{" "}
            <Link className="text-blue-600" to={own_bandnada_path()}>
              Learn More
            </Link>
          </h5>
          <HomePageButton
            onClick={_ => setShowDialog(!showDialog)}
            active={true}
          >
            Sign Up
          </HomePageButton>
        </div>
      </section>
    </div>
  )
}

export const SellItOwnBandNada = ({ showDialog, setShowDialog }) => {
  return (
    <div className={styles.contentContainer}>
      <section className={`${styles.section}`}>
        <div className="flex flex-col items-center">
          <h3 className="text-3xl md:text-5xl md:leading-tight font-black mb-4">
            Own bandNada!
          </h3>
          <h5
            className="mb-8 text-center text-xl"
            style={{ maxWidth: "610px" }}
          >
            When you use bandNada you earn shares in the company, which means
            that bandNada is owned by the community that uses it instead of by
            the ultra-wealthy.
            <div className="mt-4">
              <Link className="text-blue-600" to={own_bandnada_path()}>
                Learn More
              </Link>
            </div>
          </h5>
        </div>
      </section>
    </div>
  )
}

export const Boring = () => {
  return (
    <div className={styles.contentContainer}>
      <section className={`${styles.section}`}>
        <div className="flex flex-col items-center">
          <h3 className="text-3xl md:leading-tight font-black mb-4">
            Attention this is BORING!
          </h3>
          <h5
            className="mb-8 text-center text-xl"
            style={{ maxWidth: "610px" }}
          >
            When you use bandNada you agree to our{" "}
            <a
              className="text-blue-600"
              target="_blank"
              href="/terms_of_service"
            >
              Terms of Service
            </a>
            , and our{" "}
            <a className="text-blue-600" target="_blank" href="/privacy">
              Privacy Policy
            </a>
            .
          </h5>
        </div>
      </section>
    </div>
  )
}

export default SellIt
