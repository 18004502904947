import React, { useState } from "react"
import { Link } from "react-router-dom"
import { ReactComponent as LogoIcon } from "../../../icons/bandnada-logo.svg"
import { AuthenticationDialog } from "../AuthenticationDialog"
import NavLink from "./NavLink"
import { Search } from "react-bootstrap-icons"
import { useHistory } from "react-router"
import UserMenu from "./UserMenu"
import { isMobile } from "react-device-detect"
import { UserNotification } from "./UserNotification"
import ScenePicker from "./ScenePicker"
import {
  discover_path,
  go_live_path,
  live_now_path,
  root_path,
} from "../../../routes"

const Header = ({ user = null, query = "", standAlone = false }) => {
  const [showDialog, setShowDialog] = useState(false)

  return (
    <header className="w-full lg:fixed bg-white top-0 z-10">
      <div className="lg:grid lg:grid-cols-18 flex flex-wrap justify-between sm:items-center border-b-2 border-black bg-white z-10 py-3 px-3 lg:px-4">
        <div className="lg:col-span-5 xl:col-span-5 mt-2 lg:mt-0">
          <div className="flex">
            {standAlone ? (
              <a href={root_path()}>
                <LogoIcon className="h-6" />
              </a>
            ) : (
              <Link to={root_path()}>
                <LogoIcon className="h-6" />
              </Link>
            )}
            <ScenePicker user={user} />
          </div>
        </div>

        <div className="lg:col-span-6 xl:col-span-7 w-full order-3 sm:order-none mt-4 lg:mt-0 relative">
          {!standAlone && <SearchField query={query} />}
        </div>

        {user ? (
          <nav className="lg:col-span-7 xl:col-span-6 flex justify-end items-center">
            <NavLink
              className="hidden sm:block leading-none"
              path={discover_path()}
              content="Discover"
            />
            <NavLink
              className="hidden sm:block leading-none"
              path={live_now_path()}
              content="Live Now"
            />

            {!isMobile && (
              <NavLink
                path={go_live_path()}
                content="Go Live"
                className="leading-none"
              />
            )}

            <UserMenu user={user} />
          </nav>
        ) : (
          <nav className="lg:col-span-7 xl:col-span-6 flex justify-between w-full">
            <div>
              <NavLink
                className="leading-none pl-0"
                path={discover_path()}
                content="Discover"
              />
              <NavLink
                className="leading-none"
                path={live_now_path()}
                content="Live Now"
              />
            </div>
            <div>
              <button
                className="font-bold text-black-800 focus:outline-none hover:border-b-2 hover:border-black hover:opacity-75 cursor-pointer"
                onClick={e => setShowDialog(!showDialog)}
              >
                Sign Up / Log In
              </button>
            </div>
          </nav>
        )}
      </div>
      {showDialog ? <AuthenticationDialog /> : null}
      {user && <UserNotification userId={user.id} />}
    </header>
  )
}

const SearchField = ({ query }) => {
  const [searchField, setSearchField] = useState(query)
  let history = useHistory()

  const submitSearchQuery = e => {
    e.preventDefault()
    history.push(`/search?q=${searchField}`)
  }

  const submitSearchOnEnter = e => {
    var code = e.keyCode ? e.keyCode : e.which
    if (code === 13) {
      submitSearchQuery(e)
    }
  }

  return (
    <>
      <input
        type="text"
        name="searchQuery"
        placeholder="Search everything"
        value={searchField}
        className="appearance-none border border-gray-500 rounded w-full text-base py-2 px-2 text-black leading-tight focus:outline-none inline"
        onChange={e => setSearchField(e.target.value)}
        onKeyPress={e => submitSearchOnEnter(e)}
      />
      <Search
        className="absolute cursor-pointer"
        style={{ top: "12px", right: "8px" }}
        onClick={e => submitSearchQuery(e)}
      />
    </>
  )
}

export default Header
