import React, { useRef, useState, useLayoutEffect } from "react"
import useComponentSize from "@rehooks/component-size"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import { PlayCircle } from "react-bootstrap-icons"
import FreeTextFormat from "../shared/FreeTextFormat"
import CommentScreen from "../comments/CommentScreen"
import { PostListItemStyles } from "./constants"
import { CopyPostUrlLink } from "./CopyPostUrlLink"
import { ShowHideButton } from "../shared/Button"
import { ReactionBlock } from "./ReactionBlock"
import RelatedPosts from "./RelatedPosts"

export const VideoListItem = ({
  video,
  setIsExpanded,
  updateStatus,
  adminMode = false,
}) => {
  const [postStatus, setPostStatus] = useState(video.status)
  const [height, setHeight] = useState(0)

  const ref = useRef(null)
  let size = useComponentSize(ref)

  useLayoutEffect(() => {
    setHeight(size.height)
  })

  const containerStyle = {
    height: height,
    position: "relative",
  }

  const playBtnStyle = {
    color: "white",
    position: "absolute",
  }

  return (
    <div className="grid grid-cols-12">
      <div
        className={`${PostListItemStyles.contentDiv} flex justify-center items-center`}
        style={containerStyle}
      >
        <img
          ref={ref}
          src={video.external_photo_src}
          width="100%"
          height="auto"
          title={video.title}
          alt={video.title}
          className="cursor-pointer"
          style={playBtnStyle}
          onClick={e => setIsExpanded(true)}
        />
        <PlayCircle
          className="cursor-pointer"
          style={playBtnStyle}
          size="64"
          onClick={e => setIsExpanded(true)}
        />
      </div>
      <div className={PostListItemStyles.metaDiv}>
        <div className="flex">
          <div className="flex items-start">
            <h2
              className={`${PostListItemStyles.header} cursor-pointer`}
              dangerouslySetInnerHTML={{ __html: video.title }}
              onClick={e => setIsExpanded(true)}
            ></h2>
            <CopyPostUrlLink nadaUrl={video.nada_url} renderMode="icon" />
          </div>
        </div>
        <Link className={PostListItemStyles.bandName} to={video.local_path}>
          {video.band_name}
        </Link>
        <h3 className={PostListItemStyles.postDate}>
          Released:&nbsp;
          {SmartDateFormat({ dateStr: video.active_date, includeTime: false })}
        </h3>
        <div className={PostListItemStyles.externalLink}>
          <a href={video.external_url} target="_blank" rel="noreferrer">
            view on&nbsp;
            {video.view_on}
          </a>
        </div>
        <div className={PostListItemStyles.description}>
          <FreeTextFormat text={video.description} expandable={false} />
        </div>
        {adminMode && (
          <ShowHideButton
            updateStatus={updateStatus}
            postStatus={postStatus}
            setPostStatus={setPostStatus}
          />
        )}
        <div className="mt-4">
          <RelatedPosts postId={video.id} />
        </div>
        <div className="mt-6">
          <ReactionBlock
            contentType="Post"
            contentId={video.id}
            postId={video.id}
          />
        </div>

        <CommentScreen postId={video.id} />
      </div>
    </div>
  )
}
