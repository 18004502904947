import { api_v1_band_posts_path, api_v1_band_post_path } from "../../routes"
import { mutationRequest } from "../request"
import { DirectUpload } from "@rails/activestorage"

const EXTENSION_FILE_TYPES = {
  "image/jpeg": "jpg",
  "image/gif": "gif",
  "image/png": "png",
  "image/svg+xml": "svg",
  "image/webp": "webp",
}

export const uploadImages = (imagePreviews, setImagePreviews) => {
  let signedIds = []
  let errors = []
  let resolved = 0
  const uploadPromise = new Promise(resolve => {
    imagePreviews.forEach((imagePreview, index) => {
      if (imagePreview.signedId) {
        // This image has already been stored - doesn't need re-uploaded
        const newImagePreviewHash = { ...imagePreview, isUploaded: true }
        setImagePreviews(currentImagePreviews => [
          ...currentImagePreviews.slice(0, index),
          newImagePreviewHash,
          ...currentImagePreviews.slice(index + 1),
        ])
        resolved += 1
        signedIds[index] = imagePreview.signedId
        if (imagePreviews.length == resolved) {
          resolve({
            signedIds: signedIds,
            errors: errors,
          })
        }
      } else {
        // New image - upload it to the server now
        const upload = new DirectUpload(
          new File([imagePreview.file], imagePreview.filename.toLowerCase()),
          "/rails/active_storage/direct_uploads",
        )
        const newImagePreviewHash = { ...imagePreview, isUploading: true }
        setImagePreviews(currentImagePreviews => [
          ...currentImagePreviews.slice(0, index),
          newImagePreviewHash,
          ...currentImagePreviews.slice(index + 1),
        ])
        upload.create((error, blob) => {
          resolved += 1
          if (error) {
            console.error("error:", error)
            errors.push(error)
          }
          const newImagePreviewHash = {
            ...imagePreview,
            signedId: blob.signed_id,
            isUploading: false,
            isUploaded: true,
          }
          signedIds[index] = blob.signed_id
          setImagePreviews(currentImagePreviews => [
            ...currentImagePreviews.slice(0, index),
            newImagePreviewHash,
            ...currentImagePreviews.slice(index + 1),
          ])
          if (imagePreviews.length == resolved) {
            resolve({
              signedIds: signedIds,
              errors: errors,
            })
          }
        })
      }
    })
  })
  return uploadPromise
}

export const updateHeadlinePost = async (
  bandId,
  postId,
  postType,
  contentHash,
  relatedPostId,
  signedIds,
) => {
  try {
    const postPayload = {
      post: {
        post_type: postType,
        title: contentHash.headline_text,
        content_hash: contentHash,
        related_post_id: relatedPostId,
        attached_media: signedIds,
      },
    }
    let responseData = await mutationRequest({
      path: api_v1_band_post_path(bandId, postId),
      options: { method: "PATCH" },
      data: postPayload,
    })
    return responseData
  } catch (error) {
    return { error }
  }
}

export const createHeadlinePost = async (
  bandId,
  postType,
  contentHash,
  relatedPostId,
  signedIds,
) => {
  try {
    const postPayload = {
      post: {
        post_type: postType,
        title: contentHash.headline_text,
        content_hash: contentHash,
        related_post_id: relatedPostId,
        attached_media: signedIds,
      },
    }
    let responseData = await mutationRequest({
      path: api_v1_band_posts_path(bandId),
      options: { method: "POST" },
      data: postPayload,
    })
    return responseData
  } catch (error) {
    return { error }
  }
}

export const destroyPost = async (postId, bandId) => {
  return mutationRequest({
    path: `/api/v1/bands/${bandId}/posts/${postId}`,
    options: { method: "DELETE" },
  })
}

export const createEvent = async (bandId, postHash, userTags) => {
  try {
    return mutationRequest({
      path: `/api/v1/bands/${bandId}/posts`,
      options: { method: "POST" },
      data: {
        post: postHash,
        user_tags: userTags,
      },
    })
  } catch (error) {
    return { error }
  }
}

export const updateEvent = async (bandId, postId, postHash, userTags) => {
  try {
    return mutationRequest({
      path: `/api/v1/bands/${bandId}/posts/${postId}`,
      options: { method: "PUT" },
      data: {
        post: postHash,
        user_tags: userTags,
      },
    })
  } catch (error) {
    return { error }
  }
}

const getNewFilenameFor = (file, uploadFormat) => {
  const filenamePieces = file.name.toLowerCase().split(".")
  const fileExtension = EXTENSION_FILE_TYPES[file.type] || filenamePieces[-1]
  return `${filenamePieces[0]}_${uploadFormat}.${fileExtension}`
}

export const uploadEventImages = async (originalFile, croppedBlob) => {
  let croppedSignedId = null
  let originalSignedId = null
  const uploadPromise = new Promise(resolve => {
    const originalFilename = getNewFilenameFor(originalFile, "original")
    const originalUpload = new DirectUpload(
      new File([originalFile], originalFilename),
      "/rails/active_storage/direct_uploads",
    )
    originalUpload.create((error, blob) => {
      if (error) {
        console.error("error:", error)
        resolve({
          error:
            "Sorry there was an error uploading your image. Please try again or email support@bandnada.com if it keeps happening.",
        })
      } else {
        originalSignedId = blob.signed_id
        if (originalSignedId && (croppedSignedId || !croppedBlob)) {
          resolve({
            croppedSignedId: croppedSignedId,
            originalSignedId: originalSignedId,
          })
        }
      }
    })

    const croppedFilename = getNewFilenameFor(originalFile, "16x9")
    const croppedUpload = new DirectUpload(
      new File([croppedBlob], croppedFilename),
      "/rails/active_storage/direct_uploads",
    )
    croppedUpload.create((error, blob) => {
      if (error) {
        console.error("error:", error)
        resolve({
          error:
            "Sorry there was an error uploading your image. Please try again or email support@bandnada.com if it keeps happening.",
        })
      } else {
        croppedSignedId = blob.signed_id
      }
      if (originalSignedId && croppedSignedId) {
        resolve({
          croppedSignedId: croppedSignedId,
          originalSignedId: originalSignedId,
        })
      }
    })
  })
  return uploadPromise
}
