import React, { useEffect, useState } from "react"
import LocationSearchInput from "../../components/shared/fields/LocationSearchInput"
import { handleUpdateBandData } from "../BandEditPage"

export const BandLocation = ({ band }) => {
  const [bandLocation, setBandLocation] = useState(band?.location || null)
  const [validationState, setValidationState] = useState(
    bandLocation && bandLocation?.displayName ? "valid" : "idle",
  )
  const [validationError, setValidationError] = useState(null)

  const handleResponse = (response) => {
    if (response.success) {
      if (bandLocation?.displayName) {
        setValidationState("valid")
        setValidationError(null)
      } else {
        setValidationState("idle")
      }
    } else {
      setValidationState("invalid")
      setValidationError(response.errors.error)
    }
  }

  useEffect(() => {
    if (
      !bandLocation ||
      bandLocation?.displayName !== band.location?.displayName
    ) {
      setValidationState("validating")
      handleUpdateBandData(band.id, {
        location: bandLocation,
      }).then(handleResponse)
    }
  }, [band.id, bandLocation?.displayName])

  const fieldData = {
    field: "location",
    name: "bandLocation",
    label: `Location (required)`,
    placeholder: "The town/city that you are from",
    required: false,
  }

  return (
    <div className="grid grid-cols-12 mt-10">
      <label
        className="block col-span-12 text-gray-900 text-xs uppercase font-bold"
        htmlFor={fieldData.name}
      >
        {fieldData.label}
      </label>
      <div className="col-span-11">
        <LocationSearchInput
          initLocation={bandLocation}
          setLocation={setBandLocation}
          className={`col-span-11 shadow appearance-none border rounded-sm w-full text-base py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            validationState === "valid"
              ? "border-green-500"
              : validationState === "invalid"
                ? "border-red-500"
                : validationState === "validating"
                  ? "border-yellow-500"
                  : ""
          }`}
        />
      </div>
      <div className="col-span-1 pl-2 text-2xl">
        {validationState === "valid"
          ? "🤘"
          : validationState === "invalid"
            ? "👎"
            : validationState === "validating"
              ? "🤞"
              : validationState === "waiting"
                ? "🤔"
                : ""}
      </div>
    </div>
  )
}
