import React from "react"
import Dialog from "@reach/dialog"
import { Button, LinkButton } from "../shared/Button"

export const SuggestRedirect = ({
  bandName,
  bandPath,
  showDialog,
  setShowDialog,
}) => {
  const openDialog = () => setShowDialog(true)
  const closeDialog = () => setShowDialog(false)

  return (
    <Dialog
      isOpen={showDialog}
      onDismiss={closeDialog}
      aria-label="Suggest a redirect to main show page for viewer"
    >
      <h1 className="font-bold text-xl border-b-4 border-black">
        Glad you are enjoying {bandName}'s show!
      </h1>
      <p className="text-gray-800 text-base mt-4">
        The viewing experience is better on their page because you can
        participate in the chat, and support the artist.
      </p>
      <p className="text-gray-800 text-base">
        Would you like to go to&nbsp;
        <span className="font-bold">{bandName}'s</span> page?
      </p>
      <div className="grid grid-cols-2 mt-6">
        <div className="col-span-1">
          <LinkButton to={bandPath}>Yea, go to the fun!</LinkButton>
        </div>
        <div className="col-span-1">
          <Button bg="bg-gray-500" onClick={closeDialog}>
            I'll Stay Here :(
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
