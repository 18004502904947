import React, { useEffect, useState } from "react"
import { format } from "date-fns"
import { FeedSpinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { UserInteractionForm } from '../UserInteractionForm'
import { useBandPlayStats } from '../../hooks/useBandPlayStats'
import { UserInteractionStatCard } from '../UserInteractionStatCard'

export const UserInteractions = ({ bandId }) => {
  const [ start, setStart ] = useState(format(Date.parse('2018-01-01'), 'yyyy-MM-dd'))
  const [ end, setEnd ] = useState(format(Date.now(), 'yyyy-MM-dd'))
  const {
    status,
    data,
    error
  } = useBandPlayStats(bandId, start, end)
  
  const dateChangeHandler = (e) => {
    if (e.target.name === 'start') {
      setStart(e.target.value)
    } else {
      setEnd(e.target.value)
    }
  }

  return (
    <div>
      <h2 className="border-b-4 mt-4 mb-1 border-black font-bold text-xl uppercase">
        Play Stats
      </h2>
      <UserInteractionForm
        startDate={start}
        endDate={end}
        changeHandler={dateChangeHandler}
      />
      {status === "loading" ? (
        <FeedSpinner />
      ) : status === "error" ? (
        <RequestError error={error} />
      ) : status === "success" ? (
        <ul className="flex flex-col md:flex-row gap-5 mt-2 w-fit">
          <UserInteractionStatCard dataStat={data?.found_interactions?.play} type="FULL PLAYS" />
          <UserInteractionStatCard dataStat={data?.found_interactions?.partial} type="PARTIALS" />
          <UserInteractionStatCard dataStat={data?.found_interactions?.skip} type="SKIPS" />
        </ul>
      ) : null }
    </div>
  )
}
