import React, { useContext, useEffect, useState } from "react"
import { UserContext } from "../HomeApp"
import Header from "../shared/Header/Header"
import style from "../shared/PageStyle.module.css"
import { useFaqs } from "../../hooks/useFaqs"
import { RequestError } from "../shared/RequestError"
import { Spinner } from "../shared/Spinner"
import Heading from "../shared/Heading"
import Faq from "./Faq"
import Footer from "../shared/Footer/Footer"

const FaqPage = () => {
  const { user } = useContext(UserContext)
  const [faqData, setFaqData] = useState({})

  const { status, data, error } = useFaqs()

  useEffect(() => {
    if (status === "success") {
      setFaqData({
        title: "bandNada FAQ",
        rows: data.faqs,
      })
    }
  }, [status])

  let sectionName = null

  const setSectionName = newName => {
    sectionName = newName
    return null
  }

  return (
    <div className={style.rootElStyle}>
      <Header user={user} />
      <div className="grid grid-cols-12 w-full pb-2 mt-4 px-3 lg:px-4">
        <div className="col-span-12">
          <Heading level={2} type="sectionHeader">
            bandNada FAQ
          </Heading>
          {status === "loading" ? (
            <Spinner />
          ) : status === "error" ? (
            <RequestError error={error} />
          ) : (
            data.faqs.map(faq => (
              <div key={faq.id}>
                {sectionName !== faq.section_name ? (
                  <h2
                    className={`border-b-2 border-black font-bold text-xl ${sectionName &&
                      "mt-6"}`}
                  >
                    {faq.section_name}
                  </h2>
                ) : null}
                <Faq title={faq.title} content={faq.content} id={faq.id} />
                {setSectionName(faq.section_name)}
              </div>
            ))
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FaqPage
