import React from "react"

export function RequestError({ error }) {
  return (
    <div className="text-red-500">
      <img
        src="https://bandnada.s3.amazonaws.com/static/external_assets/web/bandnada_error_500_ruh_roh.gif"
        alt="Ruh Roh!"
        className="inline mr-3 w-20"
      />
      ⚠ Ruh Roh!! We hit some kind of nada error. We're on it though! Try
      reloading the page and if it keeps happening, check back later.
    </div>
  )
}
