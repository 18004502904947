import React, { useContext, useState } from "react"
import { UserContext } from "../../HomeApp"
import { AuthenticationDialog } from "../../shared/AuthenticationDialog"
import { Button } from "../../shared/Button"
import TaggableInput from "../../shared/fields/TaggableInput"
import ImageUpload from "../../ImageUpload"
import { DirectUpload } from "@rails/activestorage"
import { toast } from "react-toastify"
import { useMutation } from "react-query"
import { mutationRequest } from "../../../util/request"

const CommentForm = ({ postId }) => {
  const { user } = useContext(UserContext)
  const [showFullForm, setShowFullForm] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [imagePreview, setImagePreview] = useState(null)
  const [commentText, setCommentText] = useState("")
  const [imageFile, setImageFile] = useState(null)
  const [userTags, setUserTags] = useState([])
  const [showDialog, setShowDialog] = useState(false)
  const mutation = useMutation(mutationRequest)

  const submitComment = async e => {
    setIsSubmitting(true)
    e.preventDefault()
    if (imageFile) {
      const upload = new DirectUpload(
        new File([imageFile], "comment-image"),
        "/rails/active_storage/direct_uploads",
      )
      upload.create((error, blob) => {
        if (error) {
          console.error("error:", error)
          toast.error(
            "Sorry there was an error uploading your image. Please try again or email support@bandnada.com if it keeps happening.",
          )
        } else {
          const signedId = blob.signed_id
          runMutation(signedId)
        }
      })
    } else {
      runMutation()
    }
  }

  const runMutation = async (signedId = null) => {
    try {
      await mutation.mutate({
        options: { method: "POST" },
        path: `/api/v1/posts/${postId}/comments`,
        data: {
          comment: {
            comment_text: commentText,
            image: signedId,
          },
        },
      })
    } catch (e) {
      console.error("Failed to submit: ", e)
    }
    setIsSubmitting(false)
    setUserTags([])
    setImageFile(null)
    setImagePreview(null)
    setShowFullForm(false)
    setCommentText("")
  }

  return user ? (
    <>
      {showFullForm ? (
        <>
          <ImageUpload
            setImageFile={setImageFile}
            imagePreview={imagePreview}
            setImagePreview={setImagePreview}
            isSubmitting={isSubmitting}
          />
          <TaggableInput
            value={commentText}
            onChange={setCommentText}
            placeholder={"Add your comment here"}
            autoFocus={true}
            onFocus={e => setShowFullForm(true)}
            className="comment-screen"
          />
        </>
      ) : (
        <TaggableInput
          value={commentText}
          onChange={setCommentText}
          placeholder={"Add your comment here"}
          onFocus={e => setShowFullForm(true)}
          className="comment-screen"
        />
      )}

      {showFullForm && (
        <Button
          disabled={isSubmitting}
          className="mt-2"
          onClick={submitComment}
        >
          {isSubmitting ? "posting..." : "Post Comment"}
        </Button>
      )}
    </>
  ) : (
    <>
      <textarea
        className={`border w-full lg:px-2 px-4 h-12 focus:outline-none`}
        placeholder="You must be logged in to participate in the comments"
        onFocus={e => setShowDialog(true)}
      ></textarea>
      {showDialog && (
        <AuthenticationDialog
          explanation={`You must first sign up or log in to participate in the comments`}
        />
      )}
    </>
  )
}

export default CommentForm
