import { useInfiniteQuery, useQuery } from "react-query"
import { request } from "../util/request"

export const BAND_POSTS_QUERY_KEY = "posts"

export function useBandPosts(
  bandId,
  contentType,
  adminMode = false,
  page = 1,
  perPage = 30,
) {
  return useQuery(
    [BAND_POSTS_QUERY_KEY, bandId, contentType, adminMode, page, perPage],
    getPostsForBandId,
  )
}

export function useInfiniteBandPosts({
  bandId,
  contentType,
  adminMode,
  pageSize,
}) {
  const queryKey = [
    BAND_POSTS_QUERY_KEY,
    bandId,
    contentType,
    adminMode,
    null,
    pageSize,
  ]
  return useInfiniteQuery(queryKey, getPostsForBandId, {
    getNextPageParam: (lastPage, pages) => lastPage.next_page,
    enabled: bandId > 0,
  })
}

const getPostsForBandId = async ({ queryKey, pageParam = null }) => {
  const [key, bandId, contentType, adminMode, page, perPage] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands/${bandId}/posts`,
        params: {
          content_type: contentType,
          admin_mode: adminMode,
          page: page || pageParam,
          per_page: perPage,
        },
      },
    ],
  })
}
