import React, { useState, useRef, useEffect } from "react"
import { mungeSDPPublish } from "../../util/webrtc/mungeSDP"
import useGuestBroadcast from "./GuestBroadcast"
import AudioAnalyser from "../../util/webrtc/audioAnalyser"

function useBroadcaster({
  applicationName,
  streamName,
  wsURL,
  setAudioSelect,
  setVideoSelect,
  audioSelectRef,
  videoSelectRef,
  videoRef,
  audioAnalyserRef = null,
  isDebug,
  onGuestStreamError = error => {},
}) {
  const [streamInfo, setStreamInfo] = useState({
    applicationName: applicationName,
    streamName: streamName,
    sessionId: "[empty]",
  })
  const [_peerConnection, setPeerConnection] = useState(null)
  const [_wsConnection, setWsConnection] = useState(null)
  const [localStream, setLocalStream] = useState(null)
  const [activeStream, setActiveStream] = useState(null)
  const [remoteStream, setRemoteStream] = useState(null)
  const [sdpOutput, setSdpOutput] = useState("")
  const [userErrorMessage, _setUserErrorMessage] = useState(null)
  const [userSuccessMessage, _setUserSuccessMessage] = useState(null)
  const [guestStreamConnected, setGuestStreamConnected] = useState(false)

  let peerConnection = _peerConnection
  var peerConnectionConfig = { iceServers: [] }

  let wsConnection = _wsConnection
  var userData = { param1: "value1" }
  var videoBitrate = 300
  var audioBitrate = 160
  var videoFrameRate = "20"
  var userAgent = navigator.userAgent
  var newAPI = true // false
  let soundmeterRefresh = null
  let soundmeter = null
  let audioAnalyser = null

  navigator.getUserMedia =
    navigator.getUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.webkitGetUserMedia
  window.RTCPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection
  window.RTCIceCandidate =
    window.RTCIceCandidate ||
    window.mozRTCIceCandidate ||
    window.webkitRTCIceCandidate
  window.RTCSessionDescription =
    window.RTCSessionDescription ||
    window.mozRTCSessionDescription ||
    window.webkitRTCSessionDescription
  window.AudioContext = window.AudioContext || window.webkitAudioContext
  window.audioContext = new AudioContext()

  const setAudioDevice = e => {
    getStream()
  }

  const setVideoDevice = e => {
    getStream()
  }

  const setUserErrorMessage = message => {
    _setUserErrorMessage(message)
  }

  function pageReady() {
    userAgent = navigator.userAgent

    if (userAgent == null) {
      userAgent = "unknown"
    }

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(gotDevices)
        .then(getStream)
        .catch(errorHandler)
      newAPI = false
    } else if (navigator.getUserMedia) {
      navigator.getUserMedia(constraints, getUserMediaSuccess, errorHandler)
    } else {
      setUserErrorMessage("Your browser does not support getUserMedia API")
    }
  }

  function getStream() {
    if (window.stream) {
      window.stream.getTracks().forEach(function(track) {
        track.stop()
      })
    }

    debug("audioDevice: ", audioSelectRef?.current?.value)
    debug("videoDevice: ", videoSelectRef?.current?.value)

    var constraints = {
      audio: {
        autoGainControl: false,
        echoCancellation: false,
        googAutoGainControl: false,
        noiseSuppresion: false,
        deviceId: {
          exact: audioSelectRef?.current?.value,
        },
      },
      video: {
        width: { exact: 1280 },
        height: { exact: 720 },
        deviceId: { exact: videoSelectRef?.current?.value },
      },
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(getUserMediaSuccess)
      .catch(errorHandler)
  }

  function setTracks() {
    if (newAPI) {
      var localTracks = activeStream.getTracks()
      for (var localTrack of localTracks) {
        peerConnection.addTrack(localTrack, activeStream)
      }
    } else {
      peerConnection.addStream(activeStream)
    }
    setPeerConnection(peerConnection)
  }

  function replaceTracks(newStream) {
    if (newAPI) {
      let videoTrack = newStream.getVideoTracks()[0]
      let audioTrack = newStream.getAudioTracks()[0]
      peerConnection.getSenders().forEach(sender => {
        if (sender.track.kind === "audio") {
          sender.replaceTrack(audioTrack)
        }
        if (sender.track.kind === "video") {
          sender.replaceTrack(videoTrack)
        }
      })
    } else {
      // this really shouldn't be used
      peerConnection.replaceStream(newStream)
    }
    setPeerConnection(peerConnection)
  }

  function initializeAudioAnalyser(stream) {
    const canvas = audioAnalyserRef.current
    if (!canvas) return
    audioAnalyser = new AudioAnalyser(window.audioContext, stream, canvas)
  }

  function handlePeerNegotion(event) {
    // I don't think this is getting called since we're using replaceTrack now
    peerConnection
      .createOffer()
      .then(offer => {
        peerConnection.setLocalDescription(offer)
      })
      .catch(errorHandler)
    setPeerConnection(peerConnection)
  }

  function wsConnect(url) {
    wsConnection = new WebSocket(url)
    wsConnection.binaryType = "arraybuffer"

    wsConnection.onopen = function() {
      peerConnection = new RTCPeerConnection(peerConnectionConfig)

      if (peerConnection != null) {
        peerConnection.onicecandidate = gotIceCandidate
      } else {
        debug(
          "Failed to create new RTCPeerConnection. Config: ",
          peerConnectionConfig,
        )
        setUserErrorMessage(
          "Failed to start broadcast. Reload your page and try again please.",
        )
        return false
      }

      setTracks()

      peerConnection
        .createOffer()
        .then(gotDescription)
        .catch(errorHandler)
      setPeerConnection(peerConnection)
      return true
    }

    wsConnection.onmessage = function(evt) {
      //debug("wsConnection.onmessage: " + evt.data)

      var msgJSON = JSON.parse(evt.data)

      var msgStatus = Number(msgJSON["status"])
      var msgCommand = msgJSON["command"]

      if (msgStatus !== 200) {
        // debug(msgJSON["statusDescription"])
        setUserErrorMessage(msgJSON["statusDescription"])
        stopPublisher()
      } else {
        var sdpData = msgJSON["sdp"]
        if (sdpData !== undefined) {
          peerConnection
            .setRemoteDescription(new RTCSessionDescription(sdpData))
            .catch(errorHandler)
        }

        var iceCandidates = msgJSON["iceCandidates"]
        if (iceCandidates !== undefined) {
          for (var iceCandidate of iceCandidates) {
            peerConnection.addIceCandidate(new RTCIceCandidate(iceCandidate))
          }
        }
      }

      if (wsConnection != null) wsConnection.close()
      wsConnection = null
      setWsConnection(wsConnection)
      setPeerConnection(peerConnection)
    }

    wsConnection.onclose = function() {
      debug("wsConnection.onclose")
    }

    wsConnection.onerror = function(evt) {
      debug("wsConnection.onerror: " + JSON.stringify(evt))
      debug("WebSocket connection failed: " + wsURL)
      stopPublisher()
    }

    setWsConnection(wsConnection)
    return true
  }

  function getUserMediaSuccess(stream) {
    setLocalStream(stream)
    initializeAudioAnalyser(stream)
    setActiveStream(stream)
    try {
      videoRef.current.srcObject = stream
    } catch (error) {
      errorHandler("getUserMediaSuccess: ", error)
      videoRef.current.src = window.URL.createObjectURL(stream)
    }
  }

  function getRemoteMediaSuccess(remoteStream) {
    setRemoteStream(remoteStream)
    setActiveStream(remoteStream)
    peerConnection.onnegotiationneeded = handlePeerNegotion
    replaceTracks(remoteStream)
    try {
      videoRef.current.srcObject = remoteStream
    } catch (error) {
      errorHandler("getRemoteMediaSuccess: ", error)
      videoRef.current.src = window.URL.createObjectURL(remoteStream)
    }
  }

  function reactivateLocalStream() {
    setRemoteStream(null)
    setActiveStream(localStream)
    replaceTracks(localStream)
    videoRef.current.srcObject = localStream
  }

  function onGuestBroadcastError(error) {
    console.log("onGuestBroadcastError: ", error)
    onGuestStreamError(error)
  }

  const {
    startGuestBroadcast,
    stopGuestBroadcast,
    setPlaySettings,
    guestStream,
    connected,
    ready,
  } = useGuestBroadcast({ onError: onGuestBroadcastError })

  const setGuestStreamSettings = ({ wsURL, applicationName, streamName }) => {
    setPlaySettings({
      signalingURL: wsURL,
      applicationName: applicationName,
      streamName: streamName,
    })
  }

  useEffect(() => {
    if (ready) {
      getRemoteMediaSuccess(guestStream)
    }
    setGuestStreamConnected(connected)
  }, [ready])

  function startGuestStream() {
    if (!connected) startGuestBroadcast()
  }

  function stopGuestStream() {
    if (connected) {
      reactivateLocalStream()
      stopGuestBroadcast()
    }
  }

  function startPublisher() {
    if (wsConnect(wsURL)) {
      return true
    } else {
      return false
    }
  }

  function stopPublisher() {
    stopGuestStream()
    if (peerConnection != null) peerConnection.close()
    peerConnection = null

    if (wsConnection != null) wsConnection.close()
    wsConnection = null

    setPeerConnection(peerConnection)
    return true
  }

  function gotIceCandidate(event) {
    if (event.candidate != null) {
      //debug("gotIceCandidate: " + JSON.stringify({ ice: event.candidate }))
    }
  }

  function gotDescription(description) {
    var enhanceData = new Object()

    if (audioBitrate !== undefined)
      enhanceData.audioBitrate = Number(audioBitrate)
    if (videoBitrate !== undefined)
      enhanceData.videoBitrate = Number(videoBitrate)
    if (videoFrameRate !== undefined)
      enhanceData.videoFrameRate = Number(videoFrameRate)

    //description.sdp = enhanceSDP(description.sdp, enhanceData)
    description.sdp = mungeSDPPublish(description.sdp, enhanceData)

    debug("gotDescription: " + JSON.stringify({ sdp: description }))

    return peerConnection
      .setLocalDescription(description)
      .then(() => {
        wsConnection.send(
          '{"direction":"publish", "command":"sendOffer", "streamInfo":' +
            JSON.stringify(streamInfo) +
            ', "sdp":' +
            JSON.stringify(description) +
            ', "userData":' +
            JSON.stringify(userData) +
            "}",
        )
      })
      .catch(err => {
        errorHandler("set local description error: ", err)
      })
  }

  function gotDevices(deviceInfos) {
    var audioArr = []
    var videoArr = []
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i]
      var option = { value: deviceInfo.deviceId }
      if (deviceInfo.kind === "audioinput") {
        option.text = deviceInfo.label
          ? deviceInfo.label.replace(/\(.+\)/, "")
          : "microphone " + (audioSelect.length + 1)
        audioArr.push(option)
      } else if (deviceInfo.kind === "videoinput") {
        option.text = deviceInfo.label
          ? deviceInfo.label.replace(/\(.+\)/, "")
          : "camera " + (videoSelect.length + 1)
        videoArr.push(option)
      } else {
        // console.log("Found another kind of device: ", deviceInfo)
      }
    }
    setAudioSelect(audioArr)
    setVideoSelect(videoArr)
  }

  const errorHandler = (error, data = null) => {
    console.log(`ERROR: ${error}`, data)
  }

  const debug = (message, data = null) => {
    if (isDebug) {
      console.log(`DEBUG: ${message}`, data)
    }
  }

  useEffect(() => {
    pageReady()
    return () => {
      // teardown
    }
  }, [])

  return {
    // proper getters
    // actions
    startPublisher,
    stopPublisher,
    setAudioDevice,
    setVideoDevice,
    setGuestStreamSettings,
    startGuestStream,
    stopGuestStream,
    // state
    userErrorMessage,
    sdpOutput,
    guestStreamConnected,
  }
}

export default useBroadcaster
