import React, { useEffect, useState } from "react"
import { Button } from "../../shared/Button"
import TaggableInput from "../../shared/fields/TaggableInput"
import { PostListItemStyles } from "../../posts/constants"
import { TextAsImage } from "../../posts/TextAsImage"
import { LinkPreview } from "../../posts/LinkPreview"
import { Spinner } from "../../shared/Spinner"
import { useBandPosts } from "../../../hooks/useBandPosts"
import { DateFormat } from "../../../util/dates"
import ImagesUpload from "../../ImagesUpload"
import {
  createHeadlinePost,
  updateHeadlinePost,
  uploadImages,
} from "../../../util/backend_api/posts"
import PostDeleteButton from "../../PostDeleteButton"
import { useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { root_path } from "../../../routes"

const headlineProps = {
  title: "",
  id: null,
  related_post_id: null,
}

const HeadlinePostForm = ({
  bandId,
  post = headlineProps,
  setShowForm = null,
  setNewPost = null,
}) => {
  const [btnActive, setBtnActive] = useState(true)
  const [headlineText, setHeadlineText] = useState(post.original_post_text)
  const [relatedPostId, setRelatedPostId] = useState(post.related_post_id || "")
  const [postId, setPostId] = useState(post.id)
  const [formError, setFormError] = useState(null)
  const [unfurledObj, setUnfurledObj] = useState(null)
  const [imageFiles, setImageFiles] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const queryClient = useQueryClient()
  const [imagePreviews, setImagePreviews] = useState(
    post.attached_media
      ? post.attached_media.map(image => ({
          signedId: image.signed_id,
          filename: image.filename,
          contentTypeGeneric: image.content_type_generic,
          contentTypeSpecific: image.content_type_specific,
          url: image.url,
        }))
      : [],
  )

  const {
    data: relatablePostResult,
    isLoading: isLoadingRelatablePosts,
  } = useBandPosts(bandId, "relatable_content", false, 1, 50)

  const onSubmit = async () => {
    setBtnActive(false)
    const contentHash = { headline_text: headlineText }
    let postType = "headline"
    if (unfurledObj) {
      postType = unfurledObj.postType
      delete unfurledObj.postType
      contentHash.link_preview = unfurledObj
    }
    try {
      let imageUploadSignedIds = []
      if (imagePreviews.length > 0) {
        const uploadResponse = await uploadImages(
          imagePreviews,
          setImagePreviews,
        )
        if (uploadResponse.errors.length > 0) {
          setFormError(uploadResponse.errors.join(", "))
          return
        } else {
          imageUploadSignedIds = uploadResponse.signedIds
          postType = "image_album"
        }
      }
      const response = postId
        ? await updateHeadlinePost(
            bandId,
            postId,
            postType,
            contentHash,
            relatedPostId,
            imageUploadSignedIds,
          )
        : await createHeadlinePost(
            bandId,
            postType,
            contentHash,
            relatedPostId,
            imageUploadSignedIds,
          )
      if (response.error) {
        setFormError(
          response.error.message ? response.error.message : response.error,
        )
      } else if (
        response.post &&
        (response.post.title || response.post.link_preview?.best_title)
      ) {
        // only refetch if this is a brand new post. Edited posts update inline.
        if (!postId) {
          setTimeout(() => {
            queryClient.invalidateQueries(["posts"])
          }, 2500)
        }
        if (setShowForm && setNewPost) {
          setNewPost(response.post)
          setShowForm("")
        } else {
          // this means we are on the dedicated posts page, so redirect to the feed
          toast.success("Thanks for the new Post! (sending you there in...)", {
            autoClose: 2000,
            onClose: () => {
              window.location.href = response.post.nada_url
            },
          })
        }
      }
    } catch (e) {
      console.error(e)
      setFormError("Sorry, there was an unknown error. Please try again.")
      setBtnActive(true)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleHeadlineTextChange(headlineText)
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [headlineText])

  const handleHeadlineTextChange = async newText => {
    if (!newText) return
    setBtnActive(false)
    // regular expression used to check for URLs in the user's headline post text as they're typing it
    // if we find a match, we send the URL to the backend in order to unfurl it and retrieve data that
    //   is necessary for building a link preview of the URL
    const urlRegex = new RegExp(
      /https?:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi,
    )
    const youtubeVideoUrlRegex = /youtube\.com\/watch\?v\=/i
    const dropboxVideoUrlRegex = /^https?:\/\/(?:www\.)?dropbox\.com\/.*\.mp4(?:\?.*)?$/i
    const imageUrlRegex = /(.*\.(png|jpg|gif|jpeg)(\?|$))/i
    const matches = newText.replaceAll("amp;", "").match(urlRegex)

    if (matches) {
      try {
        const response = await fetch(
          `/api/v1/url_unfurl?url=${encodeURIComponent(matches[0])}`,
        )
        const responseJSON = await response.json()
        const link_meta = responseJSON.link_meta
        if (!link_meta.url.match(/https?:\/\//)) {
          link_meta.url = `https://${link_meta.url}`
        }
        if (link_meta.url.match(youtubeVideoUrlRegex)) {
          link_meta.postType = "youtube_video"
        } else if (link_meta.url.match(dropboxVideoUrlRegex)) {
          link_meta.postType = "link_video"
        } else if (link_meta.url.match(imageUrlRegex)) {
          link_meta.best_image = link_meta.url
          link_meta.postType = "link_image"
        } else {
          link_meta.postType = "link"
        }
        setUnfurledObj(link_meta)
      } catch (err) {
        setUnfurledObj(null)
        console.error("error with unfurl: ", err)
      }
    } else {
      setUnfurledObj(null)
    }
    setBtnActive(true)
  }

  const RelatablePostOptionGroup = ({ label, posts, postTypes }) => {
    return (
      <optgroup label={label}>
        {posts
          .filter(p => postTypes.includes(p.post_type))
          .map(relatablePost => (
            <RelatablePostOption
              key={relatablePost.id}
              relatablePost={relatablePost}
            />
          ))}
      </optgroup>
    )
  }

  const RelatablePostOption = ({ relatablePost }) => {
    return (
      <option key={relatablePost.id} value={relatablePost.id}>
        {DateFormat(relatablePost.active_date)}
        &nbsp;-&nbsp;{relatablePost.title}&nbsp;({relatablePost.view_on})
      </option>
    )
  }

  return (
    <>
      <div className="grid grid-cols-12 mb-6">
        <div className={PostListItemStyles.contentDiv}>
          {unfurledObj ? (
            <LinkPreview
              defaultImage={unfurledObj.best_image}
              unfurledObj={unfurledObj}
            />
          ) : btnActive ? (
            <TextAsImage text={headlineText || "Your Headline Here"} />
          ) : (
            <div className="bg-black flex h-full items-center justify-center text-center">
              <Spinner className="text-white" />
            </div>
          )}
        </div>

        <div className={PostListItemStyles.metaDiv}>
          <form>
            <label
              className="block col-span-12 text-gray-900 text-xs uppercase font-bold"
              htmlFor="post"
            >
              Post anything
            </label>
            <TaggableInput
              value={headlineText}
              onChange={val => setHeadlineText(val)}
              placeholder="Add any text and/or links here"
              className="headline-post-form"
            />
            <ImagesUpload
              setImageFiles={setImageFiles}
              imageFiles={imageFiles}
              imagePreviews={imagePreviews}
              setImagePreviews={setImagePreviews}
              isSubmitting={isSubmitting}
            />
            {!isLoadingRelatablePosts &&
            relatablePostResult?.total_count > 0 ? (
              <div className="grid grid-cols-12 mt-2">
                <label className="block col-span-12 text-gray-900 text-xs uppercase font-bold">
                  Related Post? (optional)
                </label>
                <select
                  className="col-span-12 shadow appearance-none border rounded-sm w-full text-base py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={relatedPostId}
                  onChange={e => {
                    setRelatedPostId(e.target.value)
                  }}
                >
                  <option>Choose a related post...</option>
                  <RelatablePostOptionGroup
                    label="albums"
                    posts={relatablePostResult.posts}
                    postTypes={["album"]}
                  />
                  <RelatablePostOptionGroup
                    label="singles"
                    posts={relatablePostResult.posts}
                    postTypes={["stand_alone_song"]}
                  />
                  <RelatablePostOptionGroup
                    label="videos"
                    posts={relatablePostResult.posts}
                    postTypes={["youtube_video", "link_vdeo"]}
                  />
                  <RelatablePostOptionGroup
                    label="shows"
                    posts={relatablePostResult.posts}
                    postTypes={["concert", "event"]}
                  />
                </select>
                <div className="col-span-12 pt-1 text-gray-600 text-sm">
                  Related posts will show up together. e.g. if you are posting a
                  review for a record, select the album post as related
                </div>
              </div>
            ) : null}
            <div className="col-span-12 text-red-700 lg:text-sm text-lg">
              {formError}
            </div>

            <div className="mt-8">
              {post.id ? (
                <>
                  <Button
                    disabled={!btnActive}
                    className="mr-2 mb-1"
                    bg="bg-green-400"
                    onClick={onSubmit}
                  >
                    Update Post
                  </Button>
                  <PostDeleteButton
                    postId={post.id}
                    bandId={bandId}
                    setShowForm={setShowForm}
                    queryKey={["posts"]}
                  />
                </>
              ) : (
                <Button
                  disabled={!btnActive || !headlineText}
                  bg="bg-green-400"
                  onClick={onSubmit}
                  className="mr-2"
                >
                  Make Post
                </Button>
              )}

              <Button
                disabled={!btnActive}
                bg="bg-gray-400"
                onClick={() => (window.location.href = root_path())}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default HeadlinePostForm
