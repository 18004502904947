import React from "react"
import ReactErrorBoundary from "react-error-boundary"
import { ReactComponent as LogoIcon } from "../../icons/bandnada-logo.svg"

const myErrorHandler = (error, componentStack) => {
  // Do something with the error
  // e.g. send it to sentry
}

const MyFallbackComponent = ({ componentStack, error }) => (
  <div className="p-2">
    <LogoIcon className="lg:w-1/12 w-1/3 mb-4" />
    <details>
      <summary>
        <strong>Ruh Roh. We didn't see that coming 😬</strong>
        <p>
          If you continue to get this error please email{" "}
          <strong>support@bandnada.com</strong> explaining what you were trying
          to do when you got this error and please copy and paste the error too.
        </p>
        <p>
          <strong>Error:</strong> {error?.message || "unknown error"}
        </p>
      </summary>

      {location.hostname === "localhost" && (
        <>
          <p>
            <strong>Components:</strong> <pre>{componentStack}</pre>
          </p>
          <p>
            <strong>Stacktrace:</strong> <pre>{error?.stack}</pre>
          </p>
        </>
      )}
    </details>
  </div>
)

export const ErrorBoundary = props => (
  <ReactErrorBoundary
    onError={myErrorHandler}
    FallbackComponent={MyFallbackComponent}
    env={props.env}
    {...props}
  />
)
