import { useQuery } from "react-query"
import { request } from "../util/request"

export function usePromotion() {
  return useQuery("promotion", getPromotion)
}

const getPromotion = async ({ queryKey }) => {
  const [key] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: "/api/v1/promotions.json",
        params: { filter: "active", per_page: 1 },
      },
    ],
  })
}
