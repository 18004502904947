import { createSlice } from "@reduxjs/toolkit"

export const slice = createSlice({
  name: "bandDiscography",
  initialState: {
    value: {
      currentBandId: -1,
      discography: {},
    },
  },
  reducers: {
    addToDiscography: (state, action) => {
      if (action.payload.activeDate && action.payload.bandId && action.payload.songs) {

        if (state.value.currentBandId !== action.payload.bandId) {
          state.value.currentBandId = action.payload.bandId
          state.value.discography = {}
        }

        const activeDate = action.payload.activeDate
        action.payload.songs.forEach((song) => {
          if (!state.value.discography[activeDate])
            state.value.discography[activeDate] = {}
          state.value.discography[activeDate][song.track_number] = song
        })
      }
    },
  },
})

export const {
  addToDiscography,
} = slice.actions

export const selectBandDiscography = state => state.bandDiscography.value.discography

export default slice.reducer
