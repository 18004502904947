import React, { useState } from "react"
import { Spinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import BandPreviewBlock from "./BandPreviewBlock"
import { useUserTags } from "../../hooks/useUserTags"

const TaggedBandScreen = ({
  postId,
  headerText = "Bands On The Bill",
  className = "my-4",
}) => {
  const PER_PAGE = 10
  const [page] = useState(1)
  const [perPage] = useState(PER_PAGE)

  const { isFetching, data, error } = useUserTags(
    postId,
    page,
    perPage,
    "tagged_band",
  )

  return (
    <div className={className}>
      {isFetching ? (
        <Spinner />
      ) : error ? (
        <RequestError error={error} />
      ) : data ? (
        <>
          <ul>
            {data?.user_tags?.length > 0 ? (
              <>
                <h4 className="uppercase font-bold text-gray-700 text-xs">
                  {headerText}
                </h4>

                {data.user_tags.map(
                  user_tag =>
                    user_tag.tagged_band &&
                    !user_tag.tagged_band.is_deleted && (
                      <BandPreviewBlock
                        key={`band-${user_tag.tagged_band.id}`}
                        band={user_tag.tagged_band}
                      />
                    ),
                )}
              </>
            ) : null}
          </ul>
        </>
      ) : null}
    </div>
  )
}

export default TaggedBandScreen
