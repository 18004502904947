import { useQuery } from "react-query"
import { request } from "../util/request"

export function useBandStreamGuestsForBandStream(
  bandStreamId,
  filter = "takeover",
  fields = "guest_band",
) {
  return useQuery(
    ["band_stream_guests", bandStreamId, filter, fields],
    getBandStreamGuestsForBandStream,
  )
}

const getBandStreamGuestsForBandStream = async ({ queryKey }) => {
  const [key, id, filter, fields] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/band_streams/${id}/band_stream_guests.json`,
        params: { filter: filter, fields: fields },
      },
    ],
  })
}
