import React from "react"
import styles from "./Spinner.module.css"
import cx from "classnames"

export const Spinner = props => (
  <div {...props} className={cx(props.className, styles.spinner)} />
)

export const SpinnerSmall = props => (
  <Spinner {...props} className={cx(props.className, styles.spinnerSmall)} />
)

export const FeedSpinner = () => (
  <SpinnerSmall className={"mx-3 my-2 text-gray-500"} />
)
