import { useQuery } from "react-query"
import { request } from "../util/request"

export function useBandStream(bandStreamId, filter = null, fields = null) {
  return useQuery(
    ["band_stream", bandStreamId, filter, fields],
    getBandStreamById,
    {
      enabled: !!bandStreamId,
    },
  )
}

const getBandStreamById = async ({ queryKey }) => {
  const [key, maybeId, filter, fields] = [...queryKey]
  const id = maybeId || 0
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/band_streams/${id}.json`,
        params: { filter: filter, fields: fields },
      },
    ],
  })
}
