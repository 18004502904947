import { useQuery } from "react-query"
import { request } from "../util/request"

export function usePayouts(bandId, page = 1, perPage = 5) {
  return useQuery(["payouts", bandId, page, perPage], getPayoutsForBandId, {
    keepPreviouData: true,
  })
}

const getPayoutsForBandId = async ({ queryKey }) => {
  const [key, id, page, perPage] = [...queryKey]
  return await request({
    queryKey: [
      key,
      {
        path: `/api/v1/bands/${id}/payouts`,
        params: { per_page: perPage, page: page },
      },
    ],
  })
}
