import React from "react"
import { Link } from "react-router-dom"
import { Lightning } from "react-bootstrap-icons"
import { isMobile } from "react-device-detect"

export const BandSearchResult = ({
  bandName,
  bandCoverImageUrl,
  bandId,
  bandPath,
  bandLocation,
}) => {
  return (
    <li className="col-span-1">
      <div>
        {bandCoverImageUrl ? (
          <div
            style={{
              backgroundImage: `url(${bandCoverImageUrl})`,
              backgroundSize: "cover",
              aspectRatio: "1/1",
              minHeight: isMobile ? "156px" : "unset",
            }}
            className="w-full"
          >
            <div
              className="w-full h-full justify-center flex items-center"
              style={{
                background: "rgb(0,0,0,0.3)",
                paddingTop: isMobile ? "36px" : "unset",
                paddingBottom: isMobile ? "36px" : "unset",
              }}
            ></div>
          </div>
        ) : (
          <Lightning
            className="text-white"
            width="50%"
            height="50%"
            style={{
              paddingTop: isMobile ? "36px" : "unset",
              paddingBottom: isMobile ? "36px" : "unset",
            }}
          />
        )}
      </div>
      <>
        <Link to={bandPath} title={bandName}>
          <div className="font-bold leading-tight text-black-800">
            {bandName}
          </div>
        </Link>
        <Link to={bandPath} title={bandName}>
          <div className="text-gray-800 text-sm leading-tight">
            {bandLocation}
          </div>
        </Link>
      </>
    </li>
  )
}

export default BandSearchResult
