import React, { useEffect, useState } from "react"
import FreeTextFormat from "../shared/FreeTextFormat"
import "./Faq.scss"
import { ChevronCompactDown, ChevronCompactUp } from "react-bootstrap-icons"

const Faq = ({ title, content, id }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <div className="faq-row" id={`faq-${id}`}>
        <div className="flex justify-between">
          <button
            className="focus:outline-none font-bold text-gray-800"
            onClick={e => setExpanded(!expanded)}
          >
            {title}
          </button>
          <button
            className="focus:outline-none font-bold"
            onClick={e => setExpanded(!expanded)}
          >
            {expanded ? <ChevronCompactUp /> : <ChevronCompactDown />}
          </button>
        </div>
        {expanded ? (
          <div className="faq-content">
            <FreeTextFormat
              text={content}
              expandable={false}
              expanded={true}
              linkify={true}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}

export default Faq
