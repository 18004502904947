import React, { useEffect } from "react"
import { useParams, Link } from "react-router-dom"
import { StringParam, useQueryParams } from "use-query-params"
import { Spinner } from "../shared/Spinner"
import { BandSocialIcons } from "./BandSocialIcons"
import { RequestError } from "../shared/RequestError"
import { request } from "../../util/request"
import { useQuery } from "react-query"
import { useDispatch } from "react-redux"
import { pushToQueue } from "../../data/mediaPlayerSlice"
import { useBandPosts } from "../../hooks/useBandPosts"
import { compareByKey } from "../../util/utils"
import { useBand } from "../../hooks/useBand"
import { MiniLinkPreview } from "../posts/MiniLinkPreview"

export const BandHighlightsScreen = ({ user = null }) => {
  let { custom_subdomain: bandId } = useParams()
  const [params] = useQueryParams({
    band_id: StringParam,
  })
  if (!bandId) bandId = params[bandId]
  const dispatch = useDispatch()
  const songData = useBandPosts(bandId, "songs")

  const { status: bandStatus, data: bandData, error: bandError } = useBand(
    bandId,
  )
  var band = bandData?.band

  const sortedSongs = () => {
    return [...songData.data.posts]
      .sort((a, b) => compareByKey(a, b, "track_number"))
      .map(song => ({
        ...song,
        album_name: song.album_name,
        cover_image_small: song.cover_image_small,
      }))
  }

  useEffect(() => {
    if (
      songData.data &&
      songData.data.posts &&
      songData.data.posts.length > 0
    ) {
      dispatch(pushToQueue(sortedSongs()))
    }
  }, [songData.data])

  const { data, status } = useQuery(
    [
      "band_highlights",
      {
        path: `/api/v1/bands/${bandId}/posts/highlights`,
      },
    ],
    request,
  )

  return bandStatus === "loading" ? (
    <Spinner />
  ) : (
    <div style={{ width: "100%" }}>
      <div className="grid grid-cols-12 border-b-4 mt-4 mb-4 px-3 border-black">
        <div className="lg:col-span-8 col-span-12 flex">
          <div className="flex-grow">
            <h1 className="font-extrabold text-3xl uppercase leading-none">
              {band.name}
              <span className="block font-normal lg:inline lg:ml-2 text-sm">
                {band.location && band.location.displayName
                  ? band.location.displayName
                  : null}
              </span>
            </h1>
          </div>
        </div>
        <div className="lg:col-span-4 col-span-12 lg:text-right relative">
          <BandSocialIcons band={band} />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center pb-24">
        {status === "loading" ? (
          <Spinner className="text-gray-500" />
        ) : status === "error" ? (
          <RequestError error={data.error} />
        ) : (
          data.posts.map(post => (
            <div className="w-full" key={post.id}>
              <div className="m-3">
                <MiniLinkPreview post={post} />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}
