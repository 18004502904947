import React, { useState } from "react"
import { SyncSummary } from "./SyncSummary"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const Soundcloud = ({ band }) => {
  const [url, setUrl] = useState(band.soundcloud_url || "")

  const fieldData = {
    field: "soundcloud_url",
    serviceClass: "SoundcloudService",
    name: "soundcloud",
    label: "Your Soundcloud URL",
    placeholder: "Soundcloud URL",
    notes:
      "we sync all of your soundcloud releases and display them on your page so your fans can see and listen to your recorded music on bandNada. Your url should look like https://soundcloud.com/theblackblack",
    required: false,
  }

  return (
    <>
      <EditBandFormTextField
        fieldData={fieldData}
        value={url}
        setValue={setUrl}
        bandId={band.id}
      />
      <SyncSummary fieldData={fieldData} bandId={band.id} value={url} />
    </>
  )
}
