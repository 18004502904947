import React, { useState, useEffect, useMemo } from "react"
import { ActionCableConsumer } from "react-actioncable-provider"
import { Spinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { useComments } from "../../hooks/useComments"
import { Comment } from "./Comment"
import CommentForm from "./CommentForm"

const CommentScreen = ({
  postId,
  headerText = "Comments",
  className = "my-4",
}) => {
  const PER_PAGE = 5
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(2)
  const [currentCount, setCurrentCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [_comments, setComments] = useState([])
  const addComment = comment => {
    setComments(allComments => [...allComments, comment])
  }

  const comments = useMemo(() => _comments, [_comments])

  const { isLoading, isError, isFetching, data, error } = useComments(
    postId,
    page,
    perPage,
  )

  useEffect(() => {
    if (!isLoading && !isError && !isFetching) {
      if (page === 1) {
        setComments(data.comments)
        setCurrentCount(data.comments.length)
      } else {
        setComments([...data.comments, ...comments])
        setCurrentCount(data.comments.length + currentCount)
      }
      setTotalCount(data.total_count)
    }
  }, [isFetching])

  const handleReceivedMessage = newComment => {
    if (newComment.comment) {
      if (!comments.some(el => el.id === newComment.comment.id)) {
        setCurrentCount(prevCount => prevCount + 1)
        setTotalCount(prevCount => prevCount + 1)
        addComment(newComment.comment)
      }
    }
  }

  const showMore = e => {
    if (perPage !== PER_PAGE) {
      setPerPage(PER_PAGE)
    } else {
      setPage(curPage => curPage + 1)
    }
  }

  const postChannelDisconnected = error => {
    console.error("PostChannel disconnected: ", error)
  }

  return (
    <div className={className}>
      {isFetching ? (
        <Spinner />
      ) : error ? (
        <RequestError error={error} />
      ) : data ? (
        <>
          <ActionCableConsumer
            channel={{ channel: "PostChannel", id: postId }}
            onReceived={handleReceivedMessage}
            onDisconnected={postChannelDisconnected}
          />
          <h4 className="uppercase font-bold text-gray-700 text-xs">
            {headerText}{" "}
            {currentCount > 0 ? (
              <>
                <span className="font-normal">
                  ({currentCount}/{totalCount})
                </span>
                {currentCount < totalCount ? (
                  <span>
                    <button
                      className="pl-2 text-blue-500 focus:outline-none"
                      onClick={showMore}
                    >
                      show more
                    </button>
                  </span>
                ) : null}
              </>
            ) : null}
          </h4>
          <ul>
            {comments.length > 0
              ? comments.map(comment => (
                  <Comment key={`comment-${comment.id}`} comment={comment} />
                ))
              : null}
          </ul>

          <div className="">
            <div className="grid grid-cols-12">
              <div className="col-span-12">
                <CommentForm postId={postId} />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default CommentScreen
