import React from "react"
import { CoverPhotoUploadSection } from "../BandEditPage"
import { Songkick } from "./Songkick"
import { Youtube } from "./Youtube"
import { Soundcloud } from "./Soundcloud"
import { Bandcamp } from "./Bandcamp"
import { Spotify } from "./Spotify"
import { Bandsintown } from "./Bandsintown"
import { Instagram } from "./Instagram"
import { Facebook } from "./Facebook"
import { BandName } from "./BandName"
import { EmailAddress } from "./EmailAddress"
import { MailingList } from "./MailingList"
import { Link } from "react-router-dom"
import { BandLocation } from "./BandLocation"
import { BandType } from "./BandType"
import { BandScene } from "./BandScene"
import { WebsiteUrl } from "./WebsiteUrl"
import { BandBio } from "./BandBio"

export const BandEditForm = ({ band, isOnboarding = false }) => {
  const friendlyBandUrl = (band) =>
    `https://${band.custom_subdomain}.bandnada.com`

  return (
    <>
      <BandName band={band} />
      {isOnboarding && band.name && (
        <>
          <h2 className="mt-2">
            Awesome. We've got your bandNada page setup for you at{" "}
            <Link to={band.band_path} className="text-blue-600">
              {friendlyBandUrl(band)}
            </Link>
            . Fill out as many urls below as you can, and we'll sync your
            albums/videos/shows to your page automatically.
          </h2>
          <p className="text-sm text-gray-600">
            it stays synced when you add stuff to bandcamp/youtube/etc.
          </p>
        </>
      )}

      <BandType band={band} />
      <BandLocation band={band} />
      <BandScene band={band} />
      <BandBio band={band} />
      <EmailAddress band={band} />
      <MailingList band={band} />
      <WebsiteUrl band={band} />
      <Instagram band={band} />
      <Facebook band={band} />
      {/* <Template band={band} /> */}
      <CoverPhotoUploadSection band={band} />
      <h2 className="lg:col-span-8 col-span-12 font-bold border-b mb-4 border-black lg:text-2xl text-xl uppercase mt-10">
        Music
      </h2>
      <Bandcamp band={band} />
      <Soundcloud band={band} />
      <Spotify band={band} />
      <h2 className="lg:col-span-8 col-span-12 font-bold border-b mb-4 border-black lg:text-2xl text-xl uppercase mt-10">
        Videos
      </h2>
      <Youtube band={band} />
      <h2 className="lg:col-span-8 col-span-12 font-bold border-b mb-4 border-black lg:text-2xl text-xl uppercase mt-10">
        Events
      </h2>
      <Bandsintown band={band} />
      <Songkick band={band} />
    </>
  )
}
