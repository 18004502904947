import { mutationRequest } from "../util/request"

export const postUserInteraction = async (
  bandId,
  bandStreamId,
  referrer = null,
  interactionType = "page_view",
) => {
  mutationRequest({
    options: { method: "POST" },
    path: `/api/v1/bands/${bandId}/user_interactions`,
    data: {
      user_interaction: {
        interaction_type: interactionType,
        band_stream_id: bandStreamId,
        referrer: referrer,
      },
    },
  })
}

export const postUserMediaInteraction = async (
  bandId,
  interactionType = "media_play",
  postId,
  postType,
  interactionResult = null,
) => {
  mutationRequest({
    options: { method: "POST" },
    path: `/api/v1/bands/${bandId}/user_interactions`,
    data: {
      user_interaction: {
        interaction_type: interactionType,
        post_id: postId,
        post_type: postType,
        interaction_result: interactionResult,
      },
    },
  })
}
