import React from "react"
import { BandSocialIcons } from "../BandSocialIcons"
import styles from "./styles.module.css"

const BandFooter = ({ band }) => {
  return (
    <footer className={styles.footer}>
      {band ? (
        <>
          <BandSocialIcons
            band={band}
            margins="justify-center mt-1"
            color="text-white"
          />
          <p className="mt-1">{band.name}</p>
          <p>&copy; {new Date().getFullYear()}</p>
        </>
      ) : null}
    </footer>
  )
}

export default BandFooter
