import React from "react"
import { RequestError } from "../shared/RequestError"
import { FeedSpinner } from "../shared/Spinner"
import Heading from "../shared/Heading"
import { AlbumSearchResult } from "./AlbumSearchResult"
import { ConcertSearchResult } from "./ConcertSearchResult"
import { SongSearchResult } from "./SongSearchResult"
import VenueSearchResult from "./VenueSearchResult"
import BandSearchResult from "./BandSearchResult"
import { useInfiniteSearchResults } from "../../hooks/useSearchResults"

export const SearchResults = ({ showFollow = false, cols = 1, query = "" }) => {
  const { data, error, status } = useInfiniteSearchResults({
    query: query,
    pageSize: 12,
  })

  return status === "loading" ? (
    <FeedSpinner />
  ) : status === "error" ? (
    <RequestError error={error} />
  ) : (
    data.pages.map((page, i) =>
      page.total_count === 0 ? (
        <>
          <Heading level={2} type="sectionHeader">
            Search Results
          </Heading>
          "No results"
        </>
      ) : (
        <React.Fragment key={page.page}>
          {page.bands.length > 0 && (
            <div className="mb-6">
              <Heading level={2} type="sectionHeader">
                Bands
              </Heading>
              <ul className={`grid grid-cols-${cols} gap-8`}>
                {page.bands.map(band => (
                  <BandSearchResult
                    key={band.id}
                    bandId={band.id}
                    bandName={band.name}
                    bandPath={band.band_path}
                    bandCoverImageUrl={
                      band.cover_image_url || band.cover_photo_url_square
                    }
                    bandLocation={
                      band.location ? band.location.displayName : null
                    }
                  />
                ))}
              </ul>
            </div>
          )}
          {page.concerts.length > 0 && (
            <div className="mb-6">
              <Heading level={2} type="sectionHeader">
                Concerts
              </Heading>
              <ul className={`grid grid-cols-${cols} gap-8`}>
                {page.concerts.map(concert => (
                  <ConcertSearchResult key={concert.id} concert={concert} />
                ))}
              </ul>
            </div>
          )}
          {page.venues.length > 0 && (
            <div className="mb-6">
              <Heading level={2} type="sectionHeader">
                Venues
              </Heading>
              <ul className={`grid grid-cols-${cols} gap-8`}>
                {page.venues.map(venue => (
                  <VenueSearchResult
                    key={venue.id}
                    bandId={venue.id}
                    bandName={venue.name}
                    bandPath={venue.band_path}
                    bandCoverImageUrl={venue.cover_photo_url_square}
                    bandLocation={
                      venue.location ? venue.location.displayName : null
                    }
                  />
                ))}
              </ul>
            </div>
          )}
          {page.albums.length > 0 && (
            <div className="mb-6">
              <Heading level={2} type="sectionHeader">
                Albums
              </Heading>
              <ul className={`grid grid-cols-${cols} gap-8`}>
                {page.albums.map(album => (
                  <AlbumSearchResult key={album.id} album={album} />
                ))}
              </ul>
            </div>
          )}
          {page.songs.length > 0 && (
            <>
              <Heading level={2} type="sectionHeader">
                Songs
              </Heading>
              <ul className={`grid grid-cols-${cols} gap-8`}>
                {page.songs.map(song => (
                  <SongSearchResult key={song.id} song={song} />
                ))}
              </ul>
            </>
          )}
          {page.blogs.length > 0 && (
            <div className="mb-6">
              <Heading level={2} type="sectionHeader">
                Blogs
              </Heading>
              <ul className={`grid grid-cols-${cols} gap-8`}>
                {page.blogs.map(blog => (
                  <VenueSearchResult
                    key={blog.id}
                    bandId={blog.id}
                    bandName={blog.name}
                    bandPath={blog.band_path}
                    bandCoverImageUrl={blog.cover_photo_url_square}
                    bandLocation={
                      blog.location ? blog.location.displayName : null
                    }
                  />
                ))}
              </ul>
            </div>
          )}
          {page.promoters.length > 0 && (
            <div className="mb-6">
              <Heading level={2} type="sectionHeader">
                Promoters
              </Heading>
              <ul className={`grid grid-cols-${cols} gap-8`}>
                {page.promoters.map(promoter => (
                  <VenueSearchResult
                    key={promoter.id}
                    bandId={promoter.id}
                    bandName={promoter.name}
                    bandPath={promoter.band_path}
                    bandCoverImageUrl={promoter.cover_photo_url_square}
                    bandLocation={
                      promoter.location ? promoter.location.displayName : null
                    }
                  />
                ))}
              </ul>
            </div>
          )}
        </React.Fragment>
      ),
    )
  )
}

export default SearchResults
